import { TableCell, TableRow } from "@mui/material";
import { DownloadButton } from "@stories/atoms/DownloadButton/DownloadButton";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { getDocumentName } from "permit-one-common/src/utils/string";
import { DeleteButton } from "@stories/atoms/DeleteButton/DeleteButton";
import { PermitInspectionLineItem } from "permit-one-common/src/interfaces/permitInspection";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";

interface InspectionTableRowProps {
  selected: boolean;
  inspection: PermitInspectionLineItem;
  isDownloading: boolean;
  selectedId?: string;
  downloadProgress: number;
  setSelectedId: (id: string) => void;
  onDownload: (uri: string, inspectionName: string) => void;
  deletePermitInspection: (inspection: PermitInspectionLineItem) => void;
}

export const InspectionTableRow = ({
  inspection,
  selected,
  isDownloading,
  selectedId,
  downloadProgress,
  setSelectedId,
  onDownload,
  deletePermitInspection,
}: InspectionTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell align="center">{inspection.inspectionName}</TableCell>
        <TableCell align="center">
          {inspection.created.toLocaleDateString()}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          {isDownloading && inspection.id === selectedId ? (
            <CircularProgressWithLabel value={downloadProgress} />
          ) : (
            <DownloadButton
              disabled={isDownloading}
              onClick={() => {
                setSelectedId(inspection.id);
                onDownload(
                  inspection.url,
                  getDocumentName(inspection.inspectionName, inspection.url)
                );
              }}
            />
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          <DeleteButton
            disabled={isDownloading}
            onClick={() => {
              setOpenConfirmDialog(true);
            }}
          />
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Do you want to delete the inspection?"}
        open={openConfirmDialog}
        title={"Delete Inspection"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => deletePermitInspection(inspection)}
      />
    </>
  );
};
