import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Grid, Typography } from "@mui/material";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { OrganisationForm } from "@stories/organisms/OrganisationForm/OrganisationForm";

export const BusinessAccount = () => {
  const { user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const {
    isAuthProfileLoading,
    userProfile,
    userOrganisation,
    updateUserOrganisation,
  } = useProfileContext();

  const handleSubmit = async (organisation: OrganisationLineItem) => {
    if (userProfile) {
      await updateUserOrganisation(organisation, userProfile);
    }
  };

  const loading = isAuthProfileLoading;
  return (
    <>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0 0 40px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{ fontSize: "28px !important", fontWeight: "700" }}
        >
          Business Account
        </Typography>
      </Grid>
      <OrganisationForm
        organisation={userOrganisation}
        handleSubmit={handleSubmit}
        loading={loading}
      />
    </>
  );
};
