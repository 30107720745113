import { IconButton, TableCell, TableRow } from "@mui/material";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import DeleteIcon from "@mui/icons-material/Delete";
interface CommentTableRowProps {
  conditionComment: ConditionCommentLineItem;
  deleteComment: (comment: ConditionCommentLineItem) => void;
}

export const CommentTableRow = ({
  conditionComment,
  deleteComment,
}: CommentTableRowProps) => {
  return (
    <TableRow>
      <TableCell>{conditionComment.comment}</TableCell>
      <TableCell>{`${conditionComment.firstName} ${conditionComment.lastName}`}</TableCell>
      <TableCell align="right" sx={{ textAlign: "center !important" }}>
        <IconButton
          color="error"
          size="large"
          onClick={() => {
            deleteComment(conditionComment);
          }}
        >
          <DeleteIcon
            sx={{
              fontSize: "1.1rem",
            }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
