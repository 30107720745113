import { IconButton, Tooltip, Zoom, useTheme } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
interface DeleteButtonProps {
  disabled: boolean;
  onClick: () => void;
}
export const DeleteButton = ({ disabled, onClick }: DeleteButtonProps) => {
  const theme = useTheme();
  return (
    <Tooltip TransitionComponent={Zoom} placement="top" title="Delete">
      <IconButton
        color="primary"
        size="large"
        disabled={disabled}
        onClick={onClick}
        sx={{
          color: theme.palette.error.main,
          backgroundColor: theme.palette.error.light,
        }}
      >
        <DeleteForeverIcon
          sx={{
            fontSize: "1.1rem",
          }}
        />
      </IconButton>
    </Tooltip>
  );
};
