import {
  Button,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  useTheme,
} from "@mui/material";
import AttachmentTwoToneIcon from "@mui/icons-material/AttachmentTwoTone";
import { gridSpacing } from "constant";
import SubCard from "@stories/ui-component/cards/SubCard";
import { QuillEditor } from "@stories/atoms/QuillEditor/QuillEditor";

const MailInputSkeleton = () => {
  const theme = useTheme();
  return (
    <>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <SubCard
            sx={{
              bgcolor: theme.palette.primary[200] + 40,
              "& .quill": {
                bgcolor: "grey.50",
                borderRadius: "12px",
                "& .ql-font-roboto": {
                  fontFamily: "Roboto, sans-serif",
                },
                "&.ql-editor p": {
                  fontFamily: "Roboto, sans-serif",
                },
                "& .ql-toolbar": {
                  bgcolor: "grey.100",
                  borderColor: theme.palette.primary.light,
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& .ql-container": {
                  fontFamily: "Roboto, sans-serif",
                  fontSize: "0.875rem",
                  borderColor: theme.palette.primary.light,
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  "& .ql-editor": {
                    minHeight: 225,
                  },
                },
              },
            }}
          >
            <Skeleton animation="wave" width={"100%"} height={"100%"}>
              <QuillEditor loading={false} />
            </Skeleton>
            <Grid container spacing={1} alignItems="center" sx={{ mt: 2 }}>
              <Grid item>
                <Stack direction="row">
                  <Skeleton animation="wave">
                    <IconButton size="large">
                      <AttachmentTwoToneIcon />
                    </IconButton>
                  </Skeleton>
                </Stack>
              </Grid>
              <Grid item sx={{ flexGrow: 1 }} />
              <Grid item>
                <Skeleton animation="wave">
                  <Button variant="contained">Send</Button>
                </Skeleton>
              </Grid>
            </Grid>
          </SubCard>
        </Grid>
      </Grid>
    </>
  );
};

export default MailInputSkeleton;
