import { ConditionLineItem, ConditionStatus } from "permit-one-common/src/interfaces/condition";
import { useTable } from "@hooks/utils/useTable";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { Grid, Table, TableBody, Box } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import ListTableToolbar from "@stories/molecules/ListTable/ListTableToolbar";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { TaskTableRow } from "./TaskTableRow";
import { TaskTableRowSkeleton } from "./TaskTableRowSkeleton";
import { ListConditionsTabs } from "@stories/molecules/ListConditionsTabs/ListConditionsTabs";

interface TaskTableProps {
  loading: boolean;
  selectedConditionStatus: number;
  conditions: ConditionLineItem[];
  setConditionStatus: (status: number) => void;
  handleViewCondition: (condition: ConditionLineItem) => void;
}
export const TaskTable = ({
  loading,
  conditions,
  selectedConditionStatus,
  handleViewCondition,
  setConditionStatus,
}: TaskTableProps) => {
  const TABLE_HEAD: TableHeaderCell[] = [
    { id: "task-status", label: "Status", alignRight: false },
    { id: "permit-name", label: "Permit Name", alignRight: false },
    { id: "condition-name", label: "Number", alignRight: false },
    { id: "action-required", label: "Action Required", alignRight: false },
    { id: "assignee", label: "Assignee", alignRight: false },
    { id: "discipline", label: "Discipline", alignRight: false },
    { id: "Due Date", label: "Due Date", alignRight: false },
    { id: "" },
  ];

  const {
    selected,
    filterName,
    clearSelected,
    handleSelectAllClick,
    handleFilterByName,
  } = useTable();

  let filteredConditions =
    filterName.length > 0
      ? conditions.filter(
          (t) =>
            t.conditionName.toLowerCase() === filterName.toLocaleLowerCase()
        )
      : conditions;

  filteredConditions = filteredConditions.filter((c) => {
    switch (selectedConditionStatus) {
      default:
      case 0:
        return true;
      case 1:
        return (
          c.status === ConditionStatus.Assigned ||
          c.status === ConditionStatus.Rejected
        );
      case 2:
        return c.status === ConditionStatus.Overdue;
      case 3:
        return c.status === ConditionStatus.InReview;
      case 4:
        return c.status === ConditionStatus.Approved;
    }
  });

  const isNotFound = !filteredConditions.length && !!filterName;

  return (
    <>
      <Box sx={{ padding: "0", margin: "15px 0 20px" }}>
        <ListTableToolbar
          loading={loading}
          handleOpen={() => {
            console.log("Not implemented");
          }}
          searchTitle="Search Tasks..."
          numSelected={selected.length}
          filterName={filterName}
          clearSelected={clearSelected}
          onFilter={handleFilterByName}
        />
      </Box>

      <Grid item md={12} sx={{ padding: "0 0px 20px" }}>
        <ListConditionsTabs
          loading={loading}
          value={selectedConditionStatus}
          handleChange={(newValue: number) => {
            setConditionStatus(newValue);
          }}
        />
      </Grid>

      <Table stickyHeader aria-label="sticky table" className="project-table">
        <ListTableHead
          headLabel={TABLE_HEAD}
          loading={loading}
          rowCount={filteredConditions.length}
          numSelected={selected.length}
          showCheckbox={false}
          onSelectAllClick={(e: any) =>
            handleSelectAllClick(
              e,
              filteredConditions.map((u) => u.id)
            )
          }
        />

        <TableBody>
          {!loading
            ? filteredConditions
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const selectedTask = selected.indexOf(row.id) !== -1;

                  return (
                    <TaskTableRow
                      condition={row}
                      selectedTask={selectedTask}
                      handleViewCondition={handleViewCondition}
                    />
                  );
                })
            : null}
          {loading
            ? Array.from({ length: 6 }, (_, index) => (
                <TaskTableRowSkeleton key={index} />
              ))
            : null}
          {isNotFound && (
            <NoResultsFoundRow
              colSpan={TABLE_HEAD.length + 1}
              filterName={filterName}
            />
          )}
          {filteredConditions.length === 0 && !loading ? (
            <NoRows colSpan={TABLE_HEAD.length + 1} title={"Tasks"} />
          ) : null}
        </TableBody>
      </Table>
    </>
  );
};
