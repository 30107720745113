import { NavItemType } from "types";
import * as RouteHelper from "@utils/routes";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import RecentActorsIcon from "@mui/icons-material/RecentActors";

export const getDirectoryNav = (projectId?: string): NavItemType => {
  const theme = useTheme();
  return {
    id: "directory",
    title: "Directory",
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    type: "group",
    children: [
      {
        id: "directory",
        title: "Project List",
        type: "item",
        icon: (
          <RecentActorsIcon style={{ color: theme.palette.primary.main }} />
        ),
        url: projectId ? RouteHelper.directory(projectId) : "",
      },
      {
        id: "global-directory",
        title: "Global Network",
        type: "item",
        icon: (
          <TravelExploreIcon style={{ color: theme.palette.primary.main }} />
        ),
        url: projectId ? RouteHelper.projectGlobalDirectory(projectId) : "",
      },
    ],
  };
};
