import { TableCell, TableRow, Skeleton, Button } from "@mui/material";

function PublicEditableTableRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" animation="wave">
          <Button>Button</Button>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
}

export default PublicEditableTableRowSkeleton;
