import { useStripe } from "@hooks/crud/useStripe";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { Box, Grid } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect } from "react";
import PricingCard from "./PricingCard";

interface BillingFormProps {
  loading: boolean;
  profile?: ProfileLineItem;
  organisation?: OrganisationLineItem;
  success: boolean;
  cancelled: boolean;
}

const stripe_key = "pk_test_JnqbQaHNT8HAWBT4hEiTQ72N";

export const BillingForm = ({
  profile,
  organisation,
  success,
  cancelled,
}: BillingFormProps) => {
  const { isStripeLoading, getPaymentIntent, createBillingPortalSession } =
    useStripe();

  const handleBilling = async () => {
    if (!profile) {
      return;
    }
    const stripePromise = loadStripe(stripe_key);
    const stripe = await stripePromise;
    if (!stripe) {
      throw new Error("Stripe not loaded");
    }
    const billingPortalSession = await createBillingPortalSession();
    if (!billingPortalSession) {
      return;
    }
    window.location.href = billingPortalSession.url;
  };

  const handleSubmit = async (priceId: string) => {
    if (!profile) {
      return;
    }
    const stripePromise = loadStripe(stripe_key);
    const stripe = await stripePromise;
    if (!stripe) {
      throw new Error("Stripe not loaded");
    }
    const paymentIntent = await getPaymentIntent(priceId);
    if (!paymentIntent) {
      return;
    }
    await stripe.redirectToCheckout({ sessionId: paymentIntent.sessionId });
  };

  useEffect(() => {
    if (success) {
      alert("You have subscibed successfully!");
    }
    if (cancelled) {
      alert("You have cancelled succesfully!");
    }
  }, [success, cancelled]);

  return (
    <>
      <Box
        sx={{
          padding: "40px",
          display: "flex",
          alignItems: "center",
          margin: "0 !important",
        }}
      >
        {organisation?.planActive ? (
          <Grid container spacing={3}>
            <Grid
              item
              md={12}
              sx={{
                display: "flex !important",
                justifyContent: "center !important;",
              }}
            >
              <StyledButton
                loading={false}
                color="primary"
                variant="contained"
                type="submit"
                disabled={isStripeLoading}
                onClick={handleBilling}
              >
                Manage Subscription
              </StyledButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid
              item
              md={6}
              sx={{
                display: "flex !important",
                justifyContent: "center !important;",
              }}
            >
              <PricingCard
                title="Basic"
                price={100}
                description="Basic subscription"
                disabled={isStripeLoading}
                handleBuyNow={async () => {
                  await handleSubmit("price_1P3CaTIwdGs5wETBiiHsvl3k");
                }}
              />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: "flex !important",
                justifyContent: "center !important;",
              }}
            >
              <PricingCard
                title="Pro"
                price={1000}
                description="Pro subscription"
                disabled={isStripeLoading}
                handleBuyNow={async () => {
                  await handleSubmit("price_1P3CanIwdGs5wETBstsPmZqx");
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};
