import {
  TableCell,
  TableRow,
  Skeleton,
  Button,
  Chip,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function EditableTableRowSkeleton() {
  return (
    <TableRow>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton variant="text" animation="wave" />
      </TableCell>
      <TableCell>
        <Skeleton animation="wave" sx={{ width: "80%", margin: "auto" }}>
          <Chip label="Not Assigned" />
        </Skeleton>
      </TableCell>
      <TableCell>
        <Skeleton variant="rounded" animation="wave">
          <Button>Button</Button>
        </Skeleton>
      </TableCell>
      <TableCell>
        <Skeleton variant="circular" animation="wave">
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </Skeleton>
      </TableCell>
    </TableRow>
  );
}

export default EditableTableRowSkeleton;
