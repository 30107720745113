import { ConditionSummary } from "@stories/organisms/Summary/ConditionSummary";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ConditionTabs } from "@stories/molecules/ConditionsTab/ConditionsTab";
import { PermitEventLog } from "@stories/organisms/PermitEventLog/PermitEventLog";
import {
  Box,
  Breadcrumbs,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { InspectionTable } from "@stories/organisms/Tables/InspectionTable/InspectionTable";
import { FeeTable } from "@stories/organisms/Tables/FeeTable/FeeTable";
import { LodgementTable } from "@stories/organisms/Tables/LodgementTable/LodgementTable";
import { MailCenter } from "@stories/organisms/Mail/MailCenter";
import { PermitCollaborators } from "@stories/organisms/PermitCollaborators/PermitCollaborators";
import { PermitProperties } from "@stories/organisms/PermitProperties/PermitProperties";
import { usePublicConditions } from "@hooks/crud/usePublicConditions";
import { PublicConditionTable } from "@stories/organisms/Tables/PublicConditionTable/PublicConditionTable";

export const PublicConditions = (): JSX.Element => {
  const { accessId } = useParams();

  const {
    publicConditionPageModel,
    isConditionLoading,
    createDocuments,
    createComments,
    sendForReview,
    uploadDocument,
    downloadStepPDF,
    PDFDownloadProgress,
    getPdfReport,
    getExcelReport,
    downloadStepExcel,
    excelDownloadProgress,
    getFullReportInfo,
    downloadStepAllFiles,
    allFilesDownloadProgress,
    error,
  } = usePublicConditions(accessId);
  const [selectedTab, setSelectedTab] = useState(0);

  const loading = isConditionLoading;

  const selectedConditionStep = (() => {
    switch (selectedTab) {
      default:
      case 0:
        return (
          <PublicConditionTable
            loading={loading}
            userProfile={publicConditionPageModel?.userProfile}
            userOrganisation={publicConditionPageModel?.userOrganisation}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
            conditions={publicConditionPageModel?.conditions || []}
            documents={publicConditionPageModel?.documents || []}
            createDocuments={createDocuments}
            createComments={createComments}
            sendForReview={sendForReview}
            uploadDocument={uploadDocument}
            downloadStepPDF={downloadStepPDF}
            PDFDownloadProgress={PDFDownloadProgress}
            getPdfReport={getPdfReport}
            getExcelReport={getExcelReport}
            downloadStepExcel={downloadStepExcel}
            excelDownloadProgress={excelDownloadProgress}
            getFullReportInfo={getFullReportInfo}
            downloadStepAllFiles={downloadStepAllFiles}
            allFilesDownloadProgress={allFilesDownloadProgress}
          />
        );
      case 1:
        return (
          <PermitProperties
            loading={loading}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 2:
        return (
          <MailCenter
            isLoading={loading}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
            profile={publicConditionPageModel?.userProfile}
            showDialog={false}
          />
        );
      case 3:
        return (
          <PermitCollaborators
            loading={loading}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 4:
        return (
          <PermitEventLog
            loading={loading}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 5:
        return (
          <InspectionTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 6:
        return (
          <FeeTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
      case 7:
        return (
          <LodgementTable
            permitId={publicConditionPageModel?.permit.id}
            project={publicConditionPageModel?.project}
            permit={publicConditionPageModel?.permit}
          />
        );
    }
  })();

  return (
    <Box>
      {loading ? (
        <Grid
          className="borderBottom"
          container
          spacing={2}
          sx={{
            padding: "20px 40px 20px 40px",
            margin: "0 0 40px",
            width: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid
            item
            sx={{ padding: "0px !important", alignItems: "center" }}
            md={6}
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Skeleton animation="wave">
                <Typography
                  variant="h1"
                  component="div"
                  sx={{
                    fontSize: "22px !important",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                >
                  {"Permit Name"}
                </Typography>
              </Skeleton>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className="borderBottom"
            container
            spacing={2}
            sx={{
              padding: "20px 40px 20px 40px",
              margin: "0 0 40px",
              width: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid
              item
              sx={{ padding: "0px !important", alignItems: "center" }}
              md={6}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="text.primary">
                    {publicConditionPageModel?.project?.projectName}
                  </Typography>
                  <Typography color="text.primary">
                    {publicConditionPageModel?.permit?.permitName}
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}

      <Box sx={{ padding: "10px 40px 30px" }}>
        <ConditionSummary
          conditionCounts={publicConditionPageModel?.conditionCounts}
          project={publicConditionPageModel?.project}
          permitId={publicConditionPageModel?.permit?.id}
          loading={loading}
        />
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            background: "#fff",
            borderRadius: "20px",
          }}
        >
          <Grid
            container
            sx={{
              padding: "0",
              margin: "40px 0 30px",
              borderRadius: "0",
              width: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid item md={12} sx={{ padding: "0 0 20px !important" }}>
              <ConditionTabs
                loading={loading}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </Grid>
            <Grid item md={12} sx={{ padding: "0 !important" }}>
              {selectedConditionStep}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
