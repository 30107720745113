import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

interface TextRowProps {
  loading: boolean;
  title: string;
  text: string | number | ReactNode;
  children?: React.ReactNode;
}

export const TextRow = ({ loading, title, text, children }: TextRowProps) => {
  return (
    <Grid container spacing={2} sx={{ padding: "0 ", margin: "0 !important" }}>
      <Grid item xs={4} md={3} sx={{ padding: "0 !important" }}>
        {loading ? (
          <Skeleton animation="wave" sx={{ marginRight: "5px" }} />
        ) : (
          <Typography variant="body1">
            <strong>{title}</strong>
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={8}
        md={9}
        className="right-side-table"
        sx={{
          padding: "7px 11px!important",
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, .08)",
        }}
      >
        {loading ? (
          <Skeleton animation="wave" width={200} />
        ) : (
          <>
            <Typography variant="body1">{text}</Typography>
            {children}
          </>
        )}
      </Grid>
    </Grid>
  );
};
