"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeProjectSector = exports.encodeProjectSector = exports.encodeProject = exports.decodeProject = void 0;
var project_1 = require("../interfaces/project");
var string_1 = require("../utils/string");
var organisation_1 = require("./organisation");
var decodeProject = function (project) { return __awaiter(void 0, void 0, void 0, function () {
    var safeProject;
    return __generator(this, function (_a) {
        safeProject = (0, string_1.reversePostgresSafe)(project);
        return [2 /*return*/, {
                id: safeProject.project_id,
                organisationId: safeProject.organisation_id,
                projectName: safeProject.project_name,
                projectCode: safeProject.project_code,
                projectSector: (0, exports.decodeProjectSector)(safeProject.project_sector),
                projectSectorOther: safeProject.project_sector_other,
                projectDescription: safeProject.project_description,
                streetNumber: safeProject.street_number,
                streetName: safeProject.street_name,
                suburb: safeProject.suburb,
                state: safeProject.state,
                country: safeProject.country,
                postCode: safeProject.post_code,
                lotNumber: safeProject.lot_number,
                dpsMpsNumber: safeProject.dps_mps_number,
                developer: safeProject.developer,
                contractor: safeProject.contractor,
                estimatedProjectCost: safeProject.estimated_project_cost,
                startDate: safeProject.start_date
                    ? new Date(safeProject.start_date)
                    : undefined,
                completionDate: safeProject.completion_date
                    ? new Date(safeProject.completion_date)
                    : undefined,
                totalPermits: safeProject.total_permits,
                activePermits: safeProject.active_permits,
                archivedPermits: safeProject.archived_permits,
                inReviewPermits: safeProject.in_review_permits,
                created: new Date(safeProject.created),
                archived: safeProject.archived,
                collaborators: [],
                owner: safeProject.owner
                    ? (0, organisation_1.decodeOrganisation)(safeProject.owner)
                    : undefined,
                estimatedProgress: 0,
            }];
    });
}); };
exports.decodeProject = decodeProject;
var encodeProject = function (project) {
    var safeProject = (0, string_1.makePostgresSafe)(project);
    return {
        project_id: safeProject.id,
        organisation_id: safeProject.organisationId,
        project_name: safeProject.projectName,
        project_code: safeProject.projectCode,
        project_sector: (0, exports.encodeProjectSector)(safeProject.projectSector),
        project_sector_other: safeProject.projectSectorOther,
        project_description: safeProject.projectDescription,
        street_number: safeProject.streetNumber,
        street_name: safeProject.streetName,
        suburb: safeProject.suburb,
        state: safeProject.state,
        country: safeProject.country,
        post_code: safeProject.postCode,
        lot_number: safeProject.lotNumber,
        dps_mps_number: safeProject.dpsMpsNumber,
        estimated_project_cost: safeProject.estimatedProjectCost,
        developer: safeProject.developer,
        contractor: safeProject.contractor,
        start_date: safeProject.startDate,
        completion_date: safeProject.completionDate,
        total_permits: safeProject.totalPermits,
        active_permits: safeProject.activePermits,
        archived_permits: safeProject.archivedPermits,
        in_review_permits: safeProject.inReviewPermits,
        owner: safeProject.owner
            ? (0, organisation_1.encodeOrganisation)(safeProject.owner)
            : undefined,
        created: safeProject.created,
        archived: safeProject.archived,
    };
};
exports.encodeProject = encodeProject;
var encodeProjectSector = function (projectSector) {
    switch (projectSector) {
        case project_1.ProjectSector.Residential:
            return "project-sector_residential";
        case project_1.ProjectSector.Industrial:
            return "project-sector_industrial";
        case project_1.ProjectSector.Commercial:
            return "project-sector_commercial";
        case project_1.ProjectSector.Retail:
            return "project-sector_retail";
        case project_1.ProjectSector.Other:
            return "project-sector_other";
    }
};
exports.encodeProjectSector = encodeProjectSector;
var decodeProjectSector = function (projectSector) {
    switch (projectSector) {
        default:
        case "project-sector_residential":
            return project_1.ProjectSector.Residential;
        case "project-sector_industrial":
            return project_1.ProjectSector.Industrial;
        case "project-sector_commercial":
            return project_1.ProjectSector.Commercial;
        case "project-sector_retail":
            return project_1.ProjectSector.Retail;
        case "project-sector_other":
            return project_1.ProjectSector.Other;
    }
};
exports.decodeProjectSector = decodeProjectSector;
