"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultPermitLodgement = void 0;
var uuid_1 = require("uuid");
var createDefaultPermitLodgement = function (project, permit) {
    return {
        id: (0, uuid_1.v4)(),
        projectId: project.id,
        permitId: permit.id,
        lodgementName: "",
        url: "",
        created: new Date(),
    };
};
exports.createDefaultPermitLodgement = createDefaultPermitLodgement;
