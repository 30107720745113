import { InputAdornment, Grid, Box } from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import React, { useState } from "react";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import ConditionImport from "../ConditionImport/ConditionImport";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import ConditionExport from "../ConditionExport/ConditionExport";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import ShareCondition from "../ShareCondition/ShareCondition";
import { usePdf } from "@hooks/crud/usePdf";
import { useExcel } from "@hooks/crud/useExcel";
import { useAllFiles } from "@hooks/crud/useAllFiles";

interface ConditionTableToolbarProps {
  loading: boolean;
  filterName: string;
  profile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  altControl?: React.ReactNode;
  handleImportConditons: (conditions: ConditionLineItem[]) => Promise<void>;
  handleOpenConditionEditModal: () => void;
  onFilter: (event: any) => void;
  handleShareConditions: (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string
  ) => Promise<void>;
}

export default function ConditionTableToolbar({
  loading,
  filterName,
  userOrganisation,
  profile,
  project,
  permit,
  conditions,
  handleImportConditons,
  handleOpenConditionEditModal,
  onFilter,
  handleShareConditions,
}: ConditionTableToolbarProps) {
  const { getPdfReport, downloadStepPDF, PDFDownloadProgress } = usePdf();
  const { getExcelReport, downloadStepExcel, excelDownloadProgress } =
    useExcel();
  const { getFullReportInfo, downloadStepAllFiles, allFilesDownloadProgress } =
    useAllFiles();
  const [importOpen, setImportOpen] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const isProjectOwner =
    userOrganisation && project && project.owner?.id === userOrganisation.id;
  const isPermitManager =
    profile &&
    permit &&
    permit.managers.map((pm) => pm.id).includes(profile.id);
  const isPermitApprover =
    profile &&
    permit &&
    permit.approvers.map((pa) => pa.id).includes(profile.id);

  return (
    <>
      <Grid
        container
        sx={{
          padding: "0",
          margin: "0",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0",
          }}
        >
          <Grid item md={3} sx={{ padding: "0" }}>
            <StyledTextField
              className="search"
              loading={loading}
              type="text"
              placeholder="Search Conditions..."
              name="filterProject"
              value={filterName}
              onChange={onFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            md={8}
            sx={{
              padding: "0",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            {isProjectOwner || isPermitManager || isPermitApprover ? (
              <StyledButton
                loading={loading}
                onClick={() => {
                  setShareOpen(true);
                }}
                variant="outlined"
                disabled={loading}
                startIcon={<Iconify icon="foundation:share" />}
                className="outlined-btn filled"
              >
                {"Share Checklist"}
              </StyledButton>
            ) : undefined}
            {isProjectOwner || isPermitManager || isPermitApprover ? (
              <StyledButton
                loading={loading}
                onClick={() => {
                  setExportOpen(true);
                }}
                variant="outlined"
                disabled={loading}
                startIcon={<Iconify icon="foundation:page-export-csv" />}
                className="outlined-btn filled"
              >
                {"Download Checklist"}
              </StyledButton>
            ) : undefined}
            {isProjectOwner || isPermitManager ? (
              <>
                <StyledButton
                  loading={loading}
                  onClick={() => {
                    setImportOpen(true);
                  }}
                  variant="contained"
                  disabled={loading}
                  startIcon={<Iconify icon="eva:cloud-upload-outline" />}
                >
                  {"Import Checklist"}
                </StyledButton>

                <StyledButton
                  loading={loading}
                  onClick={() => handleOpenConditionEditModal()}
                  variant="contained"
                  disabled={loading}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {"Add Condition"}
                </StyledButton>
              </>
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "0" }}>
        {importOpen && project && permit ? (
          <ConditionImport
            project={project}
            permit={permit}
            bcaCount={
              conditions.filter(
                (c) => c.conditionAuthority === ConditionAuthority.BCA
              ).length
            }
            daCount={
              conditions.filter(
                (c) => c.conditionAuthority === ConditionAuthority.DA
              ).length
            }
            loading={false}
            open={importOpen}
            handleImportConditons={handleImportConditons}
            setOpen={setImportOpen}
          />
        ) : null}
        {project && permit && exportOpen ? (
          <ConditionExport
            project={project}
            permit={permit}
            loading={false}
            open={exportOpen}
            setOpen={setExportOpen}
            getPdfReport={getPdfReport}
            downloadStepPDF={downloadStepPDF}
            PDFDownloadProgress={PDFDownloadProgress}
            getExcelReport={getExcelReport}
            downloadStepExcel={downloadStepExcel}
            excelDownloadProgress={excelDownloadProgress}
            getFullReportInfo={getFullReportInfo}
            downloadStepAllFiles={downloadStepAllFiles}
            allFilesDownloadProgress={allFilesDownloadProgress}
          />
        ) : null}
        {project && permit && shareOpen ? (
          <ShareCondition
            permit={permit}
            loading={false}
            open={shareOpen}
            setOpen={setShareOpen}
            shareConditions={handleShareConditions}
          />
        ) : null}
      </Box>
    </>
  );
}
