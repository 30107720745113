import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Avatar } from "@mui/material";
import {
  ProfileLineItem,
  createInvite,
  getProfileName,
} from "permit-one-common/src/interfaces/profile";
import { debounce } from "lodash";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { validateEmail } from "permit-one-common/src/utils/string";
import { useProfileContext } from "@hooks/context/useProfileContext";

interface UserMultiSearchProps {
  id: string;
  loading: boolean;
  name: string;
  label: string;
  existingProfiles: ProfileLineItem[];
  disabled: boolean;
  error?: boolean;
  helperText?: string;
  handleSearch: (query: string) => Promise<ProfileLineItem[]>;
  onChange: (value?: ProfileLineItem[]) => void;
  onBlur: (e: React.ChangeEvent<any>) => void;
}

export const UserMultiSearch = ({
  id,
  loading,
  name,
  label,
  disabled,
  error,
  helperText,
  existingProfiles,
  handleSearch,
  onChange,
  onBlur,
}: UserMultiSearchProps) => {
  const { userProfile } = useProfileContext();

  const [isSearching, setIsSeaching] = React.useState(false);

  const [value, setValue] = React.useState<ProfileLineItem[]>(existingProfiles);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly ProfileLineItem[]>([]);

  const runSearch = async (query: string) => {
    setIsSeaching(true);
    setOptions([]);
    if (query.length > 0) {
      const searchResults = await handleSearch(query.toLowerCase());
      if (searchResults.length > 0) {
        setOptions(searchResults);
      } else {
        const match = validateEmail(query);
        if (match && userProfile && query !== userProfile.email) {
          setOptions([createInvite(query)]);
        }
      }
    }
    setIsSeaching(false);
  };
  const fetch = React.useRef(debounce(runSearch, 500));

  React.useEffect(() => {
    if (inputValue.length > 3) {
      fetch.current(inputValue);
    }
  }, [value, inputValue]);

  if (loading) {
    return <StyledTextField loading={loading} />;
  }
  return (
    <Autocomplete
      id={id}
      fullWidth
      multiple
      loading={isSearching}
      disabled={disabled}
      getOptionLabel={(option) =>
        typeof option === "string"
          ? option
          : option.invited
          ? `Invited - ${option.email}`
          : `${option.firstName} ${option.lastName}`
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      filterSelectedOptions
      value={value}
      noOptionsText="Choose recipient."
      onChange={(event: any, newValue: ProfileLineItem[] | null) => {
        setValue(newValue || []);
        onChange(newValue || undefined);
      }}
      onBlur={onBlur}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id={id}
          name={name}
          label={label}
          fullWidth
          margin="dense"
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <Grid container alignItems="center">
              {!option.invited ? (
                <Grid item sx={{ display: "flex", width: 44 }}>
                  <Avatar
                    src={option.photoUrl}
                    sx={{ width: 24, height: 24, backgroundColor: "#fff" }}
                  >
                    {option.firstName[0].toLowerCase()}
                  </Avatar>
                </Grid>
              ) : null}
              <Grid
                item
                sx={{ width: "calc(100% - 44px)", wordWrap: "break-word" }}
              >
                <Typography color="text.secondary">
                  {`${getProfileName(option)} ${
                    option.organisationName
                      ? `(${option.organisationName})`
                      : ""
                  }`}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};
