import { ReactNode, useState } from "react";
import { ProfileProvider } from "@hooks/context/useProfileContext";
import { AppBar, Container, Toolbar, styled, useTheme } from "@mui/material";
import LayoutWrapper from "@stories/pages/Authenticate/AuthWrapper1";

interface PermitLayoutProps {
  children: ReactNode;
}

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const NoProfileLayout = (props: PermitLayoutProps) => {
  const { children } = props;

  const theme = useTheme();
  const [isDrawOpen, setIsDrawOpen] = useState(true);

  const header = <Toolbar sx={{ p: "16px" }}></Toolbar>;
  return (
    <LayoutWrapper>
      <ProfileProvider>
        
        <Main>
          <Container maxWidth="xl">{children}</Container>
        </Main>
      </ProfileProvider>
    </LayoutWrapper>
  );
};
