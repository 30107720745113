import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { useDocument } from "@hooks/crud/useDocument";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import DocumentModal from "../DocumentModal/DocumentModal";
import { useState } from "react";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import ConditionDocumentModal from "../ConditionDocumentModal/ConditionDocumentModal";
import { ConditionDocumentTable } from "../Tables/ConditionDocumentTable/ConditionDocumentTable";

interface ConditionDocumentsProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
}
export const ConditionDocuments = ({
  isLoading,
  project,
  permit,
  condition,
}: ConditionDocumentsProps): JSX.Element => {
  const { userProfile } = useProfileContext();
  const {
    isDocumentLoading,
    documents,
    createDocuments,
    updateDocuments,
    assignDocumentsToConditions,
    unassignDocumentsToConditions,
  } = useDocument(undefined, undefined, condition?.id);

  const [openSingle, setOpenSingle] = useState(false);

  const [openBulk, setOpenBulk] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<
    ConditionDocumentLineItem | undefined
  >(undefined);

  if (!userProfile) {
    return <PageLoader />;
  }

  const handleCloseSingle = () => {
    setOpenSingle(false);
    setSelectedDocument(undefined);
  };
  const handleCloseBulk = () => {
    setOpenBulk(false);
  };

  const handleOpenSingle = (document: ConditionDocumentLineItem) => {
    setSelectedDocument(document);
    setOpenSingle(true);
  };

  const handleOpenBulk = () => {
    setOpenBulk(true);
  };

  const handleRemoveDocument = (document: ConditionDocumentLineItem) => {
    if (condition) {
      document.conditions = document.conditions.filter(
        (c) => c.id !== condition?.id
      );
      unassignDocumentsToConditions([document]);
    }
  };

  const loading = isLoading || isDocumentLoading;

  return (
    <>
      <ConditionDocumentTable
        project={project}
        handleOpenBulk={handleOpenBulk}
        documents={documents}
        loading={loading}
        handleEditDocument={handleOpenSingle}
        handleRemoveDocument={handleRemoveDocument}
      />
      {/* {userProfile && project && condition ? (
        <ConditionDocumentModal
          open={openBulk}
          project={project}
          permit={permit}
          condition={condition}
          existingDocuments={documents}
          userProfile={userProfile}
          handleClose={handleCloseBulk}
          createDocuments={createDocuments}
          assignDocumentsToConditions={assignDocumentsToConditions}
          documentCount={documents.length}
        />
      ) : null} */}
      {userProfile && selectedDocument ? (
        <DocumentModal
          open={openSingle}
          project={project}
          condition={condition}
          userProfile={userProfile}
          handleClose={handleCloseSingle}
          updateDocuments={updateDocuments}
          documentCount={documents.length}
          existingDocument={selectedDocument}
        />
      ) : null}
    </>
  );
};
