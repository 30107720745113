export interface MultiProgressBarProps {
  loading: boolean;
  assigned: number;
  assignedText: string;
  overdue: number;
  overdueText: string;
  inReview: number;
  inReviewText: string;
  approved: number;
  approvedText: string;
  notApplicable?: number;
  notApplicableText?: string;
  notAssigned?: number;
  notAssignedText?: string;
}
export const MultiProgressBar = ({
  loading,
  assigned,
  assignedText,
  overdue,
  overdueText,
  inReview,
  inReviewText,
  approved,
  approvedText,
  notAssigned,
  notAssignedText,
  notApplicable,
  notApplicableText,
}: MultiProgressBarProps) => {
  let total = assigned + overdue + inReview + approved;
  if (notAssigned !== undefined) {
    total += notAssigned;
  }
  else if (notApplicable !== undefined) {
    total += notApplicable;
  }

  const assignedPercentage = (assigned / total) * 100;
  const overduePercentage = (overdue / total) * 100;
  const inReviewPercentage = (inReview / total) * 100;
  const approvedPercentage = (approved / total) * 100;
  const notApplicablePercentage = notApplicable ? (notApplicable / total) * 100 : 0;
  //const notAssignedPercentage = notAssigned ? (notAssigned / total) * 100 : 0;

  if (loading) {
    return (
      <div style={{ width: "538px" }}>
        <div className="progress">
          <div
            className="progress-bar progress-bar-ruby"
            role="progressbar"
          ></div>
        </div>
      </div>
    );
  }
  return (
    <div style={{ width: "538px" }}>
      <div className="progress">
        {assignedPercentage ? (
          <div
            className="progress-bar progress-bar-ruby"
            role="progressbar"
            style={{ width: `${assignedPercentage}%`, background: "#016BE2" }}
          ></div>
        ) : undefined}
        {inReviewPercentage > 0 ? (
          <div
            className="progress-bar progress-bar-html"
            role="progressbar"
            style={{ width: `${inReviewPercentage}%`, background: "#FF9900" }}
          ></div>
        ) : undefined}
        {overduePercentage > 0 ? (
          <div
            className="progress-bar progress-bar-css"
            role="progressbar"
            style={{ width: `${overduePercentage}%`, background: "#E10023" }}
          ></div>
        ) : undefined}
        
        {approvedPercentage > 0 ? (
          <div
            className="progress-bar progress-bar-other"
            role="progressbar"
            style={{ width: `${approvedPercentage}%`, background: "#03A742" }}
          ></div>
        ) : undefined}

        {notApplicable !== undefined && notApplicable > 0 ? (
          <div
            className="progress-bar progress-bar-other"
            role="progressbar"
            style={{ width: `${notApplicablePercentage}%`, background: "#A9A9A9" }}
          ></div>
        ) : undefined}

        {/*{notAssigned !== undefined && notAssigned > 0 ? (
          <div
            className="progress-bar progress-bar-other"
            role="progressbar"
            style={{
              width: `${notAssignedPercentage}%`,
              background: "#F26602",
            }}
          ></div>
        ) : undefined}*/}
      </div>

      <div
        className="progress-bar-labels"
        style={{
          display: "flex",
          columnGap: "10px",
          flexWrap: "wrap",
          rowGap: "5px",
          marginTop: "10px",
          maxWidth: "493px",
          margin: "10px 0 0 auto",
        }}
      >
        <div
          className="prog-bar-box"
          style={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          <span
            className="prog-bar-dot"
            style={{
              width: "10px",
              height: "10px",
              background: " #016BE2",
              borderRadius: "50%",
              display: "inline-block",
            }}
          />
          <span className="prog-bar-label">{assignedText}</span>
          <span className="prog-bar-percentage">({assigned})</span>
        </div>
        <div
          className="prog-bar-box"
          style={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          <span
            className="prog-bar-dot"
            style={{
              width: "10px",
              height: "10px",
              background: "#FF9900",
              borderRadius: "50%",
              display: "inline-block",
            }}
          />
          <span className="prog-bar-label">{inReviewText}</span>
          <span className="prog-bar-percentage">({inReview})</span>
        </div>
        <div
          className="prog-bar-box"
          style={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          <span
            className="prog-bar-dot"
            style={{
              width: "10px",
              height: "10px",
              background: "#03A742",
              borderRadius: "50%",
              display: "inline-block",
            }}
          />
          <span className="prog-bar-label">{approvedText}</span>
          <span className="prog-bar-percentage">({approved})</span>
        </div>
        <div
          className="prog-bar-box"
          style={{
            display: "flex",
            gap: "4px",
            alignItems: "center",
          }}
        >
          <span
            className="prog-bar-dot"
            style={{
              width: "10px",
              height: "10px",
              background: "#E10023",
              borderRadius: "50%",
              display: "inline-block",
            }}
          />
          <span className="prog-bar-label">{overdueText}</span>
          <span className="prog-bar-percentage">({overdue})</span>

          
        </div>

        {notApplicable !== undefined ? (
        <div
        className="prog-bar-box"
        style={{
          display: "flex",
          gap: "4px",
          alignItems: "center",
        }}>
        <span
            className="prog-bar-dot"
            style={{
              width: "10px",
              height: "10px",
              background: "#A9A9A9",
              borderRadius: "50%",
              display: "inline-block",
            }}
          />
          <span className="prog-bar-label">{notApplicableText}</span>
          <span className="prog-bar-percentage">({notApplicable})</span>
        </div>
        ) : undefined}
        
        
        {notAssigned !== undefined ? (
          <div
            className="prog-bar-box"
            style={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
            }}
          >
            <span
              className="prog-bar-dot"
              style={{
                width: "10px",
                height: "10px",
                background: "#f26602",
                borderRadius: "50%",
                display: "inline-block",
              }}
            />
            <span className="prog-bar-label">{notAssignedText}</span>
            <span className="prog-bar-percentage">({notAssigned})</span>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};
