import React from "react";

// material-ui
import { Skeleton, TableCell, Typography } from "@mui/material";

interface TableHeaderProps {
  loading: boolean;
  title: string;
  align?: string;
  width?: string;
}

export const TableHeader = ({
  loading,
  title,
  align = "left",
  width,
}: TableHeaderProps) => {
  return (
    <TableCell align="center" sx={{ width: width }}>
      {loading ? (
        <Skeleton animation="wave" sx={{ magin: "auto" }}>
          <Typography sx={{ magin: "auto" }}>{title}</Typography>
        </Skeleton>
      ) : (
        <Typography sx={{ textAlign: align }}>{title}</Typography>
      )}
    </TableCell>
  );
};
