import {
  Avatar,
  AvatarGroup,
  TableCell,
  TableRow,
  IconButton,
  Box,
  Tooltip,
  Button,
  Zoom,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import {
  ProjectLineItem,
  ProjectSector,
  projectSectorToString,
} from "permit-one-common/src/interfaces/project";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState, MouseEvent } from "react";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface ProjectCardProps {
  project: ProjectLineItem;
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  handleViewProject: (project: ProjectLineItem) => void;
  handleEditProject: (project: ProjectLineItem) => void;
  handleArchiveProject: (project: ProjectLineItem) => void;
}

export const ProjectCard = ({
  project,
  profile,
  organisation,
  handleViewProject,
  handleEditProject,
  handleArchiveProject,
}: ProjectCardProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [collaboratorImage, setCollaboratorImage] = useState<string>();

  useEffect(() => {
    const getImage = async () => {
      // const randomIndex = Math.floor(
      //   project.collaborators.length * Math.random()
      // );
      // const collaboratorImage = await getImageUrl(
      //   project.collaborators[randomIndex]
      // );
      setCollaboratorImage("https://i.pravatar.cc/300");
    };
    if (project) {
      getImage();
    }
  }, [project]);

  const canEdit =
    project.organisationId === organisation?.id && profile && profile.isAdmin;

  return (
    <>
      <TableRow style={{ padding: "10px 0 0" }} hover>
        <TableCell onClick={() => handleViewProject(project)}>
          <span
            className={project.archived ? "archivedIcon" : "checkIcon"}
            style={{
              width: "12px",
              height: "12px",
              boxShadow: "none",
              background: project.archived ? "red" : "#1ff300",
              display: "block",
              margin: "0 auto",
              borderRadius: "50%",
            }}
          />
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ width: "20%" }}
        >
          {project.projectName}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ width: "10%" }}
        >
          {project.projectSector !== ProjectSector.Other
            ? projectSectorToString(project.projectSector)
            : project.projectSectorOther}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ width: "10%" }}
        >
          {collaboratorImage && project.collaborators.length ? (
            <AvatarGroup
              max={2}
              sx={{
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  fontSize: "1rem",
                },
              }}
            >
              <Avatar alt="User 1" src={collaboratorImage} />
              {new Array(project.collaborators.length).map((_, index) => {
                return <Avatar alt={`${index}`} src={collaboratorImage} />;
              })}
            </AvatarGroup>
          ) : (
            "No Collaborators"
          )}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ width: "10%", textAlign: "center !important" }}
        >
          {project.totalPermits}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ width: "10%", textAlign: "center !important" }}
        >
          {project.activePermits}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleViewProject(project)}
          sx={{ width: "10%", textAlign: "center !important" }}
        >
          {project.archivedPermits}
        </TableCell>
        <TableCell align="right" sx={{ width: "10%" }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={(event) => handleClick(event)}>
              <MoreVertIcon />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              disabled={!canEdit}
              onClick={() => {
                handleClose();
                handleViewProject(project);
              }}
            >
              View
            </MenuItem>

            <MenuItem
              disabled={!canEdit}
              onClick={() => {
                handleClose();
                handleEditProject(project);
              }}
            >
              Edit
            </MenuItem>

            {!project.archived ? (
              <MenuItem
                disabled={!canEdit}
                onClick={() => {
                  handleClose();
                  setOpenConfirmDialog(true);
                }}
              >
                Delete
              </MenuItem>
            ) : (
              <MenuItem
                disabled={!canEdit}
                onClick={() => {
                  handleClose();
                  setOpenConfirmDialog(true);
                }}
              >
                Restore
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={
          !project.archived
            ? "Are you sure you want to archive this project?"
            : "Are you sure you want to restore this project?"
        }
        open={openConfirmDialog}
        title={!project.archived ? "Archive Project" : "Restore Project"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleArchiveProject(project)}
      />
    </>
  );
};
