import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { IconButton, Table, TableBody, Tooltip, Box } from "@mui/material";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { useTable } from "@hooks/utils/useTable";
import { DocumentTableRow } from "./DocumentTableRow";
import ListTableToolbar from "@stories/molecules/ListTable/ListTableToolbar";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { DocumentTableRowSkeleton } from "./DocumentTableRowSkeleton";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useDownloadDocuments } from "@hooks/crud/useDownloadDocuments";
import { PdfDownlodDialog } from "@stories/molecules/PdfDownlodDialog/PdfDownlodDialog";

interface DocumentTableProps {
  loading: boolean;
  project?: ProjectLineItem;
  documents: ConditionDocumentLineItem[];
  handleOpenBulk: () => void;
  handleEditDocument: (document: ConditionDocumentLineItem) => void;
  handleDeleteDocument: (document: ConditionDocumentLineItem) => void;
}

export const DocumentTable = ({
  loading,
  project,
  documents,
  handleOpenBulk,
  handleEditDocument,
  handleDeleteDocument,
}: DocumentTableProps) => {
  const {
    selected,
    clearSelected,
    handleSelectAllClick,
    handleClick,
    handleFilterByName,
  } = useTable();

  const onEditDocument = (document: ConditionDocumentLineItem) => {
    handleEditDocument(document);
    clearSelected();
  };
  const onDeleteDocument = (document: ConditionDocumentLineItem) => {
    handleDeleteDocument(document);
    clearSelected();
  };

  const { getDocumentsZip, downloadStep, downloadProgress } =
    useDownloadDocuments();

  const handleDownloadAll = async () => {
    if (project) {
      const selectedDocuments = documents
        .filter((d) => selected.includes(d.id))
        .map((d) => d.id);

      if (selectedDocuments.length > 0) {
        const reportName = `${project.projectName}-files.zip`;
        await getDocumentsZip(selectedDocuments, reportName);
      }
    }
  };

  const altControl = (
    <Tooltip title="Download All">
      <IconButton
        onClick={handleDownloadAll}
        className="blackBtn"
        sx={{
          fontSize: "13px !important",
          display: "flex",
          gap: "2px",
          color: "#fff !important",
          padding: "7px 10px !important",
        }}
      >
        <CloudDownloadIcon
          sx={{ color: "#fff", width: "15px", marginRight: "5px" }}
        />
        Download
      </IconButton>
    </Tooltip>
  );

  const TABLE_HEAD: TableHeaderCell[] = [
    { id: "document-number", label: "Number", alignRight: false },
    { id: "document-title", label: "Title", alignRight: false },
    { id: "document-revision", label: "Revision", alignRight: false },
    { id: "document-discipline", label: "Discipline", alignRight: false },
    { id: "document-category", label: "Category", alignRight: false },
    { id: "document-created-by", label: "Creator", alignRight: false },
    { id: "document-modified", label: "Upload", alignRight: false },
    { id: "document-file-type", label: "Type", alignRight: false },
    { id: "document-file-size", label: "Size", alignRight: false },
    { id: "", static: true },
  ];

  const { filterName, order, orderBy, handleRequestSort } = useTable();

  const filteredDocuments =
    documents.length > 0
      ? documents.filter(
          (u) =>
            u.documentTitle
              .toLowerCase()
              .includes(filterName.toLocaleLowerCase()) ||
            u.documentCategory
              .toLowerCase()
              .includes(filterName.toLocaleLowerCase())
        )
      : documents;

  let sortedDocuments = filteredDocuments.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.documentTitle.toLowerCase() < b.documentTitle.toLowerCase()
          ? -1
          : 1;
      case 1:
        return a.documentDiscipline.toLowerCase() <
          b.documentDiscipline.toLowerCase()
          ? -1
          : 1;
      case 2:
        return a.conditions.length < b.conditions.length ? -1 : 1;
      case 3:
        return a.revisionNumber < b.revisionNumber ? -1 : 1;
      case 4:
        return a.revisionDate.getTime() < b.revisionDate.getTime() ? -1 : 1;
      case 5:
        return a.documentCategory < b.documentCategory ? -1 : 1;
      case 6:
        return a.fileSize < b.fileSize ? -1 : 1;
      default:
        return 0;
    }
  });

  sortedDocuments =
    order === "asc" ? sortedDocuments : sortedDocuments.reverse();

  return (
    <>
      <ListTableToolbar
        loading={loading}
        handleOpen={handleOpenBulk}
        buttonTitle={"Add Documents"}
        searchTitle="Search Documents..."
        numSelected={selected.length}
        filterName={filterName}
        clearSelected={clearSelected}
        onFilter={handleFilterByName}
        altControl={altControl}
      />
      <Box sx={{ marginTop: "25px !important" }}>
        <Table stickyHeader aria-label="sticky table" className="project-table">
          <ListTableHead
            headLabel={TABLE_HEAD}
            loading={loading}
            rowCount={sortedDocuments.length}
            numSelected={selected.length}
            showCheckbox={true}
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            onSelectAllClick={(e: any) =>
              handleSelectAllClick(
                e,
                filteredDocuments.map((u) => u.id)
              )
            }
          />
          <TableBody>
            {!loading
              ? sortedDocuments.map((row) => {
                  const selectedDocument = selected.indexOf(row.id) !== -1;
                  return (
                    <DocumentTableRow
                      key={row.id}
                      document={row}
                      selected={selectedDocument}
                      handleClick={handleClick}
                      handleEditDocument={onEditDocument}
                      handleDeleteDocument={onDeleteDocument}
                    />
                  );
                })
              : null}
            {loading
              ? Array.from({ length: 6 }, (_, index) => (
                  <DocumentTableRowSkeleton key={index} />
                ))
              : null}
            {!loading && sortedDocuments.length === 0 ? (
              <NoRows colSpan={12} title={"documents"} />
            ) : null}
          </TableBody>
        </Table>
      </Box>
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Exporting Documents Zip"}
      />
    </>
  );
};
