"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.documentCategorysToTitlePrefix = exports.DocumentCategory = void 0;
var DocumentCategory;
(function (DocumentCategory) {
    DocumentCategory["AboriginalCulturalHeritageReport"] = "document-type_aboriginal-cultural-heritage-report";
    DocumentCategory["AboriginalDueDiligenceAssessment"] = "document-type_aboriginal-due-diligence-assessment";
    DocumentCategory["AccessReport"] = "document-type_access-report";
    DocumentCategory["AcidSulfateSoilsReport"] = "document-type_acid-sulfate-soils-report";
    DocumentCategory["AcousticAndVibrationAssessment"] = "document-type_acoustic-and-vibration-assessment";
    DocumentCategory["AcousticReport"] = "document-type_acoustic-report";
    DocumentCategory["AdvertisingandSignageDetailPlan"] = "document-type_advertising-and-signage-detail-plan";
    DocumentCategory["AgriculturalImpactStatement"] = "document-type_agricultural-impact-statement";
    DocumentCategory["AirQuality"] = "document-type_air-quality";
    DocumentCategory["AirQualityAssessment"] = "document-type_air-quality-assessment";
    DocumentCategory["AnyDraftSection88BConveyancingAct1919Instruments"] = "document-type_any-draft-section-88b-conveyancing-act-1919-instruments-";
    DocumentCategory["AquaticEcologyAssessmentReport"] = "document-type_aquatic-ecology-assessment-report";
    DocumentCategory["ArboristsReport"] = "document-type_arborists-report";
    DocumentCategory["ArchaeologicalAssessment"] = "document-type_archaeological-assessment";
    DocumentCategory["ArchitecturalPlans"] = "document-type_architectural-plans";
    DocumentCategory["BASIXCertificate"] = "document-type_basix-certificate";
    DocumentCategory["BCAPerformanceRequirementsComplianceStatement"] = "document-type_bca-performance-requirements-compliance-statement";
    DocumentCategory["BiodiversityTestOfSignificance"] = "document-type_biodiversity-test-of-significance";
    DocumentCategory["BiodiversityAssessmentMethodBAMLetter"] = "document-type_biodiversity-assessment-method-bam-letter-";
    DocumentCategory["BiodiversityDevelopmentAssessmentReport"] = "document-type_biodiversity-development-assessment-report";
    DocumentCategory["BiodiversityOffsetsSchemeThresholdReport"] = "document-type_biodiversity-offsets-scheme-threshold-report-";
    DocumentCategory["BuildingCodeOfAustraliaReport"] = "document-type_building-code-of-australia-report-";
    DocumentCategory["BushFireReport"] = "document-type_bushfire-report";
    DocumentCategory["CarParkingAndVehicleAccess"] = "document-type_car-parking-and-vehicle-access";
    DocumentCategory["Category1FireSafetyProvisions"] = "document-type_category-1-fire-safety-provisions";
    DocumentCategory["CivilEngineeringPlan"] = "document-type_civil-engineering-plan";
    DocumentCategory["Clause46VariationRequest"] = "document-type_clause-4.6-variation-request";
    DocumentCategory["CoastalAssessmentReport"] = "document-type_coastal-assessment-report";
    DocumentCategory["CompetitiveDesignProcessReport"] = "document-type_competitive-design-process-report";
    DocumentCategory["ConservationManagementPlanorStrategy"] = "document-type_conservation-management-plan-or-strategy";
    DocumentCategory["ConstructionManagementPlan"] = "document-type_construction-management-plan";
    DocumentCategory["ContaminationAndOrRemediationActionPlan"] = "document-type_contamination-and/or-remediation-action-plan";
    DocumentCategory["CostEstimateReport"] = "document-type_cost-estimate-report";
    DocumentCategory["CouncilDAChecklist"] = "document-type_council-da-checklist";
    DocumentCategory["CrimePreventionthroughEnvironmentalDesignCPTEDReport"] = "document-type_crime-prevention-through-environmental-design-cpted-report";
    DocumentCategory["DamReport"] = "document-type_dam-report";
    DocumentCategory["DemolitionManagementPlan"] = "document-type_demolition-management-plan";
    DocumentCategory["DemolitionWorkPlan"] = "document-type_demolition-work-plan";
    DocumentCategory["DesignStatementForComplianceWithLowRiseHousingDesignGuide"] = "document-type_design-statement-for-compliance-with-low-rise-housing-design-guide";
    DocumentCategory["DesignVerificationStatement"] = "document-type_design-verification-statement";
    DocumentCategory["DisabilityAccessPlanDDA"] = "document-type_disability-access-plan-dda";
    DocumentCategory["DrivewayAndAccessPlan"] = "document-type_driveway-and-access-plan";
    DocumentCategory["ElevationsAndSections"] = "document-type_elevations-and-sections";
    DocumentCategory["EnvironmentalImpactStatement"] = "document-type_environmental-impact-statement";
    DocumentCategory["ErosionandSedimentControlPlan"] = "document-type_erosion-and-sediment-control-plan";
    DocumentCategory["EstuarineHazardAssessmentReport"] = "document-type_estuarine-hazard-assessment-report";
    DocumentCategory["EvidenceOfAuthorityUnderminingAct1992"] = "document-type_evidence-of-authority-under-mining-act-1992";
    DocumentCategory["EvidenceOfLossOfoewRentalDwellingsExcavationandOrFillPlan"] = "document-type_evidence-of-loss-of-low-rental-dwellings-excavation-and-or-fill-plan";
    DocumentCategory["FireSafetyUpgradeReport"] = "document-type_fire-safety-upgrade-report";
    DocumentCategory["FloodRiskImpactAndOrManagementPlan"] = "document-type_flood-risk-impact-and-or-management-plan";
    DocumentCategory["Floodriskmanagementreport"] = "document-type_flood-risk-management-report";
    DocumentCategory["FloorAndOrRoofPlan"] = "document-type_floor-and-or-roof-plan";
    DocumentCategory["FloorPlans"] = "document-type_floor-plans";
    DocumentCategory["FloraAndFaunaAssessment"] = "document-type_flora-and-fauna-assessment";
    DocumentCategory["GeoTechnicalReport"] = "document-type_geotechnical-report";
    DocumentCategory["HeritageImpactStatement"] = "document-type_heritage-impact-statement";
    DocumentCategory["HunterWaterStampApproval"] = "document-type_hunter-water-stamp-approval";
    DocumentCategory["Hydrogeologicalreport"] = "document-type_hydrogeological-report";
    DocumentCategory["LandscapePlan"] = "document-type_landscape-plan";
    DocumentCategory["LifeCycleAssessmentLCALiquidTradeWasteDetails"] = "document-type_life-cycle-assessment-(lca)-liquid-trade-waste-details";
    DocumentCategory["MinesSubsidenceStampedPlansMosquitoReport"] = "document-type_mines-subsidence-stamped-plans-mosquito-report";
    DocumentCategory["NABERSAgreementToRateNABERSCommitmentAgreementNetZeroStatement"] = "document-type_nabers-agreement-to-rate-nabers-commitment-agreement-net-zero-statement";
    DocumentCategory["NotificationPlan"] = "document-type_notification-plan";
    DocumentCategory["NotificationPlans"] = "document-type_notification-plans";
    DocumentCategory["OdourAssessmentReportOther"] = "document-type_odour-assessment-report-other";
    DocumentCategory["OwnerBuilderPermit"] = "document-type_owner-builder-permit-(>$10,000)";
    DocumentCategory["OwnersConsent"] = "document-type_owners-consent";
    DocumentCategory["ParkingReport"] = "document-type_parking-report";
    DocumentCategory["PartyWallConsent"] = "document-type_party-wall-consent";
    DocumentCategory["PerformanceSolutionDocumentation"] = "document-type_performance-solution-documentation";
    DocumentCategory["Photomontage"] = "document-type_photomontage";
    DocumentCategory["PlanOfManagement"] = "document-type_plan-of-management";
    DocumentCategory["PlanningAgreement"] = "document-type_planning-agreement";
    DocumentCategory["PoliticalDonationsAndGiftsDisclosure"] = "document-type_political-donations-and-gifts-disclosure";
    DocumentCategory["PreliminaryEngineeringDrawings"] = "document-type_preliminary-engineering-drawings";
    DocumentCategory["PreliminarySewerandWaterFeasibilityStudy"] = "document-type_preliminary-sewer-and-water-feasibility-study";
    DocumentCategory["PreliminaryStormwaterManagementPlan"] = "document-type_preliminary-stormwater-management-plan";
    DocumentCategory["PriorDAStampedPlans"] = "document-type_prior-da-stamped-plans";
    DocumentCategory["PriorNoticeofDetermination"] = "document-type_prior-notice-of-determination";
    DocumentCategory["PrivateLandConservationAgreementDetails"] = "document-type_private-land-conservation-agreement-details";
    DocumentCategory["ProposedSubdivisionPlan"] = "document-type_proposed-subdivision-plan";
    DocumentCategory["PublicArtPlan"] = "document-type_public-art-plan";
    DocumentCategory["PublicSubmissionsReflectivityPlan"] = "document-type_public-submissions-reflectivity-plan";
    DocumentCategory["RoadDesignPlan"] = "document-type_road-design-plan";
    DocumentCategory["SalinityAssessment"] = "document-type_salinity-assessment";
    DocumentCategory["ScheduleofColoursMaterialsAndFinishes"] = "document-type_schedule-of-colours,-materials-and-finishes";
    DocumentCategory["Section107PlanningCertificateFormerlySection149"] = "document-type_section-10.7-planning-certificate-formerly-section-149";
    DocumentCategory["Section46ApplicationtoVaryDevelopmentStandard"] = "document-type_section-4.6-application-to-vary-development-standard";
    DocumentCategory["SectionJReport"] = "document-type_section-j-report";
    DocumentCategory["SEPP65Assessment"] = "document-type_sepp-65-assessment";
    DocumentCategory["Shadowdiagrams"] = "document-type_shadow-diagrams";
    DocumentCategory["SitePlans"] = "document-type_site-plans";
    DocumentCategory["SocialImpactAssessment"] = "document-type_social-impact-assessment";
    DocumentCategory["SolarandDaylightAccessReport"] = "document-type_solar-and-daylight-access-report";
    DocumentCategory["SpeciesImpactStatement"] = "document-type_species-impact-statement";
    DocumentCategory["SprayDriftReport"] = "document-type_spray-drift-report";
    DocumentCategory["Statementofenvironmentaleffects"] = "document-type_statement-of-environmental-effects";
    DocumentCategory["StormWaterDrainagePlan"] = "document-type_stormwater-drainage-plan";
    DocumentCategory["StormWaterManagementPlan"] = "document-type_stormwater-management-plan";
    DocumentCategory["StructuralEngineersReport"] = "document-type_structural-engineers-report";
    DocumentCategory["SubdivisionPlan"] = "document-type_subdivision-plan";
    DocumentCategory["Surveyplan"] = "document-type_survey-plan";
    DocumentCategory["SurveyPlanServicesEasements"] = "document-type_survey-plan-services-easements";
    DocumentCategory["TitleDocumentationCertificateofTitle"] = "document-type_title-documentation-certificate-of-title";
    DocumentCategory["TrafficCalmingDetails"] = "document-type_traffic-calming-details";
    DocumentCategory["TrafficImpactAssessment"] = "document-type_traffic-impact-assessment";
    DocumentCategory["TrafficManagementPlan"] = "document-type_traffic-management-plan";
    DocumentCategory["TrafficReport"] = "document-type_traffic-report";
    DocumentCategory["VariationtoDevelopmentControls"] = "document-type_variation-to-development-control(s)";
    DocumentCategory["VariationstoControlsintheDevelopmentControlPlan"] = "document-type_variations-to-controls-in-the-development-control-plan";
    DocumentCategory["VisualImpactAssessmentReport"] = "document-type_visual-impact-assessment-report";
    DocumentCategory["VoluntaryPlanningAgreement"] = "document-type_voluntary-planning-agreement";
    DocumentCategory["WasteManagementPlan"] = "document-type_waste-management-plan";
    DocumentCategory["WastewaterManagementPlan"] = "document-type_wastewater-management-plan";
    DocumentCategory["WaterManagementPlan"] = "document-type_water-management-plan";
    DocumentCategory["WaterSensitiveUrbanDesignReport"] = "document-type_water-sensitive-urban-design-report";
    DocumentCategory["WindEffectsPlan"] = "document-type_wind-effects-plan";
    DocumentCategory["Other"] = "document-type_other";
})(DocumentCategory || (exports.DocumentCategory = DocumentCategory = {}));
var documentCategorysToTitlePrefix = function (documentCategory) {
    switch (documentCategory) {
        case DocumentCategory.AboriginalCulturalHeritageReport:
            return "ACHR";
        case DocumentCategory.AboriginalDueDiligenceAssessment:
            return "ADDA";
        case DocumentCategory.AccessReport:
            return "AR";
        case DocumentCategory.AcidSulfateSoilsReport:
            return "ASSR";
        case DocumentCategory.AcousticAndVibrationAssessment:
            return "AVA";
        case DocumentCategory.AcousticReport:
            return "AR";
        case DocumentCategory.AdvertisingandSignageDetailPlan:
            return "ASP";
        case DocumentCategory.AgriculturalImpactStatement:
            return "AIS";
        case DocumentCategory.AirQuality:
            return "AQ";
        case DocumentCategory.AirQualityAssessment:
            return "AQA";
        case DocumentCategory.AnyDraftSection88BConveyancingAct1919Instruments:
            return "ADS";
        case DocumentCategory.AquaticEcologyAssessmentReport:
            return "AEAR";
        case DocumentCategory.ArboristsReport:
            return "ABR";
        case DocumentCategory.ArchaeologicalAssessment:
            return "AA";
        case DocumentCategory.ArchitecturalPlans:
            return "AP";
        case DocumentCategory.BASIXCertificate:
            return "BASIX";
        case DocumentCategory.BCAPerformanceRequirementsComplianceStatement:
            return "BCA";
        case DocumentCategory.BiodiversityTestOfSignificance:
            return "BTS";
        case DocumentCategory.BiodiversityAssessmentMethodBAMLetter:
            return "BAM";
        case DocumentCategory.BiodiversityDevelopmentAssessmentReport:
            return "BDAR";
        case DocumentCategory.BiodiversityOffsetsSchemeThresholdReport:
            return "BOTR";
        case DocumentCategory.BuildingCodeOfAustraliaReport:
            return "BCAR";
        case DocumentCategory.BushFireReport:
            return "BFR";
        case DocumentCategory.CarParkingAndVehicleAccess:
            return "CPVA";
        case DocumentCategory.Category1FireSafetyProvisions:
            return "CFSP";
        case DocumentCategory.CivilEngineeringPlan:
            return "CEP";
        case DocumentCategory.Clause46VariationRequest:
            return "CVR";
        case DocumentCategory.CoastalAssessmentReport:
            return "CAR";
        case DocumentCategory.CompetitiveDesignProcessReport:
            return "CDPR";
        case DocumentCategory.ConservationManagementPlanorStrategy:
            return "CMPS";
        case DocumentCategory.ConstructionManagementPlan:
            return "CMP";
        case DocumentCategory.ContaminationAndOrRemediationActionPlan:
            return "CAP";
        case DocumentCategory.CostEstimateReport:
            return "CER";
        case DocumentCategory.CouncilDAChecklist:
            return "CDAC";
        case DocumentCategory.CrimePreventionthroughEnvironmentalDesignCPTEDReport:
            return "CPED";
        case DocumentCategory.DamReport:
            return "DR";
        case DocumentCategory.DemolitionManagementPlan:
            return "DMP";
        case DocumentCategory.DemolitionWorkPlan:
            return "DWP";
        case DocumentCategory.DesignStatementForComplianceWithLowRiseHousingDesignGuide:
            return "DSLR";
        case DocumentCategory.DesignVerificationStatement:
            return "DVS";
        case DocumentCategory.DisabilityAccessPlanDDA:
            return "DDA";
        case DocumentCategory.DrivewayAndAccessPlan:
            return "DAP";
        case DocumentCategory.ElevationsAndSections:
            return "ES";
        case DocumentCategory.EnvironmentalImpactStatement:
            return "EIS";
        case DocumentCategory.ErosionandSedimentControlPlan:
            return "ESCP";
        case DocumentCategory.EstuarineHazardAssessmentReport:
            return "EHAR";
        case DocumentCategory.EvidenceOfAuthorityUnderminingAct1992:
            return "EAU";
        case DocumentCategory.EvidenceOfLossOfoewRentalDwellingsExcavationandOrFillPlan:
            return "ERDE";
        case DocumentCategory.FireSafetyUpgradeReport:
            return "FSUR";
        case DocumentCategory.FloodRiskImpactAndOrManagementPlan:
            return "FRIMP";
        case DocumentCategory.Floodriskmanagementreport:
            return "FRMR";
        case DocumentCategory.FloorAndOrRoofPlan:
            return "FRP";
        case DocumentCategory.FloorPlans:
            return "FP";
        case DocumentCategory.FloraAndFaunaAssessment:
            return "FFA";
        case DocumentCategory.GeoTechnicalReport:
            return "GTR";
        case DocumentCategory.HeritageImpactStatement:
            return "HIS";
        case DocumentCategory.HunterWaterStampApproval:
            return "HWSA";
        case DocumentCategory.Hydrogeologicalreport:
            return "HR";
        case DocumentCategory.LandscapePlan:
            return "LP";
        case DocumentCategory.LifeCycleAssessmentLCALiquidTradeWasteDetails:
            return "LCA";
        case DocumentCategory.MinesSubsidenceStampedPlansMosquitoReport:
            return "MSSP";
        case DocumentCategory.NABERSAgreementToRateNABERSCommitmentAgreementNetZeroStatement:
            return "NANZ";
        case DocumentCategory.NotificationPlan:
            return "NP";
        case DocumentCategory.NotificationPlans:
            return "NPS";
        case DocumentCategory.OdourAssessmentReportOther:
            return "OAR";
        case DocumentCategory.OwnerBuilderPermit:
            return "OBP";
        case DocumentCategory.OwnersConsent:
            return "OC";
        case DocumentCategory.ParkingReport:
            return "PR";
        case DocumentCategory.PartyWallConsent:
            return "PWC";
        case DocumentCategory.PerformanceSolutionDocumentation:
            return "PSD";
        case DocumentCategory.Photomontage:
            return "PM";
        case DocumentCategory.PlanOfManagement:
            return "POM";
        case DocumentCategory.PlanningAgreement:
            return "PA";
        case DocumentCategory.PoliticalDonationsAndGiftsDisclosure:
            return "PDGD";
        case DocumentCategory.PreliminaryEngineeringDrawings:
            return "PED";
        case DocumentCategory.PreliminarySewerandWaterFeasibilityStudy:
            return "PSWFS";
        case DocumentCategory.PreliminaryStormwaterManagementPlan:
            return "PSMP";
        case DocumentCategory.PriorDAStampedPlans:
            return "PDS";
        case DocumentCategory.PriorNoticeofDetermination:
            return "PND";
        case DocumentCategory.PrivateLandConservationAgreementDetails:
            return "PLCA";
        case DocumentCategory.ProposedSubdivisionPlan:
            return "PSP";
        case DocumentCategory.PublicArtPlan:
            return "PAP";
        case DocumentCategory.PublicSubmissionsReflectivityPlan:
            return "PSRP";
        case DocumentCategory.RoadDesignPlan:
            return "RDP";
        case DocumentCategory.SalinityAssessment:
            return "SA";
        case DocumentCategory.ScheduleofColoursMaterialsAndFinishes:
            return "SCMF";
        case DocumentCategory.Section107PlanningCertificateFormerlySection149:
            return "S107";
        case DocumentCategory.Section46ApplicationtoVaryDevelopmentStandard:
            return "S46";
        case DocumentCategory.SectionJReport:
            return "SJ";
        case DocumentCategory.SEPP65Assessment:
            return "SEPP65";
        case DocumentCategory.Shadowdiagrams:
            return "SD";
        case DocumentCategory.SitePlans:
            return "SP";
        case DocumentCategory.SocialImpactAssessment:
            return "SIA";
        case DocumentCategory.SolarandDaylightAccessReport:
            return "SDAR";
        case DocumentCategory.SpeciesImpactStatement:
            return "SIS";
        case DocumentCategory.SprayDriftReport:
            return "SDR";
        case DocumentCategory.Statementofenvironmentaleffects:
            return "SEE";
        case DocumentCategory.StormWaterDrainagePlan:
            return "SWDP";
        case DocumentCategory.StormWaterManagementPlan:
            return "SWMP";
        case DocumentCategory.StructuralEngineersReport:
            return "SER";
        case DocumentCategory.SubdivisionPlan:
            return "SP";
        case DocumentCategory.Surveyplan:
            return "SP";
        case DocumentCategory.SurveyPlanServicesEasements:
            return "SPSE";
        case DocumentCategory.TitleDocumentationCertificateofTitle:
            return "TDOC";
        case DocumentCategory.TrafficCalmingDetails:
            return "TCD";
        case DocumentCategory.TrafficImpactAssessment:
            return "TIA";
        case DocumentCategory.TrafficManagementPlan:
            return "TMP";
        case DocumentCategory.TrafficReport:
            return "TR";
        case DocumentCategory.VariationtoDevelopmentControls:
            return "VDC";
        case DocumentCategory.VariationstoControlsintheDevelopmentControlPlan:
            return "VDCP";
        case DocumentCategory.VisualImpactAssessmentReport:
            return "VIAR";
        case DocumentCategory.VoluntaryPlanningAgreement:
            return "VPA";
        case DocumentCategory.WasteManagementPlan:
            return "WMP";
        case DocumentCategory.WastewaterManagementPlan:
            return "WWMP";
        case DocumentCategory.WaterManagementPlan:
            return "WMP";
        case DocumentCategory.WaterSensitiveUrbanDesignReport:
            return "WSUD";
        case DocumentCategory.WindEffectsPlan:
            return "WEP";
        case DocumentCategory.Other:
            return "Oth";
    }
};
exports.documentCategorysToTitlePrefix = documentCategorysToTitlePrefix;
