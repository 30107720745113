import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { Typography } from "@mui/material";

export interface SubmitModalProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const SubmitModal = ({
  onCancel,
  onConfirm,
  open,
}: SubmitModalProps) => {
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { maxHeight: 435, width: "80%" } }}
      maxWidth="xs"
      open={open}
      className="bgBlueOverlay"
    >
      <DialogTitle>{"Reject Submission"}</DialogTitle>
      <DialogContent>
        <Typography>
          {"This action will submit the condition for review."}
        </Typography>
      </DialogContent>
      <DialogActions>
        <StyledButton
          id="confirm-dialog-button-cancel"
          loading={false}
          onClick={onCancel}
          variant="outlined"
        >
          {"Cancel"}
        </StyledButton>
        <StyledButton
          data-test-id="confirm-dialog-button-ok"
          loading={false}
          onClick={() => onConfirm()}
          color={"error"}
        >
          {"Reject"}
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
};
