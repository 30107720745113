import { Box, Step, StepIcon, StepLabel, Stepper } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

interface StepsProps {
  activeStep: number;
  steps: string[];
  disabledSteps?: number[];
}

export const Steps = ({
  activeStep,
  steps,
  disabledSteps = [],
}: StepsProps) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => {
          const isDisabled = disabledSteps.includes(steps.indexOf(label));
          return (
            <Step key={label}>
              <StepLabel
                icon={
                  isDisabled ? (
                    <StepIcon icon={<CancelIcon sx={{ color: "#C5C5C5" }} />} />
                  ) : undefined
                }
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
