"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeConditionDocument = exports.decodeConditionDocument = void 0;
var condition_1 = require("./condition");
var conditionDocumentRevision_1 = require("./conditionDocumentRevision");
var string_1 = require("../utils/string");
var conditionDocument_1 = require("../entities/conditionDocument");
var decodeConditionDocument = function (document, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var latestRevision, safeDocument, conditions;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (document.revisions.length == 0) {
                    throw new Error("No reivisions for document");
                }
                latestRevision = document.revisions[0];
                safeDocument = (0, string_1.reversePostgresSafe)(document);
                return [4 /*yield*/, Promise.all(safeDocument.conditions.map(function (c) { return (0, condition_1.decodeCondition)(c, getImageUrl); }))];
            case 1:
                conditions = _a.sent();
                return [2 /*return*/, {
                        id: safeDocument.condition_document_id,
                        projectId: safeDocument.project_id,
                        documentTitle: safeDocument.document_title,
                        documentNumber: safeDocument.document_number,
                        conditions: conditions,
                        revisions: document.revisions.map(function (dr) {
                            return (0, conditionDocumentRevision_1.decodeConditionDocumentRevision)(dr);
                        }),
                        revisionDate: new Date(latestRevision.revision_date),
                        revisionNumber: latestRevision.revision_number,
                        fileSize: latestRevision.file_size,
                        fileType: latestRevision.file_type,
                        createdBy: latestRevision.created_by,
                        createdByName: latestRevision.created_by_name,
                        modified: new Date(latestRevision.created),
                        url: latestRevision.url,
                        documentDiscipline: safeDocument.document_discipline,
                        documentCategory: safeDocument.document_category,
                        created: new Date(safeDocument.created),
                    }];
        }
    });
}); };
exports.decodeConditionDocument = decodeConditionDocument;
var encodeConditionDocument = function (document) {
    var safeDocument = (0, string_1.makePostgresSafe)(document);
    return {
        condition_document_id: safeDocument.id,
        project_id: safeDocument.projectId,
        document_title: safeDocument.documentTitle,
        document_number: safeDocument.documentNumber,
        conditions: safeDocument.conditions.map(function (c) { return (0, condition_1.encodeCondition)(c); }),
        created: safeDocument.created,
        revisions: safeDocument.revisions.map(function (dr) {
            return (0, conditionDocumentRevision_1.encodeConditionDocumentRevision)(dr);
        }),
        document_discipline: safeDocument.documentDiscipline,
        document_category: safeDocument.documentCategory,
    };
};
exports.encodeConditionDocument = encodeConditionDocument;
var decodeConditionDocumentCategory = function (documentCategory) {
    switch (documentCategory) {
        default:
            throw new Error("Unknown document type ".concat(documentCategory));
        case "document-type_aboriginal-cultural-heritage-report":
            return conditionDocument_1.DocumentCategory.AboriginalCulturalHeritageReport;
        case "document-type_aboriginal-due-diligence-assessment":
            return conditionDocument_1.DocumentCategory.AboriginalDueDiligenceAssessment;
        case "document-type_access-report":
            return conditionDocument_1.DocumentCategory.AccessReport;
        case "document-type_acid-sulfate-soils-report":
            return conditionDocument_1.DocumentCategory.AcidSulfateSoilsReport;
        case "document-type_acoustic-and-vibration-assessment":
            return conditionDocument_1.DocumentCategory.AcousticAndVibrationAssessment;
        case "document-type_acoustic-report":
            return conditionDocument_1.DocumentCategory.AcousticReport;
        case "document-type_advertising-and-signage-detail-plan":
            return conditionDocument_1.DocumentCategory.AdvertisingandSignageDetailPlan;
        case "document-type_agricultural-impact-statement":
            return conditionDocument_1.DocumentCategory.AgriculturalImpactStatement;
        case "document-type_air-quality":
            return conditionDocument_1.DocumentCategory.AirQuality;
        case "document-type_air-quality-assessment":
            return conditionDocument_1.DocumentCategory.AirQualityAssessment;
        case "document-type_any-draft-section-88b-conveyancing-act-1919-instruments-":
            return conditionDocument_1.DocumentCategory.AnyDraftSection88BConveyancingAct1919Instruments;
        case "document-type_aquatic-ecology-assessment-report":
            return conditionDocument_1.DocumentCategory.AquaticEcologyAssessmentReport;
        case "document-type_arborists-report":
            return conditionDocument_1.DocumentCategory.ArboristsReport;
        case "document-type_archaeological-assessment":
            return conditionDocument_1.DocumentCategory.ArchaeologicalAssessment;
        case "document-type_architectural-plans":
            return conditionDocument_1.DocumentCategory.ArchitecturalPlans;
        case "document-type_basix-certificate":
            return conditionDocument_1.DocumentCategory.BASIXCertificate;
        case "document-type_bca-performance-requirements-compliance-statement":
            return conditionDocument_1.DocumentCategory.BCAPerformanceRequirementsComplianceStatement;
        case "document-type_biodiversity-test-of-significance":
            return conditionDocument_1.DocumentCategory.BiodiversityTestOfSignificance;
        case "document-type_biodiversity-assessment-method-bam-letter-":
            return conditionDocument_1.DocumentCategory.BiodiversityAssessmentMethodBAMLetter;
        case "document-type_biodiversity-development-assessment-report":
            return conditionDocument_1.DocumentCategory.BiodiversityDevelopmentAssessmentReport;
        case "document-type_biodiversity-offsets-scheme-threshold-report-":
            return conditionDocument_1.DocumentCategory.BiodiversityOffsetsSchemeThresholdReport;
        case "document-type_building-code-of-australia-report-":
            return conditionDocument_1.DocumentCategory.BuildingCodeOfAustraliaReport;
        case "document-type_bushfire-report":
            return conditionDocument_1.DocumentCategory.BushFireReport;
        case "document-type_car-parking-and-vehicle-access":
            return conditionDocument_1.DocumentCategory.CarParkingAndVehicleAccess;
        case "document-type_category-1-fire-safety-provisions":
            return conditionDocument_1.DocumentCategory.Category1FireSafetyProvisions;
        case "document-type_civil-engineering-plan":
            return conditionDocument_1.DocumentCategory.CivilEngineeringPlan;
        case "document-type_clause-4.6-variation-request":
            return conditionDocument_1.DocumentCategory.Clause46VariationRequest;
        case "document-type_coastal-assessment-report":
            return conditionDocument_1.DocumentCategory.CoastalAssessmentReport;
        case "document-type_competitive-design-process-report":
            return conditionDocument_1.DocumentCategory.CompetitiveDesignProcessReport;
        case "document-type_conservation-management-plan-or-strategy":
            return conditionDocument_1.DocumentCategory.ConservationManagementPlanorStrategy;
        case "document-type_construction-management-plan":
            return conditionDocument_1.DocumentCategory.ConstructionManagementPlan;
        case "document-type_contamination-and/or-remediation-action-plan":
            return conditionDocument_1.DocumentCategory.ContaminationAndOrRemediationActionPlan;
        case "document-type_cost-estimate-report":
            return conditionDocument_1.DocumentCategory.CostEstimateReport;
        case "document-type_council-da-checklist":
            return conditionDocument_1.DocumentCategory.CouncilDAChecklist;
        case "document-type_crime-prevention-through-environmental-design-cpted-report":
            return conditionDocument_1.DocumentCategory.CrimePreventionthroughEnvironmentalDesignCPTEDReport;
        case "document-type_dam-report":
            return conditionDocument_1.DocumentCategory.DamReport;
        case "document-type_demolition-management-plan":
            return conditionDocument_1.DocumentCategory.DemolitionManagementPlan;
        case "document-type_demolition-work-plan":
            return conditionDocument_1.DocumentCategory.DemolitionWorkPlan;
        case "document-type_design-statement-for-compliance-with-low-rise-housing-design-guide":
            return conditionDocument_1.DocumentCategory.DesignStatementForComplianceWithLowRiseHousingDesignGuide;
        case "document-type_design-verification-statement":
            return conditionDocument_1.DocumentCategory.DesignVerificationStatement;
        case "document-type_disability-access-plan-dda":
            return conditionDocument_1.DocumentCategory.DisabilityAccessPlanDDA;
        case "document-type_driveway-and-access-plan":
            return conditionDocument_1.DocumentCategory.DrivewayAndAccessPlan;
        case "document-type_elevations-and-sections":
            return conditionDocument_1.DocumentCategory.ElevationsAndSections;
        case "document-type_environmental-impact-statement":
            return conditionDocument_1.DocumentCategory.EnvironmentalImpactStatement;
        case "document-type_erosion-and-sediment-control-plan":
            return conditionDocument_1.DocumentCategory.ErosionandSedimentControlPlan;
        case "document-type_estuarine-hazard-assessment-report":
            return conditionDocument_1.DocumentCategory.EstuarineHazardAssessmentReport;
        case "document-type_evidence-of-authority-under-mining-act-1992":
            return conditionDocument_1.DocumentCategory.EvidenceOfAuthorityUnderminingAct1992;
        case "document-type_evidence-of-loss-of-low-rental-dwellings-excavation-and-or-fill-plan":
            return conditionDocument_1.DocumentCategory.EvidenceOfLossOfoewRentalDwellingsExcavationandOrFillPlan;
        case "document-type_fire-safety-upgrade-report":
            return conditionDocument_1.DocumentCategory.FireSafetyUpgradeReport;
        case "document-type_flood-risk-impact-and-or-management-plan":
            return conditionDocument_1.DocumentCategory.FloodRiskImpactAndOrManagementPlan;
        case "document-type_flood-risk-management-report":
            return conditionDocument_1.DocumentCategory.Floodriskmanagementreport;
        case "document-type_floor-and-or-roof-plan":
            return conditionDocument_1.DocumentCategory.FloorAndOrRoofPlan;
        case "document-type_floor-plans":
            return conditionDocument_1.DocumentCategory.FloorPlans;
        case "document-type_flora-and-fauna-assessment":
            return conditionDocument_1.DocumentCategory.FloraAndFaunaAssessment;
        case "document-type_geotechnical-report":
            return conditionDocument_1.DocumentCategory.GeoTechnicalReport;
        case "document-type_heritage-impact-statement":
            return conditionDocument_1.DocumentCategory.HeritageImpactStatement;
        case "document-type_hunter-water-stamp-approval":
            return conditionDocument_1.DocumentCategory.HunterWaterStampApproval;
        case "document-type_hydrogeological-report":
            return conditionDocument_1.DocumentCategory.Hydrogeologicalreport;
        case "document-type_landscape-plan":
            return conditionDocument_1.DocumentCategory.LandscapePlan;
        case "document-type_life-cycle-assessment-(lca)-liquid-trade-waste-details":
            return conditionDocument_1.DocumentCategory.LifeCycleAssessmentLCALiquidTradeWasteDetails;
        case "document-type_mines-subsidence-stamped-plans-mosquito-report":
            return conditionDocument_1.DocumentCategory.MinesSubsidenceStampedPlansMosquitoReport;
        case "document-type_nabers-agreement-to-rate-nabers-commitment-agreement-net-zero-statement":
            return conditionDocument_1.DocumentCategory.NABERSAgreementToRateNABERSCommitmentAgreementNetZeroStatement;
        case "document-type_notification-plan":
            return conditionDocument_1.DocumentCategory.NotificationPlan;
        case "document-type_notification-plans":
            return conditionDocument_1.DocumentCategory.NotificationPlans;
        case "document-type_odour-assessment-report-other":
            return conditionDocument_1.DocumentCategory.OdourAssessmentReportOther;
        case "document-type_owner-builder-permit-(>$10,000)":
            return conditionDocument_1.DocumentCategory.OwnerBuilderPermit;
        case "document-type_owners-consent":
            return conditionDocument_1.DocumentCategory.OwnersConsent;
        case "document-type_parking-report":
            return conditionDocument_1.DocumentCategory.ParkingReport;
        case "document-type_party-wall-consent":
            return conditionDocument_1.DocumentCategory.PartyWallConsent;
        case "document-type_performance-solution-documentation":
            return conditionDocument_1.DocumentCategory.PerformanceSolutionDocumentation;
        case "document-type_photomontage":
            return conditionDocument_1.DocumentCategory.Photomontage;
        case "document-type_plan-of-management":
            return conditionDocument_1.DocumentCategory.PlanOfManagement;
        case "document-type_planning-agreement":
            return conditionDocument_1.DocumentCategory.PlanningAgreement;
        case "document-type_political-donations-and-gifts-disclosure":
            return conditionDocument_1.DocumentCategory.PoliticalDonationsAndGiftsDisclosure;
        case "document-type_preliminary-engineering-drawings":
            return conditionDocument_1.DocumentCategory.PreliminaryEngineeringDrawings;
        case "document-type_preliminary-sewer-and-water-feasibility-study":
            return conditionDocument_1.DocumentCategory.PreliminarySewerandWaterFeasibilityStudy;
        case "document-type_preliminary-stormwater-management-plan":
            return conditionDocument_1.DocumentCategory.PreliminaryStormwaterManagementPlan;
        case "document-type_prior-da-stamped-plans":
            return conditionDocument_1.DocumentCategory.PriorDAStampedPlans;
        case "document-type_prior-notice-of-determination":
            return conditionDocument_1.DocumentCategory.PriorNoticeofDetermination;
        case "document-type_private-land-conservation-agreement-details":
            return conditionDocument_1.DocumentCategory.PrivateLandConservationAgreementDetails;
        case "document-type_proposed-subdivision-plan":
            return conditionDocument_1.DocumentCategory.ProposedSubdivisionPlan;
        case "document-type_public-art-plan":
            return conditionDocument_1.DocumentCategory.PublicArtPlan;
        case "document-type_public-submissions-reflectivity-plan":
            return conditionDocument_1.DocumentCategory.PublicSubmissionsReflectivityPlan;
        case "document-type_road-design-plan":
            return conditionDocument_1.DocumentCategory.RoadDesignPlan;
        case "document-type_salinity-assessment":
            return conditionDocument_1.DocumentCategory.SalinityAssessment;
        case "document-type_schedule-of-colours,-materials-and-finishes":
            return conditionDocument_1.DocumentCategory.ScheduleofColoursMaterialsAndFinishes;
        case "document-type_section-10.7-planning-certificate-formerly-section-149":
            return conditionDocument_1.DocumentCategory.Section107PlanningCertificateFormerlySection149;
        case "document-type_section-4.6-application-to-vary-development-standard":
            return conditionDocument_1.DocumentCategory.Section46ApplicationtoVaryDevelopmentStandard;
        case "document-type_section-j-report":
            return conditionDocument_1.DocumentCategory.SectionJReport;
        case "document-type_sepp-65-assessment":
            return conditionDocument_1.DocumentCategory.SEPP65Assessment;
        case "document-type_shadow-diagrams":
            return conditionDocument_1.DocumentCategory.Shadowdiagrams;
        case "document-type_site-plans":
            return conditionDocument_1.DocumentCategory.SitePlans;
        case "document-type_social-impact-assessment":
            return conditionDocument_1.DocumentCategory.SocialImpactAssessment;
        case "document-type_solar-and-daylight-access-report":
            return conditionDocument_1.DocumentCategory.SolarandDaylightAccessReport;
        case "document-type_species-impact-statement":
            return conditionDocument_1.DocumentCategory.SpeciesImpactStatement;
        case "document-type_spray-drift-report":
            return conditionDocument_1.DocumentCategory.SprayDriftReport;
        case "document-type_statement-of-environmental-effects":
            return conditionDocument_1.DocumentCategory.Statementofenvironmentaleffects;
        case "document-type_stormwater-drainage-plan":
            return conditionDocument_1.DocumentCategory.StormWaterDrainagePlan;
        case "document-type_stormwater-management-plan":
            return conditionDocument_1.DocumentCategory.StormWaterManagementPlan;
        case "document-type_structural-engineers-report":
            return conditionDocument_1.DocumentCategory.StructuralEngineersReport;
        case "document-type_subdivision-plan":
            return conditionDocument_1.DocumentCategory.SubdivisionPlan;
        case "document-type_survey-plan":
            return conditionDocument_1.DocumentCategory.Surveyplan;
        case "document-type_survey-plan-services-easements":
            return conditionDocument_1.DocumentCategory.SurveyPlanServicesEasements;
        case "document-type_title-documentation-certificate-of-title":
            return conditionDocument_1.DocumentCategory.TitleDocumentationCertificateofTitle;
        case "document-type_traffic-calming-details":
            return conditionDocument_1.DocumentCategory.TrafficCalmingDetails;
        case "document-type_traffic-impact-assessment":
            return conditionDocument_1.DocumentCategory.TrafficImpactAssessment;
        case "document-type_traffic-management-plan":
            return conditionDocument_1.DocumentCategory.TrafficManagementPlan;
        case "document-type_traffic-report":
            return conditionDocument_1.DocumentCategory.TrafficReport;
        case "document-type_variation-to-development-control(s)":
            return conditionDocument_1.DocumentCategory.VariationtoDevelopmentControls;
        case "document-type_variations-to-controls-in-the-development-control-plan":
            return conditionDocument_1.DocumentCategory.VariationstoControlsintheDevelopmentControlPlan;
        case "document-type_visual-impact-assessment-report":
            return conditionDocument_1.DocumentCategory.VisualImpactAssessmentReport;
        case "document-type_voluntary-planning-agreement":
            return conditionDocument_1.DocumentCategory.VoluntaryPlanningAgreement;
        case "document-type_waste-management-plan":
            return conditionDocument_1.DocumentCategory.WasteManagementPlan;
        case "document-type_wastewater-management-plan":
            return conditionDocument_1.DocumentCategory.WastewaterManagementPlan;
        case "document-type_water-management-plan":
            return conditionDocument_1.DocumentCategory.WaterManagementPlan;
        case "document-type_water-sensitive-urban-design-report":
            return conditionDocument_1.DocumentCategory.WaterSensitiveUrbanDesignReport;
        case "document-type_wind-effects-plan":
            return conditionDocument_1.DocumentCategory.WindEffectsPlan;
        case "document-type_other":
            return conditionDocument_1.DocumentCategory.Other;
    }
};
var encodeConditionDocumentCategory = function (documentCategory) {
    switch (documentCategory) {
        case conditionDocument_1.DocumentCategory.AboriginalCulturalHeritageReport:
            return "document-type_aboriginal-cultural-heritage-report";
        case conditionDocument_1.DocumentCategory.AboriginalDueDiligenceAssessment:
            return "document-type_aboriginal-due-diligence-assessment";
        case conditionDocument_1.DocumentCategory.AccessReport:
            return "document-type_access-report";
        case conditionDocument_1.DocumentCategory.AcidSulfateSoilsReport:
            return "document-type_acid-sulfate-soils-report";
        case conditionDocument_1.DocumentCategory.AcousticAndVibrationAssessment:
            return "document-type_acoustic-and-vibration-assessment";
        case conditionDocument_1.DocumentCategory.AcousticReport:
            return "document-type_acoustic-report";
        case conditionDocument_1.DocumentCategory.AdvertisingandSignageDetailPlan:
            return "document-type_advertising-and-signage-detail-plan";
        case conditionDocument_1.DocumentCategory.AgriculturalImpactStatement:
            return "document-type_agricultural-impact-statement";
        case conditionDocument_1.DocumentCategory.AirQuality:
            return "document-type_air-quality";
        case conditionDocument_1.DocumentCategory.AirQualityAssessment:
            return "document-type_air-quality-assessment";
        case conditionDocument_1.DocumentCategory.AnyDraftSection88BConveyancingAct1919Instruments:
            return "document-type_any-draft-section-88b-conveyancing-act-1919-instruments-";
        case conditionDocument_1.DocumentCategory.AquaticEcologyAssessmentReport:
            return "document-type_aquatic-ecology-assessment-report";
        case conditionDocument_1.DocumentCategory.ArboristsReport:
            return "document-type_arborists-report";
        case conditionDocument_1.DocumentCategory.ArchaeologicalAssessment:
            return "document-type_archaeological-assessment";
        case conditionDocument_1.DocumentCategory.ArchitecturalPlans:
            return "document-type_architectural-plans";
        case conditionDocument_1.DocumentCategory.BASIXCertificate:
            return "document-type_basix-certificate";
        case conditionDocument_1.DocumentCategory.BCAPerformanceRequirementsComplianceStatement:
            return "document-type_bca-performance-requirements-compliance-statement";
        case conditionDocument_1.DocumentCategory.BiodiversityTestOfSignificance:
            return "document-type_biodiversity-test-of-significance";
        case conditionDocument_1.DocumentCategory.BiodiversityAssessmentMethodBAMLetter:
            return "document-type_biodiversity-assessment-method-bam-letter-";
        case conditionDocument_1.DocumentCategory.BiodiversityDevelopmentAssessmentReport:
            return "document-type_biodiversity-development-assessment-report";
        case conditionDocument_1.DocumentCategory.BiodiversityOffsetsSchemeThresholdReport:
            return "document-type_biodiversity-offsets-scheme-threshold-report-";
        case conditionDocument_1.DocumentCategory.BuildingCodeOfAustraliaReport:
            return "document-type_building-code-of-australia-report-";
        case conditionDocument_1.DocumentCategory.BushFireReport:
            return "document-type_bushfire-report";
        case conditionDocument_1.DocumentCategory.CarParkingAndVehicleAccess:
            return "document-type_car-parking-and-vehicle-access";
        case conditionDocument_1.DocumentCategory.Category1FireSafetyProvisions:
            return "document-type_category-1-fire-safety-provisions";
        case conditionDocument_1.DocumentCategory.CivilEngineeringPlan:
            return "document-type_civil-engineering-plan";
        case conditionDocument_1.DocumentCategory.Clause46VariationRequest:
            return "document-type_clause-4.6-variation-request";
        case conditionDocument_1.DocumentCategory.CoastalAssessmentReport:
            return "document-type_coastal-assessment-report";
        case conditionDocument_1.DocumentCategory.CompetitiveDesignProcessReport:
            return "document-type_competitive-design-process-report";
        case conditionDocument_1.DocumentCategory.ConservationManagementPlanorStrategy:
            return "document-type_conservation-management-plan-or-strategy";
        case conditionDocument_1.DocumentCategory.ConstructionManagementPlan:
            return "document-type_construction-management-plan";
        case conditionDocument_1.DocumentCategory.ContaminationAndOrRemediationActionPlan:
            return "document-type_contamination-and/or-remediation-action-plan";
        case conditionDocument_1.DocumentCategory.CostEstimateReport:
            return "document-type_cost-estimate-report";
        case conditionDocument_1.DocumentCategory.CouncilDAChecklist:
            return "document-type_council-da-checklist";
        case conditionDocument_1.DocumentCategory.CrimePreventionthroughEnvironmentalDesignCPTEDReport:
            return "document-type_crime-prevention-through-environmental-design-cpted-report";
        case conditionDocument_1.DocumentCategory.DamReport:
            return "document-type_dam-report";
        case conditionDocument_1.DocumentCategory.DemolitionManagementPlan:
            return "document-type_demolition-management-plan";
        case conditionDocument_1.DocumentCategory.DemolitionWorkPlan:
            return "document-type_demolition-work-plan";
        case conditionDocument_1.DocumentCategory.DesignStatementForComplianceWithLowRiseHousingDesignGuide:
            return "document-type_design-statement-for-compliance-with-low-rise-housing-design-guide";
        case conditionDocument_1.DocumentCategory.DesignVerificationStatement:
            return "document-type_design-verification-statement";
        case conditionDocument_1.DocumentCategory.DisabilityAccessPlanDDA:
            return "document-type_disability-access-plan-dda";
        case conditionDocument_1.DocumentCategory.DrivewayAndAccessPlan:
            return "document-type_driveway-and-access-plan";
        case conditionDocument_1.DocumentCategory.ElevationsAndSections:
            return "document-type_elevations-and-sections";
        case conditionDocument_1.DocumentCategory.EnvironmentalImpactStatement:
            return "document-type_environmental-impact-statement";
        case conditionDocument_1.DocumentCategory.ErosionandSedimentControlPlan:
            return "document-type_erosion-and-sediment-control-plan";
        case conditionDocument_1.DocumentCategory.EstuarineHazardAssessmentReport:
            return "document-type_estuarine-hazard-assessment-report";
        case conditionDocument_1.DocumentCategory.EvidenceOfAuthorityUnderminingAct1992:
            return "document-type_evidence-of-authority-under-mining-act-1992";
        case conditionDocument_1.DocumentCategory.EvidenceOfLossOfoewRentalDwellingsExcavationandOrFillPlan:
            return "document-type_evidence-of-loss-of-low-rental-dwellings-excavation-and-or-fill-plan";
        case conditionDocument_1.DocumentCategory.FireSafetyUpgradeReport:
            return "document-type_fire-safety-upgrade-report";
        case conditionDocument_1.DocumentCategory.FloodRiskImpactAndOrManagementPlan:
            return "document-type_flood-risk-impact-and-or-management-plan";
        case conditionDocument_1.DocumentCategory.Floodriskmanagementreport:
            return "document-type_flood-risk-management-report";
        case conditionDocument_1.DocumentCategory.FloorAndOrRoofPlan:
            return "document-type_floor-and-or-roof-plan";
        case conditionDocument_1.DocumentCategory.FloorPlans:
            return "document-type_floor-plans";
        case conditionDocument_1.DocumentCategory.FloraAndFaunaAssessment:
            return "document-type_flora-and-fauna-assessment";
        case conditionDocument_1.DocumentCategory.GeoTechnicalReport:
            return "document-type_geotechnical-report";
        case conditionDocument_1.DocumentCategory.HeritageImpactStatement:
            return "document-type_heritage-impact-statement";
        case conditionDocument_1.DocumentCategory.HunterWaterStampApproval:
            return "document-type_hunter-water-stamp-approval";
        case conditionDocument_1.DocumentCategory.Hydrogeologicalreport:
            return "document-type_hydrogeological-report";
        case conditionDocument_1.DocumentCategory.LandscapePlan:
            return "document-type_landscape-plan";
        case conditionDocument_1.DocumentCategory.LifeCycleAssessmentLCALiquidTradeWasteDetails:
            return "document-type_life-cycle-assessment-(lca)-liquid-trade-waste-details";
        case conditionDocument_1.DocumentCategory.MinesSubsidenceStampedPlansMosquitoReport:
            return "document-type_mines-subsidence-stamped-plans-mosquito-report";
        case conditionDocument_1.DocumentCategory.NABERSAgreementToRateNABERSCommitmentAgreementNetZeroStatement:
            return "document-type_nabers-agreement-to-rate-nabers-commitment-agreement-net-zero-statement";
        case conditionDocument_1.DocumentCategory.NotificationPlan:
            return "document-type_notification-plan";
        case conditionDocument_1.DocumentCategory.NotificationPlans:
            return "document-type_notification-plans";
        case conditionDocument_1.DocumentCategory.OdourAssessmentReportOther:
            return "document-type_odour-assessment-report-other";
        case conditionDocument_1.DocumentCategory.OwnerBuilderPermit:
            return "document-type_owner-builder-permit-(>$10,000)";
        case conditionDocument_1.DocumentCategory.OwnersConsent:
            return "document-type_owners-consent";
        case conditionDocument_1.DocumentCategory.ParkingReport:
            return "document-type_parking-report";
        case conditionDocument_1.DocumentCategory.PartyWallConsent:
            return "document-type_party-wall-consent";
        case conditionDocument_1.DocumentCategory.PerformanceSolutionDocumentation:
            return "document-type_performance-solution-documentation";
        case conditionDocument_1.DocumentCategory.Photomontage:
            return "document-type_photomontage";
        case conditionDocument_1.DocumentCategory.PlanOfManagement:
            return "document-type_plan-of-management";
        case conditionDocument_1.DocumentCategory.PlanningAgreement:
            return "document-type_planning-agreement";
        case conditionDocument_1.DocumentCategory.PoliticalDonationsAndGiftsDisclosure:
            return "document-type_political-donations-and-gifts-disclosure";
        case conditionDocument_1.DocumentCategory.PreliminaryEngineeringDrawings:
            return "document-type_preliminary-engineering-drawings";
        case conditionDocument_1.DocumentCategory.PreliminarySewerandWaterFeasibilityStudy:
            return "document-type_preliminary-sewer-and-water-feasibility-study";
        case conditionDocument_1.DocumentCategory.PreliminaryStormwaterManagementPlan:
            return "document-type_preliminary-stormwater-management-plan";
        case conditionDocument_1.DocumentCategory.PriorDAStampedPlans:
            return "document-type_prior-da-stamped-plans";
        case conditionDocument_1.DocumentCategory.PriorNoticeofDetermination:
            return "document-type_prior-notice-of-determination";
        case conditionDocument_1.DocumentCategory.PrivateLandConservationAgreementDetails:
            return "document-type_private-land-conservation-agreement-details";
        case conditionDocument_1.DocumentCategory.ProposedSubdivisionPlan:
            return "document-type_proposed-subdivision-plan";
        case conditionDocument_1.DocumentCategory.PublicArtPlan:
            return "document-type_public-art-plan";
        case conditionDocument_1.DocumentCategory.PublicSubmissionsReflectivityPlan:
            return "document-type_public-submissions-reflectivity-plan";
        case conditionDocument_1.DocumentCategory.RoadDesignPlan:
            return "document-type_road-design-plan";
        case conditionDocument_1.DocumentCategory.SalinityAssessment:
            return "document-type_salinity-assessment";
        case conditionDocument_1.DocumentCategory.ScheduleofColoursMaterialsAndFinishes:
            return "document-type_schedule-of-colours,-materials-and-finishes";
        case conditionDocument_1.DocumentCategory.Section107PlanningCertificateFormerlySection149:
            return "document-type_section-10.7-planning-certificate-formerly-section-149";
        case conditionDocument_1.DocumentCategory.Section46ApplicationtoVaryDevelopmentStandard:
            return "document-type_section-4.6-application-to-vary-development-standard";
        case conditionDocument_1.DocumentCategory.SectionJReport:
            return "document-type_section-j-report";
        case conditionDocument_1.DocumentCategory.SEPP65Assessment:
            return "document-type_sepp-65-assessment";
        case conditionDocument_1.DocumentCategory.Shadowdiagrams:
            return "document-type_shadow-diagrams";
        case conditionDocument_1.DocumentCategory.SitePlans:
            return "document-type_site-plans";
        case conditionDocument_1.DocumentCategory.SocialImpactAssessment:
            return "document-type_social-impact-assessment";
        case conditionDocument_1.DocumentCategory.SolarandDaylightAccessReport:
            return "document-type_solar-and-daylight-access-report";
        case conditionDocument_1.DocumentCategory.SpeciesImpactStatement:
            return "document-type_species-impact-statement";
        case conditionDocument_1.DocumentCategory.SprayDriftReport:
            return "document-type_spray-drift-report";
        case conditionDocument_1.DocumentCategory.Statementofenvironmentaleffects:
            return "document-type_statement-of-environmental-effects";
        case conditionDocument_1.DocumentCategory.StormWaterDrainagePlan:
            return "document-type_stormwater-drainage-plan";
        case conditionDocument_1.DocumentCategory.StormWaterManagementPlan:
            return "document-type_stormwater-management-plan";
        case conditionDocument_1.DocumentCategory.StructuralEngineersReport:
            return "document-type_structural-engineers-report";
        case conditionDocument_1.DocumentCategory.SubdivisionPlan:
            return "document-type_subdivision-plan";
        case conditionDocument_1.DocumentCategory.Surveyplan:
            return "document-type_survey-plan";
        case conditionDocument_1.DocumentCategory.SurveyPlanServicesEasements:
            return "document-type_survey-plan-services-easements";
        case conditionDocument_1.DocumentCategory.TitleDocumentationCertificateofTitle:
            return "document-type_title-documentation-certificate-of-title";
        case conditionDocument_1.DocumentCategory.TrafficCalmingDetails:
            return "document-type_traffic-calming-details";
        case conditionDocument_1.DocumentCategory.TrafficImpactAssessment:
            return "document-type_traffic-impact-assessment";
        case conditionDocument_1.DocumentCategory.TrafficManagementPlan:
            return "document-type_traffic-management-plan";
        case conditionDocument_1.DocumentCategory.TrafficReport:
            return "document-type_traffic-report";
        case conditionDocument_1.DocumentCategory.VariationtoDevelopmentControls:
            return "document-type_variation-to-development-control(s)";
        case conditionDocument_1.DocumentCategory.VariationstoControlsintheDevelopmentControlPlan:
            return "document-type_variations-to-controls-in-the-development-control-plan";
        case conditionDocument_1.DocumentCategory.VisualImpactAssessmentReport:
            return "document-type_visual-impact-assessment-report";
        case conditionDocument_1.DocumentCategory.VoluntaryPlanningAgreement:
            return "document-type_voluntary-planning-agreement";
        case conditionDocument_1.DocumentCategory.WasteManagementPlan:
            return "document-type_waste-management-plan";
        case conditionDocument_1.DocumentCategory.WastewaterManagementPlan:
            return "document-type_wastewater-management-plan";
        case conditionDocument_1.DocumentCategory.WaterManagementPlan:
            return "document-type_water-management-plan";
        case conditionDocument_1.DocumentCategory.WaterSensitiveUrbanDesignReport:
            return "document-type_water-sensitive-urban-design-report";
        case conditionDocument_1.DocumentCategory.WindEffectsPlan:
            return "document-type_wind-effects-plan";
        case conditionDocument_1.DocumentCategory.Other:
            return "document-type_other";
    }
};
