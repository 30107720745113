import {
  ProfileLineItem,
  createDefaultProfile,
} from "permit-one-common/src/interfaces/profile";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useAuthContext } from "@hooks/context/useAuthContext";
import * as RouteHelper from "@utils/routes";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { Card, Stack } from "@mui/material";
import { AcceptInviteForm } from "@stories/organisms/AcceptInviteForm/AcceptInviteForm";
import { browserStorage } from "permit-one-common/src/utils/browserStorage";

export const AcceptInvite = (): JSX.Element => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const { createUserProfile } = useProfileContext();

  if (!user) {
    throw Error("No user in state.");
  }

  const finalizeAccount = async (newProfile: ProfileLineItem) => {
    await createUserProfile(newProfile);
    browserStorage.removeItem("isInvite");
    navigate(RouteHelper.listProjects);
  };

  const title = "Create Account";

  return (
    <>
      <Card>
        <SubTitle title={title} />
        <Stack spacing={4}>
          <AcceptInviteForm
            profile={createDefaultProfile(user)}
            handleSubmit={finalizeAccount}
          />
        </Stack>
      </Card>
    </>
  );
};
