import { DEFAULT_ROWS_PER_PAGE } from "permit-one-common/src/interfaces/tables";
import { useState } from "react";

export type Order = "asc" | "desc";
export const useTable = () => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState(0);

  const handleRequestSort = (property: number) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [anchorElement, setAnchorElement] = useState<Element | undefined>(
    undefined
  );

  const [page, setPage] = useState(0);

  const [selected, setSelected] = useState<string[]>([]);

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const handleOpenMenu = (event: any) => {
    setAnchorElement(event.target as Element);
  };

  const handleCloseMenu = () => {
    setAnchorElement(undefined);
  };

  const handleSelectAllClick = (event: any, fullList: string[]) => {
    if (event.target.checked) {
      setSelected(fullList);
      return;
    }
    setSelected([]);
  };

  const clearSelected = () => {
    setSelected([]);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event: any) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return {
    anchorElement,
    setAnchorElement,
    page,
    setPage,
    selected,
    setSelected,
    clearSelected,
    filterName,
    setFilterName,
    rowsPerPage,
    order,
    orderBy,
    setRowsPerPage,
    handleRequestSort,
    handleOpenMenu,
    handleCloseMenu,
    handleSelectAllClick,
    handleClick,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilterByName,
  };
};
