"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.conditionDisciplineToString = exports.conditionDisciplineToPrefix = exports.ConditionDiscipline = exports.ConditionStatus = exports.conditionAuthorityToString = exports.ConditionAuthority = void 0;
var ConditionAuthority;
(function (ConditionAuthority) {
    ConditionAuthority["BCA"] = "condition-authority_bca";
    ConditionAuthority["DA"] = "condition-authority_da";
})(ConditionAuthority || (exports.ConditionAuthority = ConditionAuthority = {}));
var conditionAuthorityToString = function (conditionAuthority) {
    switch (conditionAuthority) {
        case ConditionAuthority.BCA:
            return "BCA";
        case ConditionAuthority.DA:
            return "DA";
    }
};
exports.conditionAuthorityToString = conditionAuthorityToString;
var ConditionStatus;
(function (ConditionStatus) {
    ConditionStatus["NotAssigned"] = "condition-status_not-assigned";
    ConditionStatus["Assigned"] = "condition-status_assigned";
    ConditionStatus["InReview"] = "condition-status_in_review";
    ConditionStatus["Overdue"] = "condition-status_overdue";
    ConditionStatus["Rejected"] = "condition-status_rejected";
    ConditionStatus["Approved"] = "condition-status_approved";
    ConditionStatus["NotApplicable"] = "condition-status_not-applicable";
})(ConditionStatus || (exports.ConditionStatus = ConditionStatus = {}));
var ConditionDiscipline;
(function (ConditionDiscipline) {
    ConditionDiscipline["Access"] = "condition-discipline_access";
    ConditionDiscipline["Accounting"] = "condition-discipline_accounting";
    ConditionDiscipline["Acoustics"] = "condition-discipline_acoustics";
    ConditionDiscipline["Administration"] = "condition-discipline_administration";
    ConditionDiscipline["Arborist"] = "condition-discipline_arborist";
    ConditionDiscipline["Architectural"] = "condition-discipline_architectural";
    ConditionDiscipline["BCAConsultant"] = "condition-discipline_bca_consultant";
    ConditionDiscipline["Bushfire"] = "condition-discipline_bushfire";
    ConditionDiscipline["Civil"] = "condition-discipline_civil";
    ConditionDiscipline["Client"] = "condition-discipline_client";
    ConditionDiscipline["Contamination"] = "condition-discipline_contamination";
    ConditionDiscipline["Construction"] = "condition-discipline_construction";
    ConditionDiscipline["Ecological"] = "condition-discipline_ecological";
    ConditionDiscipline["Electrical"] = "condition-discipline_electrical";
    ConditionDiscipline["Environmental"] = "condition-discipline_environmental";
    ConditionDiscipline["ESD"] = "condition-discipline_esd";
    ConditionDiscipline["Fa\u00E7ade"] = "condition-discipline_facade";
    ConditionDiscipline["FireEngineering"] = "condition-discipline_fire_engineering";
    ConditionDiscipline["FirePenetrations"] = "condition-discipline_fire_penetrations";
    ConditionDiscipline["FireProtection"] = "condition-discipline_fire_protection";
    ConditionDiscipline["Geotechnical"] = "condition-discipline_geotechnical";
    ConditionDiscipline["Heritage"] = "condition-discipline_heritage";
    ConditionDiscipline["Hydraulic"] = "condition-discipline_hydraulic";
    ConditionDiscipline["InteriorDesign"] = "condition-discipline_interior_design";
    ConditionDiscipline["Landscaping"] = "condition-discipline_landscaping";
    ConditionDiscipline["Legal"] = "condition-discipline_legal";
    ConditionDiscipline["LiftsConveyors"] = "condition-discipline_lifts_conveyors";
    ConditionDiscipline["MarketAnalysis"] = "condition-discipline_market_analysis";
    ConditionDiscipline["Mechanical"] = "condition-discipline_mechanical";
    ConditionDiscipline["PrincipalCertifyingAuthority"] = "condition-discipline_principal_certifying_authority";
    ConditionDiscipline["QuantitySurveyor"] = "condition-discipline_quantity_surveyor";
    ConditionDiscipline["Structure"] = "condition-discipline_structure";
    ConditionDiscipline["Subcontractor"] = "condition-discipline_subcontractor";
    ConditionDiscipline["Survey"] = "condition-discipline_survey";
    ConditionDiscipline["TownPlanner"] = "condition-discipline_town_planner";
    ConditionDiscipline["TrafficEngineering"] = "condition-discipline_traffic_engineering";
    ConditionDiscipline["WasteEngineer"] = "condition-discipline_waste_engineer";
    ConditionDiscipline["Waterproofing"] = "condition-discipline_waterproofing";
    ConditionDiscipline["Other"] = "condition-discipline_other";
})(ConditionDiscipline || (exports.ConditionDiscipline = ConditionDiscipline = {}));
var conditionDisciplineToPrefix = function (conditionDiscipline) {
    switch (conditionDiscipline) {
        case ConditionDiscipline.Access:
            return "ACS";
        case ConditionDiscipline.Accounting:
            return "ACC";
        case ConditionDiscipline.Acoustics:
            return "ACO";
        case ConditionDiscipline.Administration:
            return "ADM";
        case ConditionDiscipline.Arborist:
            return "ARB";
        case ConditionDiscipline.Architectural:
            return "ARC";
        case ConditionDiscipline.BCAConsultant:
            return "BCA";
        case ConditionDiscipline.Bushfire:
            return "BUS";
        case ConditionDiscipline.Civil:
            return "CIV";
        case ConditionDiscipline.Client:
            return "CLI";
        case ConditionDiscipline.Contamination:
            return "CON";
        case ConditionDiscipline.Construction:
            return "COS";
        case ConditionDiscipline.Ecological:
            return "ECO";
        case ConditionDiscipline.Electrical:
            return "ELE";
        case ConditionDiscipline.Environmental:
            return "ENV";
        case ConditionDiscipline.ESD:
            return "ESD";
        case ConditionDiscipline.Façade:
            return "FAC";
        case ConditionDiscipline.FireEngineering:
            return "FIR";
        case ConditionDiscipline.FirePenetrations:
            return "FIP";
        case ConditionDiscipline.FireProtection:
            return "FIP";
        case ConditionDiscipline.Geotechnical:
            return "GEO";
        case ConditionDiscipline.Heritage:
            return "HER";
        case ConditionDiscipline.Hydraulic:
            return "HYD";
        case ConditionDiscipline.InteriorDesign:
            return "INT";
        case ConditionDiscipline.Landscaping:
            return "LAN";
        case ConditionDiscipline.Legal:
            return "LEG";
        case ConditionDiscipline.LiftsConveyors:
            return "LIF";
        case ConditionDiscipline.MarketAnalysis:
            return "MAR";
        case ConditionDiscipline.Mechanical:
            return "MEC";
        case ConditionDiscipline.PrincipalCertifyingAuthority:
            return "PCA";
        case ConditionDiscipline.QuantitySurveyor:
            return "QUS";
        case ConditionDiscipline.Structure:
            return "STR";
        case ConditionDiscipline.Subcontractor:
            return "SUB";
        case ConditionDiscipline.Survey:
            return "SUR";
        case ConditionDiscipline.TownPlanner:
            return "TOW";
        case ConditionDiscipline.TrafficEngineering:
            return "TRA";
        case ConditionDiscipline.WasteEngineer:
            return "WAS";
        case ConditionDiscipline.Waterproofing:
            return "WAT";
        case ConditionDiscipline.Other:
            return "OTH";
    }
};
exports.conditionDisciplineToPrefix = conditionDisciplineToPrefix;
var conditionDisciplineToString = function (conditionDiscipline) {
    switch (conditionDiscipline) {
        case ConditionDiscipline.Access:
            return "Access";
        case ConditionDiscipline.Accounting:
            return "Accounting";
        case ConditionDiscipline.Acoustics:
            return "Acoustics";
        case ConditionDiscipline.Administration:
            return "Administration";
        case ConditionDiscipline.Arborist:
            return "Arborist";
        case ConditionDiscipline.Architectural:
            return "Architectural";
        case ConditionDiscipline.BCAConsultant:
            return "BCA Consultant";
        case ConditionDiscipline.Bushfire:
            return "Bushfire";
        case ConditionDiscipline.Civil:
            return "Civil Engineering";
        case ConditionDiscipline.Client:
            return "Client";
        case ConditionDiscipline.Contamination:
            return "Contamination";
        case ConditionDiscipline.Construction:
            return "Construction";
        case ConditionDiscipline.Ecological:
            return "Ecological";
        case ConditionDiscipline.Electrical:
            return "Electrical";
        case ConditionDiscipline.Environmental:
            return "Environmental";
        case ConditionDiscipline.ESD:
            return "ESD";
        case ConditionDiscipline.Façade:
            return "Façade";
        case ConditionDiscipline.FireEngineering:
            return "Fire Engineering";
        case ConditionDiscipline.FirePenetrations:
            return "Fire Penetrations";
        case ConditionDiscipline.FireProtection:
            return "Fire Protection";
        case ConditionDiscipline.Geotechnical:
            return "Geotechnical";
        case ConditionDiscipline.Heritage:
            return "Heritage";
        case ConditionDiscipline.Hydraulic:
            return "Hydraulic";
        case ConditionDiscipline.InteriorDesign:
            return "Interior Design";
        case ConditionDiscipline.Landscaping:
            return "Landscaping";
        case ConditionDiscipline.Legal:
            return "Legal";
        case ConditionDiscipline.LiftsConveyors:
            return "Lifts & Conveyors";
        case ConditionDiscipline.MarketAnalysis:
            return "Market Analysis";
        case ConditionDiscipline.Mechanical:
            return "Mechanical";
        case ConditionDiscipline.PrincipalCertifyingAuthority:
            return "Principal Certifying Authority";
        case ConditionDiscipline.QuantitySurveyor:
            return "Quantity Surveyor";
        case ConditionDiscipline.Structure:
            return "Structure";
        case ConditionDiscipline.Subcontractor:
            return "Subcontractor";
        case ConditionDiscipline.Survey:
            return "Survey";
        case ConditionDiscipline.TownPlanner:
            return "Town Planner";
        case ConditionDiscipline.TrafficEngineering:
            return "Traffic Engineering";
        case ConditionDiscipline.WasteEngineer:
            return "Waste Engineer";
        case ConditionDiscipline.Waterproofing:
            return "Waterproofing";
        case ConditionDiscipline.Other:
            return "Other";
    }
};
exports.conditionDisciplineToString = conditionDisciplineToString;
