import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ProjectCardSkeleton } from "./ProjectCardSkeleton";
import { ProjectCard } from "./ProjectCard";
import { KeyedObject } from "types";
import { TableHeader } from "@stories/atoms/TableHeader/TableHeader";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";

interface ProjectTableProps {
  loading: boolean;
  projects: ProjectLineItem[];
  organisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  searchString: string;
  tabNumber: number;
  handleOpen: () => void;
  handleViewProject: (project: ProjectLineItem) => void;
  handleEditProject: (project: ProjectLineItem) => void;
  handleArchiveProject: (project: ProjectLineItem) => void;
}

export const ProjectTable = ({
  loading,
  projects,
  organisation,
  profile,
  searchString,
  tabNumber,
  handleViewProject,
  handleEditProject,
  handleArchiveProject,
}: ProjectTableProps) => {
  const filteredProjects = projects
    .filter((p) =>
      p.projectName.toLowerCase().includes(searchString.toLowerCase())
    )
    .filter((p) => {
      return p.archived === Boolean(tabNumber);
    });

  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table" className="project-table">
        <TableHead>
          <TableRow style={{ padding: "10px 0 0", border: "none" }}>
            <TableCell title="" sx={{ width: "5%" }} />
            <TableHeader loading={loading} title="Project Name" />
            <TableHeader loading={loading} title="Sector" />
            <TableHeader loading={loading} title="Collaborators" />
            <TableHeader
              loading={loading}
              title="Total Permits"
              align={"center"}
            />
            <TableHeader
              loading={loading}
              title="Active Permits"
              align={"center"}
            />
            <TableHeader
              loading={loading}
              title="Archived Permits"
              align={"center"}
            />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading
            ? filteredProjects.map((row: KeyedObject) => {
                return (
                  <ProjectCard
                    key={`project-card-${row.id}`}
                    project={row as ProjectLineItem}
                    profile={profile}
                    organisation={organisation}
                    handleViewProject={handleViewProject}
                    handleEditProject={handleEditProject}
                    handleArchiveProject={handleArchiveProject}
                  />
                );
              })
            : null}
          {filteredProjects.length === 0 && !loading ? (
            <NoRows colSpan={8} title={"Projects"} />
          ) : null}
          {loading && (
            <>
              <ProjectCardSkeleton />
              <ProjectCardSkeleton />
              <ProjectCardSkeleton />
              <ProjectCardSkeleton />
              <ProjectCardSkeleton />
              <ProjectCardSkeleton />
            </>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
