import * as React from "react";
import { listApiData, postApiData } from "@hooks/utils/api";
import { PermitInspectionLineItem } from "permit-one-common/src/interfaces/permitInspection";
import {
  decodePermitInspection,
  encodePermitInspection,
} from "permit-one-common/src/decoders/permitInspection";
import { PermitInspection } from "permit-one-common/src/entities/permitInspection";

export const usePermitInspection = (permitId?: string) => {
  const [permitInspections, setPermitInspections] = React.useState<
    PermitInspectionLineItem[]
  >([]);

  const [isPermitInspectionsLoading, setIsPermitInspectionsLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listPermitInspections = async (permitId: string): Promise<void> => {
    try {
      setIsPermitInspectionsLoading(true);
      if (permitId) {
        const mailThreadResult = await listApiData(
          "listPermitInspections",
          "permitInspection",
          permitId
        );
        const permitInspectionLineItems = mailThreadResult.data.map((m) =>
          decodePermitInspection(m as PermitInspection)
        );
        setPermitInspections(permitInspectionLineItems);
      }
    } catch (e: any) {
      setError("Could not list inspections");
    } finally {
      setIsPermitInspectionsLoading(false);
    }
  };

  const createPermitInspection = async (
    permitInspection: PermitInspectionLineItem
  ): Promise<void> => {
    try {
      setIsPermitInspectionsLoading(true);
      await postApiData(
        "createPermitInspection",
        "permitInspection",
        encodePermitInspection(permitInspection)
      );
      setPermitInspections([...permitInspections, permitInspection]);
    } catch (e: any) {
      setError("Could not create permit inspection");
    } finally {
      setIsPermitInspectionsLoading(false);
    }
  };

  const deletePermitInspection = async (
    permitInspection: PermitInspectionLineItem
  ): Promise<void> => {
    try {
      setIsPermitInspectionsLoading(true);
      await postApiData(
        "deletePermitInspection",
        "permitInspection",
        encodePermitInspection(permitInspection)
      );
      const remainingPermitInspections = permitInspections.filter(
        (p) => p.id !== permitInspection.id
      );
      setPermitInspections(remainingPermitInspections);
    } catch (e: any) {
      setError("Could not create permit inspection");
    } finally {
      setIsPermitInspectionsLoading(false);
    }
  };

  React.useEffect(() => {
    if (permitId) {
      listPermitInspections(permitId);
    }
  }, [permitId]);

  return {
    permitInspections,
    createPermitInspection,
    listPermitInspections,
    deletePermitInspection,
    isPermitInspectionsLoading,
    error,
  };
};
