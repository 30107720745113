"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultConditionDocument = exports.documentCategoryToString = void 0;
var uuid_1 = require("uuid");
var string_1 = require("../utils/string");
var conditionDocument_1 = require("../entities/conditionDocument");
var condition_1 = require("../entities/condition");
var documentCategoryToString = function (documentCategory) {
    switch (documentCategory) {
        case conditionDocument_1.DocumentCategory.AboriginalCulturalHeritageReport:
            return "Aboriginal Cultural Heritage Report";
        case conditionDocument_1.DocumentCategory.AboriginalDueDiligenceAssessment:
            return "Aboriginal Due Diligence Assessment";
        case conditionDocument_1.DocumentCategory.AccessReport:
            return "Access report";
        case conditionDocument_1.DocumentCategory.AcidSulfateSoilsReport:
            return "Acid sulfate soils report";
        case conditionDocument_1.DocumentCategory.AcousticAndVibrationAssessment:
            return "Acoustic and Vibration Assessment";
        case conditionDocument_1.DocumentCategory.AcousticReport:
            return "Acoustic report";
        case conditionDocument_1.DocumentCategory.AdvertisingandSignageDetailPlan:
            return "Advertising and Signage Detail Plan";
        case conditionDocument_1.DocumentCategory.AgriculturalImpactStatement:
            return "Agricultural Impact Statement";
        case conditionDocument_1.DocumentCategory.AirQuality:
            return "Air Quality";
        case conditionDocument_1.DocumentCategory.AirQualityAssessment:
            return "Air Quality Assessment";
        case conditionDocument_1.DocumentCategory.AnyDraftSection88BConveyancingAct1919Instruments:
            return "Any draft Section 88B Conveyancing Act 1919 instruments";
        case conditionDocument_1.DocumentCategory.AquaticEcologyAssessmentReport:
            return "Aquatic Ecology Assessment Report";
        case conditionDocument_1.DocumentCategory.ArboristsReport:
            return "Arborists report";
        case conditionDocument_1.DocumentCategory.ArchaeologicalAssessment:
            return "Archaeological Assessment";
        case conditionDocument_1.DocumentCategory.ArchitecturalPlans:
            return "Architectural plans";
        case conditionDocument_1.DocumentCategory.BASIXCertificate:
            return "BASIX certificate";
        case conditionDocument_1.DocumentCategory.BCAPerformanceRequirementsComplianceStatement:
            return "BCA Performance Requirements Compliance Statement";
        case conditionDocument_1.DocumentCategory.BiodiversityTestOfSignificance:
            return "Biodiversity Test Of Significance";
        case conditionDocument_1.DocumentCategory.BiodiversityAssessmentMethodBAMLetter:
            return "Biodiversity Assessment Method BAM Letter ";
        case conditionDocument_1.DocumentCategory.BiodiversityDevelopmentAssessmentReport:
            return "Biodiversity Development Assessment Report";
        case conditionDocument_1.DocumentCategory.BiodiversityOffsetsSchemeThresholdReport:
            return "Biodiversity Offsets Scheme Threshold Report";
        case conditionDocument_1.DocumentCategory.BuildingCodeOfAustraliaReport:
            return "Building code of australia report";
        case conditionDocument_1.DocumentCategory.BushFireReport:
            return "Bush Fire Report";
        case conditionDocument_1.DocumentCategory.CarParkingAndVehicleAccess:
            return "Car parking and vehicle access";
        case conditionDocument_1.DocumentCategory.Category1FireSafetyProvisions:
            return "Category 1 Fire Safety Provisions";
        case conditionDocument_1.DocumentCategory.CivilEngineeringPlan:
            return "Civil Engineering Plan";
        case conditionDocument_1.DocumentCategory.Clause46VariationRequest:
            return "Clause 4.6 variation request";
        case conditionDocument_1.DocumentCategory.CoastalAssessmentReport:
            return "Coastal Assessment Report";
        case conditionDocument_1.DocumentCategory.CompetitiveDesignProcessReport:
            return "Competitive Design Process Report";
        case conditionDocument_1.DocumentCategory.ConservationManagementPlanorStrategy:
            return "Conservation management plan or strategy";
        case conditionDocument_1.DocumentCategory.ConstructionManagementPlan:
            return "Construction Management Plan";
        case conditionDocument_1.DocumentCategory.ContaminationAndOrRemediationActionPlan:
            return "Contamination and/or Remediation Action Plan";
        case conditionDocument_1.DocumentCategory.CostEstimateReport:
            return "Cost estimate report";
        case conditionDocument_1.DocumentCategory.CouncilDAChecklist:
            return "Council DA checklist";
        case conditionDocument_1.DocumentCategory.CrimePreventionthroughEnvironmentalDesignCPTEDReport:
            return "Crime Prevention through Environmental Design CPTED Report";
        case conditionDocument_1.DocumentCategory.DamReport:
            return "Dam Report";
        case conditionDocument_1.DocumentCategory.DemolitionManagementPlan:
            return "Demolition Management Plan";
        case conditionDocument_1.DocumentCategory.DemolitionWorkPlan:
            return "Demolition Work Plan";
        case conditionDocument_1.DocumentCategory.DesignStatementForComplianceWithLowRiseHousingDesignGuide:
            return "Design statement for compliance with low rise housing design guide";
        case conditionDocument_1.DocumentCategory.DesignVerificationStatement:
            return "Design verification statement";
        case conditionDocument_1.DocumentCategory.DisabilityAccessPlanDDA:
            return "Disability Access Plan DDA";
        case conditionDocument_1.DocumentCategory.DrivewayAndAccessPlan:
            return "Driveway and Access Plan";
        case conditionDocument_1.DocumentCategory.ElevationsAndSections:
            return "Elevations and Sections";
        case conditionDocument_1.DocumentCategory.EnvironmentalImpactStatement:
            return "Environmental Impact Statement";
        case conditionDocument_1.DocumentCategory.ErosionandSedimentControlPlan:
            return "Erosion and Sediment Control Plan";
        case conditionDocument_1.DocumentCategory.EstuarineHazardAssessmentReport:
            return "Estuarine Hazard Assessment Report";
        case conditionDocument_1.DocumentCategory.EvidenceOfAuthorityUnderminingAct1992:
            return "Evidence of authority under mining act 1992";
        case conditionDocument_1.DocumentCategory.EvidenceOfLossOfoewRentalDwellingsExcavationandOrFillPlan:
            return "Evidence of Loss Of oew Rental Dwellings Excavation and Or Fill Plan";
        case conditionDocument_1.DocumentCategory.FireSafetyUpgradeReport:
            return "Fire Safety Upgrade Report";
        case conditionDocument_1.DocumentCategory.FloodRiskImpactAndOrManagementPlan:
            return "Flood Risk Impact And Or Management Plan";
        case conditionDocument_1.DocumentCategory.Floodriskmanagementreport:
            return "Flood risk management report";
        case conditionDocument_1.DocumentCategory.FloorAndOrRoofPlan:
            return "Floor and/or Roof Plan";
        case conditionDocument_1.DocumentCategory.FloorPlans:
            return "Floor Plans";
        case conditionDocument_1.DocumentCategory.FloraAndFaunaAssessment:
            return "Flora and Fauna Assessment";
        case conditionDocument_1.DocumentCategory.GeoTechnicalReport:
            return "Geo Technical Report";
        case conditionDocument_1.DocumentCategory.HeritageImpactStatement:
            return "Heritage Impact Statement";
        case conditionDocument_1.DocumentCategory.HunterWaterStampApproval:
            return "Hunter Water Stamp Approval";
        case conditionDocument_1.DocumentCategory.Hydrogeologicalreport:
            return "Hydrogeological report";
        case conditionDocument_1.DocumentCategory.LandscapePlan:
            return "Landscape plan";
        case conditionDocument_1.DocumentCategory.LifeCycleAssessmentLCALiquidTradeWasteDetails:
            return "Life Cycle Assessment LCA Liquid Trade Waste details";
        case conditionDocument_1.DocumentCategory.MinesSubsidenceStampedPlansMosquitoReport:
            return "Mines Subsidence Stamped Plans Mosquito Report";
        case conditionDocument_1.DocumentCategory.NABERSAgreementToRateNABERSCommitmentAgreementNetZeroStatement:
            return "NABERS Agreement to Rate NABERS Commitment Agreement Net Zero Statement";
        case conditionDocument_1.DocumentCategory.NotificationPlan:
            return "Notification Plan";
        case conditionDocument_1.DocumentCategory.NotificationPlans:
            return "Notification plans";
        case conditionDocument_1.DocumentCategory.OdourAssessmentReportOther:
            return "Odour Assessment Report Other";
        case conditionDocument_1.DocumentCategory.OwnerBuilderPermit:
            return "Owner Builder Permit";
        case conditionDocument_1.DocumentCategory.OwnersConsent:
            return "Owners Consent";
        case conditionDocument_1.DocumentCategory.ParkingReport:
            return "Parking Report";
        case conditionDocument_1.DocumentCategory.PartyWallConsent:
            return "Party Wall Consent";
        case conditionDocument_1.DocumentCategory.PerformanceSolutionDocumentation:
            return "Performance Solution Documentation";
        case conditionDocument_1.DocumentCategory.Photomontage:
            return "Photomontage";
        case conditionDocument_1.DocumentCategory.PlanOfManagement:
            return "Plan Of Management";
        case conditionDocument_1.DocumentCategory.PlanningAgreement:
            return "Planning Agreement";
        case conditionDocument_1.DocumentCategory.PoliticalDonationsAndGiftsDisclosure:
            return "Political Donations And Gifts Disclosure";
        case conditionDocument_1.DocumentCategory.PreliminaryEngineeringDrawings:
            return "Preliminary Engineering Drawings";
        case conditionDocument_1.DocumentCategory.PreliminarySewerandWaterFeasibilityStudy:
            return "Preliminary Sewer and Water Feasibility Study";
        case conditionDocument_1.DocumentCategory.PreliminaryStormwaterManagementPlan:
            return "Preliminary Stormwater Management Plan";
        case conditionDocument_1.DocumentCategory.PriorDAStampedPlans:
            return "Prior DA Stamped plans";
        case conditionDocument_1.DocumentCategory.PriorNoticeofDetermination:
            return "Prior Notice of Determination";
        case conditionDocument_1.DocumentCategory.PrivateLandConservationAgreementDetails:
            return "Private land conservation agreement details";
        case conditionDocument_1.DocumentCategory.ProposedSubdivisionPlan:
            return "Proposed Subdivision plan";
        case conditionDocument_1.DocumentCategory.PublicArtPlan:
            return "Public Art Plan";
        case conditionDocument_1.DocumentCategory.PublicSubmissionsReflectivityPlan:
            return "Public Submissions Reflectivity Plan";
        case conditionDocument_1.DocumentCategory.RoadDesignPlan:
            return "Road Design Plan";
        case conditionDocument_1.DocumentCategory.SalinityAssessment:
            return "Salinity Assessment";
        case conditionDocument_1.DocumentCategory.ScheduleofColoursMaterialsAndFinishes:
            return "Schedule of Colours Materials And Finishes";
        case conditionDocument_1.DocumentCategory.Section107PlanningCertificateFormerlySection149:
            return "Section 10.7 Planning Certificate Formerly Section 149";
        case conditionDocument_1.DocumentCategory.Section46ApplicationtoVaryDevelopmentStandard:
            return "Section 4.6 Application to Vary Development Standard";
        case conditionDocument_1.DocumentCategory.SectionJReport:
            return "Section J Report";
        case conditionDocument_1.DocumentCategory.SEPP65Assessment:
            return "SEPP 65 Assessment";
        case conditionDocument_1.DocumentCategory.Shadowdiagrams:
            return "Shadow diagrams";
        case conditionDocument_1.DocumentCategory.SitePlans:
            return "Site plans";
        case conditionDocument_1.DocumentCategory.SocialImpactAssessment:
            return "Social Impact Assessment";
        case conditionDocument_1.DocumentCategory.SolarandDaylightAccessReport:
            return "Solar and Daylight Access Report";
        case conditionDocument_1.DocumentCategory.SpeciesImpactStatement:
            return "Species Impact Statement";
        case conditionDocument_1.DocumentCategory.SprayDriftReport:
            return "Spray Drift Report";
        case conditionDocument_1.DocumentCategory.Statementofenvironmentaleffects:
            return "Statement of environmental effects";
        case conditionDocument_1.DocumentCategory.StormWaterDrainagePlan:
            return "Storm Water Drainage Plan";
        case conditionDocument_1.DocumentCategory.StormWaterManagementPlan:
            return "Storm Water Management Plan";
        case conditionDocument_1.DocumentCategory.StructuralEngineersReport:
            return "Structural Engineers Report";
        case conditionDocument_1.DocumentCategory.SubdivisionPlan:
            return "Subdivision Plan";
        case conditionDocument_1.DocumentCategory.Surveyplan:
            return "Survey plan";
        case conditionDocument_1.DocumentCategory.SurveyPlanServicesEasements:
            return "Survey Plan Services Easements";
        case conditionDocument_1.DocumentCategory.TitleDocumentationCertificateofTitle:
            return "Title Documentation Certificate of Title";
        case conditionDocument_1.DocumentCategory.TrafficCalmingDetails:
            return "Traffic Calming Details";
        case conditionDocument_1.DocumentCategory.TrafficImpactAssessment:
            return "Traffic Impact Assessment";
        case conditionDocument_1.DocumentCategory.TrafficManagementPlan:
            return "Traffic Management Plan";
        case conditionDocument_1.DocumentCategory.TrafficReport:
            return "Traffic report";
        case conditionDocument_1.DocumentCategory.VariationtoDevelopmentControls:
            return "Variation to Development Controls";
        case conditionDocument_1.DocumentCategory.VariationstoControlsintheDevelopmentControlPlan:
            return "Variations to Controls in the Development Control Plan";
        case conditionDocument_1.DocumentCategory.VisualImpactAssessmentReport:
            return "Visual Impact Assessment Report";
        case conditionDocument_1.DocumentCategory.VoluntaryPlanningAgreement:
            return "Voluntary Planning Agreement";
        case conditionDocument_1.DocumentCategory.WasteManagementPlan:
            return "Waste Management Plan";
        case conditionDocument_1.DocumentCategory.WastewaterManagementPlan:
            return "Wastewater Management Plan";
        case conditionDocument_1.DocumentCategory.WaterManagementPlan:
            return "Water Management Plan";
        case conditionDocument_1.DocumentCategory.WaterSensitiveUrbanDesignReport:
            return "Water Sensitive Urban Design Report";
        case conditionDocument_1.DocumentCategory.WindEffectsPlan:
            return "Wind Effects Plan";
        case conditionDocument_1.DocumentCategory.Other:
            return "Other";
    }
};
exports.documentCategoryToString = documentCategoryToString;
var createDefaultConditionDocument = function (projectId, file, createdBy, createdByName, documentCount, project, permit, condition) {
    var conditionId = (0, uuid_1.v4)();
    var intialRevision = {
        id: (0, uuid_1.v4)(),
        conditionDocumentId: conditionId,
        revisionNumber: 1,
        revisionDate: new Date(),
        createdBy: createdBy,
        createdByName: createdByName,
        fileType: file.type,
        fileSize: file.size,
        url: "",
        created: new Date(),
    };
    return {
        id: conditionId,
        projectId: projectId,
        documentNumber: (0, string_1.generateDocumentName)((project === null || project === void 0 ? void 0 : project.projectName) || "", condition_1.ConditionDiscipline.Other, conditionDocument_1.DocumentCategory.Other, documentCount),
        documentTitle: (0, string_1.removeFileExtension)(file.name),
        createdBy: createdBy,
        createdByName: createdByName,
        modified: new Date(),
        conditions: condition ? [condition] : [],
        revisions: [intialRevision],
        documentCategory: conditionDocument_1.DocumentCategory.Other,
        documentDiscipline: condition_1.ConditionDiscipline.Other,
        revisionNumber: 1,
        revisionDate: new Date(),
        fileType: file.type,
        fileSize: file.size,
        url: "",
        file: file,
        created: new Date(),
    };
};
exports.createDefaultConditionDocument = createDefaultConditionDocument;
