import * as React from "react";
import { listApiData, postApiData } from "@hooks/utils/api";
import { PermitLodgementLineItem } from "permit-one-common/src/interfaces/permitLodgement";
import {
  decodePermitLodgement,
  encodePermitLodgement,
} from "permit-one-common/src/decoders/permitLodgement";
import { PermitLodgement } from "permit-one-common/src/entities/permitLodgement";

export const usePermitLodgement = (permitId?: string) => {
  const [permitLodgements, setPermitLodgements] = React.useState<
    PermitLodgementLineItem[]
  >([]);

  const [isPermitLodgementsLoading, setIsPermitLodgementsLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listPermitLodgements = async (permitId: string): Promise<void> => {
    try {
      setIsPermitLodgementsLoading(true);
      if (permitId) {
        const mailThreadResult = await listApiData(
          "listPermitLodgements",
          "permitLodgement",
          permitId
        );
        const permitLodgementLineItems = mailThreadResult.data.map((m) =>
          decodePermitLodgement(m as PermitLodgement)
        );
        setPermitLodgements(permitLodgementLineItems);
      }
    } catch (e: any) {
      setError("Could not list lodgements");
    } finally {
      setIsPermitLodgementsLoading(false);
    }
  };

  const createPermitLodgement = async (
    permitLodgement: PermitLodgementLineItem
  ): Promise<void> => {
    try {
      setIsPermitLodgementsLoading(true);
      await postApiData(
        "createPermitLodgement",
        "permitLodgement",
        encodePermitLodgement(permitLodgement)
      );
      setPermitLodgements([...permitLodgements, permitLodgement]);
    } catch (e: any) {
      setError("Could not create permit lodgement");
    } finally {
      setIsPermitLodgementsLoading(false);
    }
  };

  const deletePermitLodgement = async (
    permitLodgement: PermitLodgementLineItem
  ): Promise<void> => {
    try {
      setIsPermitLodgementsLoading(true);
      await postApiData(
        "deletePermitLodgement",
        "permitLodgement",
        encodePermitLodgement(permitLodgement)
      );
      const remainingPermitLodgements = permitLodgements.filter(
        (p) => p.id !== permitLodgement.id
      );
      setPermitLodgements(remainingPermitLodgements);
    } catch (e: any) {
      setError("Could not create permit lodgement");
    } finally {
      setIsPermitLodgementsLoading(false);
    }
  };

  React.useEffect(() => {
    if (permitId) {
      listPermitLodgements(permitId);
    }
  }, [permitId]);

  return {
    permitLodgements,
    createPermitLodgement,
    listPermitLodgements,
    deletePermitLodgement,
    isPermitLodgementsLoading,
    error,
  };
};
