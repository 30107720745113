// material-ui
import {
  Button,
  TableCell,
  TableRow,
  IconButton,
  Skeleton,
  Box,
  Stack,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
export const ProjectCardSkeleton = () => {
  return (
    <TableRow style={{ padding: "10px 0 0" }}>
      <TableCell>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "10%", textAlign: "center !important" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "10%", textAlign: "center !important" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell sx={{ width: "10%", textAlign: "center !important" }}>
        <Skeleton animation="wave"></Skeleton>
      </TableCell>
      <TableCell align="right" sx={{ width: "10%" }}>
        <Box display="flex" gap="5px" justifyContent={"right"}>
          <Skeleton animation="wave" variant="circular">
            <IconButton color="error" size="large">
              <DeleteIcon sx={{ fontSize: "1.1rem" }} />
            </IconButton>
          </Skeleton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
