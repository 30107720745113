import React, { useMemo } from "react";
// @mui
import { CssBaseline, ThemeOptions } from "@mui/material";
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material/styles";
//
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";
import { GlobalStyles } from "./globalStyles";
import customShadows from "./customShadows";
import componentsOverride from "./overrides";

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const customShadow = customShadows();
export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const themeOptions = useMemo<ThemeOptions>(
    () => ({
      palette,
      shape: { borderRadius: 6 },
      typography,
      shadows: shadows(),
    }),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};

export type ColourOption =
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error";
