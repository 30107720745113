import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { Grid, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

export interface ConfirmationDialogProps {
  message: string;
  open: boolean;
  title: string;
  intent: "info" | "success" | "error";
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationDialog = ({
  open,
  title,
  message,
  intent,
  onCancel,
  onConfirm,
}: ConfirmationDialogProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="xs"
      open={open}
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ padding: "25px 30px 20px", fontSize: "20px !important" }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 14,
            top: 11,
            color: "#fff",
            background: "#000",
            "&:hover": {
              background: "#023AF3",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 30px 30px" }}>
        <Typography
          sx={{ background: "#fff", padding: "10px", borderRadius: "10px" }}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "0 30px 30px" }}>
        <Grid item md={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            loading={false}
            disabled={isSubmitting}
            color={intent}
            variant="contained"
            onClick={() => {
              setIsSubmitting(true);
              onConfirm();
            }}
            sx={{
              background: "red !important",
              "&:hover": {
                background: "#e30020 !important",
              },
            }}
          >
            Confirm
          </StyledButton>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
