import { useProjectContext } from "@hooks/context/useProjectContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Box, Typography, Grid } from "@mui/material";
import { MailCenter } from "@stories/organisms/Mail/MailCenter";
import { useNavigate } from "react-router-dom";
import * as RouteHelper from "@utils/routes";

export const ProjectMail = (): JSX.Element => {
  const navigate = useNavigate();
  const { selectedProject, isProjectLoading } = useProjectContext();
  const { userProfile, isAuthProfileLoading } = useProfileContext();

  const loading = isProjectLoading || isAuthProfileLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0 0 30px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontSize: "28px !important",
            fontWeight: "700",
            color: "#333333",
          }}
        >
          Project Correspondence
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          padding: "10px 40px 20px 40px",
          width: "100%",
          margin: "0",
        }}
      >
        <Grid item md={12} sx={{ padding: "0 !important" }}>
          <MailCenter
            isLoading={loading}
            profile={userProfile}
            project={selectedProject}
            showDialog={false}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
