import * as React from "react";
import { Profile, ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { decodeProfile, encodeProfile } from "permit-one-common/src/decoders/profile";
import {
  getApiData,
  listApiData,
  postApiData,
  postBatchApiData,
} from "@hooks/utils/api";
import { ToastOptions, toast } from "react-toastify";
import { getImageUrl } from "@hooks/utils/useUpload";

export const useProfile = (organisationId?: string) => {
  const [profiles, setProfiles] = React.useState<ProfileLineItem[]>([]);
  const [isProfilesLoading, setIsProfilesLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const getProfile = async (
    id: string
  ): Promise<ProfileLineItem | undefined> => {
    try {
      setIsProfilesLoading(true);
      const profileResult = await getApiData("getProfile", "profile", id);
      if (Object.keys(profileResult.data).length === 0) {
        return undefined;
      }
      return await decodeProfile(profileResult.data as Profile, getImageUrl);
    } catch (e: any) {
      setError("Could not fetch profile");
    } finally {
      setIsProfilesLoading(false);
    }
  };

  const listProfiles = async (organisationId: string): Promise<void> => {
    try {
      setIsProfilesLoading(true);
      const profileResult = await listApiData(
        "listProfiles",
        "profile",
        organisationId
      );
      const profiles = await Promise.all(
        profileResult.data.map((p) => decodeProfile(p as Profile, getImageUrl))
      );
      setProfiles(
        profiles.map((p) => {
          return { ...p, organisationId };
        })
      );
    } catch (e: any) {
      setError("Could not list profiles");
    } finally {
      setIsProfilesLoading(false);
    }
  };

  const searchProfiles = async (query: string): Promise<ProfileLineItem[]> => {
    try {
      const profileResult = await listApiData(
        "searchProfiles",
        "profile",
        query
      );
      return await Promise.all(
        profileResult.data.map((p) => decodeProfile(p as Profile, getImageUrl))
      );
    } catch (e: any) {
      setError("Could not search profiles");
      return [];
    }
  };

  const createProfile = async (
    profile: ProfileLineItem
  ): Promise<ProfileLineItem> => {
    try {
      setIsProfilesLoading(true);
      const encodedProfile = encodeProfile(profile);
      const upsertedProfile = await postApiData(
        "createProfile",
        "profile",
        encodedProfile
      );
      const decodedProfile = await decodeProfile(
        upsertedProfile.data as Profile, getImageUrl
      );
      setProfiles([...profiles, decodedProfile]);

      toast("Profile created!", {
        type: "success",
      } as ToastOptions);

      return decodedProfile;
    } catch (e: any) {
      setError("Could not create profile");
    } finally {
      setIsProfilesLoading(false);
    }
    return profile;
  };

  const createProfileAlias = async (
    profile: ProfileLineItem
  ): Promise<ProfileLineItem> => {
    try {
      setIsProfilesLoading(true);
      const encodedProfile = encodeProfile(profile);
      const upsertedProfile = await postApiData(
        "createProfileAlias",
        "profile",
        encodedProfile
      );
      const decodedProfile = await decodeProfile(
        upsertedProfile.data as Profile, getImageUrl
      );
      setProfiles([...profiles, decodedProfile]);

      toast("Profile created!", {
        type: "success",
      } as ToastOptions);

      return decodedProfile;
    } catch (e: any) {
      setError("Could not create profile alias");
    } finally {
      setIsProfilesLoading(false);
    }
    return profile;
  };

  const createProfiles = async (
    newProfiles: ProfileLineItem[]
  ): Promise<ProfileLineItem[]> => {
    try {
      setIsProfilesLoading(true);
      const encodedProfiles = newProfiles.map((p) => encodeProfile(p));
      const upsertedProfiles = await postBatchApiData(
        "createProfiles",
        "profile",
        encodedProfiles
      );
      const decodedProfiles = await Promise.all(
        upsertedProfiles.data.map((p) => decodeProfile(p as Profile, getImageUrl))
      );
      setProfiles([...profiles, ...decodedProfiles]);
      return profiles;
    } catch (e: any) {
      setError("Could not create profiles");
    } finally {
      setIsProfilesLoading(false);
    }
    return newProfiles;
  };

  const updateProfile = async (profile: ProfileLineItem): Promise<void> => {
    try {
      setIsProfilesLoading(true);
      const encodedProfile = encodeProfile(profile);
      await postApiData("updateProfile", "profile", encodedProfile);
      setProfiles(
        profiles.map((c) => {
          if (c.id === profile.id) {
            return profile;
          }
          return c;
        })
      );
      toast("Profile updated!", {
        type: "success",
      } as ToastOptions);
    } catch (e: any) {
      setError("Could not update profile");
      toast("Could not update profile!", {
        type: "error",
      } as ToastOptions);
    } finally {
      setIsProfilesLoading(false);
    }
  };

  const deleteProfile = async (profile: ProfileLineItem): Promise<void> => {
    try {
      setIsProfilesLoading(true);
      const encodedProfile = encodeProfile(profile);
      await postApiData("removeProfile", "profile", encodedProfile);
      setProfiles(
        profiles.filter((p) => p.id !== profile.id || p.email !== profile.email)
      );
    } catch (e: any) {
      setError("Could not delete profile");
    } finally {
      setIsProfilesLoading(false);
    }
  };

  const inviteProfile = async (
    invitedProfile: ProfileLineItem
  ): Promise<void> => {
    try {
      setIsProfilesLoading(true);
      const encodedProfile = encodeProfile(invitedProfile);
      const upsertedProfile = await postApiData(
        "inviteProfile",
        "profile",
        encodedProfile
      );
      const decodedProfile = await decodeProfile(
        upsertedProfile.data as Profile, getImageUrl
      );
      setProfiles([...profiles, decodedProfile]);
    } catch (e: any) {
      setError("Could not list profiles");
    } finally {
      setIsProfilesLoading(false);
    }
  };

  React.useEffect(() => {
    if (organisationId) {
      listProfiles(organisationId);
    } else {
      setIsProfilesLoading(false);
    }
  }, [organisationId]);

  return {
    profiles,
    getProfile,
    listProfiles,
    createProfile,
    createProfiles,
    updateProfile,
    inviteProfile,
    deleteProfile,
    searchProfiles,
    isProfilesLoading,
    error,
  };
};
