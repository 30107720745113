import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { Chip, Stack, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";
import { MailRowProps } from "./MailProps";
import AttachmentTwoToneIcon from "@mui/icons-material/AttachmentTwoTone";

export const MailRow = ({ row, profile, setSelectedMail }: MailRowProps) => {
  const theme = useTheme();
  const darkBG = theme.palette.mode === "dark" ? "dark.main" : "grey.100";
  const isRead = row.topMail.readBy.includes(profile?.id || "");

  const mailRowStatus =
    row.topMail?.sender.id === profile?.id
      ? "Sent"
      : isRead
      ? "Read"
      : "Unread";

  return (
    <TableRow
      hover
      sx={{
        bgcolor: !row.topMail.readBy.includes(profile?.id || "") ? darkBG : "",
        "& td:last-of-type>div": {
          position: "absolute",
          top: "50%",
          right: 5,
          transform: "translateY(-50%)",
          display: "none",
          background:
            theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          py: 1,
          px: 1.75,
          "& button + button": {
            ml: 0.625,
          },
        },
        "&:hover": {
          "& td:last-of-type>div": {
            display: "block",
          },
        },
      }}
      tabIndex={-1}
      onClick={() => {
        setSelectedMail(row);
      }}
    >
      <TableCell align="center" style={{ width: "5%" }}>
        <Chip
          label={mailRowStatus}
          sx={{
            backgroundColor: isRead ? "#AFE2AF" : "#FCC8CF",
            color: "black",
            fontWeight: "bold !important",
            fontSize: "14px",
          }}
        />
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} style={{ width: "20%" }}>
        {isRead ? row.subject : <strong>{row.subject}</strong>}
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} style={{ width: "20%" }}>
        {isRead ? (
          `${row.permitName ? row.permitName : ""} ${
            row.conditionName ? row.conditionName : ""
          }`
        ) : (
          <strong>{`${row.permitName ? row.permitName : ""} ${
            row.conditionName ? row.conditionName : ""
          }`}</strong>
        )}
      </TableCell>
      <TableCell align="center" style={{ width: "5%" }}>
        {row.topMail.attachments && (
          <Stack direction="row">
            {row.topMail.attachments.length > 0 ? (
              <AttachmentTwoToneIcon />
            ) : null}
          </Stack>
        )}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          position: "relative",
          textAlign: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
        style={{ width: "20%" }}
      >
        <p style={{ textAlign: "center" }}>
          {format(new Date(row.topMail.created), "d MMM yy")}
        </p>
      </TableCell>
    </TableRow>
  );
};
