// ----------------------------------------------------------------------

import { Theme } from "@mui/material";
import { customShadow } from "@theme/Theme";

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          boxShadow: customShadow.z20,
        },
      },
    },
  };
}
