// third-party

import { NavItemType } from "types";

import * as RouteHelper from "@utils/routes";

import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";

export const getDashboardNav = (projectId?: string): NavItemType => {
  const theme = useTheme();
  return {
    id: "project",
    title: "Overview",
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    type: "group",
    children: [
      {
        id: "overview",
        title: "Project Dashboard",
        type: "item",
        icon: (
          <TrackChanges
            sx={{
              color: theme.palette.primary.main,
            }}
          />
        ),
        url: projectId ? RouteHelper.overview(projectId) : "",
      },
    ],
  };
};
