import { NavItemType } from "types";
import * as RouteHelper from "@utils/routes";
import FolderCopy from "@mui/icons-material/FolderCopy";
import { useTheme } from "@mui/material";

export const getProjectsNav = (): NavItemType => {
  const theme = useTheme();
  return {
    id: "projects",
    title: "Projects",
    icon: <FolderCopy sx={{ color: theme.palette.primary.main }} />,
    type: "group",
    children: [
      {
        id: "list-projects",
        title: "All Projects",
        type: "item",
        url: RouteHelper.listProjects,
        icon: <FolderCopy sx={{ color: theme.palette.primary.main }} />,
        breadcrumbs: false,
      },
    ],
  };
};
