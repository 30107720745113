import { createDefaultProfile } from "permit-one-common/src/interfaces/profile";
import { useNavigate } from "react-router-dom";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useAuthContext } from "@hooks/context/useAuthContext";
import * as RouteHelper from "@utils/routes";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { Steps } from "@stories/atoms/Steps/Steps";
import { useStep } from "@hooks/utils/useStep";
import { useState } from "react";
import { Card, Stack, Box } from "@mui/material";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { CreateOrganisationForm } from "@stories/organisms/CreateOrganisationForm/CreateOrganisationForm";
import { CreateProfileForm } from "@stories/organisms/CreataProfileForm/CreateProfileForm";

const steps = ["Create profile", "Account Type"];

export const CreateAccount = (): JSX.Element => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  if (!user) {
    throw Error("No user in state.");
  }
  const { createUserProfileAndOrganisation } = useProfileContext();

  const [profile, setProfile] = useState(createDefaultProfile(user));

  const { activeStep, handleNext, handleBack } = useStep();
  const [loading, setLoading] = useState(false);

  if (!user) {
    throw Error("No user in state.");
  }

  const finalizeAccount = async (organisation: OrganisationLineItem) => {
    await createUserProfileAndOrganisation(organisation, {
      ...profile,
      isAdmin: true,
    });
    navigate(RouteHelper.listProjects);
  };

  const title = "Create Account";

  const selectedCreateProfileStep = (() => {
    switch (activeStep) {
      default:
      case 0:
        return (
          <CreateProfileForm
            handleNext={handleNext}
            profile={profile}
            setProfile={setProfile}
          />
        );
      case 1:
        return (
          <CreateOrganisationForm
            handleBack={handleBack}
            loading={loading}
            setLoading={setLoading}
            finalizeAccount={finalizeAccount}
          />
        );
    }
  })();

  return (
    <>
      <Card>
        <Box sx={{ padding: "30px 20px 0" }}>
          <SubTitle title={title} />
        </Box>
        <Stack spacing={4}>
          <Steps activeStep={activeStep} steps={steps} />
          {selectedCreateProfileStep}
        </Stack>
      </Card>
    </>
  );
};
