import * as React from "react";

import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  Grid,
  Skeleton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
export const MailRowSkeleton = () => {
  const theme = useTheme();

  return (
    <TableRow
      hover
      sx={{
        bgcolor: "",
        "& td:last-of-type>div": {
          position: "absolute",
          top: "50%",
          right: 5,
          transform: "translateY(-50%)",
          display: "none",
          background:
            theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "12px",
          py: 1,
          px: 1.75,
          "& button + button": {
            ml: 0.625,
          },
        },
        "&:hover": {
          "& td:last-of-type>div": {
            display: "block",
          },
        },
      }}
      tabIndex={-1}
    >
      <TableCell align="center" style={{ width: "10%" }}>
        <Skeleton animation="wave">
          <Chip
            label={"READ"}
            sx={{
              backgroundColor: theme.palette.warning.dark,
            }}
          />
        </Skeleton>
      </TableCell>
      <TableCell sx={{ cursor: "pointer", width: "20%" }}>
        <Skeleton animation="wave">
          <Typography variant={"body2"}>{"Message body"}</Typography>
        </Skeleton>
      </TableCell>
      <TableCell align="center" style={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" style={{ width: "20%" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell align="center" sx={{ position: "relative", width: "10%" }}>
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );
};
