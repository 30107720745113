import { useTable } from "@hooks/utils/useTable";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { Table, TableBody, TableContainer } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import ListTableToolbar from "@stories/molecules/ListTable/ListTableToolbar";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { CollaboratorTableRow } from "./CollaboratorTableRow";
import { CollaboratorTableRowSkeleton } from "./CollaboratorTableRowSkeleton";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";

interface CollaboratorTableProps {
  loading: boolean;
  profiles: DirectoryContactLineItem[];
}

export const CollaboratorTable = ({
  loading,
  profiles,
}: CollaboratorTableProps) => {
  const TABLE_HEAD: TableHeaderCell[] = [
    { id: "name", label: "Name", alignRight: false },
    { id: "company", label: "Company", alignRight: false },
    { id: "role", label: "Role", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "phone", label: "Phone", alignRight: false },
  ];
  const directoryList = profiles;

  const {
    page,
    selected,
    filterName,
    rowsPerPage,
    clearSelected,
    handleSelectAllClick,
    handleFilterByName,
  } = useTable();

  const filteredUsers =
    filterName.length > 0
      ? directoryList.filter(
          (u) => u.name.toLowerCase() === filterName.toLocaleLowerCase()
        )
      : directoryList;

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <ListTableToolbar
        loading={loading}
        handleOpen={() => {
          console.log("not implemeted");
        }}
        searchTitle="Search Contacts..."
        numSelected={selected.length}
        filterName={filterName}
        onFilter={handleFilterByName}
        clearSelected={clearSelected}
      />

      <TableContainer sx={{ minWidth: 800, marginTop: "20px" }}>
        <Table stickyHeader aria-label="sticky table" className="project-table">
          <ListTableHead
            headLabel={TABLE_HEAD}
            loading={loading}
            rowCount={directoryList.length}
            numSelected={selected.length}
            showCheckbox={false}
            onSelectAllClick={(e: any) =>
              handleSelectAllClick(
                e,
                directoryList.map((u) => u.id)
              )
            }
          />
          <TableBody>
            {!loading
              ? filteredUsers
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const isSelected = selected.indexOf(row.id) !== -1;

                    return (
                      <CollaboratorTableRow
                        key={row.id}
                        loading={loading}
                        selected={isSelected}
                        collaborator={row}
                      />
                    );
                  })
              : null}
            {isNotFound && (
              <NoResultsFoundRow
                colSpan={TABLE_HEAD.length + 1}
                filterName={filterName}
              />
            )}
            {directoryList.length === 0 && !loading ? (
              <NoRows colSpan={TABLE_HEAD.length + 1} title={"Collaborators"} />
            ) : null}
            {loading
              ? Array.from({ length: 6 }, (_, index) => (
                  <CollaboratorTableRowSkeleton key={index} />
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
