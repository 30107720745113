import { TableCell, TableRow } from "@mui/material";
import { DownloadButton } from "@stories/atoms/DownloadButton/DownloadButton";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { getDocumentName } from "permit-one-common/src/utils/string";
import { DeleteButton } from "@stories/atoms/DeleteButton/DeleteButton";
import { PermitLodgementLineItem } from "permit-one-common/src/interfaces/permitLodgement";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";

interface LodgementTableRowProps {
  selected: boolean;
  lodgement: PermitLodgementLineItem;
  isDownloading: boolean;
  selectedId?: string;
  downloadProgress: number;
  setSelectedId: (id: string) => void;
  onDownload: (uri: string, lodgementName: string) => void;
  deletePermitLodgement: (lodgement: PermitLodgementLineItem) => void;
}

export const LodgementTableRow = ({
  lodgement,
  selected,
  isDownloading,
  selectedId,
  downloadProgress,
  setSelectedId,
  onDownload,
  deletePermitLodgement,
}: LodgementTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell align="center">{lodgement.lodgementName}</TableCell>
        <TableCell align="center">
          {lodgement.created.toLocaleDateString()}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          {isDownloading && lodgement.id === selectedId ? (
            <CircularProgressWithLabel value={downloadProgress} />
          ) : (
            <DownloadButton
              disabled={isDownloading}
              onClick={() => {
                setSelectedId(lodgement.id);
                onDownload(
                  lodgement.url,
                  getDocumentName(lodgement.lodgementName, lodgement.url)
                );
              }}
              
            />
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          <DeleteButton
            disabled={isDownloading}
            onClick={() => {
              setOpenConfirmDialog(true);
            }}
          />
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Do you want to delete the lodgement?"}
        open={openConfirmDialog}
        title={"Delete Lodgement"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => deletePermitLodgement(lodgement)}
      />
    </>
  );
};
