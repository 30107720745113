import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { Avatar, Stack, TableCell, TableRow, Typography } from "@mui/material";

interface GlobalDirectoryTableRowProps {
  isSelected: boolean;
  contact: DirectoryContactLineItem;
}

export const GlobalDirectoryTableRow = ({
  isSelected,
  contact,
}: GlobalDirectoryTableRowProps) => {
  const displayName = !contact.organisationName
    ? "User pending"
    : `${contact.firstName} ${contact.lastName}`;

  return (
    <TableRow hover tabIndex={-1} role="checkbox" selected={isSelected}>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {contact.photoUrl ? (
            <Avatar
              alt={`${contact.firstName} ${contact.lastName}`}
              src={contact.photoUrl}
              sx={{ marginRight: "20px", background: "#fff" }}
            />
          ) : null}
          <Typography sx={{ margin: "10px" }}>{displayName}</Typography>
        </Stack>
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        {contact.organisationName}
      </TableCell>
      <TableCell align="center" sx={{ width: "10%" }}>
        {contact.jobTitle}
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        {contact.email}
      </TableCell>
      <TableCell align="center" sx={{ width: "10%" }}>
        {contact.phoneNumber}
      </TableCell>
    </TableRow>
  );
};
