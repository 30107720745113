import { Skeleton, TableCell, TableRow } from "@mui/material";

export const FeeTableRowSkeleton = () => {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox">
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "25%" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center" sx={{ width: "25%" }}>
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
    </>
  );
};
