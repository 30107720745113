import { IconButton, Skeleton, TableCell, TableRow } from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";

export const UserTableRowSkeleton = () => {
  return (
    <>
      <TableRow hover tabIndex={-1}>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="right">
          <Skeleton animation="wave" variant="circular">
            <IconButton size="small" color="inherit">
              <Iconify icon={"eva:more-vertical-fill"} />
            </IconButton>
          </Skeleton>
        </TableCell>
      </TableRow>
    </>
  );
};
