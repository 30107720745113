import { useCondition } from "@hooks/crud/useCondition";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { ConditionSummary } from "@stories/organisms/Summary/ConditionSummary";
import { ConditionTable } from "@stories/organisms/Tables/ConditionTable/ConditionTable";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePermit } from "@hooks/crud/usePermit";
import ConditionModal from "@stories/organisms/ConditionModal/ConditionModal";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { ConditionTabs } from "@stories/molecules/ConditionsTab/ConditionsTab";
import { PermitEventLog } from "@stories/organisms/PermitEventLog/PermitEventLog";
import {
  Box,
  Breadcrumbs,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { InspectionTable } from "@stories/organisms/Tables/InspectionTable/InspectionTable";
import { FeeTable } from "@stories/organisms/Tables/FeeTable/FeeTable";
import * as RouteHelper from "@utils/routes";
import { LodgementTable } from "@stories/organisms/Tables/LodgementTable/LodgementTable";
import { MailCenter } from "@stories/organisms/Mail/MailCenter";
import { PermitCollaborators } from "@stories/organisms/PermitCollaborators/PermitCollaborators";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { PermitProperties } from "@stories/organisms/PermitProperties/PermitProperties";
import { useConditionCount } from "@hooks/crud/useConditionCount";

export const Conditions = (): JSX.Element => {
  const navigate = useNavigate();
  const { permitId } = useParams();
  const { selectedProject, isProjectLoading } = useProjectContext();
  const { userProfile, userOrganisation, isAuthProfileLoading } =
    useProfileContext();

  const { selectedPermit, isPermitLoading, getPermit } = usePermit(
    undefined,
    permitId
  );

  const {
    conditions,
    isConditionLoading,
    createCondition,
    importConditions,
    updateCondition,
    deleteCondition,
    shareConditions,
  } = useCondition(undefined, permitId);

  const { isConditionCountLoading, conditionCounts } = useConditionCount(
    selectedProject?.id,
    permitId
  );

  const [selectedCondition, setSelectedCondition] = useState<
    ConditionLineItem | undefined
  >(undefined);
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleCloseConditionEditModal = () => {
    setOpen(false);
    setSelectedCondition(undefined);
  };
  const handleOpenConditionEditModal = (
    selecetedCondition?: ConditionLineItem
  ) => {
    setOpen(true);
    setSelectedCondition(selecetedCondition);
  };

  const handleImportConditons = async (conditions: ConditionLineItem[]) => {
    await importConditions(conditions);
    // setImportedConditions(conditions);
  };

  const handleCreateCondition = (condition: ConditionLineItem) => {
    createCondition(condition).then(() => {
      if (permitId) {
        getPermit(permitId);
      }
    });
  };

  const handleUpdateCondition = (condition: ConditionLineItem) => {
    updateCondition(condition).then(() => {
      if (permitId) {
        getPermit(permitId);
      }
    });
  };

  const handleEditCondition = (condition: ConditionLineItem) => {
    handleOpenConditionEditModal(condition);
  };

  const handleDeleteCondition = (condition: ConditionLineItem) => {
    deleteCondition(condition).then(() => {
      if (permitId) {
        getPermit(permitId);
      }
    });
  };

  const handleShareConditions = async (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string
  ): Promise<void> => {
    await shareConditions(email, firstName, lastName, projectId, permitId);
  };

  const handleViewCondition = (condition: ConditionLineItem) => {
    if (selectedProject && selectedPermit) {
      navigate(
        RouteHelper.condition(
          selectedProject.id,
          selectedPermit.id,
          condition.id
        )
      );
    } else {
      throw Error("No condition or condition selected");
    }
  };

  const loading =
    isAuthProfileLoading ||
    isProjectLoading ||
    isPermitLoading ||
    isConditionLoading ||
    isConditionCountLoading;

  const selectedConditionStep = (() => {
    switch (selectedTab) {
      default:
      case 0:
        return (
          <ConditionTable
            handleOpenConditionEditModal={handleOpenConditionEditModal}
            loading={loading}
            profile={userProfile}
            userOrganisation={userOrganisation}
            project={selectedProject}
            permit={selectedPermit}
            conditions={conditions}
            setConditions={handleImportConditons}
            handleImportConditons={handleImportConditons}
            handleViewCondition={handleViewCondition}
            handleEditCondition={handleUpdateCondition}
            handleDeleteCondition={handleDeleteCondition}
            handleShareConditions={handleShareConditions}
          />
        );
      case 1:
        return (
          <PermitProperties
            loading={loading}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      case 2:
        return (
          <MailCenter
            isLoading={loading}
            project={selectedProject}
            permit={selectedPermit}
            profile={userProfile}
            showDialog={false}
          />
        );
      case 3:
        return (
          <PermitCollaborators loading={loading} permit={selectedPermit} />
        );
      case 4:
        return <PermitEventLog loading={loading} permit={selectedPermit} />;
      case 5:
        return (
          <InspectionTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      case 6:
        return (
          <FeeTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
      case 7:
        return (
          <LodgementTable
            permitId={permitId}
            project={selectedProject}
            permit={selectedPermit}
          />
        );
    }
  })();

  const handlOnBack = () => {
    if (selectedProject) {
      navigate(RouteHelper.permits(selectedProject.id));
    }
  };
  return (
    <Box>
      {loading ? (
        <Grid
          className="borderBottom"
          container
          spacing={2}
          sx={{
            padding: "20px 40px 20px 40px",
            margin: "0 0 40px",
            width: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid
            item
            sx={{ padding: "0px !important", alignItems: "center" }}
            md={6}
          >
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <Skeleton animation="wave" variant="circular">
                <IconButton
                  size="large"
                  sx={{
                    color: "black",
                    width: "auto",
                    height: "auto",
                    padding: "5px",
                  }}
                >
                  <ArrowCircleLeftOutlinedIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography
                  variant="h1"
                  component="div"
                  sx={{
                    fontSize: "22px !important",
                    fontWeight: "700",
                    marginTop: "10px",
                  }}
                >
                  {"Permit Name"}
                </Typography>
              </Skeleton>
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            className="borderBottom"
            container
            spacing={2}
            sx={{
              padding: "20px 40px 20px 40px",
              margin: "0 0 40px",
              width: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid
              item
              sx={{ padding: "0px !important", alignItems: "center" }}
              md={6}
            >
              <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back"
                >
                  <IconButton
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      width: "auto",
                      height: "auto",
                      padding: "5px",
                    }}
                  >
                    <ArrowCircleLeftOutlinedIcon sx={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    className="visited-link"
                    style={{
                      textDecoration: "none",
                      color: "#00000",
                      fontSize: "22px",
                    }}
                    to={RouteHelper.permits(selectedProject?.id || "")}
                  >
                    {selectedProject?.projectName}
                  </Link>
                  <Typography color="text.primary">
                    {selectedPermit?.permitName}
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}

      <Box sx={{ padding: "10px 40px 30px" }}>
        <ConditionSummary
          conditionCounts={conditionCounts}
          project={selectedProject}
          permitId={permitId}
          loading={loading}
        />
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            background: "#fff",
            borderRadius: "20px",
          }}
        >
          <Grid
            container
            sx={{
              padding: "0",
              margin: "40px 0 30px",
              borderRadius: "0",
              width: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid item md={12} sx={{ padding: "0 0 20px !important" }}>
              <ConditionTabs
                loading={loading}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </Grid>
            <Grid item md={12} sx={{ padding: "0 !important" }}>
              {selectedConditionStep}
            </Grid>
          </Grid>
        </Box>

        {selectedProject && selectedPermit ? (
          <ConditionModal
            open={open}
            project={selectedProject}
            permit={selectedPermit}
            conditionCount={conditions.length}
            handleClose={handleCloseConditionEditModal}
            createCondition={handleCreateCondition}
            updateCondition={handleUpdateCondition}
            existingCondition={selectedCondition}
          />
        ) : null}
      </Box>
    </Box>
  );
};
