import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import PersonIcon from "@mui/icons-material/Person";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { Card } from "@mui/material";
import { usePermitEvent } from "@hooks/crud/usePermitEvent";
import {
  PermitEventLineItem,
  permitEventTypeToString,
} from "permit-one-common/src/interfaces/permitEvent";
import WarningAmber from "@mui/icons-material/WarningAmber";
import { Loader } from "@stories/atoms/Loaders/Loader/Loader";
import { PermitEventType } from "permit-one-common/src/entities/permitEvent";
import * as RouteHelper from "@utils/routes";
import { Link } from "react-router-dom";

interface PermitEventLogProps {
  loading: boolean;
  permit?: PermitLineItem;
}

export const permitEventToLink = (permitEvent: PermitEventLineItem): string => {
  switch (permitEvent.eventType) {
    default:
    case PermitEventType.PermitCreated:
    case PermitEventType.PermitAssignedManager:
    case PermitEventType.PermitAssignedApprover:
    case PermitEventType.PermitApproved:
    case PermitEventType.PermitInReview:
    case PermitEventType.PermitRejected:
    case PermitEventType.PermitUpdated:
    case PermitEventType.PermitUploadedDocument:
      return RouteHelper.conditions(
        permitEvent.projectId,
        permitEvent.permitId
      );
    case PermitEventType.ConditionAssigned:
    case PermitEventType.ConditionApproved:
    case PermitEventType.ConditionInReview:
    case PermitEventType.ConditionRejected:
    case PermitEventType.ConditionUpdated:
    case PermitEventType.ConditionDocumentUploaded:
      return RouteHelper.condition(
        permitEvent.projectId,
        permitEvent.permitId,
        permitEvent.conditionId || ""
      );
    case PermitEventType.ConditionDocumentRevisionUploaded:
      return RouteHelper.condition(
        permitEvent.projectId,
        permitEvent.permitId,
        permitEvent.conditionId || "",
        "document"
      );
  }
};

export const permitEventTypeToIcon = (
  permitEventType: PermitEventType
): React.ReactNode => {
  switch (permitEventType) {
    case PermitEventType.PermitCreated:
      return (
        <TimelineDot>
          <AddCircleOutline />
        </TimelineDot>
      );
    case PermitEventType.PermitAssignedManager:
    case PermitEventType.PermitAssignedApprover:
    case PermitEventType.ConditionAssigned:
      return (
        <TimelineDot>
          <PersonIcon />
        </TimelineDot>
      );
    case PermitEventType.PermitApproved:
    case PermitEventType.ConditionApproved:
      return (
        <TimelineDot color="success">
          <CheckCircleOutline />
        </TimelineDot>
      );
    case PermitEventType.PermitInReview:
    case PermitEventType.ConditionInReview:
      return (
        <TimelineDot>
          <AddCircleOutline />
        </TimelineDot>
      );
    case PermitEventType.PermitRejected:
    case PermitEventType.ConditionRejected:
      return (
        <TimelineDot color="error">
          <WarningAmber />
        </TimelineDot>
      );
    case PermitEventType.PermitUpdated:
    case PermitEventType.ConditionUpdated:
      return (
        <TimelineDot color="primary">
          <RepeatIcon />
        </TimelineDot>
      );
    case PermitEventType.PermitUploadedDocument:
    case PermitEventType.ConditionDocumentUploaded:
    case PermitEventType.ConditionDocumentRevisionUploaded:
      return (
        <TimelineDot color="primary">
          <CloudUploadIcon />
        </TimelineDot>
      );
  }
  return <AddCircleOutline />;
};

export const PermitEventLog = ({
  loading,
  permit,
}: PermitEventLogProps): JSX.Element => {
  const { isPermitEventLoading, permitEvents } = usePermitEvent(permit?.id);
  if (loading || isPermitEventLoading) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }
  if (!permit) {
    throw new Error("Missing project, permit, or permit in PermitEventLog");
  }
  return (
    <Timeline
      position="alternate"
      sx={{ marginTop: "15px" }}
      className="timeLine"
    >
      {permitEvents.map((c, index) => {
        return (
          <Link to={permitEventToLink(c)}>
            <TimelineItem sx={{}}>
              <TimelineSeparator>
                {permitEventTypeToIcon(c.eventType)}
                {index < permitEvents.length - 1 ? <TimelineConnector /> : null}
              </TimelineSeparator>
              <div
                className="list"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "15px 10px",
                  minWidth: "300px",
                  borderRadius: "4px",
                  border: "1px solid rgb(242, 242, 242",
                }}
              >
                {/* <Typography sx={{fontWeight:'500', color:'black' , fontSize:'12px'}}>Date</Typography> */}
                <TimelineOppositeContent
                  variant="body1"
                  color="black"
                  sx={{
                    padding: "0",
                    fontWeight: "400",
                    fontSize: "11px",
                    color: "rgb(102, 102, 102)",
                    textAlign: "left !important",
                  }}
                >
                  {c.created.toDateString()}
                </TimelineOppositeContent>

                <TimelineContent sx={{ padding: "0 !important" }}>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      fontSize: "18px !important",
                      color: "black",
                    }}
                  >
                    {c.info}
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "400",
                      color: "rgb(102, 102, 102)",
                      fontSize: "12px !important",
                      textDecoration: "none !important",
                    }}
                  >
                    {permitEventTypeToString(c.eventType)}
                  </Typography>
                </TimelineContent>
              </div>
            </TimelineItem>
          </Link>
        );
      })}
      {permitEvents.length == 0 ? (
        <Typography
          sx={{ fontSize: "16px", color: "black", fontWeight: "500" }}
        >
          {"No permit events"}
        </Typography>
      ) : null}
    </Timeline>
  );
};
