"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultConditionComment = void 0;
var uuid_1 = require("uuid");
var createDefaultConditionComment = function (project, permit, condition, profile, comment) {
    return {
        id: (0, uuid_1.v4)(),
        projectId: project.id,
        permitId: permit.id,
        conditionId: condition.id,
        profileId: profile.id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        comment: comment,
        created: new Date(),
    };
};
exports.createDefaultConditionComment = createDefaultConditionComment;
