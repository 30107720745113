import {
  ConditionLineItem,
  ConditionStatus,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { Box, Button, Chip, TableCell, TableRow } from "@mui/material";
import { getProfileName } from "permit-one-common/src/interfaces/profile";
import { conditionDisciplineToString } from "permit-one-common/src/entities/condition";

interface TaskTableRowProps {
  selectedTask: boolean;
  condition: ConditionLineItem;
  handleViewCondition: (condition: ConditionLineItem) => void;
}
export const TaskTableRow = ({
  selectedTask,
  condition,
  handleViewCondition,
}: TaskTableRowProps) => {
  const handleRowClick = (condition: ConditionLineItem) => {
    handleViewCondition(condition);
  };
  const commentsString =
    condition?.conditionComments?.replace(/<(.|\n)*?>/g, "") || "";
  const commentsSubString = commentsString.substring(0, 20);

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selectedTask}>
        <TableCell
          align="center"
          sx={{ width: "2%" }}
          onClick={() => handleRowClick(condition)}
        >
          <Chip
            label={conditionStatusToString(condition.status)}
            sx={{
              backgroundColor: conditionStatusToColor(condition?.status),
              color: "black",
            }}
            variant="filled"
            className="chip"
          />
        </TableCell>
        <TableCell
          sx={{ width: "15%" }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {condition.permitName}
        </TableCell>
        <TableCell
          sx={{
            width: "20%",
          }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {condition.conditionName}
        </TableCell>
        <TableCell
          sx={{
            width: "20%",
          }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {commentsSubString}
        </TableCell>
        <TableCell
          sx={{ width: "15%" }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {condition.assignees.length > 0
            ? condition.assignees.map((a) => getProfileName(a)).join(", ")
            : conditionStatusToString(ConditionStatus.NotAssigned)}
        </TableCell>
        <TableCell
          sx={{ width: "15%" }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {condition.discipline}
        </TableCell>
        <TableCell
          sx={{ width: "10%" }}
          align="center"
          onClick={() => handleRowClick(condition)}
        >
          {condition.dueDate.toLocaleDateString()}
        </TableCell>
        <TableCell align="right" sx={{ width: "5%" }}>
          <Box
            display="flex"
            gap="5px"
            justifyContent={"right"}
            height={"37px"}
          >
            <Button
              color="primary"
              size="large"
              onClick={() => handleViewCondition(condition)}
              className="blackBtn"
              sx={{ color: "white", fontSize: "12px" }}
            >
              view
            </Button>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
