import { TableCell, TableRow, Chip, Stack } from "@mui/material";
import {
  ConditionLineItem,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { StyledButton } from "../StyledButton/StyledButton";
import Iconify from "../Iconify/Iconify";
import { useState } from "react";
import ConditionDocumentModal from "@stories/organisms/ConditionDocumentModal/ConditionDocumentModal";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";

export function createMarkup(dirty: string) {
  return { __html: dirty };
}

interface PublicEditableTableRowProps {
  loading: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  condition: ConditionLineItem;
  documents: ConditionDocumentLineItem[];
  createDocuments: (files: ConditionDocumentLineItem[]) => Promise<void>;
  createComments: (comments: ConditionCommentLineItem[]) => Promise<void>;
  sendForReview: (condition: ConditionLineItem) => void;
  uploadDocument: (file: File) => void;
  userProfile: ProfileLineItem;
}

function PublicEditableTableRow({
  loading,
  project,
  permit,
  condition,
  documents,
  createDocuments,
  createComments,
  sendForReview,
  uploadDocument,
  userProfile,
}: PublicEditableTableRowProps) {
  const [openBulk, setOpenBulk] = useState(false);

  const handleCloseBulk = () => {
    setOpenBulk(false);
  };

  const handleOpenBulk = () => {
    setOpenBulk(true);
  };

  return (
    <>
      <TableRow key={condition.id}>
        <TableCell sx={{ border: "1px solid #000" }}>
          {`${
            condition.conditionAuthority === ConditionAuthority.DA
              ? "DA -"
              : "BCA -"
          }${condition.permitItemNumber}`}
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.conditionDetails || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
          }}
        >
          <div
            style={{
              position: "relative",
              padding: "5px !important",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.conditionComments || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
          }}
        >
          <div
            style={{
              position: "relative",
              padding: "5px !important",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.discipline || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell
          sx={{
            verticalAlign: "top",
          }}
        >
          <div
            style={{
              position: "relative",
              padding: "5px !important",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
              dangerouslySetInnerHTML={{
                __html: condition?.assigneeComments || "",
              }}
            />
          </div>
        </TableCell>
        <TableCell>
          <Chip
            label={conditionStatusToString(condition?.status)}
            variant="filled"
            background-color={conditionStatusToColor(condition?.status)}
            sx={{
              backgroundColor: conditionStatusToColor(condition?.status),
            }}
          />
        </TableCell>
        <TableCell align="right">
          <Stack
            direction="row"
            spacing={2}
            alignItems={"end"}
            alignContent={"end"}
            justifyItems={"end"}
          >
            <StyledButton
              loading={loading}
              onClick={() => {
                handleOpenBulk();
              }}
              variant="outlined"
              disabled={loading}
              startIcon={<Iconify icon="eva:cloud-upload-outline" />}
              className="outlined-btn filled"
            >
              {"Documents"}
            </StyledButton>
          </Stack>
        </TableCell>
      </TableRow>
      {userProfile && project ? (
        <ConditionDocumentModal
          open={openBulk}
          userProfile={userProfile}
          project={project}
          handleClose={handleCloseBulk}
          permit={permit}
          condition={condition}
          handleEditCondition={sendForReview}
        />
      ) : null}
    </>
  );
}

export default PublicEditableTableRow;
