import { forwardRef } from "react";
import { Icon, IconifyIcon } from "@iconify/react";
import { Box } from "@mui/material";

interface IconifyProps {
  icon: IconifyIcon | string;
  width?: number;
  height?: number;
  sx?: any;
  color?: string;
  other?: any;
}
const Iconify = forwardRef(
  ({ icon, width = 20, height, color, sx, ...other }: IconifyProps, ref) => (
    <Box
      ref={ref}
      component={Icon}
      icon={icon}
      color={color}
      sx={{ width, height: height, ...sx }}
      {...other}
    />
  )
);

export default Iconify;
