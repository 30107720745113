import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import { PermitInspectionLineItem } from "permit-one-common/src/interfaces/permitInspection";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Alert, FormLabel, Stack } from "@mui/material";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { useRef, useState } from "react";
import { isImageFile, uploadImage } from "@hooks/utils/useUpload";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ToastOptions, toast } from "react-toastify";

interface InspectionFormProps {
  project: ProjectLineItem;
  permit: PermitLineItem;
  inspection: PermitInspectionLineItem;
  handleSubmit: (permit: PermitInspectionLineItem) => void;
  handleClose: () => void;
}

export const InspectionForm = ({
  project,
  permit,
  inspection,
  handleSubmit,
  handleClose,
}: InspectionFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState<string>();

  const validationSchema = yup.object({
    inspectionName: yup.string().required("Inspection name is required"),
    url: yup.string().required("Upload required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      inspectionName: inspection.inspectionName,
      url: inspection.url,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleSubmit({
        ...inspection,
        inspectionName: values.inspectionName,
        url: values.url,
      });
    },
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const files = e.currentTarget.files;

      const fileToUpload = files[0];

      if (fileToUpload) {
        setIsUploading(true);
        const fileKey = `inspections/${project.id}/${permit.id}/${fileToUpload.name}`;
        if (isImageFile(fileToUpload.name)) {
          await uploadImage(
            fileKey,
            fileToUpload,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
            },
            () => {
              setUploadProgress(0);

              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
              setIsUploading(false);
            }
          );
        }

        formik.setFieldValue("url", fileKey);
        setFileName(fileToUpload.name);
      }
      setIsUploading(false);
    }
  };

  const formDisabled = formik.isSubmitting || isUploading;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container sx={{ padding: "0 " }}>
        <Grid item xs={12} sm={12} sx={{ padding: "0 0 15px" }}>
          <TextField
            id="inspectionName"
            name="inspectionName"
            label="Inspection Name"
            fullWidth
            margin="dense"
            disabled={formDisabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.inspectionName}
            error={
              formik.touched.inspectionName &&
              Boolean(formik.errors.inspectionName)
            }
            helperText={
              formik.touched.inspectionName ? formik.errors.inspectionName : ""
            }
          />
        </Grid>
        <Grid item sm={12} md={12} sx={{ padding: "0 0 30px" }}>
          <Stack>
            <FormLabel
              component="legend"
              sx={{
                padding: "0 0 10px",
                fontSize: "14px",
                lineHeight: "16px",
                color: "black",
              }}
            >
              Upload Inspection
            </FormLabel>
            <Button className="gray-hover-button"
              variant="contained"
              disabled={formDisabled}
              onClick={onFileInput}
              sx={{background:'transparent !important', 
              border:'1px solid rgba(145, 158, 171, 0.32) !important' , 
              color:'black !important', gap:'10px',
              '&:hover':{
                borderColor:'black ',
                backgroundColor:'#F2F2F2 !important'
              },
              padding:'7px 10px',
              
            }}
            >
              <input
                hidden
                ref={fileInput}
                accept="*/*"
                multiple
                type="file"
                onChange={onUpload}
              />
              <CloudUploadIcon /> Upload
            </Button>
            {isUploading ? (
              <CircularProgressWithLabel value={uploadProgress} />
            ) : null}
            {fileName ? (
              <Alert
                color="success"
                sx={{ marginTop: "10px", background: "#ebebeb" }}
              >
                {fileName}
              </Alert>
            ) : null}
          </Stack>
        </Grid>
        <Grid
          item
          sm={12}
          md={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <StyledButton
            loading={false}
            color="primary"
            variant="contained"
            type="submit"
            disabled={formDisabled}
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
