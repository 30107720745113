import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import { gridSpacing } from "constant";
import DownloadForOfflineTwoToneIcon from "@mui/icons-material/DownloadForOfflineTwoTone";
import { fileTypeToIcon } from "../../../utils/file";
import { downloadFile } from "@hooks/utils/useUpload";
import { getDocumentName } from "permit-one-common/src/utils/string";

interface AttachmentCardProps {
  uri: string;
}

const AttachmentCard = ({ uri }: AttachmentCardProps) => {
  const onDownload = async (uri: string, greenMetricName: string) => {
    await downloadFile(uri, greenMetricName, (progress: any) => {
      const total = (progress.loaded / progress.total) * 100;
      console.log(total);
    });
  };

  return (
    <Card
      sx={{ background: "#E9F0F5", padding: "20px" }}
      onClick={() => {
        onDownload(uri, getDocumentName(uri, uri));
      }}
      className="messageDet"
    >
      <Box>{fileTypeToIcon(uri)}</Box>
    </Card>
  );
};

export default AttachmentCard;
