import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import {
  ConditionDocumentLineItem,
  documentCategoryToString,
} from "permit-one-common/src/interfaces/conditionDocument";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Alert, FormLabel, Stack } from "@mui/material";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { conditionDisciplineToString } from "permit-one-common/src/entities/condition";

import { useRef, useState } from "react";
import {
  UploadStatus,
  isImageFile,
  uploadFile,
  uploadImage,
} from "@hooks/utils/useUpload";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ToastOptions, toast } from "react-toastify";
import JSZip from "jszip";
import { createDefaultConditionDocumentRevision } from "permit-one-common/src/interfaces/conditionDocumentRevision";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ConditionDiscipline } from "permit-one-common/src/entities/condition";
import { DocumentCategory } from "permit-one-common/src/entities/conditionDocument";

interface DocumentFormProps {
  project?: ProjectLineItem;
  condition?: ConditionLineItem;
  document: ConditionDocumentLineItem;
  userProfile: ProfileLineItem;
  handleSubmit: (documents: ConditionDocumentLineItem[]) => void;
  handleClose: () => void;
}

export const DocumentForm = ({
  project,
  condition,
  document,
  userProfile,
  handleSubmit,
  handleClose,
}: DocumentFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileName, setFileName] = useState<string>();

  const validationSchema = yup.object({
    documentTitle: yup.string().required("Title is required"),
    documentNumber: yup.string().required("Document Code is required"),
    documentCategory: yup.string().required("Document Type other is required"),
    documentDiscipline: yup.string().required("Discipline is required"),
    url: yup.string(),
    fileType: yup.string(),
    fileSize: yup.number().required("Upload required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      documentTitle: document.documentTitle,
      documentNumber: document.documentNumber,
      documentCategory: document.documentCategory,
      documentDiscipline: document.documentDiscipline,
      url: document.url.length ? document.url : "",
      fileSize: document.fileSize,
      fileType: document.fileType,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setUploadStatus(UploadStatus.Saving);

      const isNewVesion = document.url !== values.url;
      const currentRevisionNumber = isNewVesion
        ? document.revisionNumber + 1
        : document.revisionNumber;
      await handleSubmit([
        {
          ...document,
          documentCategory: values.documentCategory,
          documentDiscipline: values.documentDiscipline,
          documentNumber: values.documentNumber,
          documentTitle: values.documentTitle,
          revisionNumber: currentRevisionNumber,
          fileSize: values.fileSize,
          url: values.url,
          revisions: isNewVesion
            ? [
                createDefaultConditionDocumentRevision(
                  document.id,
                  currentRevisionNumber,
                  values.url,
                  userProfile.id,
                  `${userProfile.firstName} ${userProfile.lastName}`,
                  values.fileType,
                  values.fileSize
                ),
                ...document.revisions,
              ]
            : document.revisions,
        },
      ]);
      setUploadStatus(UploadStatus.Idle);
    },
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploadProgress(0);
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const files = e.currentTarget.files;

      let fileToUpload = null;
      if (files.length > 1) {
        const zip = new JSZip();
        for (const file of Array.from(files)) {
          zip.file(file.name, file);
        }
        const zippedBlob = await zip.generateAsync({ type: "blob" });
        fileToUpload = new File([zippedBlob], "zipped.zip");
      } else {
        fileToUpload = files[0];
      }

      if (fileToUpload) {
        setUploadStatus(UploadStatus.Uploading);
        const fileKey = `documents/${project?.id}/${condition?.id || 0}/${
          fileToUpload.name
        }`;
        if (isImageFile(fileToUpload.name)) {
          await uploadImage(
            fileKey,
            fileToUpload,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
            },
            () => {
              setUploadProgress(0);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
              setUploadStatus(UploadStatus.Idle);
            }
          );
        } else {
          await uploadFile(fileKey, fileToUpload, (progress: any) => {
            console.log(progress);
            console.log(JSON.stringify(progress));

            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
          });
        }

        formik.setFieldValue("url", fileKey);
        formik.setFieldValue("fileSize", fileToUpload.size);
        setFileName(fileToUpload.name);
      }
      setUploadStatus(UploadStatus.Idle);
    }
  };

  const formDisabled =
    formik.isSubmitting || uploadStatus !== UploadStatus.Idle;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container sx={{ padding: "0 " }}>
        <Grid item xs={12} md={12} sx={{ padding: "0 0 25px" }}>
          <TextField
            fullWidth
            id="documentTitle"
            name="documentTitle"
            disabled={formDisabled}
            value={formik.values.documentTitle}
            onChange={formik.handleChange}
            error={
              formik.touched.documentTitle &&
              Boolean(formik.errors.documentTitle)
            }
            label="Title"
          />
        </Grid>
        <Grid item xs={12} md={12} sx={{ padding: "0 0 25px" }}>
          <TextField
            fullWidth
            id="documentNumber"
            name="documentNumber"
            disabled={formDisabled}
            value={formik.values.documentNumber}
            onChange={formik.handleChange}
            error={
              formik.touched.documentNumber &&
              Boolean(formik.errors.documentNumber)
            }
            label="Number"
          />
        </Grid>
        <Grid item xs={12} sm={12} sx={{ padding: "0 0 25px" }}>
          <FormControl fullWidth>
            <TextField
              select
              id="documentDiscipline"
              name="documentDiscipline"
              disabled={formDisabled}
              value={formik.values.documentDiscipline}
              onChange={formik.handleChange}
              error={
                formik.touched.documentDiscipline &&
                Boolean(formik.errors.documentDiscipline)
              }
              label="Discipline"
            >
              {Object.values(ConditionDiscipline).map((c, index) => (
                <MenuItem
                  key={`condition-document-discipline-${index}`}
                  value={c}
                >
                  {conditionDisciplineToString(c)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ padding: "0 0 25px" }}>
          <TextField
            select
            id="documentCategory"
            name="documentCategory"
            fullWidth
            disabled={formDisabled}
            value={formik.values.documentCategory}
            onChange={formik.handleChange}
            error={
              formik.touched.documentCategory &&
              Boolean(formik.errors.documentCategory)
            }
            label="Document Type"
          >
            {Object.values(DocumentCategory).map((d, index) => (
              <MenuItem key={`document-types-${index}`} value={d}>
                {documentCategoryToString(d)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={12} sx={{ padding: "0 0 25px" }}>
          <Stack>
            <FormLabel
              component="legend"
              sx={{
                padding: "0 0 10px",
                fontSize: "14px",
                lineHeight: "16px",
                color: "black",
                fontWeight: 400,
              }}
            >
              {" "}
              Resubmit Document
            </FormLabel>
            <Button
              className="gray-hover-button"
              variant="contained"
              disabled={formDisabled}
              onClick={onFileInput}
              sx={{
                background: "transparent !important",
                border: "1px solid rgba(145, 158, 171, 0.32) !important",
                color: "black !important",
                gap: "10px",
                "&:hover": {
                  borderColor: "black ",
                  backgroundColor: "#F2F2F2 !important",
                },
                padding: "7px 10px",
              }}
            >
              <input
                hidden
                ref={fileInput}
                accept="*/*"
                multiple
                type="file"
                onChange={onUpload}
              />
              <CloudUploadIcon /> Upload
            </Button>
            {uploadStatus === UploadStatus.Uploading ? (
              <CircularProgressWithLabel value={uploadProgress} />
            ) : null}
            {fileName ? (
              <Alert
                color="success"
                sx={{ marginTop: "10px", background: "#ebebeb" }}
              >
                {fileName}
              </Alert>
            ) : null}
          </Stack>
        </Grid>

        <Grid
          item
          sm={12}
          md={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <StyledButton
            loading={false}
            color="primary"
            variant="contained"
            type="submit"
            disabled={formDisabled}
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
