import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { useState } from "react";
import { DownloadButton } from "../DownloadButton/DownloadButton";
import { downloadFile } from "@hooks/utils/useUpload";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { getDocumentName } from "permit-one-common/src/utils/string";

interface LinkRowProps {
  loading: boolean;
  permit: PermitLineItem;
  title: string;
  link: string;
}

export const LinkRow = ({ loading, permit, title, link }: LinkRowProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const onDownload = async (uri: string, downloadName: string) => {
    setDownloadProgress(0);
    setIsDownloading(true);
    await downloadFile(uri, downloadName, (progress: any) => {
      const total = (progress.loaded / progress.total) * 100;
      setDownloadProgress(total);
    });
    setIsDownloading(false);
  };

  return (
    <Stack mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={4} md={4}>
          {loading ? (
            <Skeleton animation="wave" />
          ) : (
            <Typography variant="body1">
              <strong>{title}</strong>
            </Typography>
          )}
        </Grid>
        <Grid item xs={8} md={8}>
          {loading ? (
            <Skeleton animation="wave" width={200} />
          ) : isDownloading ? (
            <CircularProgressWithLabel value={downloadProgress} />
          ) : (
            <DownloadButton
              disabled={isDownloading}
              onClick={() => {
                onDownload(link, getDocumentName(permit.permitName, link));
              }}
            />
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};
