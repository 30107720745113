"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePermitFee = exports.decodePermitFee = void 0;
var string_1 = require("../utils/string");
var decodePermitFee = function (permitFee) {
    var safePermitFee = (0, string_1.reversePostgresSafe)(permitFee);
    return {
        id: safePermitFee.permit_fee_id,
        projectId: safePermitFee.project_id,
        permitId: safePermitFee.permit_id,
        feeName: safePermitFee.fee_name,
        url: safePermitFee.url,
        created: new Date(safePermitFee.created),
    };
};
exports.decodePermitFee = decodePermitFee;
var encodePermitFee = function (permitFee) {
    var safePermitFee = (0, string_1.makePostgresSafe)(permitFee);
    return {
        permit_fee_id: safePermitFee.id,
        project_id: safePermitFee.projectId,
        permit_id: safePermitFee.permitId,
        fee_name: safePermitFee.feeName,
        url: safePermitFee.url,
        created: safePermitFee.created,
    };
};
exports.encodePermitFee = encodePermitFee;
