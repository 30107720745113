import * as React from "react";
import { Auth } from "aws-amplify";
import { UserLineItem } from "permit-one-common/src/interfaces/user";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useProfile } from "@hooks/crud/useProfile";
import { CognitoUser } from "@aws-amplify/auth";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";

type AuthResponse = {
  authenticated: boolean;
  error?: string;
};

enum AuthenticateChallenge {
  NONE = "NONE",
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
  SMS_MFA = "SMS_MFA",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
}

// We shouldn't have to do this, see this issue for details: https://github.com/aws-amplify/amplify-js/issues/3733
type AuthUser = CognitoUser & {
  challengeName?: AuthenticateChallenge;
  preferredMFA?: AuthenticateChallenge;
};

type AC = {
  loggedIn: boolean;
  isFirstLoad: boolean;
  user?: UserLineItem;
  isAuthLoading: boolean;
  userProfile?: ProfileLineItem;
  setUserProfile: (profile: ProfileLineItem) => void;
  isAuthenticated: () => Promise<boolean>;
  authenticate: (email: string) => Promise<AuthResponse>;
  register: (email: string) => Promise<AuthResponse>;
  answerCustomChallenge: (email: string, answer: string) => Promise<boolean>;
  signOut: () => Promise<void>;
};

export const AuthContext = React.createContext<AC>({
  loggedIn: false,
  isFirstLoad: false,
  user: undefined,
  isAuthLoading: false,
  userProfile: undefined,
  setUserProfile: () => {
    return;
  },
  isAuthenticated: () => Promise.resolve(false),
  answerCustomChallenge: () => Promise.resolve(false),
  authenticate: () => Promise.resolve({ authenticated: false }),
  register: () => Promise.resolve({ authenticated: false }),
  signOut: () => Promise.resolve(),
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const requestMagicLink = async (email: string) => {
  const res = await fetch(
    `https://nn95jitwp4.execute-api.ap-southeast-2.amazonaws.com/dev/signIn`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    }
  );
  return res.json();
};

const AuthProvider = (props: AuthProviderProps) => {
  const [user, setUser] = React.useState<UserLineItem>();

  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isAuthLoading, setIsAuthLoading] = React.useState(true);
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  const { getProfile } = useProfile();

  const [userProfile, setUserProfile] = React.useState<ProfileLineItem>();

  const getUserId = (cognitoUser: AuthUser) => {
    const payload = cognitoUser.getSignInUserSession()?.getIdToken().payload;
    if (!payload) {
      throw new Error("User not configured correctly.");
    }
    const subId = payload["sub"];
    if (!subId) {
      throw new Error("User sub id not available");
    }
    return subId;
  };

  const isAuthenticated = async () => {
    try {
      setIsAuthLoading(true);
      const res = await Auth.currentAuthenticatedUser();
      const userId = res.attributes.sub;
      setUser({ id: userId, email: res.username });
      console.log("Get profile!!!!");
      const profile = await getProfile(userId);
      if (profile) {
        setUserProfile({ ...profile });
      }
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const authenticate = async (email: string) => {
    try {
      setIsAuthLoading(true);
      await requestMagicLink(email);
      setIsAuthLoading(false);
      return { authenticated: true };
    } catch (e) {
      setIsAuthLoading(false);
      return { authenticated: false };
      // skip if user already exists
    }
  };

  const register = async (email: string) => {
    try {
      setIsAuthLoading(true);
      const res = await Auth.signUp({
        username: email,
        password: `password${Math.random().toString().slice(0, 8)}`,
        attributes: { email },
      });
      console.log(res);
      await requestMagicLink(email);
      setIsAuthLoading(false);
      return { authenticated: true };
    } catch (error: any) {
      console.log(error);
      setIsAuthLoading(false);
      return authenticate(email);
    }
  };

  const answerCustomChallenge = async (email: string, answer: string) => {
    setIsAuthLoading(true);
    try {
      const cognitoUser = await Auth.signIn(email);
      const res = await Auth.sendCustomChallengeAnswer(cognitoUser, answer);

      res.getSession(() => {
        res.getUserAttributes((err: any) => {
          if (err) {
            alert(err.message || JSON.stringify(err));
            return;
          }
        });
      });
      const userId = getUserId(res);
      setUser({ id: userId, email: res.username } as UserLineItem);
      console.log("Get profile!!!!");
      const profile = await getProfile(userId);
      if (profile) {
        setUserProfile({ ...profile });
      }
      setLoggedIn(true);
      return true;
    } catch (e) {
      return false;
    } finally {
      setIsAuthLoading(false);
    }
  };

  const signOut = React.useCallback(async () => {
    setIsAuthLoading(true);
    await Auth.signOut();
    setLoggedIn(false);
    setIsAuthLoading(false);
  }, []);

  React.useEffect(() => {
    if (isFirstLoad) {
      isAuthenticated()
        .then((res) => {
          setLoggedIn(res);
          setIsAuthLoading(false);
        })
        .finally(() => {
          setIsFirstLoad(false);
        });
    }
  }, []);

  if (isFirstLoad) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        isFirstLoad,
        isAuthLoading,
        user,
        userProfile,
        setUserProfile,
        isAuthenticated,
        authenticate,
        register,
        answerCustomChallenge,
        signOut,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => React.useContext(AuthContext);

export { AuthProvider, useAuthContext };
