import * as React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ConditionDocumentForm } from "./ConditionDocumentForm";
import { ConditionDocumentSteps } from "@stories/molecules/ConditionDocumentSteps/ConditionDocumentSteps";
import { ConditionCommentForm } from "./ConditionCommentForm";
import { ConditionReviewForm } from "./ConditionReviewForm";
import CloseIcon from "@mui/icons-material/Close";
import { useDocument } from "@hooks/crud/useDocument";
import { useConditionComment } from "@hooks/crud/useConditionComment";

interface ConditionDocumentModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  condition: ConditionLineItem;
  userProfile: ProfileLineItem;
  handleEditCondition: (condition: ConditionLineItem) => void;
  handleClose: () => void;
}

export default function ConditionDocumentModal({
  open,
  project,
  permit,
  condition,
  userProfile,
  handleEditCondition,
  handleClose,
}: ConditionDocumentModalProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  const {
    isDocumentLoading,
    documents,
    createDocuments,
    updateDocuments,
    deleteDocument,
  } = useDocument(project?.id, permit?.id, condition?.id);

  const { isCommentsLoading, comments, createComments, deleteComment } =
    useConditionComment(condition?.id);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmitDocuments = async () => {
    handleNext();
  };

  const onSubmitComments = async () => {
    handleNext();
  };

  const onSendForReview = async () => {
    await handleEditCondition({
      ...condition,
      status: ConditionStatus.InReview,
    });
    handleClose();
  };

  const onAccept = async () => {
    await handleEditCondition({
      ...condition,
      status: ConditionStatus.Approved,
    });
    handleClose();
  };

  const onReject = async (rejectMessage: string) => {
    const rejectionMessage = `<p style='color:red;'>${rejectMessage}</p>`;
    await handleEditCondition({
      ...condition,
      status: ConditionStatus.Rejected,
      conditionComments: condition.conditionComments + rejectionMessage,
    });
    handleClose();
  };

  const selectedDocumentStep = (() => {
    switch (activeStep) {
      default:
      case 0:
        return (
          <ConditionDocumentForm
            project={project}
            permit={permit}
            condition={condition}
            userProfile={userProfile}
            documents={documents}
            createDocuments={createDocuments}
            updateDocuments={updateDocuments}
            deleteDocument={deleteDocument}
            handleSubmit={onSubmitDocuments}
            handleClose={handleClose}
            documentCount={documents.length}
          />
        );
      case 1:
        return (
          <ConditionCommentForm
            project={project}
            permit={permit}
            condition={condition}
            profile={userProfile}
            comments={comments}
            createComments={createComments}
            deleteComment={deleteComment}
            handleSubmit={onSubmitComments}
            handleBack={handleBack}
            handleClose={handleClose}
          />
        );
      case 2:
        return (
          <ConditionReviewForm
            handleBack={handleBack}
            handleClose={handleClose}
            onSendForReview={onSendForReview}
            onReject={onReject}
            onAccept={onAccept}
            userProfile={userProfile}
            project={project}
            permit={permit}
            condition={condition}
            comments={comments}
            documents={documents}
          />
        );
    }
  })();

  const title = "Add Documents";
  const loading = false;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"lg"}
      className="bgBlueOverlay"
    >
      <DialogTitle
        sx={{ padding: "30px 35px 25px", fontSize: "20px !important" }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 14,
            top: 11,
            color: "#fff",
            background: "#000",
            "&:hover": {
              background: "#005cd1",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "30px 35px 40px" }}>
        <ConditionDocumentSteps activeStep={activeStep} loading={loading} />
        {selectedDocumentStep}
      </DialogContent>
    </Dialog>
  );
}
