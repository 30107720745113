import React from "react";
import { Box, Typography } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  ConditionLineItem,
  ConditionStatus,
} from "permit-one-common/src/interfaces/condition";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

interface ProgressTableProps {
  condition: ConditionLineItem;
  submitter: ProfileLineItem[];
  approver: ProfileLineItem[];
}

const ProgressTable: React.FC<ProgressTableProps> = ({
  condition,
  submitter,
  approver,
}) => {
  const getIcon = (status: "complete" | "in-progress" | "pending") => {
    switch (status) {
      case "complete":
        return <CheckIcon color="success" />;
      case "in-progress":
        return <CheckIcon color="action" />;
      case "pending":
        return <CloseIcon color="error" />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 600,
        bgcolor: "background.paper",
        border: "1px solid #ddd",
        borderRadius: 2,
        boxShadow: 3,
        p: 3,
      }}
    >
      <Typography variant="h6" component="div" sx={{ mb: 2 }}>
        Process Timeline
      </Typography>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              {getIcon(
                condition.status !== ConditionStatus.InReview &&
                  condition.status !== ConditionStatus.Approved
                  ? "complete"
                  : "pending"
              )}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1">
              {submitter.map((s) => s.name).join(", ")}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              {getIcon(
                condition.status === ConditionStatus.InReview
                  ? "in-progress"
                  : "pending"
              )}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1">
              {approver.map((s) => s.name).join(", ")}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot>
              {getIcon(
                condition.status === ConditionStatus.Approved
                  ? "complete"
                  : "pending"
              )}
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="body1">Approved</Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
};

export default ProgressTable;
