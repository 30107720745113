import { v4 as uuid } from "uuid";
import * as React from "react";
import { getImageUrl } from "@hooks/utils/useUpload";
import { decodePublicCondition } from "permit-one-common/src/decoders/publicCondition";
import { PublicCondition } from "permit-one-common/src/entities/publicCondition";
import { PublicConditionLineItem } from "permit-one-common/src/interfaces/publicCondition";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import {
  FileGeneration,
  FileGenerationStatus,
} from "permit-one-common/src/entities/fileGeneration";
import { FileGenerationLineItem } from "permit-one-common/src/interfaces/fileGeneration";
import { decodeFileGeneration } from "permit-one-common/src/decoders/fileGeneration";
import { sleep } from "permit-one-common/src/utils/sleep";

const publicEndpoint =
  "https://nn95jitwp4.execute-api.ap-southeast-2.amazonaws.com/dev/signIn";

export const usePublicConditions = (accessId?: string) => {
  const [publicConditionPageModel, setPublicConditionPageModel] =
    React.useState<PublicConditionLineItem>();
  const [isConditionLoading, setIsConditionLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const [downloadStepPDF, setDownloadStepPDF] =
    React.useState<FileGenerationStatus>(FileGenerationStatus.Idle);
  const [PDFDownloadProgress, setPDFDownloadProgress] =
    React.useState<number>(0);

  const [downloadStepExcel, setDownloadStepExcel] =
    React.useState<FileGenerationStatus>(FileGenerationStatus.Idle);
  const [excelDownloadProgress, setExcelDownloadProgress] =
    React.useState<number>(0);

  const [downloadStepAllFiles, setDownloadStepAllFiles] =
    React.useState<FileGenerationStatus>(FileGenerationStatus.Idle);
  const [allFilesDownloadProgress, setAllFilesDownloadProgress] =
    React.useState<number>(0);

  const getPublicConditonModel = async (id: string): Promise<void> => {
    try {
      setIsConditionLoading(true);
      const res = await fetch(publicEndpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          accessId: id,
          callFn: "getPublicConditonModel",
        }),
      });

      if (res) {
        const content = await res.json();
        const conditionLineItem = await decodePublicCondition(
          content as PublicCondition,
          getImageUrl
        );
        setPublicConditionPageModel(conditionLineItem);
      } else {
        setPublicConditionPageModel(undefined);
      }
    } catch (e: any) {
      setError("Could not fetch public condition");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const createDocuments = async (
    conditionDocuments: ConditionDocumentLineItem[]
  ) => {
    try {
      if (publicConditionPageModel) {
        setIsConditionLoading(true);
        const res = await fetch(publicEndpoint, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...conditionDocuments,
            callFn: "createDocuments",
          }),
        });

        if (res.ok) {
          console.log("Create documents.");
        } else {
          console.error("Create documents.");
        }

        const newDocuments = [
          ...publicConditionPageModel.documents,
          ...conditionDocuments,
        ];
        setPublicConditionPageModel({
          ...publicConditionPageModel,
          documents: newDocuments,
        });
      }
    } catch (e: any) {
      setError("Could not create documents");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const createComments = async (
    conditionComments: ConditionCommentLineItem[]
  ) => {
    try {
      setIsConditionLoading(true);
      if (publicConditionPageModel) {
        const res = await fetch(publicEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            comments: conditionComments,
            callFn: "createComments",
          }),
        });

        if (res.ok) {
          console.log("Create documents.");
        } else {
          console.error("Create documents.");
        }

        const newComments = [
          ...publicConditionPageModel.comments,
          ...conditionComments,
        ];

        setPublicConditionPageModel({
          ...publicConditionPageModel,
          comments: newComments,
        });
      }
    } catch (e: any) {
      setError("Could not create documents");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const sendForReview = async (condition: ConditionLineItem) => {
    try {
      setIsConditionLoading(true);
      if (publicConditionPageModel) {
        const res = await fetch(publicEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            condition: condition,
            callFn: "sendForReview",
          }),
        });

        if (res.ok) {
          console.log("Create documents.");
        } else {
          console.error("Create documents.");
        }
      }
    } catch (e: any) {
      setError("Could not create documents");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const uploadDocument = async (file: File) => {
    try {
      setIsConditionLoading(true);
      const response = await fetch(publicEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          filename: file.name,
          callFn: "getPresignedUrl",
        }),
      });

      const { url } = await response.json();

      // Step 2: Upload the file to S3 using the presigned URL
      const uploadResponse = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": file.type,
        },
        body: file,
      });
      if (uploadResponse.ok) {
        console.log("File uploaded successfully.");
      } else {
        console.error("Failed to upload file.");
      }
    } catch (e: any) {
      setError("Could not create documents");
    } finally {
      setIsConditionLoading(false);
    }
  };

  const getPdfReport = async (
    projectId: string,
    permitId: string,
    zipFileTitle: string
  ) => {
    try {
      if (!publicConditionPageModel) {
        throw new Error("Could not fetch pdf report");
      }
      const fileGenerationId = uuid();
      setPDFDownloadProgress(0);
      setDownloadStepPDF(FileGenerationStatus.Fetching);

      let fileGenerationResult: FileGenerationLineItem | undefined = undefined;
      let counter = 0;
      while (counter < 300) {
        const response = await fetch(publicEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            callFn: "getPdfReport",
            fileGenerationId,
            projectId,
            permitId,
          }),
        });

        const json = response.json();
        fileGenerationResult = decodeFileGeneration(
          json as unknown as FileGeneration
        );

        setDownloadStepPDF(fileGenerationResult.status);
        if (fileGenerationResult.status === FileGenerationStatus.Error) {
          setError("Could not fetch pdf report");
          break;
        } else if (
          fileGenerationResult.status === FileGenerationStatus.Generated
        ) {
          break;
        }
        await sleep(1000);
        counter++;
      }

      if (fileGenerationResult && fileGenerationResult.url) {
        const response = await fetch(fileGenerationResult.url);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = zipFileTitle; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setError("Could not fetch pdf report");
        setDownloadStepPDF(FileGenerationStatus.Error);
      }
    } catch {
      setError("Could not fetch pdf report");
      setDownloadStepPDF(FileGenerationStatus.Error);
    } finally {
      setDownloadStepPDF(FileGenerationStatus.Idle);
    }
  };

  const getExcelReport = async (
    projectId: string,
    permitId: string,
    zipFileTitle: string
  ) => {
    try {
      if (!publicConditionPageModel) {
        throw new Error("Could not fetch excel report");
      }
      const fileGenerationId = uuid();
      setExcelDownloadProgress(0);
      setDownloadStepExcel(FileGenerationStatus.Fetching);

      let fileGenerationResult: FileGenerationLineItem | undefined = undefined;
      let counter = 0;
      while (counter < 300) {
        const response = await fetch(publicEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            callFn: "getXlsReport",
            profileId: publicConditionPageModel?.userProfile?.id || "",
            fileGenerationId,
            projectId,
            permitId,
          }),
        });

        const json = response.json();
        fileGenerationResult = decodeFileGeneration(
          json as unknown as FileGeneration
        );

        setDownloadStepExcel(fileGenerationResult.status);
        if (fileGenerationResult.status === FileGenerationStatus.Error) {
          setError("Could not fetch pdf report");
          break;
        } else if (
          fileGenerationResult.status === FileGenerationStatus.Generated
        ) {
          break;
        }
        await sleep(1000);
        counter++;
      }

      if (fileGenerationResult && fileGenerationResult.url) {
        const response = await fetch(fileGenerationResult.url);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = zipFileTitle; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setError("Could not fetch pdf report");
        setDownloadStepPDF(FileGenerationStatus.Error);
      } else {
        setError("Could not fetch pdf report");
        setDownloadStepExcel(FileGenerationStatus.Error);
      }
    } catch {
      setError("Could not fetch pdf report");
      setDownloadStepExcel(FileGenerationStatus.Error);
    } finally {
      setDownloadStepExcel(FileGenerationStatus.Idle);
    }
  };

  const getFullReportInfo = async (
    projectId: string,
    permitId: string,
    zipFileTitle: string
  ) => {
    try {
      if (!publicConditionPageModel) {
        throw new Error("Could not fetch full report");
      }
      const fileGenerationId = uuid();
      setAllFilesDownloadProgress(0);
      setDownloadStepAllFiles(FileGenerationStatus.Fetching);

      let fileGenerationResult: FileGenerationLineItem | undefined = undefined;
      let counter = 0;
      while (counter < 300) {
        const response = await fetch(publicEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            callFn: "getFileGeneration",
            fileGenerationId,
            projectId,
            permitId,
          }),
        });

        const json = await response.json();
        fileGenerationResult = decodeFileGeneration(json as FileGeneration);

        setDownloadStepAllFiles(fileGenerationResult.status);
        setAllFilesDownloadProgress(fileGenerationResult.progress);

        if (fileGenerationResult.status === FileGenerationStatus.Error) {
          setError("Could not fetch pdf report");
          break;
        } else if (
          fileGenerationResult.status === FileGenerationStatus.Generated
        ) {
          break;
        }
        await sleep(1000);
        counter++;
      }
      setAllFilesDownloadProgress(0);

      if (fileGenerationResult && fileGenerationResult.url) {
        const response = await fetch(fileGenerationResult.url);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = zipFileTitle; // Set the desired file name
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setError("Could not fetch pdf report");
        setDownloadStepPDF(FileGenerationStatus.Error);
      } else {
        setError("Could not fetch pdf report");
        setDownloadStepAllFiles(FileGenerationStatus.Error);
      }
    } catch {
      setError("Could not fetch pdf report");
      setDownloadStepAllFiles(FileGenerationStatus.Error);
    } finally {
      setDownloadStepAllFiles(FileGenerationStatus.Idle);
    }
  };

  React.useEffect(() => {
    if (accessId) {
      getPublicConditonModel(accessId);
    }
  }, [accessId]);

  return {
    publicConditionPageModel,
    isConditionLoading,
    downloadStepPDF,
    PDFDownloadProgress,
    downloadStepExcel,
    excelDownloadProgress,
    createDocuments,
    createComments,
    uploadDocument,
    sendForReview,
    getPdfReport,
    getExcelReport,
    getFullReportInfo,
    downloadStepAllFiles,
    allFilesDownloadProgress,
    error,
  };
};
