import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterLogo from "assets/images/foot-logo.png";
import instgram from "assets/images/instgram-logo.webp";
import linkedin from "assets/images/linkedin-logo.webp";
import LogoActive from "assets/images/LogoActive.png";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const Support = () => {
  const [IsSticky, setIsSticky] = useState(false);
  const [IsActive, setIsActive] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const [menus, setMenus] = useState([
    {
      label: "Solutions",
      items: [
        { label: "permit management", url: "/permit-management" },
        { label: "Communication portal", url: "/communication-portal" },
        { label: "document Control", url: "/document-control" },
        { label: "Reporting & Analytics", url: "/reporting-and-analytics" },
      ],
      isOpen: false,
    },
    {
      label: "Resources",
      items: [
        { label: "Templates", url: "/templates" },
        { label: "News & Articles", url: "/blog" },
        { label: "API Integration", url: "/api-integration" },
      ],
      isOpen: false,
    },
    {
      label: "Company",
      items: [
        { label: "Our Team", url: "/our-team" },
        { label: "Support", url: "/support" },
        { label: "Privacy Policy", url: "/privacy-policy" },
        { label: "Terms & Conditions ", url: "/terms-conditions" },
      ],
      isOpen: false,
    },
    // Add more menu objects as needed
  ]);

  const handleMenuToggle = (menuIndex: number) => {
    setMenus((prevMenus) => {
      const updatedMenus = [...prevMenus];
      updatedMenus[menuIndex].isOpen = !updatedMenus[menuIndex].isOpen;
      return updatedMenus;
    });
  };

  const accordionData = [
    {
      id: 1,
      title: "What is PermitOne?",
      content: ` <p>PermitOne is a comprehensive and intuitive permit management platform designed to streamline and 
      optimize the permit-related processes within the construction industry. It offers a central hub for various stakeholders, including town planners, building certifiers, developers, project managers, contractors, and consultants, to efficiently manage permit applications, track progress, and ensure compliance with regulations
      PermitOne simplifies the coordination and communication among project teams, automates notifications, and offers real-time updates, enhancing overall project control, efficiency, and compliance. </p> `,
    },
    {
      id: 2,
      title: "How can PermitOne help developments?",
      content: `<ul>
      <li>Efficiency and Time Savings: PermitOne streamlines the permit management process, automating many tasks that would otherwise be time-consuming and manual. This efficiency saves valuable time for all stakeholders involved, from town planners to project managers, allowing projects to move forward faster. </li>

      <li>Cost Reduction: By reducing the time and effort required for permit management, PermitOne can lead to cost savings. Fewer manual errors and quicker approvals mean fewer delays and potential financial penalties. </li>
      
      <li> Improved Compliance: The platform ensures that all permit conditions are tracked and met, reducing the risk of non-compliance issues. This, in turn, helps avoid costly fines or project disruptions due to regulatory issues.</li>
      
      <li>Enhanced Communication: PermitOne offers real-time collaboration tools that facilitate communication between various parties involved in a construction project. This leads to better coordination, fewer misunderstandings, and ultimately, more successful projects.</li>
      
      <li>Quality Assurance: Through customizable checklists and AI-driven insights, PermitOne helps maintain high-quality standards in construction projects. Building certifiers and consultants can rely on the platform for accurate assessments and adherence to regulations.</li>
      
      <li>Data-Driven Decisions: The platform's reporting and analytics capabilities provide valuable data insights, allowing project managers and developers to make informed decisions based on real-time information.</li>
      
      <li>Streamlined Workflows: PermitOne simplifies and automates permit-related workflows, reducing the administrative burden on professionals. This enables them to focus on their core tasks, leading to greater productivity and job satisfaction.</li>
      </ul>`,
    },
    {
      id: 3,
      title: "How does PermitOne manage permits?",
      content: `<p>PermitOne revolutionises permit management through robust automation, simplifying the often complex and time-consuming process in the construction industry. Automated data entry reduces manual errors and speeds up permit applications, while task allocation and real-time updates ensure that project teams remain coordinated and informed throughout the permit lifecycle.

      The platform's customisable checklists and automated notifications streamline compliance monitoring, guaranteeing that all permit conditions are met. Moreover, AI-driven insights provide a data-driven approach to permit management, offering predictive analytics and compliance forecasting for more informed decision-making. By automating these critical aspects, PermitOne not only saves time but also enhances collaboration, reduces risks, and ultimately contributes to more efficient and successful construction projects.</p>
      `,
    },
    {
      id: 4,
      title: "Can PermitOne integrate with existing project management tools?",
      content: `<p>Yes, PermitOne is designed to seamlessly integrate with various project management tools commonly used in the construction industry. This integration ensures a smooth workflow and allows users to leverage their existing systems to streamline the data input process and minimise duplication in business processes.</p>
      `,
    },
    {
      id: 5,
      title: " Is PermitOne compatible with different project sizes? ",
      content: `<p>Yes, PermitOne is scalable and can accommodate projects of different sizes. From large-scale commercial projects to residential houses, PermitOne can effectively manage the permit management process.</p> `,
    },
    {
      id: 6,
      title: "Is my data secure with PermitOne? ",
      content: `<p>Yes, data security is a top priority for PermitOne. We implement stringent security measures to protect user data from unauthorised access, loss, or misuse. We use industry-standard encryption protocols, secure data storage, and regular backups to ensure the highest level of data security.
      </p>`,
    },

    {
      id: 7,
      title: "How easy is it to onboard and use PermitOne? ",
      content: `<p>Onboarding and using PermitOne is designed to be user-friendly and intuitive. We provide user guides, tutorials, and customer support to help users navigate the platform easily. Our goal is to ensure a smooth onboarding experience and minimise the learning curve for new users.</p> 
      `,
    },

    {
      id: 8,
      title:
        "Are there any training or support resources available for PermitOne users?",
      content: `<p>Yes, we offer comprehensive training and support resources for PermitOne users. We provide documentation, video tutorials, and a dedicated support team to assist users with any questions or issues they may encounter. We are committed to ensuring our users have the resources they need to effectively utilise PermitOne.</p>
      `,
    },

    {
      id: 9,
      title: "What pricing options are available for PermitOne? ",
      content: `<p>We offer flexible pricing options for PermitOne based on the needs of our clients. Our pricing structure takes into account factors such as project size, user requirements, and additional features. Please contact our sales team to discuss pricing details and find a plan that suits your specific needs.
      </p>
      `,
    },
    {
      id: 10,
      title: "Can I request a demo or trial of PermitOne before purchasing? ",
      content: `<p>Yes, we offer demos and trials of PermitOne to interested customers. This allows you to explore the platform's features, interface, and functionality before making a purchasing decision. Please reach out to our sales team to schedule a demo or discuss trial options.
      </p>
      `,
    },
    {
      id: 11,
      title: "How can I get in touch with the PermitOne support team? ",
      content: `<p>Getting in touch with our support team is easy. You can reach out to us through our dedicated support channels, including email, phone, or our online support portal. Our team is available to assist you with any questions, technical issues, or support requests you may have.
      </p>
      `,
    },
    {
      id: 12,
      title:
        "Does PermitOne offer customisation options to meet specific needs?",
      content: `<p>Yes, PermitOne offers customisation options to meet the specific needs of your organisation. We understand that different projects and companies have unique requirements, and we strive to accommodate them. Our team can work with you to tailor PermitOne to align with your specific goals and workflows.
      </p>
      `,
    },
    {
      id: 13,
      title: "Can PermitOne accommodate multiple users and teams?",
      content: `<p>Absolutely, PermitOne is designed to support multiple users and teams. It allows you to invite team members, assign roles and permissions, and collaborate efficiently. Whether you have a small team or a large organisation, PermitOne can accommodate your user and team management needs.
      </p>
      `,
    },

    // Add more accordion data as needed
  ];

  return (
    <>
      {/* header Section  */}
      <header
        className={IsSticky === true ? "active sub-header" : "sub-header"}
      >
        <div className="container-new">
          <div className="navigation">
            <a href="/" className="site-logo">
              <img src={LogoActive} alt="socialpro" />
              {/* {IsSticky === true ? (
                <img src={Logo} alt="socialpro" />
              ) : (
                <img src={LogoActive} alt="socialpro" />
              )} */}
            </a>
            <ul>
              <li>
                <span>Solutions</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/permit-management">permit management</Link>
                  </li>
                  <li>
                    <Link to="/communication-portal">Communication portal</Link>
                  </li>
                  <li>
                    <Link to="/document-control">document Control</Link>
                  </li>
                  <li>
                    <Link to="/reporting-and-analytics">
                      Reporting & Analytics
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Resources</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/templates">templates</Link>
                  </li>
                  <li>
                    <Link to="/blog">News & Articles</Link>
                  </li>
                  <li>
                    <Link to="/api-integration">API Integration</Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Company</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/our-team">Our Team</Link>
                  </li>
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="head-btns">
              <a href="/request-demo" className="demo-btn">
                Request a Demo
              </a>
              <a href="/login" className="nav-login sub-head">
                <svg
                  data-bbox="0 0 50 50"
                  data-type="shape"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 50 50"
                  fill="#fff"
                >
                  <g>
                    <path
                      d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                      fill="#005CD1"
                    ></path>
                  </g>
                </svg>
                <span>Login</span>
              </a>
            </div>

            {IsActive === false ? (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                {IsSticky === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#005CD1"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                {IsSticky === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#005CD1"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        {/* Mob Navigation  */}

        <div className={IsActive === true ? "active mob-bar" : "mob-bar"}>
          <div className="mob-nav">
            <ul>
              {menus.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => handleMenuToggle(index)}
                  className={
                    menu.isOpen ? "dropdown-link svg-rotate" : "dropdown-link"
                  }
                >
                  <span>
                    {menu.label}
                    <ArrowDropDownIcon />
                  </span>
                  {menu.isOpen && (
                    <ul className=" mob-nav-dropdown">
                      {menu.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <Link to={item.url}>{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <li className="demo-btn">
                <a href="/request-demo" className="demo-link">
                  Request a Demo
                </a>
                <a href="/login" className="nav-login">
                  <svg
                    data-bbox="0 0 50 50"
                    data-type="shape"
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 50 50"
                    fill="#fff"
                  >
                    <g>
                      <path
                        d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                        fill="#005CD1"
                      ></path>
                    </g>
                  </svg>
                  <span>Login</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      {/* secondary Hero section */}
      <div className=" bg-overlay sec-hero banner-pt">
        <div className="container-new">
          <h3>SUPPORT</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            We're here to help
          </h2>
          <p>
            Need some assistance with navigating PermitOne? Our team of
            dedicated support specialists is also on-hand to help you with any
            specific queries, enter your details below and our team will get
            back to you as soon as possible.
          </p>
        </div>
      </div>

      {/* new form Section  */}
      <div className="accordion-section bg-white support-section">
        <div className="container-new">
          <h2>Frequently asked questions</h2>
          <div
            className="accordion-wrap"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {accordionData.map((item) => (
              <Accordion
                key={item.id}
                defaultExpanded={item.id === 1}
                className="accordion-item"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className="accordion-title"
                >
                  {item.title}
                </AccordionSummary>
                <AccordionDetails className="accordion-content">
                  {<div dangerouslySetInnerHTML={{ __html: item.content }} />}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-overlay question-cta ">
        <div className="container-new">
          <h3>Still have questions?</h3>
          <p>Please chat to our friendly team.</p>
          <a href="/request-demo" className="btn-dark-blue">
            Get in touch
          </a>
        </div>
      </div>

      {/* Footer Section  */}
      <footer className="darkblue-bg">
        <div className="container-new footer-flex">
          <div className="footer-links">
            <a href="/" className="footer-logo-new">
              <img src={FooterLogo} alt="FooterLogo" />
            </a>
            <div className="footer-social mob-hide">
              <ul className="social-links">
                <li>
                  <a
                    href=" https://www.instagram.com/permit.one/"
                    target="_blank"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br></br>SOFTWARE PTY LTD
              </p>
            </div>
          </div>
          <div className="footer-links">
            <h4>SOLUTION</h4>
            <ul>
              <li>
                <Link to="/permit-management">permit management</Link>
              </li>
              <li>
                <Link to="/communication-portal">Communication portal</Link>
              </li>
              <li>
                <Link to="/document-control">document Control</Link>
              </li>
              <li>
                <Link to="/reporting-and-analytics">Reporting & Analytics</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>RESOURCES</h4>
            <ul>
              <li>
                <Link to="/request-demo">Request a demo</Link>
              </li>
              <li>
                <Link to="/templates">TEMPLATES</Link>
              </li>
              <li>
                <Link to="/blog">News & Articles</Link>
              </li>
              <li>
                <Link to="/api-integration">API Integration</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>COMPANY</h4>
            <ul>
              <li>
                <Link to="/our-team">Our Team</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
            </ul>
            <div className="footer-social desk-hide">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.instagram.com/permit.one/"
                    target="_blank"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br></br>SOFTWARE PTY LTD
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
