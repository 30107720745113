import { TableCell, TableRow, Typography } from "@mui/material";

interface NoRowsProps {
  title: string;
  colSpan: number;
}
export const NoRows = ({ colSpan, title }: NoRowsProps) => {
  return (
    <TableRow className="emptyrow nofoundrow ">
      <TableCell
        align="center"
        colSpan={colSpan}
        sx={{ textAlign: "center !important" }}
      >
        <Typography paragraph>No {title}</Typography>
      </TableCell>
    </TableRow>
  );
};
