import { Box, List } from "@mui/material";
import TabButton from "../TabButton/TabButton";

interface ListConditionsTabsProps {
  loading: boolean;
  value: number;
  handleChange: (newValue: number) => void;
}

export const ListConditionsTabs = ({
  loading,
  value,
  handleChange,
}: ListConditionsTabsProps): JSX.Element => {
  return (
    <Box>
      <List
        className="mailList"
        sx={{ display: "flex", maxWidth: "max-content" }}
      >
        <TabButton
          isLoading={loading}
          title={"All"}
          tabIndex={0}
          selectedTab={value}
          handleSelect={handleChange}
        />
        <TabButton
          isLoading={loading}
          title={"Assigned"}
          tabIndex={1}
          selectedTab={value}
          handleSelect={handleChange}
        />
        <TabButton
          isLoading={loading}
          title={"In Review"}
          tabIndex={2}
          selectedTab={value}
          handleSelect={handleChange}
        />
        <TabButton
          isLoading={loading}
          title={"Approved"}
          tabIndex={3}
          selectedTab={value}
          handleSelect={handleChange}
        />
        <TabButton
          isLoading={loading}
          title={"Overdue"}
          tabIndex={4}
          selectedTab={value}
          handleSelect={handleChange}
        />
        <TabButton
          isLoading={loading}
          title={"Not Applicable"}
          tabIndex={5}
          selectedTab={value}
          handleSelect={handleChange}
        />
      </List>
    </Box>
  );
};
