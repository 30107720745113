import { ReactNode } from "react";
// import { ProjectProvider } from "@hooks/context/useProjectContext";
import { styled } from "@mui/material/styles";
import { ProfileProvider } from "@hooks/context/useProfileContext";
import { Container, Stack } from "@mui/material";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  marginLeft: "5vw",
  marginRight: "5vw",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

interface PermitLayoutProps {
  children: ReactNode;
}

export const NoLayout = (props: PermitLayoutProps) => {
  const { children } = props;

  return (
    <StyledRoot>
      <ProfileProvider>
        <Main>
          <Container maxWidth="xl">
            <Stack spacing={2}>{children}</Stack>
          </Container>
        </Main>
      </ProfileProvider>
    </StyledRoot>
  );
};
