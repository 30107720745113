import * as React from "react";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import {
  ConditionLineItem,
  createDefaultCondition,
} from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ConditionForm } from "../ConditionForm/ConditionForm";
import CloseIcon from "@mui/icons-material/Close";

interface ConditionModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  conditionCount: number;
  existingCondition?: ConditionLineItem;
  createCondition: (conditions: ConditionLineItem) => void;
  updateCondition: (conditions: ConditionLineItem) => void;
  handleClose: () => void;
}

export default function ConditionModal({
  open,
  project,
  permit,
  conditionCount,
  existingCondition,
  createCondition,
  updateCondition,
  handleClose,
}: ConditionModalProps) {
  const onSubmit = async (condition: ConditionLineItem) => {
    if (existingCondition) {
      await updateCondition(condition);
    } else {
      await createCondition(condition);
    }
    handleClose();
  };
  const title = existingCondition ? "Edit Condition" : "Create Condition";
  return (
    <div>
      <Dialog
        fullWidth={true}
        open={open}
        onClose={handleClose}
        className="bgBlueOverlay"
      >
        <DialogTitle
          sx={{ padding: "30px 34px 25px", fontSize: "20px !important" }}
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 14,
              top: 11,
              color: "#fff",
              background: "#011F44",
              "&:hover": {
                background: "#023AF3",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ padding: "30px 20px 40px" }}>
          <ConditionForm
            conditionCount={conditionCount}
            condition={
              existingCondition ||
              createDefaultCondition(project, permit, conditionCount)
            }
            handleSubmit={onSubmit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
