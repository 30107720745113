import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FileRow } from "./FileRow";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";

interface SelectedDocumentsProps {
  project: ProjectLineItem;
  documentCount: number;
  conditionDocuments: ConditionDocumentLineItem[];
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  removeFile: (documents: ConditionDocumentLineItem) => void;
}

export const SelectedDocumentsRow = ({
  project,
  conditionDocuments,
  documentCount,
  updateDocuments,
  removeFile,
}: SelectedDocumentsProps) => {
  return (
    <Table aria-label="table" className="project-table popup-input">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "20%" }}>Local Name</TableCell>
          <TableCell sx={{ width: "20%" }}>Title</TableCell>
          <TableCell sx={{ width: "20%" }}>Discipline</TableCell>
          <TableCell sx={{ width: "10%" }}>Category</TableCell>
          <TableCell sx={{ width: "10%" }}>Remove</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {conditionDocuments.map((conditionDocument, index) => (
          <FileRow
            key={conditionDocument.id}
            project={project}
            documentCount={documentCount}
            conditionDocument={conditionDocument}
            updateDocuments={updateDocuments}
            index={index}
            removeFile={removeFile}
          />
        ))}
        {conditionDocuments.length === 0 ? (
          <NoRows colSpan={6} title={"files"} />
        ) : null}
      </TableBody>
    </Table>
  );
};
