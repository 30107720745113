import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import {
  ConditionCommentLineItem,
  createDefaultConditionComment,
} from "permit-one-common/src/interfaces/conditionComment";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

interface AddCommentModalProps {
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  condition: ConditionLineItem;
  userProfile: ProfileLineItem;
  setOpen: (open: boolean) => void;
  createComments: (conditionComments: ConditionCommentLineItem[]) => void;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  comment: Yup.string()
    .required("Comment is required")
    .min(5, "Comment must be at least 5 characters")
    .max(500, "Comment cannot exceed 500 characters"),
});

const AddCommentModal = ({
  open,
  project,
  permit,
  condition,
  userProfile,
  setOpen,
  createComments,
}: AddCommentModalProps) => {
  const handleClose = () => setOpen(false);

  const handleSubmit = async (values: { comment: string }) => {
    const newcomment = createDefaultConditionComment(
      project,
      permit,
      condition,
      userProfile,
      values.comment
    );
    await createComments([newcomment]);
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-comment-modal-title"
        aria-describedby="add-comment-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="add-comment-modal-title" variant="h6" component="h2">
            Add Comment
          </Typography>
          <Formik
            initialValues={{ comment: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <form>
                <Field
                  as={TextField}
                  name="comment"
                  label="Comment"
                  multiline
                  rows={4}
                  fullWidth
                  variant="outlined"
                  helperText={
                    touched.comment && errors.comment ? errors.comment : ""
                  }
                  error={touched.comment && Boolean(errors.comment)}
                  sx={{ mt: 2 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <Button onClick={handleClose} sx={{ mr: 1 }}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </div>
  );
};

export default AddCommentModal;
