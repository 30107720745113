import * as React from "react";
import { PermitCount, PermitCountLineItem } from "permit-one-common/src/interfaces/permit";
import { decodePermitCount } from "permit-one-common/src/decoders/permit";
import { getApiData } from "@hooks/utils/api";

export const usePermitCount = (projectId?: string) => {
  const [permitCounts, setPermitCounts] = React.useState<PermitCountLineItem>();
  const [isPermitCountLoading, setIsPermitCountLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const getPermitCounts = async (id: string): Promise<void> => {
    try {
      setIsPermitCountLoading(true);
      const permitCountsResult = await getApiData(
        "getPermitCounts",
        "permit",
        id
      );
      if (permitCountsResult) {
        const permitCountsLineItem = await decodePermitCount(
          permitCountsResult.data as PermitCount
        );
        setPermitCounts(permitCountsLineItem);
      } else {
        setPermitCounts(undefined);
      }
    } catch (e: any) {
      setError("Could not fetch permit");
    } finally {
      setIsPermitCountLoading(false);
    }
  };

  React.useEffect(() => {
    if (projectId) {
      getPermitCounts(projectId);
    }
  }, [projectId]);

  return {
    permitCounts,
    isPermitCountLoading,
    error,
  };
};
