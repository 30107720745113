// material-ui
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
// assets
import { ReactNode } from "react";

interface TabButtonProps {
  isLoading: boolean;
  title: string;
  tabIndex: number;
  icon?: ReactNode;
  selectedTab: number;
  handleSelect: (s: number) => void;
}

const TabButton = ({
  isLoading,
  title,
  selectedTab,
  tabIndex,
  icon,
  handleSelect,
}: TabButtonProps) => {
  return isLoading ? (
    <ListItemButton
      selected={tabIndex === selectedTab}
      sx={{ minWidth: "100px", border: "1px solid rgb(208, 215, 222)" }}
    >
      {icon ? (
        <Skeleton animation="wave" variant="circular">
          <ListItemIcon>{icon}</ListItemIcon>
        </Skeleton>
      ) : undefined}
      <Skeleton animation="wave">
        <ListItemText primary={title} />
      </Skeleton>
    </ListItemButton>
  ) : (
    <ListItemButton
      selected={tabIndex === selectedTab}
      onClick={() => handleSelect(tabIndex)}
      sx={{ minWidth: "100px", border: "1px solid rgb(208, 215, 222)" }}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : undefined}
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export default TabButton;
