import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { CollaboratorTable } from "../Tables/CollaboratorTable/CollaboratorTable";
import { useDirectory } from "@hooks/crud/useDirectory";

interface ConditionCollaboratorsProps {
  loading: boolean;
  condition?: ConditionLineItem;
}
export const ConditionCollaborators = ({
  condition,
}: ConditionCollaboratorsProps): JSX.Element => {
  const { directory, isDirectoryLoading } = useDirectory(
    undefined,
    undefined,
    condition?.id
  );

  return (
    <CollaboratorTable loading={isDirectoryLoading} profiles={directory} />
  );
};
