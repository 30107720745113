import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import {
  postcodeValidator,
  postcodeValidatorExistsForCountry,
} from "postcode-validator";
import { Country, State } from "country-state-city";
import { validateABN } from "permit-one-common/src/utils/abn";
import { Autocomplete } from "@mui/material";
import phone from "phone";

interface PermitApplicantFormProps {
  permit: PermitLineItem;
  handleBack: () => void;
  handleSubmit: (permit: PermitLineItem) => void;
}

export const PermitApplicantForm = ({
  permit,
  handleBack,
  handleSubmit,
}: PermitApplicantFormProps) => {
  const validationSchema = yup.object({
    applicantName: yup.string().required("Applicant name is required"),
    abn: yup
      .string()
      .required("ABN is required")
      .test(
        "Valid ABN",
        "Not a valid ABN - i.e. 53 004 085 616",
        function (item) {
          return validateABN(item);
        }
      ),
    streetNumber: yup.string().required("Street number is required"),
    streetName: yup.string().required("Street name is required"),
    suburb: yup.string().required("Suburb is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    postCode: yup
      .string()
      .required("Post code is required")
      .test(
        "Valid for country",
        "Not a valid post code for country",
        function (item) {
          if (postcodeValidatorExistsForCountry(this.parent.country)) {
            const valid = postcodeValidator(item, this.parent.country);
            return valid;
          }
          return true;
        }
      ),
    contactPersonName: yup.string().required("Contact person name is required"),
    contactPersonPhone: yup
      .string()
      .required("Contact person phone is required")
      .test("Valid Phone", "Phone not valid for country", function (item) {
        const res = phone(item, {
          country: this.parent.country,
          validateMobilePrefix: false,
        });
        return res.isValid;
      }),
    contactPersonEmail: yup
      .string()
      .required("Contact person email is required")
      .email("Invalid email"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      applicantName: permit.applicantName,
      abn: permit.abn,
      streetNumber: permit.applicantStreetNumber,
      streetName: permit.applicantStreetName,
      suburb: permit.applicantSuburb,
      state: permit.applicantState,
      country: permit.applicantCountry,
      postCode: permit.applicantPostCode,
      contactPersonName: permit.applicantContactPersonName,
      contactPersonPhone: permit.applicantContactPersonPhone,
      contactPersonEmail: permit.applicantContactPersonEmail,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleSubmit({
        ...permit,
        applicantName: values.applicantName,
        abn: values.abn,
        applicantStreetNumber: values.streetNumber,
        applicantStreetName: values.streetName,
        applicantSuburb: values.suburb,
        applicantState: values.state,
        applicantCountry: values.country,
        applicantPostCode: values.postCode,
        applicantContactPersonName: values.contactPersonName,
        applicantContactPersonPhone: values.contactPersonPhone,
        applicantContactPersonEmail: values.contactPersonEmail,
      });
    },
  });

  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(formik.values.country);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="applicantName"
            name="applicantName"
            label="Applicant Name"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.applicantName}
            error={
              formik.touched.applicantName &&
              Boolean(formik.errors.applicantName)
            }
            helperText={
              formik.touched.applicantName ? formik.errors.applicantName : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="abn"
            name="abn"
            label="ABN/ACN (ABN ONLY FOR NOW)"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.abn}
            error={formik.touched.abn && Boolean(formik.errors.abn)}
            helperText={formik.touched.abn ? formik.errors.abn : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="streetNumber"
            name="streetNumber"
            label="Street Number"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.streetNumber}
            error={
              formik.touched.streetNumber && Boolean(formik.errors.streetNumber)
            }
            helperText={
              formik.touched.streetNumber ? formik.errors.streetNumber : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="streetName"
            name="streetName"
            label="Street Name"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.streetName}
            error={
              formik.touched.streetName && Boolean(formik.errors.streetName)
            }
            helperText={
              formik.touched.streetName ? formik.errors.streetName : ""
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              select
              id="country"
              name="country"
              disabled={formik.isSubmitting}
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country ? formik.errors.country : ""}
              label="Country"
            >
              {countries.map((c) => (
                <MenuItem key={c.isoCode} value={c.isoCode}>
                  {c.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              select
              id="state"
              name="state"
              disabled={formik.isSubmitting}
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state ? formik.errors.state : ""}
              label="State"
            >
              {states.map((s, index) => (
                <MenuItem key={`${s.isoCode}-${index}`} value={s.isoCode}>
                  {s.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="suburb"
            name="suburb"
            label="Suburb"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.suburb}
            error={formik.touched.suburb && Boolean(formik.errors.suburb)}
            helperText={formik.touched.suburb ? formik.errors.suburb : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="postCode"
            name="postCode"
            label="Post Code"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postCode}
            error={formik.touched.postCode && Boolean(formik.errors.postCode)}
            helperText={formik.touched.postCode ? formik.errors.postCode : ""}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="contactPersonName"
            name="contactPersonName"
            label="Contact Person Name"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPersonName}
            error={
              formik.touched.contactPersonName &&
              Boolean(formik.errors.contactPersonName)
            }
            helperText={
              formik.touched.contactPersonName
                ? formik.errors.contactPersonName
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="contactPersonEmail"
            name="contactPersonEmail"
            label="Contact Person Email"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPersonEmail}
            error={
              formik.touched.contactPersonEmail &&
              Boolean(formik.errors.contactPersonEmail)
            }
            helperText={
              formik.touched.contactPersonEmail
                ? formik.errors.contactPersonEmail
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="contactPersonPhone"
            name="contactPersonPhone"
            label="Contact Person Phone"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPersonPhone}
            error={
              formik.touched.contactPersonPhone &&
              Boolean(formik.errors.contactPersonPhone)
            }
            helperText={
              formik.touched.contactPersonPhone
                ? formik.errors.contactPersonPhone
                : ""
            }
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        mt={2}
        sx={{
          display: "flex !important",
          padding: "10px 0 0",
          justifyContent: "space-between",
        }}
      >
        <StyledButton
          loading={false}
          color="primary"
          variant="contained"
          disabled={formik.isSubmitting}
          onClick={handleBack}
        >
          Back
        </StyledButton>
        <StyledButton
          loading={false}
          color="primary"
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Next
        </StyledButton>
      </Grid>
    </form>
  );
};
