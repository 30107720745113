import {
  TableCell,
  TableRow,
  Chip,
  Stack,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { QuillInput } from "../QuillInput/QuillInput";
import {
  ConditionLineItem,
  conditionStatusToColor,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { StyledButton } from "../StyledButton/StyledButton";
import Iconify from "../Iconify/Iconify";
import { useState, MouseEvent } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import ConditionDocumentModal from "@stories/organisms/ConditionDocumentModal/ConditionDocumentModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ConditionAuthority } from "permit-one-common/src/entities/condition";

export function createMarkup(dirty: string) {
  return { __html: dirty };
}

interface EditableTableRowProps {
  loading: boolean;
  permit: PermitLineItem;
  condition: ConditionLineItem;
  conditions: ConditionLineItem[];
  setData: (data: ConditionLineItem[]) => void;
  handleEditCondition: (condition: ConditionLineItem) => void;
  handleDeleteCondition: (condition: ConditionLineItem) => void;
  handleOpenConditionEditModal: (condition: ConditionLineItem) => void;
}

function EditableTableRow({
  loading,
  permit,
  condition,
  conditions,
  setData,
  handleEditCondition,
  handleDeleteCondition,
  handleOpenConditionEditModal,
}: EditableTableRowProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [currentCondition, setCurrentCondition] =
    useState<ConditionLineItem>(condition);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const [openBulk, setOpenBulk] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const { userProfile } = useProfileContext();
  const { selectedProject, isProjectLoading } = useProjectContext();

  const [showDescriptionEditor, setShowDescriptionEditor] =
    useState<boolean>(false);
  const [showCommentEditor, setEditCommentEditor] = useState<boolean>(false);
  const [showDisciplineEditor, setShowDisciplineEditor] =
    useState<boolean>(false);
  const [showAssigneeNotesEditor, setShowAssigneeNotesEditor] =
    useState<boolean>(false);

  const handleTextChange = (
    id: string,
    column: keyof ConditionLineItem,
    newText: string
  ) => {
    const matchingCondition = conditions.find((item) => item.id === id);
    if (!matchingCondition) {
      return;
    }
    if (matchingCondition[column] !== newText) {
      setCurrentCondition({ ...matchingCondition, [column]: newText });
    }
  };

  const handleTextSave = () => {
    handleEditCondition(currentCondition);
  };

  const handleCloseBulk = () => {
    setOpenBulk(false);
  };

  const handleOpenBulk = () => {
    setOpenBulk(true);
  };

  return (
    <>
      <TableRow
        key={condition.id}
        onMouseLeave={() => {
          setShowDescriptionEditor(false);
          setEditCommentEditor(false);
        }}
      >
        <TableCell sx={{ border: "1px solid #000" }}>
          {`${
            condition.conditionAuthority === ConditionAuthority.DA
              ? "DA -"
              : "BCA -"
          }${condition.permitItemNumber}`}
        </TableCell>

        {/*<TableCell sx={{ border: "1px solid #000" }}>
  {condition.conditionAuthority === ConditionAuthority.DA ? `DA - ${condition.permitItemNumber}` : `BCA - ${condition.permitItemNumber}`}
        </TableCell>*/}
        <TableCell
          onClick={() => {
            setShowDescriptionEditor(true);
            setEditCommentEditor(false);
            setShowAssigneeNotesEditor(false);
          }}
          sx={{
            verticalAlign: "top",
          }}
        >
          {showDescriptionEditor ? (
            <QuillInput
              loading={false}
              value={currentCondition.conditionDetails}
              onChange={(value) =>
                handleTextChange(condition.id, "conditionDetails", value)
              }
              onBlur={() => {
                setShowDescriptionEditor(false);
                setEditCommentEditor(false);
                setShowAssigneeNotesEditor(false);
                setShowDisciplineEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.conditionDetails || "",
                }}
              />
              <EditIcon
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </TableCell>
        <TableCell
          onClick={() => {
            setEditCommentEditor(true);
            setShowDescriptionEditor(false);
            setShowAssigneeNotesEditor(false);
            setShowDisciplineEditor(false);
          }}
          sx={{
            verticalAlign: "top",
          }}
        >
          {showCommentEditor ? (
            <QuillInput
              loading={false}
              value={currentCondition.conditionComments}
              onChange={(value) =>
                handleTextChange(condition.id, "conditionComments", value)
              }
              onBlur={() => {
                setEditCommentEditor(false);
                setShowDescriptionEditor(false);
                setShowAssigneeNotesEditor(false);
                setShowDisciplineEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                position: "relative",
                padding: "5px !important",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.conditionComments || "",
                }}
              />
              <EditIcon
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </TableCell>
        <TableCell
          onClick={() => {
            setEditCommentEditor(false);
            setShowDescriptionEditor(false);
            setShowAssigneeNotesEditor(true);
            setShowDisciplineEditor(false);
          }}
          sx={{
            verticalAlign: "top",
          }}
        >
          {showAssigneeNotesEditor ? (
            <QuillInput
              loading={false}
              value={currentCondition.discipline}
              onChange={(value) =>
                handleTextChange(condition.id, "discipline", value)
              }
              onBlur={() => {
                setEditCommentEditor(false);
                setShowDescriptionEditor(false);
                setShowAssigneeNotesEditor(false);
                setShowDisciplineEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                position: "relative",
                padding: "5px !important",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.discipline || "",
                }}
              />
              <EditIcon
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </TableCell>
        <TableCell
          onClick={() => {
            setEditCommentEditor(false);
            setShowDescriptionEditor(false);
            setShowAssigneeNotesEditor(false);
            setShowDisciplineEditor(true);
          }}
          sx={{
            verticalAlign: "top",
          }}
        >
          {showDisciplineEditor ? (
            <QuillInput
              loading={false}
              value={currentCondition.discipline}
              onChange={(value) =>
                handleTextChange(condition.id, "assigneeComments", value)
              }
              onBlur={() => {
                setEditCommentEditor(false);
                setShowDescriptionEditor(false);
                setShowAssigneeNotesEditor(false);
                setShowDisciplineEditor(false);
                handleTextSave();
              }}
            />
          ) : (
            <div
              style={{
                position: "relative",
                padding: "5px !important",
              }}
            >
              <div
                style={{
                  width: "95%",
                }}
                dangerouslySetInnerHTML={{
                  __html: currentCondition?.assigneeComments || "",
                }}
              />
              <EditIcon
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  width: "20px",
                  height: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
          )}
        </TableCell>
        <TableCell>
          <Chip
            label={conditionStatusToString(condition?.status)}
            variant="filled"
            background-color={conditionStatusToColor(condition?.status)}
            sx={{
              backgroundColor: conditionStatusToColor(condition?.status),
            }}
          />
        </TableCell>
        <TableCell align="right">
          <Stack
            direction="row"
            spacing={2}
            alignItems={"end"}
            alignContent={"end"}
            justifyItems={"end"}
          >
            <StyledButton
              loading={loading}
              onClick={() => {
                handleOpenBulk();
              }}
              variant="outlined"
              disabled={loading}
              startIcon={<Iconify icon="eva:cloud-upload-outline" />}
              className="outlined-btn filled"
            >
              {"Documents"}
            </StyledButton>
          </Stack>
        </TableCell>
        <TableCell>
          <IconButton onClick={(event) => handleClick(event)}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                handleOpenConditionEditModal(condition);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirmDialog(true);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
      {userProfile && selectedProject && permit && condition && openBulk ? (
        <ConditionDocumentModal
          open={openBulk}
          project={selectedProject}
          permit={permit}
          condition={condition}
          userProfile={userProfile}
          handleClose={handleCloseBulk}
          handleEditCondition={handleEditCondition}
        />
      ) : null}

      {openConfirmDialog ? (
        <ConfirmationDialog
          message={"Do you want to delete this condition?"}
          open={openConfirmDialog}
          title={"Delete condition"}
          intent={"error"}
          onCancel={() => setOpenConfirmDialog(false)}
          onConfirm={() => handleDeleteCondition(condition)}
        />
      ) : null}
    </>
  );
}

export default EditableTableRow;
