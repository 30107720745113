import { useState } from "react";
import {
  ProjectLineItem,
  ProjectSector,
  projectSectorToString,
} from "permit-one-common/src/interfaces/project";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { PermitType, permitTypeToString, constructionTypearrayToString } from "permit-one-common/src/entities/permit";
import {
  ConditionStatus,
  conditionStatusToString,
} from "permit-one-common/src/interfaces/condition";
import { TextRow } from "@stories/atoms/TextRow/TextRow";
import { Grid } from "@mui/material";
import { getProfileName } from "permit-one-common/src/interfaces/profile";
import parse from "html-react-parser";
import { LinkRow } from "@stories/atoms/LinkRow/LinkRow";
import {TableHeader} from "@stories/atoms/TableHeader/TableHeader";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { usePermit } from "@hooks/crud/usePermit";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import PermitModal from "@stories/organisms/PermitModal/PermitModal";

interface PermitPropertiesProps {
  loading: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
}



export const PermitProperties = ({
  loading,
  project,
  permit,
}: PermitPropertiesProps): JSX.Element => {


  const { selectedProject, getProject } = useProjectContext();
  const { userProfile, userOrganisation } = useProfileContext();

  const { permits, createPermit, updatePermit } = usePermit(
    selectedProject?.id
  );

  const [selectedPermit, setSelectPermit] = useState<PermitLineItem | undefined> 
  (undefined);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectPermit(undefined);
  }

  const handleOpen = (permitToEdit?: PermitLineItem) => {
    setOpen(true);
    setSelectPermit(permitToEdit);
  }

  const handleCreatePermit = (permit: PermitLineItem) => {
    if (selectedProject) {
      createPermit(permit).then(() => {
        getProject(selectedProject.id);
      });
    }
  };

  const handleUpdatePermit = (permit: PermitLineItem) => {
    if (selectedProject) {
      updatePermit(permit).then(() => {
        getProject(selectedProject.id);
      });
    }
  };


  return (
    <>
    <Grid className="permitDetails" sx={{ padding: "20px 5px 0", marginLeft: "auto" }}>
    {userOrganisation &&
              userOrganisation.id === selectedProject?.organisationId ? (
    <StyledButton
                  loading={loading}
                  className="css-kok4z1"
                  variant="contained"
                  onClick={() => handleOpen(selectedPermit || permit)}
                >
                  Edit Permit Details
  </StyledButton>) : null}

  {selectedProject && userOrganisation && userProfile && open ? (
          <PermitModal
            open={open}
            handleClose={handleClose}
            createPermit={handleCreatePermit}
            updatePermit={handleUpdatePermit}
            permitCount={permits.length}
            organisation={userOrganisation}
            profile={userProfile}
            project={selectedProject}
            existingPermit={selectedPermit}
          />
        ) : null}
    </Grid>

    <Grid>
      <Grid className="permitDetails" sx={{ padding: "20px 5px 0" }}>
        <TableHeader
        loading={loading}
        title={"Permit Details"}
        />

        <TextRow
          loading={loading}
          title={"Project Name"}
          text={project?.projectName || ""}
        />
        <TextRow
          loading={loading}
          title={"Project Type"}
          text={
            project
              ? project?.projectSector !== ProjectSector.Other
                ? projectSectorToString(project?.projectSector)
                : project.projectSectorOther
              : ""
          }
        />
        <TextRow
          loading={loading}
          title={"Permit Name"}
          text={permit?.permitName || ""}
        />
        <TextRow
          loading={loading}
          title={"Permit Type"}
          text={
            permit
              ? permit?.permitType !== PermitType.Other
                ? permitTypeToString(permit.permitType)
                : permit.permitTypeOther
              : ""
          }
        />
        {/* <TextRow
          loading={loading}
          title={"Value of Works"}
          text={permit ? `$${permit.valueOfPermitWork}` : ""}
        /> */}
        <TextRow
          loading={loading}
          title={"Condition Details"}
          text={
            permit?.permitDescription
              ? `${parse(permit.permitDescription)}`
              : ""
          }
        />
        <TextRow
          loading={loading}
          title={"Permit Manager"}
          text={
            permit && permit.managers.length > 0
              ? permit.managers.map((a) => getProfileName(a)).join(", ")
              : conditionStatusToString(ConditionStatus.NotAssigned)
          }
        />
        <TextRow
          loading={loading}
          title={"Permit Approver"}
          text={
            permit && permit.approvers.length > 0
              ? permit.approvers.map((a) => getProfileName(a)).join(", ")
              : conditionStatusToString(ConditionStatus.NotAssigned)
          }
        />

        {permit?.uri ? (
          <LinkRow
            loading={loading}
            title={"Permit Attachment"}
            permit={permit}
            link={permit?.uri || ""}
          />
        ) : null}
      </Grid>

      <Grid className="permitDetails" sx={{ padding: "20px 5px 0" }}>
      <TableHeader
        loading={loading}
        title={"Building Work Detail"}
        />

      <TextRow
          loading={loading}
          title={"Storeys Contained"}
          text={permit?.storeysContained || ""}
        />
        <TextRow
          loading={loading}
          title={"Rise in Storeys"}
          text={permit?.riseInStoreys || ""}
        />
        <TextRow
          loading={loading}
          title={"Type of Construction"}
          text={
            permit
            ? 
            constructionTypearrayToString(permit.typeOfConstruction)
            : ""
          }
      />
      <TextRow
          loading={loading}
          title={"BCA Version"}
          text={permit?.bcaVersion || ""}
        />
        <TextRow
          loading={loading}
          title={"Stage details of work permitted"}
          text={permit?.stageDetailsOfWork || ""}
        />
        <TextRow
          loading={loading}
          title={"Building classifications"}
          text={permit?.buildingClassification || ""}
        />

        {permit?.uri ? (
          <LinkRow
            loading={loading}
            title={"Permit Attachment"}
            permit={permit}
            link={permit?.uri || ""}
          />
        ) : null}
      </Grid>

      <Grid className="permitDetails" sx={{ padding: "20px 5px 0" }}>
      <TableHeader
        loading={loading}
        title={"Development Approval Details"}
        />

        <TextRow
          loading={loading}
          title={"Development Approval number"}
          text={permit?.developmentApprovalNumber || ""}
        />
        <TextRow
          loading={loading}
          title={"council Authority"}
          text={permit?.councilAuthority || ""}
        />
        <TextRow
          loading={loading}
          title={"development Approval Date"}
          text={permit?.developmentApprovalDate?.toString() || ""}
        />
        <TextRow
          loading={loading}
          title={"development Approval Lapse Date"}
          text={permit?.developmentApprovalLapseDate?.toString() || ""}
        />

        {permit?.uri ? (
          <LinkRow
            loading={loading}
            title={"Permit Attachment"}
            permit={permit}
            link={permit?.uri || ""}
          />
        ) : null}
      </Grid>

      <Grid className="permitDetails" sx={{ padding: "20px 5px 0" }}>
      <TableHeader
        loading={loading}
        title={"Address"}
        />
        
        <TextRow
          loading={loading}
          title={"Street Number"}
          text={permit?.landStreetNumber || ""}
        />
        <TextRow
          loading={loading}
          title={"Street Name"}
          text={permit?.landStreetName || ""}
        />
        <TextRow
          loading={loading}
          title={"Suburb"}
          text={permit?.landSuburb || ""}
        />
        <TextRow
          loading={loading}
          title={"Post code"}
          text={permit?.landPostCode || ""}
        />
        <TextRow
          loading={loading}
          title={"Country"}
          text={permit?.landCountry || ""}
        />
        <TextRow
          loading={loading}
          title={"State"}
          text={permit?.landState || ""}
        />
        <TextRow
          loading={loading}
          title={"Lot Number"}
          text={permit?.landLotNumber || ""}
        />
        <TextRow
          loading={loading}
          title={"DPS/MPS Number"}
          text={permit?.landDpsMpsNumber || ""}
        />

        {permit?.uri ? (
          <LinkRow
            loading={loading}
            title={"Permit Attachment"}
            permit={permit}
            link={permit?.uri || ""}
          />
        ) : null}
      </Grid>

      <Grid className="permitDetails" sx={{ padding: "20px 5px 0" }}>
      <TableHeader
        loading={loading}
        title={"Applicant"}
        />
        
        <TextRow
          loading={loading}
          title={"Applicant Name"}
          text={permit?.applicantName || ""}
        />
        <TextRow
          loading={loading}
          title={"ABN"}
          text={permit?.abn || ""}
        />
        <TextRow
          loading={loading}
          title={"Street Number"}
          text={permit?.applicantStreetNumber || ""}
        />
        <TextRow
          loading={loading}
          title={"Street Name"}
          text={permit?.applicantStreetName || ""}
        />
        <TextRow
          loading={loading}
          title={"Country"}
          text={permit?.applicantCountry || ""}
        />
        <TextRow
          loading={loading}
          title={"State"}
          text={permit?.applicantState || ""}
        />
        <TextRow
          loading={loading}
          title={"Suburb"}
          text={permit?.applicantSuburb || ""}
        />
        <TextRow
          loading={loading}
          title={"Post Code"}
          text={permit?.applicantPostCode || ""}
        />
        <TextRow
          loading={loading}
          title={"Contact Person Name"}
          text={permit?.applicantContactPersonName || ""}
        />
        <TextRow
          loading={loading}
          title={"Contact Person Email"}
          text={permit?.applicantContactPersonEmail || ""}
        />
        <TextRow
          loading={loading}
          title={"Contact Person Phone"}
          text={permit?.applicantContactPersonPhone || ""}
        />

        {permit?.uri ? (
          <LinkRow
            loading={loading}
            title={"Permit Attachment"}
            permit={permit}
            link={permit?.uri || ""}
          />
        ) : null}
        </Grid>

        <Grid className="permitDetails" sx={{ padding: "20px 5px 0" }}>
        <TableHeader
        loading={loading}
        title={"Owner"}
        />

        <TextRow
          loading={loading}
          title={"Owner Name"}
          text={permit?.ownerApplicantName || ""}
        />
        <TextRow
          loading={loading}
          title={"ABN"}
          text={permit?.ownerAbn || ""}
        />
        <TextRow
          loading={loading}
          title={"Street Number"}
          text={permit?.ownerStreetNumber || ""}
        />
        <TextRow
          loading={loading}
          title={"Street Name"}
          text={permit?.ownerStreetName || ""}
        />
        <TextRow
          loading={loading}
          title={"Country"}
          text={permit?.ownerCountry || ""}
        />
        <TextRow
          loading={loading}
          title={"State"}
          text={permit?.ownerState || ""}
        />
        <TextRow
          loading={loading}
          title={"Suburb"}
          text={permit?.ownerSuburb || ""}
        />
        <TextRow
          loading={loading}
          title={"Post Code"}
          text={permit?.ownerPostCode || ""}
        />
        <TextRow
          loading={loading}
          title={"Contact Person Name"}
          text={permit?.ownerContactPersonName || ""}
        />
        <TextRow
          loading={loading}
          title={"Contact Person Email"}
          text={permit?.ownerContactPersonEmail || ""}
        />
        <TextRow
          loading={loading}
          title={"Contact Person Phone"}
          text={permit?.ownerContactPersonPhone || ""}
        />

        {permit?.uri ? (
          <LinkRow
            loading={loading}
            title={"Permit Attachment"}
            permit={permit}
            link={permit?.uri || ""}
          />
        ) : null}
        </Grid>

        <Grid className="permitDetails" sx={{ padding: "20px 5px 0" }}>
        <TableHeader
        loading={loading}
        title={"Additional Info"}
        />

        <TextRow
          loading={loading}
          title={"Memorandum Note"}
          text={permit?.memorandumNote || ""}
        />
        <TextRow
          loading={loading}
          title={"Approvers"}
          text={permit?.approvers?.toString() || ""}
        />

        {permit?.uri ? (
          <LinkRow
            loading={loading}
            title={"Permit Attachment"}
            permit={permit}
            link={permit?.uri || ""}
          />
        ) : null}
        </Grid>
      </Grid>
    </>
  );
};
