import React, { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

interface AnimatedCounterProps {
  from: number;
  to: number;
}

function AnimatedCounter({ from, to }: AnimatedCounterProps) {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });

  const [animatedValue, setAnimatedValue] = useState<number>(from);
  const previousValueRef = useRef<number>(from);

  useEffect(() => {
    if (inView) {
      let start = previousValueRef.current;
      const end = to;
      const duration = 1000;
      const increment = (end - start) / (duration / 16); // Assuming 60fps

      const animate = () => {
        if (start < end) {
          setAnimatedValue(Math.floor(start));
          start += increment;
          requestAnimationFrame(animate);
        } else {
          setAnimatedValue(end);
        }
      };

      requestAnimationFrame(animate);
    }
  }, [inView, to]);

  useEffect(() => {
    if (!inView) {
      previousValueRef.current = from;
      setAnimatedValue(from);
    }
  }, [inView, from]);

  return (
    
      <div ref={inViewRef} className='count'>
        {inView ? (
          <span>{animatedValue}</span>
        ) : (
          ``
        )}
      </div>
    
  );
}

export default AnimatedCounter;
