// material-ui
import { useState } from "react";
import { Grid, List, InputAdornment } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ComposeDialog from "./ComposeDialog";
import InboxTwoToneIcon from "@mui/icons-material/InboxTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { MailBoxCount } from "./MailProps";
import { MailThreadLineItem } from "permit-one-common/src/interfaces/mail";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import MailDrawerButton from "./MailDrawerButton";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";

export enum MailBoxFilter {
  All = "ALL",
  Inbox = "INBOX",
  Sent = "SENT",
}
interface MailDrawerProps {
  isLoading: boolean;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  filter: MailBoxFilter;
  handleFilter: (s: MailBoxFilter) => void;
  handleCreateMail: (mail: MailThreadLineItem) => Promise<void>;
  unreadCounts: MailBoxCount | undefined;
  showDialog?: boolean;
}

const MailDrawer = ({
  isLoading,
  profile,
  project,
  permit,
  condition,
  filter,
  unreadCounts,
  showDialog,
  handleFilter,
  handleCreateMail,
}: MailDrawerProps) => {
  const [inputValue, setInputValue] = useState("");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const loading = isLoading;

  return (
    <Grid
      container
      sx={{
        padding: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Grid
        item
        md={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0",
        }}
      >
        <Grid item md={3} sx={{ padding: "0" }}>
          <StyledTextField
            className="search"
            loading={loading}
            type="text"
            placeholder="Search Mail..."
            name="filterProject"
            value={inputValue}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid
          item
          md={8}
          sx={{
            padding: "0",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          {showDialog ? (
            <ComposeDialog
              isLoading={loading}
              profile={profile}
              project={project}
              permit={permit}
              condition={condition}
              handleSubmit={handleCreateMail}
            />
          ) : null}
        </Grid>
      </Grid>
      <Grid item sx={{ marginTop: "20px" }}>
        <List
          className="mailList"
          sx={{ display: "flex", maxWidth: "max-content" }}
        >
          <MailDrawerButton
            isLoading={loading}
            title={"All Mail"}
            filter={filter}
            handleFilter={() => handleFilter(MailBoxFilter.All)}
            filterType={MailBoxFilter.All}
            icon={<EmailIcon />}
            unreadCounts={unreadCounts?.all}
          />
          <MailDrawerButton
            isLoading={loading}
            title={"Inbox"}
            filter={filter}
            handleFilter={() => handleFilter(MailBoxFilter.Inbox)}
            filterType={MailBoxFilter.Inbox}
            icon={<InboxTwoToneIcon />}
            unreadCounts={unreadCounts?.inbox}
          />
          <MailDrawerButton
            isLoading={loading}
            title={"Sent"}
            filter={filter}
            handleFilter={() => handleFilter(MailBoxFilter.Sent)}
            filterType={MailBoxFilter.Sent}
            icon={<SendTwoToneIcon />}
            unreadCounts={unreadCounts?.sent}
          />
        </List>
      </Grid>
    </Grid>
  );
};

export default MailDrawer;
