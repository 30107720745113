import * as React from "react";
import { decodeTaskCount } from "permit-one-common/src/decoders/task";
import { getApiData } from "@hooks/utils/api";
import { TaskCount, TaskCountLineItem } from "permit-one-common/src/interfaces/task";

export const useTaskCount = (projectId?: string, permitId?: string) => {
  const [taskCounts, setTaskCounts] = React.useState<TaskCountLineItem>();
  const [isTaskCountLoading, setIsTaskCountLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const getTaskCounts = async (
    id: string,
    permitId?: string
  ): Promise<void> => {
    try {
      setIsTaskCountLoading(true);
      const taskCountsResult = await getApiData("getTaskCounts", "task", id, {
        permitId,
      });
      if (taskCountsResult) {
        const taskCountsLineItem = await decodeTaskCount(
          taskCountsResult.data as TaskCount
        );
        setTaskCounts(taskCountsLineItem);
      } else {
        setTaskCounts(undefined);
      }
    } catch (e: any) {
      setError("Could not fetch task");
    } finally {
      setIsTaskCountLoading(false);
    }
  };

  React.useEffect(() => {
    if (projectId) {
      getTaskCounts(projectId, permitId);
    }
  }, [projectId, permitId]);

  return {
    taskCounts,
    isTaskCountLoading,
    error,
  };
};
