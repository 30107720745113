import {
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import {
  ConditionDocumentLineItem,
  documentCategoryToString,
} from "permit-one-common/src/interfaces/conditionDocument";
import { conditionDisciplineToString } from "permit-one-common/src/entities/condition";

import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { generateDocumentName } from "permit-one-common/src/utils/string";
import { ConditionDiscipline } from "permit-one-common/src/entities/condition";
import { DocumentCategory } from "permit-one-common/src/entities/conditionDocument";
import DeleteIcon from "@mui/icons-material/Delete";

interface FileRowProps {
  project: ProjectLineItem;
  conditionDocument: ConditionDocumentLineItem;
  documentCount: number;
  index: number;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  removeFile: (document: ConditionDocumentLineItem) => void;
}

export const FileRow = ({
  project,
  conditionDocument,
  documentCount,
  index,
  updateDocuments,
  removeFile,
}: FileRowProps) => {
  const handleSetTitle = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updateDocuments([
      {
        ...conditionDocument,
        documentTitle: event.target.value,
      },
    ]);
  };

  const handleSetDiscipline = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await updateDocuments([
      {
        ...conditionDocument,
        documentDiscipline: event.target.value as ConditionDiscipline,
        documentNumber: generateDocumentName(
          project?.projectName || "",
          event.target.value as ConditionDiscipline,
          conditionDocument.documentCategory,
          documentCount
        ),
      },
    ]);
  };

  const handleSetCategory = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await updateDocuments([
      {
        ...conditionDocument,
        documentCategory: event.target.value as DocumentCategory,
        documentNumber: generateDocumentName(
          project?.projectName || "",
          conditionDocument.documentDiscipline,
          event.target.value as DocumentCategory,
          documentCount
        ),
      },
    ]);
  };
  return (
    <TableRow>
      <TableCell>
        {conditionDocument?.file?.name ||
          generateDocumentName(
            project?.projectName || "",
            conditionDocument.documentDiscipline,
            conditionDocument.documentCategory,
            documentCount
          )}
      </TableCell>
      <TableCell>
        <TextField
          fullWidth
          id="documentTitle"
          name="documentTitle"
          value={conditionDocument.documentTitle}
          onChange={handleSetTitle}
        />
      </TableCell>
      <TableCell>
        <TextField
          select
          fullWidth
          id="documentDiscipline"
          name="documentDiscipline"
          value={conditionDocument.documentDiscipline}
          onChange={handleSetDiscipline}
          sx={{
            width: "207px !important",
            maxWidth: "207px !important",
            minWidth: "207px",
          }}
        >
          {Object.values(ConditionDiscipline).map((c, index) => (
            <MenuItem
              key={`${conditionDocument.id}-condition-document-discipline-${index}`}
              value={c}
            >
              {conditionDisciplineToString(c)}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell>
        <TextField
          select
          id="documentCategory"
          name="documentCategory"
          fullWidth
          value={conditionDocument.documentCategory}
          onChange={handleSetCategory}
          sx={{}}
        >
          {Object.values(DocumentCategory).map((d, index) => (
            <MenuItem
              key={`${conditionDocument.id}-document-types-${index}`}
              value={d}
            >
              {documentCategoryToString(d)}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell align="right" sx={{ textAlign: "center !important" }}>
        <IconButton
          color="error"
          size="large"
          onClick={() => {
            removeFile(conditionDocument);
          }}
        >
          <DeleteIcon
            sx={{
              fontSize: "1.1rem",
            }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
