import {
  PermitLineItem,
  permitStatusToColor,
  permitStatusToString,
} from "permit-one-common/src/interfaces/permit";
import { PermitType, permitTypeToString } from "permit-one-common/src/entities/permit";
import {
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Box,
  Tooltip,
  Zoom,
  MenuItem,
  Menu,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useState, MouseEvent } from "react";
import { ProfileLineItem, getProfileName } from "permit-one-common/src/interfaces/profile";
import { PermitStatus } from "permit-one-common/src/entities/permit";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreIcon from "@mui/icons-material/Restore";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {daysActive} from "permit-one-common/src/utils/date"

interface PermitTableRowProps {
  userOrganisation?: OrganisationLineItem;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  permit: PermitLineItem;
  handleViewPermit: (permit: PermitLineItem) => void;
  handleEditPermit: (permit: PermitLineItem) => void;
  handleDeletePermit: (permit: PermitLineItem) => void;
  handleDestroyPermit: (permit: PermitLineItem) => void;
  handleRestorePermit: (permit: PermitLineItem) => void;
}

export const PermitTableRow = ({
  userOrganisation,
  profile,
  project,
  permit,
  handleViewPermit,
  handleEditPermit,
  handleDeletePermit,
  handleDestroyPermit,
  handleRestorePermit,
}: PermitTableRowProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleRowClick = (permit: PermitLineItem) => {
    handleViewPermit(permit);
  };

  const {
    id,
    permitName,
    permitType,
    permitTypeOther,
    managers,
    approvers,
    created,
    status,
  } = permit;

  const isProjectOwner =
    userOrganisation && project && project.owner?.id === userOrganisation.id;
  const isPermitManager =
    profile &&
    permit &&
    permit.managers.map((pm) => pm.id).includes(profile.id);

  const canEdit = isProjectOwner || isPermitManager;

  return (
    <>
      <TableRow hover key={id} tabIndex={-1} role="checkbox">
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "5%" }}
        >
          <Chip
            label={permitStatusToString(status)}
            sx={{ backgroundColor: permitStatusToColor(status) }}
            variant="filled"
            background-color={permitStatusToColor(status)}
            className="chip"
          />
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "25%" }}
        >
          {permitName}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "25%" }}
        >
          {permitType !== PermitType.Other
            ? permitTypeToString(permitType)
            : permitTypeOther}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "15%" }}
        >
          {managers.length > 0
            ? managers.map((a) => getProfileName(a)).join(", ")
            : "Not Assigned"}
        </TableCell>

        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "15%" }}
        >
          {approvers.length > 0
            ? approvers.map((a) => getProfileName(a)).join(", ")
            : "Not Assigned"}
        </TableCell>
        <TableCell
          align="center"
          onClick={() => handleRowClick(permit)}
          sx={{ width: "10%" }}
        >
          {daysActive(created)}
        </TableCell>
        <TableCell align="right" sx={{ width: "10%" }}>
          <Box display="flex" gap="5px" justifyContent={"right"}>
            <IconButton onClick={(event) => handleClick(event)}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleRowClick(permit);
                }}
              >
                View
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleEditPermit(permit);
                }}
              >
                Edit
              </MenuItem>
              {permit.status !== PermitStatus.Archived ? (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenConfirmDialog(true);
                  }}
                >
                  Delete
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setOpenConfirmDialog(true);
                  }}
                >
                  Restore
                </MenuItem>
              )}
            </Menu>
          </Box>
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={
          permit.status !== PermitStatus.Archived
            ? "Are you sure you want to archive this permit?"
            : "Are you sure you want to restore this permit?"
        }
        open={openConfirmDialog}
        title={
          permit.status !== PermitStatus.Archived
            ? "Archive Project"
            : "Restore Project"
        }
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={
          permit.status !== PermitStatus.Archived
            ? () => handleDeletePermit(permit)
            : () => handleRestorePermit(permit)
        }
      />
    </>
  );
};
