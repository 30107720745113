"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultProject = exports.projectSectorToString = exports.ProjectSector = void 0;
var uuid_1 = require("uuid");
var ProjectSector;
(function (ProjectSector) {
    ProjectSector["Residential"] = "project-sector_residential";
    ProjectSector["Industrial"] = "project-sector_industrial";
    ProjectSector["Commercial"] = "project-sector_commercial";
    ProjectSector["Retail"] = "project-sector_retail";
    ProjectSector["Other"] = "project-sector_other";
})(ProjectSector || (exports.ProjectSector = ProjectSector = {}));
var projectSectorToString = function (projectSector) {
    switch (projectSector) {
        case ProjectSector.Residential:
            return "Residential";
        case ProjectSector.Industrial:
            return "Industrial";
        case ProjectSector.Commercial:
            return "Commercial";
        case ProjectSector.Retail:
            return "Retail";
        case ProjectSector.Other:
            return "Other";
    }
};
exports.projectSectorToString = projectSectorToString;
var createDefaultProject = function (organisation) {
    return {
        id: (0, uuid_1.v4)(),
        organisationId: organisation.id,
        owner: organisation,
        projectName: "",
        projectCode: "",
        projectSector: ProjectSector.Residential,
        projectSectorOther: "",
        projectDescription: "",
        streetNumber: "",
        streetName: "",
        suburb: "",
        state: "",
        country: "AU",
        postCode: "",
        lotNumber: "",
        dpsMpsNumber: "",
        estimatedProjectCost: 0,
        developer: "",
        totalPermits: 0,
        activePermits: 0,
        archivedPermits: 0,
        inReviewPermits: 0,
        created: new Date(),
        archived: false,
        collaborators: [],
        estimatedProgress: 0,
    };
};
exports.createDefaultProject = createDefaultProject;
