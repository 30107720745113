"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.permitEventTypeToString = void 0;
var EntityPermitEvent = __importStar(require("../entities/permitEvent"));
var permitEvent_1 = require("../entities/permitEvent");
var permitEventTypeToString = function (permitEventType) {
    switch (permitEventType) {
        case EntityPermitEvent.PermitEventType.PermitCreated:
            return "Permit created";
        case permitEvent_1.PermitEventType.PermitAssignedManager:
            return "Assigned manger";
        case permitEvent_1.PermitEventType.PermitAssignedApprover:
            return "Assigned approver";
        case permitEvent_1.PermitEventType.PermitInReview:
            return "Permit in review";
        case permitEvent_1.PermitEventType.PermitRejected:
            return "Permit rejected";
        case permitEvent_1.PermitEventType.PermitUpdated:
            return "Permit updated";
        case permitEvent_1.PermitEventType.PermitApproved:
            return "Permit approved";
        case permitEvent_1.PermitEventType.PermitUploadedDocument:
            return "Permit uploaded document";
        case permitEvent_1.PermitEventType.ConditionCreated:
            return "Permit condition created";
        case permitEvent_1.PermitEventType.ConditionAssigned:
            return "Condition assignee added";
        case permitEvent_1.PermitEventType.ConditionInReview:
            return "Condition in review";
        case permitEvent_1.PermitEventType.ConditionRejected:
            return "Condition rejected";
        case permitEvent_1.PermitEventType.ConditionUpdated:
            return "Condition updated";
        case permitEvent_1.PermitEventType.ConditionApproved:
            return "Condition approved";
        case permitEvent_1.PermitEventType.ConditionDocumentUploaded:
            return "Condition document uploaded";
        case permitEvent_1.PermitEventType.ConditionDocumentRevisionUploaded:
            return "Condition document revision uplaoded";
        case permitEvent_1.PermitEventType.ConditionDocumentAssigned:
            return "Condition document assigned";
        case permitEvent_1.PermitEventType.ConditionDocumentUnassigned:
            return "Condition document unassigned";
    }
    return "Unknown";
};
exports.permitEventTypeToString = permitEventTypeToString;
