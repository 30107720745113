import { useFormik } from "formik";
import * as yup from "yup";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { UserFinder } from "../UserFinder/UserFinder";
import { useProfile } from "@hooks/crud/useProfile";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";

interface UserFormProps {
  isLoading: boolean;
  organisation?: OrganisationLineItem;
  profiles: ProfileLineItem[];
  existingUser?: ProfileLineItem;
  handleSubmit: (user: ProfileLineItem) => void;
}

export const UserForm = ({
  isLoading,
  organisation,
  profiles,
  existingUser,
  handleSubmit,
}: UserFormProps) => {
  const { searchProfiles } = useProfile();
  const [newUser, setNewUser] = useState<ProfileLineItem | undefined>(
    existingUser
  );

  const validationSchema = yup.object({
    newUser: yup.string(),
    admin: yup.boolean(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newUser: existingUser?.email || "",
      admin: existingUser?.isAdmin || false,
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (newUser && organisation) {
        handleSubmit({
          ...newUser,
          organisationId: organisation.id,
          isAdmin: formik.values.admin,
        });
      }
    },
  });

  const onUserChange = (profile?: ProfileLineItem) => {
    formik.setFieldValue("newUser", profile?.email || "");
    setNewUser(profile);
  };

  const loading = isLoading || formik.isSubmitting;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <UserFinder
            id="newUser"
            name="newUser"
            label="User email"
            disabled={loading || existingUser !== undefined}
            onChange={onUserChange}
            onBlur={formik.handleBlur}
            handleSearch={searchProfiles}
            existingProfile={existingUser}
            blacklistedProfiles={profiles}
            error={formik.touched.newUser && Boolean(formik.errors.newUser)}
            helperText={formik.touched.newUser ? formik.errors.newUser : ""}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControlLabel
            id={"admin"}
            name={"admin"}
            defaultChecked={formik.values.admin}
            disabled={loading}
            control={
              <Checkbox
                checked={formik.values.admin}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("admin", e.target.checked);
                }}
              />
            }
            label="Is Admin"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex !important",
            justifyContent: "right !important;",
          }}
        >
          <StyledButton
            loading={loading}
            variant="contained"
            type="submit"
            disabled={loading}
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </form>
  );
};
