"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeMail = exports.decodeMail = exports.encodeMailThread = exports.decodeMailThread = void 0;
var profile_1 = require("./profile");
var string_1 = require("../utils/string");
var decodeMailThread = function (mailThread, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var mail, topMail, safeMailThread;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(mailThread.mail.map(function (m) {
                    return (0, exports.decodeMail)(m, getImageUrl);
                }))];
            case 1:
                mail = _a.sent();
                topMail = mail.reduce(function (a, b) { return (a.created > b.created ? a : b); });
                safeMailThread = (0, string_1.reversePostgresSafe)(mailThread);
                return [2 /*return*/, {
                        id: safeMailThread.mail_thread_id,
                        subject: safeMailThread.subject,
                        projectId: safeMailThread.project_id,
                        projectName: safeMailThread.project_name,
                        permitId: safeMailThread.permit_id,
                        permitName: safeMailThread.permit_name,
                        conditionId: safeMailThread.condition_id,
                        conditionName: safeMailThread.condition_name,
                        mail: mail,
                        topMail: topMail,
                        created: new Date(safeMailThread.created),
                    }];
        }
    });
}); };
exports.decodeMailThread = decodeMailThread;
var encodeMailThread = function (mailThread) {
    var safeMailThread = (0, string_1.makePostgresSafe)(mailThread);
    var mail = safeMailThread.mail.map(function (m) { return (0, exports.encodeMail)(m); });
    return {
        mail_thread_id: safeMailThread.id,
        subject: safeMailThread.subject,
        project_id: safeMailThread.projectId,
        project_name: safeMailThread.projectName, // Add project_name property
        permit_id: safeMailThread.permitId,
        permit_name: safeMailThread.permitName, // Add permit_name property
        condition_id: safeMailThread.conditionId,
        condition_name: safeMailThread.conditionName, // Add condition_name property
        mail: mail,
        created: new Date(safeMailThread.created),
    };
};
exports.encodeMailThread = encodeMailThread;
var decodeMail = function (mail, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var safeMail, sender;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                safeMail = (0, string_1.reversePostgresSafe)(mail);
                return [4 /*yield*/, (0, profile_1.decodeProfile)(safeMail.sender, getImageUrl)];
            case 1:
                sender = _a.sent();
                return [2 /*return*/, {
                        id: safeMail.mail_id,
                        mailThreadId: safeMail.mail_thread_id,
                        message: safeMail.message,
                        created: new Date(safeMail.created),
                        sender: sender,
                        attachments: safeMail.attachments,
                        sent: safeMail.sent,
                        readBy: safeMail.read_by,
                    }];
        }
    });
}); };
exports.decodeMail = decodeMail;
var encodeMail = function (mail) {
    var safeMail = (0, string_1.makePostgresSafe)(mail);
    return {
        mail_id: safeMail.id,
        mail_thread_id: safeMail.mailThreadId,
        message: safeMail.message,
        created: safeMail.created,
        sender_id: safeMail.sender.id,
        sender: (0, profile_1.encodeProfile)(safeMail.sender),
        attachments: safeMail.attachments,
        sent: safeMail.sent,
        read_by: safeMail.readBy,
    };
};
exports.encodeMail = encodeMail;
