"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePaymentPlan = exports.decodePaymentPlan = exports.encodeOrganisation = exports.decodeOrganisation = void 0;
var organisation_1 = require("../interfaces/organisation");
var string_1 = require("../utils/string");
var decodeOrganisation = function (organisation) {
    var safeOrganisation = (0, string_1.reversePostgresSafe)(organisation);
    return {
        id: safeOrganisation.organisation_id,
        organisationName: safeOrganisation.organisation_name,
        abn: safeOrganisation.abn,
        planType: (0, exports.decodePaymentPlan)(organisation.plan_type),
        country: safeOrganisation.country,
        state: safeOrganisation.state,
        city: safeOrganisation.city,
        postCode: safeOrganisation.post_code,
        memberType: safeOrganisation.member_type,
        stripeId: safeOrganisation.stripe_id,
        subscriptionId: safeOrganisation.subscription_id,
        planActive: safeOrganisation.plan_active,
        created: new Date(safeOrganisation.created),
    };
};
exports.decodeOrganisation = decodeOrganisation;
var encodeOrganisation = function (organisation) {
    var safeOrganisation = (0, string_1.makePostgresSafe)(organisation);
    return {
        organisation_id: safeOrganisation.id,
        organisation_name: safeOrganisation.organisationName,
        abn: safeOrganisation.abn,
        plan_type: (0, exports.encodePaymentPlan)(safeOrganisation.planType),
        country: safeOrganisation.country,
        state: safeOrganisation.state,
        city: safeOrganisation.city,
        post_code: safeOrganisation.postCode,
        member_type: safeOrganisation.memberType,
        stripe_id: safeOrganisation.stripeId,
        subscription_id: safeOrganisation.subscriptionId,
        plan_active: safeOrganisation.planActive,
        created: safeOrganisation.created,
    };
};
exports.encodeOrganisation = encodeOrganisation;
var decodePaymentPlan = function (profileType) {
    switch (profileType) {
        default:
            throw Error("Invalid profile type");
        case "plan-type_free":
            return organisation_1.PaymentPlan.Free;
        case "plan-type_business":
            return organisation_1.PaymentPlan.Business;
        case "plan-type_enterprise":
            return organisation_1.PaymentPlan.Enterprise;
    }
};
exports.decodePaymentPlan = decodePaymentPlan;
var encodePaymentPlan = function (profileType) {
    switch (profileType) {
        case organisation_1.PaymentPlan.Free:
            return "plan-type_free";
        case organisation_1.PaymentPlan.Business:
            return "plan-type_business";
        case organisation_1.PaymentPlan.Enterprise:
            return "plan-type_enterprise";
    }
};
exports.encodePaymentPlan = encodePaymentPlan;
