// third-party

// type
import { NavItemType } from "types";
import * as RouteHelper from "@utils/routes";
import FolderCopy from "@mui/icons-material/FolderCopy";
import { useTheme } from "@mui/material";
import Summarize from "@mui/icons-material/Summarize";

export const getWorkingNav = (accessId?: string): NavItemType => {
  const theme = useTheme();
  return {
    id: "working-nav",
    title: "Manage",
    icon: <FolderCopy sx={{ color: theme.palette.primary.main }} />,
    type: "group",
    children: [
      {
        id: "Conditions",
        title: "Conditions",
        type: "item",
        url: accessId ? RouteHelper.conditionPublic(accessId) : "",
        icon: <Summarize sx={{ color: theme.palette.primary.main }} />,
        breadcrumbs: false,
      },
    ],
  };
};
