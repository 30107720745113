"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMimeType = void 0;
var getMimeType = function (filename) {
    switch (filename.split(".").pop()) {
        case "pdf":
            return "application/pdf";
        case "png":
            return "image/png";
        case "jpg":
            return "image/jpeg";
        default:
            return "";
    }
};
exports.getMimeType = getMimeType;
