import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ConditionLineItem, ConditionStatus } from "permit-one-common/src/interfaces/condition";
import dayjs from "dayjs";
import { useState } from "react";
import { useProfile } from "@hooks/crud/useProfile";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import {
  ConditionAuthority,
  conditionAuthorityToString,
} from "permit-one-common/src/entities/condition";
import { UserMultiSearch } from "@stories/molecules/UserMultiSearch/UserMultiSearch";
import { InputAdornment, Typography, useTheme } from "@mui/material";
import SubCard from "@stories/ui-component/cards/SubCard";
import { QuillEditor } from "@stories/atoms/QuillEditor/QuillEditor";

interface ConditionFormProps {
  condition: ConditionLineItem;
  conditionCount: number;
  handleSubmit: (condition: ConditionLineItem) => void;
}

export const ConditionForm = ({
  condition,
  conditionCount,
  handleSubmit,
}: ConditionFormProps) => {
  const theme = useTheme();

  const { searchProfiles } = useProfile();
  const [assignees, setAssignees] = useState<ProfileLineItem[]>(
    condition.assignees
  );
  const [disableStatus, setDisableStatus] = useState<boolean>(
    condition?.assignees ? Boolean(condition.assignees.length) : false
  );

  const validationSchema = yup.object({
    conditionAuthority: yup
      .string()
      .required("Condition authority is required"),
    permitItemNumber: yup.string().required("Permit item number is required"),
    conditionComments: yup.string(),
    conditionDetails: yup.string(),
    assignee: yup.array(),
    status: yup.string(),
    discipline: yup.string().required("Discipline is required"),
    disciplineOther: yup
      .string()
      .test(
        "Required if other",
        "Discipline description required",
        function (item) {
          if (this.parent.discipline === "Other") {
            if (!item) {
              return false;
            }
            if (item.length === 0) {
              return false;
            }
          }
          return true;
        }
      ),
    dueDate: yup.date().required("Condition Due Date is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      conditionAuthority: condition.conditionAuthority,
      permitItemNumber:
        condition.permitItemNumber < 0
          ? conditionCount + 1
          : condition.permitItemNumber,
      conditionName: condition.conditionName,
      conditionComments: condition.conditionComments,
      conditionDetails: condition.conditionDetails,
      assignee: condition?.assignees || [],
      discipline: condition.discipline,
      disciplineOther: condition.disciplineOther,
      dueDate: dayjs(condition.dueDate),
      status: condition.status,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let conditionStatus = values.status as ConditionStatus;
      if (
        assignees.length > 0 &&
        values.status === ConditionStatus.NotAssigned
      ) {
        conditionStatus = ConditionStatus.Assigned;
      }

      await handleSubmit({
        ...condition,
        permitItemNumber: values.permitItemNumber,
        conditionName: values.conditionName,
        conditionComments: values.conditionComments,
        conditionDetails: values.conditionDetails,
        assignees: assignees,
        discipline: values.discipline,
        disciplineOther: values.disciplineOther,
        dueDate: values.dueDate.toDate(),
        status: conditionStatus,
      });
    },
  });

  const onAssigneeChange = (profiles?: ProfileLineItem[]) => {
    if (profiles) {
      setAssignees(profiles);
      formik.setFieldValue(
        "approvers",
        profiles.map((p) => p.id)
      );
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6} mt={1}>
          <TextField
            select
            id="conditionAuthority"
            name="conditionAuthority"
            label="Condition Authority"
            fullWidth
            disabled={formik.isSubmitting}
            value={formik.values.conditionAuthority}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.conditionAuthority &&
              Boolean(formik.errors.conditionAuthority)
            }
            helperText={
              formik.touched.conditionAuthority
                ? formik.errors.conditionAuthority
                : ""
            }
          >
            {Object.values(ConditionAuthority).map((p, index) => (
              <MenuItem key={`permit-type-${index}`} value={p}>
                {conditionAuthorityToString(p)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item md={6}>
          <TextField
            id="permitItemNumber"
            name="permitItemNumber"
            label="Condition Item Number"
            margin="dense"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.permitItemNumber}
            error={
              formik.touched.permitItemNumber &&
              Boolean(formik.errors.permitItemNumber)
            }
            helperText={
              formik.touched.permitItemNumber
                ? formik.errors.permitItemNumber
                : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {formik.values.conditionAuthority === ConditionAuthority.DA
                    ? "DA -"
                    : "BCA -"}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6}>
          <FormControl fullWidth>
            <TextField
              id="discipline"
              name="discipline"
              label="Discipline"
              fullWidth
              margin="dense"
              disabled={formik.isSubmitting}
              value={formik.values.discipline}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.discipline && Boolean(formik.errors.discipline)
              }
              helperText={
                formik.touched.discipline ? formik.errors.discipline : ""
              }
            />
          </FormControl>
        </Grid>
        <Grid item md={12} className="fullwidth messageBox" mb={1}>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Roboto,sans-serif",
              fontWeight: "400",
              color: "#637381",
              marginBottom: "10px",
            }}
          >
            Condition Details
          </Typography>
          <SubCard
            sx={{
              bgcolor: theme.palette.primary[200] + 40,
              "& .quill": {
                "& .ql-font-roboto": {
                  fontFamily: "'Roboto', sans-serif",
                },
                bgcolor: "grey.50",
                borderRadius: "12px",
                "& .ql-toolbar": {
                  bgcolor: "grey.100",
                  borderColor: theme.palette.primary.light,
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "&.ql-editor p": {
                  fontFamily: "'Roboto', sans-serif",
                },
                "& .ql-container": {
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "0.875rem",
                  borderColor: theme.palette.primary.light,
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  "& .ql-editor": {
                    minHeight: 150,
                  },
                },
              },
            }}
          >
            <QuillEditor
              loading={false}
              allowImages={true}
              onChange={(value: string) => {
                formik.setFieldValue("conditionDetails", value);
              }}
              value={formik.values.conditionDetails}
            />
            {formik.touched.conditionDetails &&
            Boolean(formik.errors.conditionDetails) ? (
              <Typography color={"error"}>
                {formik.errors.conditionDetails}
              </Typography>
            ) : undefined}
          </SubCard>
        </Grid>
        <Grid item md={12} className="fullwidth messageBox" mb={1}>
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Roboto,sans-serif",
              fontWeight: "400",
              color: "#637381",
              marginBottom: "10px",
            }}
          >
            Condition Comments
          </Typography>
          <SubCard
            sx={{
              bgcolor: theme.palette.primary[200] + 40,
              "& .quill": {
                "& .ql-font-roboto": {
                  fontFamily: "'Roboto', sans-serif",
                },
                bgcolor: "grey.50",
                borderRadius: "12px",
                "& .ql-toolbar": {
                  bgcolor: "grey.100",
                  borderColor: theme.palette.primary.light,
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "&.ql-editor p": {
                  fontFamily: "'Roboto', sans-serif",
                },
                "& .ql-container": {
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "0.875rem",
                  borderColor: theme.palette.primary.light,
                  borderBottomLeftRadius: "12px",
                  borderBottomRightRadius: "12px",
                  "& .ql-editor": {
                    minHeight: 150,
                  },
                },
              },
            }}
          >
            <QuillEditor
              loading={false}
              allowImages={true}
              onChange={(value: string) => {
                formik.setFieldValue("conditionComments", value);
              }}
              value={formik.values.conditionComments}
            />

            {formik.touched.conditionComments &&
            Boolean(formik.errors.conditionComments) ? (
              <Typography color={"error"}>
                {formik.errors.conditionComments}
              </Typography>
            ) : undefined}
          </SubCard>
        </Grid>
        <Grid item md={12}>
          <UserMultiSearch
            loading={false}
            id="assignees"
            name="assignees"
            label="Assignees"
            existingProfiles={assignees}
            disabled={formik.isSubmitting}
            onChange={(value?: ProfileLineItem[] | undefined) => {
              if (value) {
                if (formik.values.status === ConditionStatus.NotAssigned) {
                  formik.setFieldValue(
                    "status",
                    ConditionStatus.Assigned,
                    true
                  );
                }
                setDisableStatus(false);
              } else {
                formik.setFieldValue(
                  "status",
                  ConditionStatus.NotAssigned,
                  true
                );
                setDisableStatus(true);
              }
              onAssigneeChange(value);
            }}
            onBlur={formik.handleBlur}
            handleSearch={searchProfiles}
            error={formik.touched.assignee && Boolean(formik.errors.assignee)}
          />
        </Grid>
        <Grid item md={12}>
          <DatePicker
            sx={{ width: "100%" }}
            onChange={(value) => {
              if (value) {
                formik.setFieldValue("dueDate", value, true);
              }
            }}
            format={"DD/MM/YYYY"}
            disabled={formik.isSubmitting}
            value={formik.values.dueDate}
            slotProps={{
              textField: {
                margin: "dense",
                label: "Due date",
                helperText:
                  formik.touched.dueDate && formik.errors.dueDate
                    ? "Invalid date"
                    : "",
                error: formik.touched.dueDate && Boolean(formik.errors.dueDate),
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          display: "flex !important",
          justifyContent: "right !important;",
          padding: "10px 0 0",
        }}
      >
        <StyledButton
          loading={false}
          color="primary"
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Submit
        </StyledButton>
      </Grid>
    </form>
  );
};
