import { Storage as AWSStorage } from "aws-amplify";
import { BUCKET_NAME } from "App";
import { getMimeType } from "permit-one-common/src/utils/mime";

export enum UploadStatus {
  Idle = "Idle",
  Uploading = "Uploading",
  Saving = "Saving",
}

export const isImageFile = (fileName: string) => {
  return (
    fileName.includes("jpg") ||
    fileName.includes("png") ||
    fileName.includes("jpeg")
  );
};
export const getImageUrl = async (location: string): Promise<string> => {
  const url = await AWSStorage.get(location, {
    bucket: BUCKET_NAME,
    cacheControl: "no-cache",
  });
  return url;
};

export const uploadFile = async (
  location: string,
  body: File,
  onProgress: (progress: any) => void,
  onError?: (error: any) => void
) => {
  await AWSStorage.put(location, body, {
    bucket: BUCKET_NAME,
    progressCallback: onProgress,
    errorCallback: onError,
  });
};

export const uploadImage = async (
  location: string,
  body: File,
  onProgress: (progress: any) => void,
  onError: (error: any) => void
): Promise<void> =>
  new Promise((resolve, reject) => {
    AWSStorage.put(location, body, {
      bucket: BUCKET_NAME,
      progressCallback: onProgress,
      errorCallback: onError,
    })
      .then(() => {
        resolve();
      })
      .catch(() => {
        alert("Could not upload image");
        reject();
      });
  });

const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download.txt";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener("click", clickHandler);
    }, 150);
  };
  a.addEventListener("click", clickHandler, false);
  a.click();
  return a;
};

interface BucketConfig {
  [key: string]: string | number | true;
}

export const downloadFile = async (
  location: string,
  downloadName: string,
  onProgress?: (progress: string) => void,
  newTab = false
) => {
  const config: BucketConfig = {
    download: true,
    cacheControl: "max-age=120",
  };
  const r = (await AWSStorage.get(location, {
    ...config,
    cacheControl: "no-cache",
    progressCallback: onProgress,
  })) as unknown as {
    Body: Blob;
  };

  if (newTab) {
    const mimeType = getMimeType(downloadName);
    const downloadedFile = new File([r.Body], downloadName, { type: mimeType });
    const url = window.URL.createObjectURL(downloadedFile);
    window.open(url, "_blank");
    URL.revokeObjectURL(url);
  } else {
    downloadBlob(r.Body, downloadName);
  }
};
