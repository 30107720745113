import { useProfileContext } from "@hooks/context/useProfileContext";
import { useGlobalDirectory } from "@hooks/crud/useGlobalDirectory";
import { Box, Grid, Typography } from "@mui/material";
import { GlobalDirectoryTable } from "@stories/organisms/Tables/GlobalDirectoryTable/GlobalDirectoryTable";

export const GlobalDirectory = (): JSX.Element => {
  const { isAuthProfileLoading } = useProfileContext();

  const { isDirectoryLoading, directory } = useGlobalDirectory();

  const loading = isAuthProfileLoading || isDirectoryLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0 0 40px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontSize: "28px !important",
            fontWeight: "700",
            color: "#333333",
          }}
        >
          Global Directory
        </Typography>
      </Grid>
      <Box sx={{ padding: "0 40px 40px" }}>
        <GlobalDirectoryTable loading={loading} directory={directory} />
      </Box>
    </Box>
  );
};
