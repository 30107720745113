import { Link as RouterLink } from "react-router-dom";

// material-ui
import { Link } from "@mui/material";

// project imports
import Logo from "assets/images/home/SocialPro_Blue.png";
import { DASHBOARD_PATH } from "config";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection2 = (monochrome: any) => (
  <Link component={RouterLink} to={DASHBOARD_PATH} aria-label="theme-logo">
     <img src={Logo} alt="logo" width="200px"/>
  </Link>
);

export default LogoSection2;
