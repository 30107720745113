import { useTheme } from "@mui/material/styles";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { format } from "date-fns";
import { MailDetailsProps } from "./MailProps";
import { gridSpacing } from "constant";
import Avatar from "@stories/ui-component/extended/Avatar";

const MailDetails = ({ exisitngMail }: MailDetailsProps) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      spacing={gridSpacing}
      sx={{ padding: "0 !important", width: "100%", margin: "0 0 40px" }}
    >
      <Grid
        item
        xs={12}
        sx={{ padding: "0 !important", width: "100%", margin: "0" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={matchDownSM ? 1 : 0}
        >
          <Grid item sx={{ padding: "0 !important", margin: "0" }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={matchDownSM ? 1 : 2}
            >
              <Avatar
                alt={exisitngMail?.sender.lastName}
                src={exisitngMail?.sender && exisitngMail.sender.photoUrl}
                size={matchDownSM ? "xs" : "sm"}
              />
              <Grid
                container
                alignItems="center"
                sx={{ padding: "0 !important" }}
              >
                <Grid item md={12} sx={{ padding: "0 !important" }}>
                  <Stack
                    direction={matchDownSM ? "column" : "row"}
                    alignItems={matchDownSM ? "flex-start" : "center"}
                    spacing={matchDownSM ? 0 : 1}
                    sx={{ padding: "0" }}
                  >
                    <Typography
                      variant={matchDownSM ? "h5" : "h4"}
                      sx={{ fontSize: "20px !important" }}
                    >
                      {exisitngMail?.sender.firstName}
                    </Typography>
                    <Typography
                      sx={{
                        display: { xs: "block", sm: "none" },
                        fontSize: "14px !important",
                      }}
                      variant="subtitle2"
                    >
                      From: &lt;{exisitngMail?.sender.email}&gt;
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item sx={{ display: { xs: "none", sm: "block" } }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ fontSize: "12px !important" }}
                  >
                    From: &lt;{exisitngMail?.sender.email}&gt;
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              {format(new Date(exisitngMail?.created || new Date()), "d MMM")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MailDetails;
