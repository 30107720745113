"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ownerApplicantToString = exports.OwnerApplicant = exports.constructionTypearrayToString = exports.constructionTypeToString = exports.ConstructionType = exports.permitTypeToAbbreviationString = exports.permitTypeToString = exports.PermitType = exports.PermitStatus = void 0;
var PermitStatus;
(function (PermitStatus) {
    PermitStatus["Active"] = "permit-status_active";
    PermitStatus["Complete"] = "permit-status_complete";
    PermitStatus["InReview"] = "permit-status_in-review";
    PermitStatus["Archived"] = "permit-status_archived";
})(PermitStatus || (exports.PermitStatus = PermitStatus = {}));
var PermitType;
(function (PermitType) {
    PermitType["ConstructionCertificate"] = "permit-type_construction-certificate";
    PermitType["ComplyingDevelopmentCertificate"] = "permit-type_complying-development-certificate";
    PermitType["OccupancyPermit"] = "permit-type_occupancy-permit";
    PermitType["Other"] = "permit-type_other";
})(PermitType || (exports.PermitType = PermitType = {}));
var permitTypeToString = function (permitType) {
    switch (permitType) {
        case PermitType.ConstructionCertificate:
            return "Construction Certificate (CC)";
        case PermitType.OccupancyPermit:
            return "Occupancy Permit (OP)";
        case PermitType.ComplyingDevelopmentCertificate:
            return "Complying Development Certificate (CDC)";
        case PermitType.Other:
            return "Other";
    }
};
exports.permitTypeToString = permitTypeToString;
var permitTypeToAbbreviationString = function (permitType) {
    switch (permitType) {
        case PermitType.ConstructionCertificate:
            return "CC";
        case PermitType.OccupancyPermit:
            return "OP";
        case PermitType.ComplyingDevelopmentCertificate:
            return "CDC";
        case PermitType.Other:
            return "O";
    }
};
exports.permitTypeToAbbreviationString = permitTypeToAbbreviationString;
var ConstructionType;
(function (ConstructionType) {
    ConstructionType["TypeA"] = "construction-type_type-a";
    ConstructionType["TypeB"] = "construction-type_type-b";
    ConstructionType["TypeC"] = "construction-type_type-c";
})(ConstructionType || (exports.ConstructionType = ConstructionType = {}));
var constructionTypeToString = function (constructionType) {
    switch (constructionType) {
        case ConstructionType.TypeA:
            return "Type A";
        case ConstructionType.TypeB:
            return "Type B";
        case ConstructionType.TypeC:
            return "Type C";
    }
};
exports.constructionTypeToString = constructionTypeToString;
var constructionTypearrayToString = function (constructionTypes) {
    return constructionTypes.map(exports.constructionTypeToString).join(', ');
};
exports.constructionTypearrayToString = constructionTypearrayToString;
var OwnerApplicant;
(function (OwnerApplicant) {
    OwnerApplicant["Owner"] = "owner-applicant_owner";
    OwnerApplicant["Applicant"] = "owner-applicant_applicant";
})(OwnerApplicant || (exports.OwnerApplicant = OwnerApplicant = {}));
var ownerApplicantToString = function (ownerApplicant) {
    switch (ownerApplicant) {
        case OwnerApplicant.Owner:
            return "Owner";
        case OwnerApplicant.Applicant:
            return "Applicant";
    }
};
exports.ownerApplicantToString = ownerApplicantToString;
