import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import EditableTableRowSkeleton from "./PublicEditableTableRowSkeleton";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { TableHeader } from "../TableHeader/TableHeader";
import PublicEditableTableRow from "./PublicEditableTableRow";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";

interface PublicEditableTableProps {
  loading: boolean;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  documents: ConditionDocumentLineItem[];
  createDocuments: (files: ConditionDocumentLineItem[]) => Promise<void>;
  createComments: (comments: ConditionCommentLineItem[]) => Promise<void>;
  sendForReview: (condition: ConditionLineItem) => void;
  uploadDocument: (file: File) => void;

  userProfile?: ProfileLineItem;
}

function PublicEditableTable({
  loading,
  project,
  permit,
  conditions,
  documents,
  createDocuments,
  createComments,
  sendForReview,
  uploadDocument,
  userProfile,
}: PublicEditableTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader loading={loading} title={"Item"} width={"5%"} />
            <TableHeader loading={loading} title={"Condition"} width={"20%"} />
            <TableHeader loading={loading} title={"Comments"} width={"20%"} />
            <TableHeader loading={loading} title={"Discipline"} width={"20%"} />
            <TableHeader
              loading={loading}
              title={"Assignee Notes"}
              width={"20%"}
            />
            <TableHeader loading={loading} title={"Status"} width={"5%"} />
            <TableHeader loading={loading} title={"Actions"} width={"10%"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || !project
            ? Array.from(new Array(10)).map((_, index) => (
                <EditableTableRowSkeleton key={index} />
              ))
            : null}
          {!loading && permit && project && userProfile
            ? conditions.map((row) => (
                <PublicEditableTableRow
                  key={row.id}
                  condition={row}
                  loading={loading}
                  project={project}
                  permit={permit}
                  documents={documents}
                  createDocuments={createDocuments}
                  createComments={createComments}
                  sendForReview={sendForReview}
                  uploadDocument={uploadDocument}
                  userProfile={userProfile}
                />
              ))
            : null}
          {!loading && conditions.length === 0 ? (
            <NoRows title={"Conditions"} colSpan={7} />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PublicEditableTable;
