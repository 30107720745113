// material-ui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

import LogoSection from "../LogoSection";

interface HeaderProps {
  isDrawOpen: boolean;
  setIsDrawOpen: (open: boolean) => void;
}
const Header = ({ isDrawOpen, setIsDrawOpen }: HeaderProps) => {
  const theme = useTheme();
  return (
    <>
      <Box
        className={isDrawOpen === true ? "admin-header" : "admin-header active"}
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          gap: "20px",
          padding: "25px 0 30px",
        }}
      >
        <LogoSection />
      </Box>
    </>
  );
};

export default Header;
