import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import DashboardCard from "@stories/molecules/DashboardCard/DashboardCard";
import { MultiProgressBar } from "@stories/molecules/MultiProgressBar/MultiProgressBar";
import * as RouteHelper from "@utils/routes";
import { useNavigate } from "react-router-dom";
import { PermitCountLineItem } from "permit-one-common/src/interfaces/permit";

interface PermitSummaryProps {
  permitCounts?: PermitCountLineItem;
  project?: ProjectLineItem;
  loading: boolean;
}
export const PermitSummary = ({
  permitCounts,
  project,
  loading,
}: PermitSummaryProps) => {
  const navigate = useNavigate();

  const activePermits = permitCounts?.active || 0;
  const inReviewPermits = permitCounts?.inReview || 0;
  const archivedPermits = permitCounts?.archived || 0;
  const approvedPermits = permitCounts?.approved || 0;
  const allPermits =
    activePermits + inReviewPermits + archivedPermits + approvedPermits;
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "calc(100% - 538px)" }}>
          <Stack direction="row" spacing={1}>
            {loading ? (
              <Skeleton animation="wave" variant="text">
                <Typography
                  sx={{
                    fontSize: "19px",
                    color: "rgb(31, 35, 40) !important",
                    margin: "0 0 10px",
                    fontWeight: 700,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  Permit Status{" "}
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                sx={{
                  fontSize: "19px",
                  color: "rgb(31, 35, 40) !important",
                  margin: "0 0 10px",
                  fontWeight: 700,
                }}
                color="text.secondary"
                gutterBottom
              >
                Permit Status{" "}
              </Typography>
            )}

            {loading ? (
              <Skeleton animation="wave" variant="text">
                <Typography
                  sx={{
                    fontSize: "19px",
                    color: "rgb(31, 35, 40) !important",
                    margin: "0 0 10px",
                    fontWeight: 700,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  <span className="task-number">(199)</span>
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                sx={{
                  fontSize: "19px",
                  color: "rgb(31, 35, 40) !important",
                  margin: "0 0 10px",
                  fontWeight: 700,
                }}
                color="text.secondary"
                gutterBottom
              >
                <span className="task-number">({allPermits})</span>
              </Typography>
            )}
          </Stack>

          {loading ? (
            <Skeleton animation="wave" variant="text">
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgb(101, 109, 118) !important",
                  margin: "-5px 0 30px",
                  fontWeight: 500,
                }}
                color="text.secondary"
                gutterBottom
              >
                Summary of all Project Permits.
              </Typography>
            </Skeleton>
          ) : (
            <Typography
              sx={{
                fontSize: "14px",
                color: "rgb(101, 109, 118) !important",
                margin: "-5px 0 30px",
                fontWeight: 500,
              }}
              color="text.secondary"
              gutterBottom
            >
              Summary of all Project Permits.
            </Typography>
          )}
        </div>
        <MultiProgressBar
          loading={loading}
          assigned={activePermits}
          assignedText={"Active"}
          overdue={archivedPermits}
          overdueText={"Archived"}
          inReview={inReviewPermits}
          inReviewText={"In Review"}
          approved={approvedPermits}
          approvedText={"Approved"}
        />
      </div>
      <Box
        className="dashb-cards"
        flexGrow="1"
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={6}
            md={2.4}
            sx={{ paddingLeft: "20px" }}
            className="assigned"
          >
            <DashboardCard
              loading={loading}
              title={"Active"}
              subTitle={activePermits.toLocaleString()}
              desc={"Current permits requiring action and approval"}
              handleClick={() => {
                if (project) {
                  navigate(`${RouteHelper.tasks(project.id)}?status=1`);
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            md={2.4}
            sx={{ paddingLeft: "20px" }}
            className="approved"
          >
            <DashboardCard
              loading={loading}
              title={"Approved"}
              subTitle={approvedPermits.toLocaleString()}
              desc={"Permits approved with no further action required"}
              handleClick={() => {
                if (project) {
                  navigate(`${RouteHelper.permits(project.id)}?status=1`);
                }
              }}
            />
          </Grid>

          <Grid item xs={6} md={2.4} className="inreview">
            <DashboardCard
              loading={loading}
              title={"In Review"}
              subTitle={inReviewPermits.toLocaleString()}
              desc={"Permits where all items are in review"}
              handleClick={() => {
                console.log("Not implemented");
              }}
            />
          </Grid>

          <Grid
            item
            xs={6}
            md={2.4}
            sx={{ paddingLeft: "20px" }}
            className="overdue"
          >
            <DashboardCard
              loading={loading}
              title={"Archived"}
              subTitle={archivedPermits.toLocaleString()}
              desc={"Permits closed out or no longer requiring action"}
              handleClick={() => {
                console.log("Not implemented");
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
