import * as React from "react";
import { listApiData } from "@hooks/utils/api";
import { PermitEvent, PermitEventLineItem } from "permit-one-common/src/interfaces/permitEvent";
import { decodePermitEvent } from "permit-one-common/src/decoders/permitEvent";

export const usePermitEvent = (permitId?: string, conditiondId?: string) => {
  const [permitEvents, setPermitEvents] = React.useState<PermitEventLineItem[]>(
    []
  );
  const [isPermitEventLoading, setIsPermitEventLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listPermitEvents = async (
    permitId: string,
    conditiondId?: string
  ): Promise<void> => {
    try {
      setIsPermitEventLoading(true);
      const permitResult = await listApiData(
        "listPermitEvents",
        "permitEvent",
        permitId,
        { conditiondId }
      );
      const permitLineItems = permitResult.data.map((c) =>
        decodePermitEvent(c as PermitEvent)
      );

      setPermitEvents(permitLineItems);
    } catch (e: any) {
      setError("Could not list permits");
    } finally {
      setIsPermitEventLoading(false);
    }
  };

  React.useEffect(() => {
    if (permitId) {
      listPermitEvents(permitId, conditiondId);
    } else {
      setIsPermitEventLoading(false);
    }
  }, [permitId, conditiondId]);

  return {
    permitEvents,
    listPermitEvents,
    isPermitEventLoading,
    error,
  };
};
