import { useEffect, useState } from "react";
import * as RouteHelper from "@utils/routes";
import { matchPath, useLocation } from "react-router-dom";

// material-ui
import { styled, useTheme, Theme } from "@mui/material/styles";
import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";

import LAYOUT_CONST, { drawerWidth } from "constant";
import useConfig from "@hooks/utils/useConfig";

// assets
import { ProfileProvider } from "@hooks/context/useProfileContext";
import { ProjectProvider } from "@hooks/context/useProjectContext";
import "./dashboard.css";
function useRouteMatch(patterns: readonly string[]) {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

interface MainStyleProps {
  theme: Theme;
  open: boolean;
  layout: string;
}

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, layout }: MainStyleProps) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...(!open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter + 200,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft:
          layout === LAYOUT_CONST.VERTICAL_LAYOUT
            ? -(drawerWidth - 72)
            : "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginTop: 88,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginRight: "10px",
        marginTop: 88,
      },
    }),
    ...(open && {
      // 'margin 538ms cubic-bezier(0.4, 0, 1, 1) 0ms',
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter + 200,
      }),
      marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
      marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.up("md")]: {
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 88,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginTop: 88,
      },
    }),
  })
);

interface MainDashboardLayoutProps {
  children: React.ReactNode;
}
const MainDashboardLayout = ({ children }: MainDashboardLayoutProps) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const routeMatch = useRouteMatch([
    RouteHelper.listProjects,
    RouteHelper.overview(":projectId"),
    RouteHelper.tasks(":projectId"),
    RouteHelper.permits(":projectId"),
    RouteHelper.conditions(":projectId", ":permitId"),
    RouteHelper.condition(":projectId", ":permitId", ":conditionId"),
    RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "properties"
    ),
    RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "documents"
    ),
    RouteHelper.condition(":projectId", ":permitId", ":conditionId", "mail"),
    RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "collaborators"
    ),
    RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "event-log"
    ),
    RouteHelper.inbox(":projectId"),
    RouteHelper.mail(":projectId"),
    RouteHelper.documents(":projectId"),
    RouteHelper.directory(":projectId"),
    RouteHelper.projectGlobalDirectory(":projectId"),
  ]);

  const [isDrawOpen, setIsDrawOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState<string[]>([]);

  useEffect(() => {
    const currentPathId =
      routeMatch?.pattern?.path.split("/")[1] || "NO_ID_SELECTED";
    if (currentPathId.includes("condition")) {
      setSelectedItem(["permits"]);
    } else {
      setSelectedItem([currentPathId]);
    }
  }, [location]);

  const { container, layout } = useConfig();

  const header = (
    <Toolbar sx={{ padding: "0" }}>
      <Header setIsDrawOpen={setIsDrawOpen} isDrawOpen={isDrawOpen} />
    </Toolbar>
  );

  return (
    <ProfileProvider>
      <ProjectProvider>
        <Box sx={{ display: "flex" }} className="dashboardPage">
          <CssBaseline />
          <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{ bgcolor: theme.palette.background.default }}
          >
            {header}
          </AppBar>

          {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && (
            <Sidebar
              isDrawOpen={isDrawOpen}
              selectedItem={selectedItem}
              setIsDrawOpen={setIsDrawOpen}
              setSelectedItem={setSelectedItem}
            />
          )}

          <Main theme={theme} open={isDrawOpen} layout={layout}>
            <Container
              maxWidth={container ? "lg" : false}
              {...(!container && { sx: { px: { xs: 0 } } })}
            >
              {children}
            </Container>
          </Main>
        </Box>
      </ProjectProvider>
    </ProfileProvider>
  );
};

export default MainDashboardLayout;
