import { ProfileForm } from "@stories/organisms/ProfileForm/ProfileForm";
import { useAuthContext } from "@hooks/context/useAuthContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { Grid, Typography } from "@mui/material";
import { SettingsTabs } from "@stories/organisms/SettingsTabs/SettingsTabs";
import { useState } from "react";
import { BillingForm } from "./Billing/BillingForm";
import { NotificationsForm } from "./NotificationsForm";
import { useSearchParams } from "react-router-dom";

export const UserAccount = () => {
  const { user } = useAuthContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const success = searchParams.get("success");
  const cancelled = searchParams.get("cancelled");
  const redirect = searchParams.get("redirect");
  setSearchParams();

  if (!user) {
    throw Error("No user in state.");
  }

  const goToBilling = !!success || !!cancelled || !!redirect;
  const [selectedSettingsTab, setSelectedSettingsTab] = useState<number>(
    goToBilling ? 1 : 0
  );

  const {
    isAuthProfileLoading,
    userProfile,
    userOrganisation,
    updateUserProfile,
  } = useProfileContext();

  const handleSubmit = async (profile: ProfileLineItem) => {
    if (userProfile) {
      await updateUserProfile(profile);
    }
  };

  const handleConditionStatusChange = (newValue: number) => {
    setSelectedSettingsTab(newValue);
  };

  const loading = isAuthProfileLoading;

  const selectedSettingStep = (() => {
    switch (selectedSettingsTab) {
      default:
      case 0:
        return (
          <ProfileForm
            loading={loading}
            profile={userProfile}
            handleSubmit={handleSubmit}
          />
        );
      case 1:
        return (
          <BillingForm
            loading={loading}
            profile={userProfile}
            organisation={userOrganisation}
            success={!!success}
            cancelled={!!cancelled}
          />
        );
      case 2:
        return (
          <NotificationsForm
            loading={loading}
            profile={userProfile}
            handleSubmit={handleSubmit}
          />
        );
    }
  })();

  return (
    <>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{ fontSize: "28px !important", fontWeight: "700" }}
        >
          User Account
        </Typography>
      </Grid>
      <Grid
        container
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid item md={12}>
          <SettingsTabs
            loading={loading}
            value={selectedSettingsTab}
            handleChange={handleConditionStatusChange}
          />
        </Grid>
        <Grid item>{selectedSettingStep}</Grid>
      </Grid>
    </>
  );
};
