import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { useTable } from "@hooks/utils/useTable";
import { Grid } from "@mui/material";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ListConditionsTabs } from "@stories/molecules/ListConditionsTabs/ListConditionsTabs";
import { ConditionStatus } from "permit-one-common/src/entities/condition";
import { useEffect, useState } from "react";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import PublicConditionTableToolbar from "@stories/molecules/ListTable/PublicConditionTableToolbar";
import PublicEditableTable from "@stories/atoms/PublicEditableTable/PublicEditableTable";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";
import { FileGenerationStatus } from "permit-one-common/src/entities/fileGeneration";

interface PublicConditionTableProps {
  loading: boolean;
  userProfile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  documents: ConditionDocumentLineItem[];
  createDocuments: (files: ConditionDocumentLineItem[]) => Promise<void>;
  createComments: (comments: ConditionCommentLineItem[]) => Promise<void>;
  sendForReview: (condition: ConditionLineItem) => void;
  uploadDocument: (file: File) => void;
  showToolbar?: boolean;
  downloadStepPDF: FileGenerationStatus;
  PDFDownloadProgress: number;
  getPdfReport: (
    projectId: string,
    permitId: string,
    reportName: string
  ) => Promise<void>;
  getExcelReport: (
    projectId: string,
    permitId: string,
    reportName: string
  ) => Promise<void>;

  downloadStepExcel: FileGenerationStatus;
  excelDownloadProgress: number;
  getFullReportInfo: (
    projectId: string,
    permitId: string,
    zipFileTitle: string
  ) => Promise<void>;
  downloadStepAllFiles: FileGenerationStatus;
  allFilesDownloadProgress: number;
}
export const PublicConditionTable = ({
  loading,
  userProfile,
  userOrganisation,
  project,
  permit,
  conditions,
  documents,
  createDocuments,
  createComments,
  sendForReview,
  uploadDocument,
  downloadStepPDF,
  PDFDownloadProgress,
  getPdfReport,
  getExcelReport,
  downloadStepExcel,
  excelDownloadProgress,
  getFullReportInfo,
  downloadStepAllFiles,
  allFilesDownloadProgress,
  showToolbar = true,
}: PublicConditionTableProps) => {
  const params = new URLSearchParams(location.search);
  const initialConditionStatus = parseInt(params.get("status") || "0");

  const {
    selected,
    filterName,
    order,
    orderBy,
    handleRequestSort,
    handleSelectAllClick,
    handleFilterByName,
  } = useTable();

  const [selectedConditionStatus, setSelectedConditionStatus] =
    useState<number>(initialConditionStatus);

  useEffect(() => {
    setSelectedConditionStatus(initialConditionStatus);
  }, [initialConditionStatus]);

  const handleConditionStatusChange = (newValue: number) => {
    setSelectedConditionStatus(newValue);
  };

  let filteredConditions =
    filterName.length > 0
      ? conditions.filter(
          (t) =>
            t.conditionName.toLowerCase() === filterName.toLocaleLowerCase()
        )
      : conditions;

  filteredConditions = filteredConditions.filter((c) => {
    switch (selectedConditionStatus) {
      default:
      case 0:
        return true;
      case 1:
        return (
          c.status === ConditionStatus.Assigned ||
          c.status === ConditionStatus.InReview ||
          c.status === ConditionStatus.Rejected
        );
      case 2:
        return c.status === ConditionStatus.Overdue;
      case 3:
        return c.status === ConditionStatus.InReview;
      case 4:
        return c.status === ConditionStatus.Approved;
    }
  });

  let sortedConditions = filteredConditions.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return (a?.conditionDetails?.toLocaleLowerCase() || "") <
          (b?.conditionDetails?.toLocaleLowerCase() || "")
          ? -1
          : 1;
      case 1:
        return (a?.conditionComments?.toLocaleLowerCase() || "") <
          (b?.conditionComments?.toLocaleLowerCase() || "")
          ? -1
          : 1;
      case 2:
        // if (a.assignees && b.assignees) {
        //   return a.assignees < b.assignees ? -1 : 1;
        // }
        // if (!a.assignees && b.assignees) {
        //   return -1;
        // }
        // if (a.assignees && !b.assignees) {
        //   return 1;
        // }
        return 0;
      case 3:
        return a.discipline < b.discipline ? -1 : 1;
      case 4:
        return a.dueDate.getTime() < b.dueDate.getTime() ? -1 : 1;
      case 5:
        return a.status < b.status ? -1 : 1;
      default:
        return 0;
    }
  });
  sortedConditions =
    order === "asc" ? sortedConditions : sortedConditions.reverse();

  return (
    <>
      {showToolbar ? (
        <PublicConditionTableToolbar
          loading={loading}
          profile={userProfile}
          userOrganisation={userOrganisation}
          project={project}
          permit={permit}
          filterName={filterName}
          onFilter={handleFilterByName}
          downloadStepPDF={downloadStepPDF}
          PDFDownloadProgress={PDFDownloadProgress}
          getPdfReport={getPdfReport}
          getExcelReport={getExcelReport}
          downloadStepExcel={downloadStepExcel}
          excelDownloadProgress={excelDownloadProgress}
          getFullReportInfo={getFullReportInfo}
          downloadStepAllFiles={downloadStepAllFiles}
          allFilesDownloadProgress={allFilesDownloadProgress}
        />
      ) : null}
      <Grid item md={12} sx={{ padding: "30px 0px 20px" }}>
        <ListConditionsTabs
          loading={loading}
          value={selectedConditionStatus}
          handleChange={handleConditionStatusChange}
        />
      </Grid>
      <PublicEditableTable
        loading={loading}
        project={project}
        permit={permit}
        conditions={sortedConditions}
        documents={documents}
        createDocuments={createDocuments}
        createComments={createComments}
        sendForReview={sendForReview}
        uploadDocument={uploadDocument}
        userProfile={userProfile}
      />
    </>
  );
};
