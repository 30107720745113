import { DocumentTable } from "@stories/organisms/Tables/DocumentTable/DocumentTable";
import { useDocument } from "@hooks/crud/useDocument";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useState } from "react";
import { Box, Typography, Grid } from "@mui/material";
import { ConditionDocumentLineItem } from "permit-one-common/src/interfaces/conditionDocument";
import BulkDocumentModal from "@stories/organisms/BulkDocumentModal/BulkDocumentModal";
import { useProfileContext } from "@hooks/context/useProfileContext";
import DocumentModal from "@stories/organisms/DocumentModal/DocumentModal";

export const Documents = (): JSX.Element => {
  const { userProfile, isAuthProfileLoading } = useProfileContext();
  const { selectedProject, isProjectLoading } = useProjectContext();
  const [openBulk, setOpenBulk] = useState(false);
  const [openSingle, setOpenSingle] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState<
    ConditionDocumentLineItem | undefined
  >(undefined);

  const {
    isDocumentLoading,
    documents,
    createDocuments,
    updateDocuments,
    deleteDocument,
  } = useDocument(selectedProject?.id);

  const handleCloseSingle = () => {
    setOpenSingle(false);
    setSelectedDocument(undefined);
  };

  const handleCloseBulk = () => {
    setOpenBulk(false);
  };

  const handleOpenSingle = (conditionDocument: ConditionDocumentLineItem) => {
    setSelectedDocument(conditionDocument);
    setOpenSingle(true);
  };

  const handleOpenBulk = () => {
    setOpenBulk(true);
  };

  const handleDeleteDocument = (document: ConditionDocumentLineItem) => {
    deleteDocument(document);
  };

  const loading = isAuthProfileLoading || isProjectLoading || isDocumentLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0 0 20px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontSize: "28px !important",
            fontWeight: "700",
            color: "#333333",
          }}
        >
          Documents
        </Typography>
      </Grid>

      <Box sx={{ padding: "0 40px 40px" }}>
        <DocumentTable
          documents={documents}
          loading={loading}
          project={selectedProject}
          handleOpenBulk={handleOpenBulk}
          handleEditDocument={handleOpenSingle}
          handleDeleteDocument={handleDeleteDocument}
        />
        {userProfile && selectedProject ? (
          <BulkDocumentModal
            open={openBulk}
            userProfile={userProfile}
            project={selectedProject}
            handleClose={handleCloseBulk}
            createDocuments={createDocuments}
            documentCount={documents.length}
          />
        ) : null}
        {userProfile && selectedProject && selectedDocument ? (
          <DocumentModal
            open={openSingle}
            project={selectedProject}
            userProfile={userProfile}
            handleClose={handleCloseSingle}
            updateDocuments={updateDocuments}
            documentCount={documents.length}
            existingDocument={selectedDocument}
          />
        ) : null}
      </Box>
    </Box>
  );
};
