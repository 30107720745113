import * as React from "react";
import { ConditionCount, ConditionCountLineItem } from "permit-one-common/src/interfaces/condition";
import { decodeConditionCount } from "permit-one-common/src/decoders/condition";
import { getApiData } from "@hooks/utils/api";

export const useConditionCount = (projectId?: string, permitId?: string) => {
  const [conditionCounts, setConditionCounts] =
    React.useState<ConditionCountLineItem>();
  const [isConditionCountLoading, setIsConditionCountLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const getConditionCounts = async (
    id: string,
    permitId?: string
  ): Promise<void> => {
    try {
      setIsConditionCountLoading(true);
      const conditionCountsResult = await getApiData(
        "getConditionCounts",
        "condition",
        id,
        {
          permitId,
        }
      );
      if (conditionCountsResult) {
        const conditionCountsLineItem = await decodeConditionCount(
          conditionCountsResult.data as ConditionCount
        );
        setConditionCounts(conditionCountsLineItem);
      } else {
        setConditionCounts(undefined);
      }
    } catch (e: any) {
      setError("Could not fetch condition");
    } finally {
      setIsConditionCountLoading(false);
    }
  };

  React.useEffect(() => {
    if (projectId) {
      getConditionCounts(projectId, permitId);
    }
  }, [projectId, permitId]);

  return {
    conditionCounts,
    isConditionCountLoading,
    error,
  };
};
