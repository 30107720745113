// material-ui
import { Typography } from "@mui/material";

// project imports
import { getNavMenuItems } from "../menu-items";
import NavGroup from "./NavGroup";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";

// ==============================|| SIDEBAR MENU LIST ||============================== //

interface MenuListProps {
  loading: boolean;
  selectedProject?: ProjectLineItem;
  isOwner: boolean;
  isDrawOpen: boolean;
  selectedItem: string[];
  setSelectedItem: (item: string[]) => void;
  setIsDrawOpen: (isDrawOpen: boolean) => void;
}
const MenuList = ({
  loading,
  isDrawOpen,
  selectedItem,
  setSelectedItem,
  setIsDrawOpen,
}: MenuListProps) => {
  // const getMenu = Menu();
  const menuItem = getNavMenuItems();

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return (
          <NavGroup
            loading={loading}
            key={`nav-bar-item-${item.id}`}
            item={item}
            isDrawOpen={isDrawOpen}
            setIsDrawOpen={setIsDrawOpen}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
