import { Skeleton } from "@mui/material";
import { Steps } from "@stories/atoms/Steps/Steps";

const steps = ["Documents", "Comments", "Review"];

interface ConditionDocumentStepsProps {
  loading: boolean;
  activeStep: number;
}

export const ConditionDocumentSteps = ({
  loading,
  activeStep,
}: ConditionDocumentStepsProps) => {
  if (loading) {
    return (
      <Skeleton animation="wave" sx={{ margin: "auto" }}>
        <Steps activeStep={activeStep} steps={steps} />
      </Skeleton>
    );
  }
  return <Steps activeStep={activeStep} steps={steps} />;
};
