import { useTheme } from "@mui/material/styles";
import {
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { format } from "date-fns";
import { gridSpacing } from "constant";
import Avatar from "@stories/ui-component/extended/Avatar";

const MailDetailsSkeleton = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={matchDownSM ? 1 : 0}
        >
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              spacing={matchDownSM ? 1 : 2}
            >
              <Skeleton animation="wave" variant="circular">
                <Avatar alt={""} src={""} size={matchDownSM ? "xs" : "sm"} />
              </Skeleton>
              <Grid container alignItems="center">
                <Grid item xs={12} sx={{ padding: "0 !important" }}>
                  <Stack
                    direction={matchDownSM ? "column" : "row"}
                    alignItems={matchDownSM ? "flex-start" : "center"}
                    spacing={matchDownSM ? 0 : 1}
                  >
                    <Skeleton animation="wave">
                      <Typography variant={matchDownSM ? "h5" : "h5"}>
                        {"First Name"}
                      </Typography>
                    </Skeleton>
                    <Skeleton animation="wave">
                      <Typography variant={matchDownSM ? "h5" : "h4"}>
                        {"From a@b.com"}
                      </Typography>
                    </Skeleton>
                  </Stack>
                </Grid>
                <Grid item sx={{ display: { xs: "none", sm: "block" } }}>
                  <Skeleton animation="wave">
                    <Typography variant={matchDownSM ? "h5" : "h4"}>
                      {"From a@b.com"}
                    </Typography>
                  </Skeleton>
                </Grid>
              </Grid>
            </Stack>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2">
              <Skeleton animation="wave">
                <Typography variant={matchDownSM ? "h5" : "h4"}>
                  {format(new Date(new Date()), "d MMM")}
                </Typography>
              </Skeleton>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MailDetailsSkeleton;
