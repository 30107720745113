import * as React from "react";
import { MailThread, MailThreadLineItem } from "permit-one-common/src/interfaces/mail";
import { decodeMailThread, encodeMailThread } from "permit-one-common/src/decoders/mail";
import { listApiData, postApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";

export const useMail = (
  projectId?: string,
  permitId?: string,
  conditionId?: string
) => {
  const [selectedMail, setSelectedMail] = React.useState<MailThreadLineItem>();

  const [mailThreads, setMailThreads] = React.useState<MailThreadLineItem[]>(
    []
  );
  const [isMailLoading, setIsMailLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listMailThreadsForCondition = async (id: string): Promise<void> => {
    try {
      setIsMailLoading(true);
      const mailThreadResult = await listApiData(
        "listMailThreadsForCondition",
        "mail",
        id
      );
      const mailThreadLineItems = await Promise.all(
        mailThreadResult.data.map((m) => decodeMailThread(m as MailThread, getImageUrl))
      );
      setMailThreads(mailThreadLineItems);
    } catch (e: any) {
      setError("Could not fetch mail");
    } finally {
      setIsMailLoading(false);
    }
  };

  const listMailForProject = async (projectId: string): Promise<void> => {
    try {
      setIsMailLoading(true);
      const mailThreadResult = await listApiData(
        "listMailThreadsForProject",
        "mail",
        projectId
      );
      const mailThreadLineItems = await Promise.all(
        mailThreadResult.data.map((m) => decodeMailThread(m as MailThread, getImageUrl))
      );
      setMailThreads(mailThreadLineItems);
    } catch (e: any) {
      setError("Could not list mails");
    } finally {
      setIsMailLoading(false);
    }
  };

  const listMailForPermit = async (permitId: string): Promise<void> => {
    try {
      setIsMailLoading(true);
      const mailThreadResult = await listApiData(
        "listMailThreadsForPermit",
        "mail",
        permitId
      );
      const mailThreadLineItems = await Promise.all(
        mailThreadResult.data.map((m) => decodeMailThread(m as MailThread, getImageUrl))
      );
      setMailThreads(mailThreadLineItems);
    } catch (e: any) {
      setError("Could not list mails");
    } finally {
      setIsMailLoading(false);
    }
  };

  const createMailThread = async (
    newMail: MailThreadLineItem
  ): Promise<void> => {
    try {
      setIsMailLoading(true);
      const repsonse = await postApiData(
        "createMailThread",
        "mail",
        encodeMailThread(newMail)
      );
      const decodedMail = await decodeMailThread(repsonse.data as MailThread, getImageUrl);
      setMailThreads([...mailThreads, decodedMail]);
    } catch (e: any) {
      setError("Could not list mails");
    } finally {
      setIsMailLoading(false);
    }
  };

  const updateMailThread = async (
    newMail: MailThreadLineItem
  ): Promise<MailThreadLineItem> => {
    try {
      setIsMailLoading(true);
      const repsonse = await postApiData(
        "updateMailThread",
        "mail",
        encodeMailThread(newMail)
      );
      const decodedMail = await decodeMailThread(repsonse.data as MailThread, getImageUrl);
      setSelectedMail(decodedMail);
      setMailThreads(
        mailThreads.map((m) => {
          if (m.id === decodedMail.id) {
            return decodedMail;
          }
          return m;
        })
      );
      setIsMailLoading(false);
      return decodedMail;
    } catch (e: any) {
      setError("Could not list mails");
    }
    return newMail;
  };

  React.useEffect(() => {
    if (conditionId) {
      listMailThreadsForCondition(conditionId);
    } else if (projectId) {
      listMailForProject(projectId);
    } else if (permitId) {
      listMailForPermit(permitId);
    }
  }, [projectId, permitId, conditionId]);

  return {
    mailThreads,
    selectedMail,
    listMailThreadsForCondition,
    createMailThread,
    updateMailThread,
    isMailLoading,
    error,
  };
};
