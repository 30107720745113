const LAYOUT_CONST = {
  VERTICAL_LAYOUT: "vertical",
  HORIZONTAL_LAYOUT: "horizontal",
  DEFAULT_DRAWER: "default",
  MINI_DRAWER: "mini-drawer",
};

// theme constant
export const gridSpacing = 2;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export default LAYOUT_CONST;
