import { PermitSummary } from "@stories/organisms/Summary/PermitSummary";
import { PermitTable } from "@stories/organisms/Tables/PermitTable/PermitTable";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useState } from "react";
import PermitModal from "@stories/organisms/PermitModal/PermitModal";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { usePermit } from "@hooks/crud/usePermit";
import { useNavigate } from "react-router-dom";
import * as RouteHelper from "@utils/routes";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { usePermitCount } from "@hooks/crud/usePermitCount";
import { PermitStatus } from "permit-one-common/src/entities/permit";

export const Permits = (): JSX.Element => {
  const navigate = useNavigate();
  const { selectedProject, isProjectLoading, getProject } = useProjectContext();
  const { userProfile, userOrganisation } = useProfileContext();

  const { permits, isPermitLoading, createPermit, updatePermit } = usePermit(
    selectedProject?.id
  );

  const { isPermitCountLoading, permitCounts } = usePermitCount(
    selectedProject?.id
  );
  const [selectedPermit, setSelectedPermit] = useState<
    PermitLineItem | undefined
  >(undefined);

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setSelectedPermit(undefined);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleCreatePermit = (permit: PermitLineItem) => {
    if (selectedProject) {
      createPermit(permit).then(() => {
        getProject(selectedProject.id);
      });
    }
  };

  const handleUpdatePermit = (permit: PermitLineItem) => {
    if (selectedProject) {
      updatePermit(permit).then(() => {
        getProject(selectedProject.id);
      });
    }
  };

  const handleEditPermit = (permit: PermitLineItem) => {
    setSelectedPermit(permit);
    handleOpen();
  };

  const handleViewPermit = (permit: PermitLineItem) => {
    if (selectedProject) {
      navigate(RouteHelper.conditions(selectedProject.id, permit.id));
    }
  };

  const handleDeletePermit = async (permit: PermitLineItem) => {
    if (selectedProject) {
      await updatePermit({
        ...permit,
        status: PermitStatus.Archived,
      }).then(() => {
        getProject(selectedProject.id);
      });
    }
  };

  const handleDestroyPermit = async (permit: PermitLineItem) => {
    if (selectedProject) {
      await updatePermit({
        ...permit,
        archived: true,
      }).then(() => {
        getProject(selectedProject.id);
      });
    }
  };

  const handleRestorePermit = async (permit: PermitLineItem) => {
    if (selectedProject) {
      await updatePermit({
        ...permit,
        status: PermitStatus.Active,
      }).then(() => {
        getProject(selectedProject.id);
      });
    }
  };

  const [inputValue, setInputValue] = useState("");

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const loading = isPermitLoading || isProjectLoading || isPermitCountLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0 !important",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{ fontSize: "28px !important", fontWeight: "700" }}
        >
          Permits
        </Typography>
      </Grid>

      <Box sx={{ padding: "15px 30px 30px" }}>
        <PermitSummary
          permitCounts={permitCounts}
          project={selectedProject}
          loading={loading}
        />
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            background: "transparent",
          }}
        >
          <Grid
            container
            sx={{
              padding: "20px 0 0",
              margin: "0",
              width: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid
              item
              md={4}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "space-between",
                padding: "0 !important",
                width: "100%",
              }}
            >
              <div className="search">
                <StyledTextField
                  className="search"
                  loading={loading}
                  type="text"
                  placeholder="Search Permits..."
                  name="filterProject"
                  value={inputValue}
                  onChange={handleSearchChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item md={6} />
            <Grid
              item
              md={2}
              sx={{
                padding: "0 !important",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "right",
              }}
            >
              {userOrganisation &&
              userOrganisation.id === selectedProject?.organisationId ? (
                <StyledButton
                  loading={loading}
                  className="css-kok4z1"
                  variant="contained"
                  startIcon={<AddCircleIcon />}
                  onClick={handleOpen}
                >
                  Add Permit
                </StyledButton>
              ) : null}
            </Grid>
          </Grid>
        </Box>
        <PermitTable
          loading={loading}
          profile={userProfile}
          userOrganisation={userOrganisation}
          project={selectedProject}
          permits={permits}
          handleEditPermit={handleEditPermit}
          handleViewPermit={handleViewPermit}
          handleDeletePermit={handleDeletePermit}
          handleDestroyPermit={handleDestroyPermit}
          handleRestorePermit={handleRestorePermit}
          searchString={inputValue}
        />
        {selectedProject && userOrganisation && userProfile && open ? (
          <PermitModal
            open={open}
            handleClose={handleClose}
            createPermit={handleCreatePermit}
            updatePermit={handleUpdatePermit}
            permitCount={permits.length}
            organisation={userOrganisation}
            profile={userProfile}
            project={selectedProject}
            existingPermit={selectedPermit}
          />
        ) : null}
      </Box>
    </Box>
  );
};
