import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { Avatar, TableCell, TableRow, Typography } from "@mui/material";

interface DirectoryTableRowProps {
  isSelected: boolean;
  contact: DirectoryContactLineItem;
}

export const DirectoryTableRow = ({
  isSelected,
  contact,
}: DirectoryTableRowProps) => {
  const displayName = !contact.organisationName
    ? "User pending"
    : `${contact.name}`;

  return (
    <TableRow hover tabIndex={-1} role="checkbox" selected={isSelected}>
      <TableCell align="center" sx={{ width: "5%" }}>
        {contact.photoUrl ? (
          <Avatar
            alt={contact.name}
            src={contact.photoUrl}
            sx={{ margin: "0 auto 0 10px", background: "#fff" }}
          />
        ) : null}
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        <Typography sx={{ margin: "10px" }}>{displayName}</Typography>
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        {contact.organisationName}
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        {contact.jobTitle}
      </TableCell>
      <TableCell align="center" sx={{ width: "20%" }}>
        {contact.email}
      </TableCell>
      <TableCell align="center" sx={{ width: "15%" }}>
        {contact.phoneNumber}
      </TableCell>
    </TableRow>
  );
};
