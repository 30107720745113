import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { UserFinder } from "@stories/molecules/UserFinder/UserFinder";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { useState } from "react";
import { useProfile } from "@hooks/crud/useProfile";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";

interface InviteFormProps {
  handleSubmit: (profiles: ProfileLineItem[]) => void;
  handleClose: () => void;
}

export const InviteForm = ({ handleSubmit, handleClose }: InviteFormProps) => {
  const { searchProfiles } = useProfile();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onInviteChange = (profile?: ProfileLineItem) => {
    console.log("Not implemented");
  };

  const onInviteBlur = () => {
    console.log("Not implemented");
  };

  const onInviteSubmit = () => {
    handleSubmit([]);
  };

  return (
    <Container component="main" maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} sm={12}>
          <UserFinder
            id="manager"
            name="manager"
            label="Search users"
            disabled={isSubmitting}
            onChange={onInviteChange}
            onBlur={onInviteBlur}
            handleSearch={searchProfiles}
            existingProfile={undefined}
            error={undefined}
            helperText={undefined}
          />
        </Grid>
       
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            display: "flex !important",
            justifyContent:'flex-end'
          }}
        >
          <StyledButton
            loading={false}
            color="primary"
            variant="contained"
            onClick={onInviteSubmit}
            disabled={isSubmitting}
          >
            Submit
          </StyledButton>
        </Grid>
      </Grid>
    </Container>
  );
};
