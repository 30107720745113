import { useMail } from "@hooks/crud/useMail";
import { Box, Grid } from "@mui/material";
import MailTable from "@stories/organisms/Mail/MailTable";
import { MailThreadLineItem } from "permit-one-common/src/interfaces/mail";
import { useState } from "react";
import { MailView } from "@stories/organisms/Mail/MailView";
import MailDrawer, { MailBoxFilter } from "@stories/organisms/Mail/MailDrawer";
import { MailBoxCount } from "@stories/organisms/Mail/MailProps";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";

interface MailCenterProps {
  isLoading: boolean;
  profile?: ProfileLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  showDialog: boolean;
}
export const MailCenter = ({
  isLoading,
  profile,
  project,
  permit,
  condition,
  showDialog,
}: MailCenterProps): JSX.Element => {
  const { isMailLoading, mailThreads, createMailThread, updateMailThread } =
    useMail(project?.id, permit?.id, condition?.id);

  const [selectedMail, setSelectedMail] = useState<MailThreadLineItem>();
  const [mailBoxFilter, setMailBoxFilter] = useState(MailBoxFilter.All);

  const handleMailBoxFilter = (value: MailBoxFilter) => {
    setMailBoxFilter(value);
    setSelectedMail(undefined);
  };

  const handleUpdateMailThread = async (
    updatedMailThread: MailThreadLineItem
  ) => {
    const mailThreadRes = await updateMailThread(updatedMailThread);
    setSelectedMail(mailThreadRes);
  };

  const unreadMails = mailThreads.filter(
    (m) => m.topMail.sender.id !== profile?.id
  );
  const unreadCounts = {
    all: unreadMails.length,
    inbox: unreadMails.length,
    sent: 0,
  } as MailBoxCount;
  const loading = isLoading || isMailLoading;

  const filteredMailThreads = (() => {
    switch (mailBoxFilter) {
      default:
      case MailBoxFilter.All:
        return mailThreads;
      case MailBoxFilter.Inbox:
        return mailThreads.filter((m) => m.topMail.sender.id !== profile?.id);
      case MailBoxFilter.Sent:
        return mailThreads.filter((m) => m.topMail.sender.id === profile?.id);
    }
  })();

  const showRedirect = !showDialog;
  return (
    <Box sx={{ padding: 0 }}>
      <Grid item md={12} sx={{ padding: "0" }}>
        <Grid item sx={{ padding: "0" }}>
          <MailDrawer
            isLoading={loading}
            filter={mailBoxFilter}
            handleFilter={handleMailBoxFilter}
            handleCreateMail={createMailThread}
            unreadCounts={unreadCounts}
            project={project}
            permit={permit}
            condition={condition}
            profile={profile}
            showDialog={showDialog}
          />
        </Grid>
        <Grid item md={12} sx={{ padding: "25px 0 0" }}>
          {selectedMail ? (
            <MailView
              selectedMail={selectedMail}
              isLoading={loading}
              profile={profile}
              updateMailThread={handleUpdateMailThread}
              showRedirect={!showDialog}
              handlOnBack={() => {
                setSelectedMail(undefined);
              }}
            />
          ) : (
            <MailTable
              loading={loading}
              profile={profile}
              mailThreads={filteredMailThreads}
              setSelectedMail={setSelectedMail}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
