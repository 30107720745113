import { DirectoryTable } from "@stories/organisms/Tables/DirectoryTable/DirectoryTable";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { useProfileContext } from "@hooks/context/useProfileContext";
import { useDirectory } from "@hooks/crud/useDirectory";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { Box, Grid, Typography } from "@mui/material";
import { useProfile } from "@hooks/crud/useProfile";

export const Directory = (): JSX.Element => {
  const { selectedProject, isProjectLoading } = useProjectContext();
  const { isAuthProfileLoading } = useProfileContext();

  const { isDirectoryLoading, directory } = useDirectory(selectedProject?.id);
  const { isProfilesLoading, createProfiles } = useProfile();

  const loading =
    isProjectLoading ||
    isProfilesLoading ||
    isAuthProfileLoading ||
    isDirectoryLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0 0 40px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontSize: "28px !important",
            fontWeight: "700",
            color: "#333333",
          }}
        >
          Directory
        </Typography>
      </Grid>
      <Box sx={{ padding: "0 40px 40px" }}>
        <DirectoryTable
          loading={loading}
          directory={directory}
          sendInvite={async (profiles: ProfileLineItem[]) => {
            await createProfiles(profiles);
          }}
        />
      </Box>
    </Box>
  );
};
