import { Alert, Slide, Snackbar } from "@mui/material";
import { ReactElement } from "react";
import { v4 as uuid } from "uuid";

export interface SnackBarMessage {
  info: string;
  intent: "success" | "info" | "warning" | "error";
}

interface AppSnackbarProps {
  open: boolean;
  message: SnackBarMessage;
  handleClose: () => void;
}

interface TransitionLeftProps {
  children: ReactElement<any, any>;
}
function TransitionLeft(props: TransitionLeftProps) {
  return <Slide {...props} direction="left" />;
}

export const AppSnackbar = ({
  open,
  handleClose,
  message,
}: AppSnackbarProps) => {
  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
      key={uuid()}
    >
      <Alert severity={message.intent}>{message.info}</Alert>
    </Snackbar>
  );
};
