import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import dayjs from "dayjs";
import { useState } from "react";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { Stack, Switch, Typography } from "@mui/material";

interface PermitDAFormProps {
  permit: PermitLineItem;
  handleBack: () => void;
  handleSubmit: (permit: PermitLineItem) => void;
}

export const PermitDAForm = ({
  permit,
  handleBack,
  handleSubmit,
}: PermitDAFormProps) => {
  const [formEnabled, setFormEnabled] = useState(
    Boolean(permit.developmentApprovalDate) ||
      Boolean(permit.developmentApprovalLapseDate) ||
      Boolean(permit.councilAuthority) ||
      Boolean(permit.developmentApprovalNumber)
  );

  const validationSchema = yup.object({
    developmentApprovalNumber: yup.string(),
    developmentApprovalDate: yup.date(),
    councilAuthority: yup.string(),
    developmentApprovalLapseDate: yup.date(),
  });
  const formik = useFormik({
    initialValues: {
      developmentApprovalNumber: permit.developmentApprovalNumber,
      developmentApprovalDate: permit.developmentApprovalDate
        ? dayjs(permit.developmentApprovalDate)
        : undefined,
      councilAuthority: permit.councilAuthority,
      developmentApprovalLapseDate: permit.developmentApprovalLapseDate
        ? dayjs(permit.developmentApprovalLapseDate)
        : undefined,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await handleSubmit({
        ...permit,
        developmentApprovalNumber: values.developmentApprovalNumber,
        developmentApprovalDate: values.developmentApprovalDate
          ? values.developmentApprovalDate.toDate()
          : undefined,
        councilAuthority: values.councilAuthority,
        developmentApprovalLapseDate: values.developmentApprovalLapseDate
          ? values.developmentApprovalLapseDate.toDate()
          : undefined,
      });
    },
  });

  const label = { inputProps: { "aria-label": "Enable Form" } };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={2}>
        <Grid item md={12}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Switch
              {...label}
              checked={formEnabled}
              onChange={(e) => setFormEnabled(e.target.checked)}
            />
            <Typography>{formEnabled ? "Enabled" : "Disabled"}</Typography>
          </Stack>
        </Grid>
        <Grid item md={6}>
          <TextField
            id="developmentApprovalNumber"
            name="developmentApprovalNumber"
            label="Development Approval Number"
            fullWidth
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.developmentApprovalNumber}
            error={
              formik.touched.developmentApprovalNumber &&
              Boolean(formik.errors.developmentApprovalNumber)
            }
            helperText={
              formik.touched.developmentApprovalNumber
                ? formik.errors.developmentApprovalNumber
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="councilAuthority"
            name="councilAuthority"
            label="Council Authority"
            fullWidth
            disabled={formik.isSubmitting || !formEnabled}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.councilAuthority}
            error={
              formik.touched.councilAuthority &&
              Boolean(formik.errors.councilAuthority)
            }
            helperText={
              formik.touched.councilAuthority
                ? formik.errors.councilAuthority
                : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <DatePicker
            onChange={(value) => {
              if (value) {
                formik.setFieldValue("developmentApprovalDate", value, true);
              }
            }}
            disabled={formik.isSubmitting || !formEnabled}
            value={formik.values.developmentApprovalDate}
            slotProps={{
              textField: {
                label: "Development Approval Date",
                helperText:
                  formik.touched.developmentApprovalDate &&
                  formik.errors.developmentApprovalDate
                    ? "Invalid Date"
                    : "",
                error:
                  formik.touched.developmentApprovalDate &&
                  Boolean(formik.errors.developmentApprovalDate),
              },
            }}
            sx={{ width: "100%" }}
            className="datepicker"
          />
        </Grid>
        <Grid item md={6}>
          <DatePicker
            onChange={(value) => {
              if (value) {
                formik.setFieldValue(
                  "developmentApprovalLapseDate",
                  value,
                  true
                );
              }
            }}
            disabled={formik.isSubmitting || !formEnabled}
            value={formik.values.developmentApprovalLapseDate}
            slotProps={{
              textField: {
                label: "Development Approval Lapse Date",
                helperText:
                  formik.touched.developmentApprovalLapseDate &&
                  formik.errors.developmentApprovalLapseDate
                    ? "Invalid Date"
                    : "",
                error:
                  formik.touched.developmentApprovalLapseDate &&
                  Boolean(formik.errors.developmentApprovalLapseDate),
              },
            }}
            sx={{ width: "100%" }}
            className="datepicker"
          />
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        mt={2}
        sx={{
          display: "flex !important",
          padding: "10px 0 0",
          justifyContent: "space-between",
        }}
      >
        <StyledButton
          loading={false}
          color="primary"
          variant="contained"
          disabled={formik.isSubmitting}
          onClick={handleBack}
        >
          Back
        </StyledButton>
        <StyledButton
          loading={false}
          color="primary"
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Next
        </StyledButton>
      </Grid>
    </form>
  );
};
