import * as React from "react";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { useProject } from "@hooks/crud/useProject";
import { useParams } from "react-router-dom";
import { useProfileContext } from "./useProfileContext";

type PC = {
  isProjectLoading: boolean;
  error: string | null;
  selectedProject?: ProjectLineItem;
  allProjects: ProjectLineItem[];
  setSelectedProject: (project: ProjectLineItem) => void;
  getProject: (id: string) => void;
  createProject: (project: ProjectLineItem) => Promise<void>;
  updateProject: (project: ProjectLineItem) => Promise<void>;
  deleteProject: (project: ProjectLineItem) => Promise<void>;
};

export const ProjectContext = React.createContext<PC>({
  isProjectLoading: true,
  error: null,
  selectedProject: undefined,
  allProjects: [],
  setSelectedProject: () => Promise.resolve(),
  getProject: () => Promise.resolve(),
  createProject: () => Promise.resolve(),
  updateProject: () => Promise.resolve(),
  deleteProject: () => Promise.resolve(),
});

type ProjectProviderProps = {
  children: React.ReactNode;
};

const ProjectProvider = (props: ProjectProviderProps) => {
  const { projectId } = useParams();
  if (!projectId) {
    throw Error("Project id is required");
  }
  const { userProfile, userOrganisation } = useProfileContext();
  if (!userProfile) {
    throw Error("User profile is required");
  }
  const {
    isProjectLoading,
    error,
    selectedProject,
    projects,
    setSelectedProject,
    getProject,
    createProject,
    updateProject,
    deleteProject,
  } = useProject(userProfile);

  React.useEffect(() => {
    if (projectId && userOrganisation) {
      getProject(projectId);
    }
  }, [projectId, userOrganisation]);

  return (
    <ProjectContext.Provider
      value={{
        isProjectLoading,
        error: error,
        selectedProject: selectedProject,
        allProjects: projects,
        setSelectedProject,
        getProject,
        createProject,
        updateProject,
        deleteProject,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

const useProjectContext = () => React.useContext(ProjectContext);

export { ProjectProvider, useProjectContext };
