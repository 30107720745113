"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultConditionDocumentRevision = void 0;
var uuid_1 = require("uuid");
var createDefaultConditionDocumentRevision = function (conditionDocumentId, revisionNumber, url, createdBy, createdByName, fileType, fileSize) {
    return {
        id: (0, uuid_1.v4)(),
        conditionDocumentId: conditionDocumentId,
        revisionNumber: revisionNumber,
        createdBy: createdBy,
        createdByName: createdByName,
        fileSize: fileSize,
        fileType: fileType,
        url: url,
        created: new Date(),
    };
};
exports.createDefaultConditionDocumentRevision = createDefaultConditionDocumentRevision;
