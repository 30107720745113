// material-ui
import {
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
// assets
import { MailBoxFilter } from "./MailDrawer";
import { ReactNode } from "react";

interface MailDrawerButtonProps {
  isLoading: boolean;
  title: string;
  filter: MailBoxFilter;
  filterType: MailBoxFilter;
  icon: ReactNode;
  handleFilter: (s: MailBoxFilter) => void;
  unreadCounts: number | undefined;
}

const MailDrawerButton = ({
  isLoading,
  title,
  filter,
  filterType,
  icon,
  unreadCounts,
  handleFilter,
}: MailDrawerButtonProps) => {
  return isLoading ? (
    <ListItemButton
      selected={filter === filterType}
      sx={{
        minWidth: "150px",
        marginRight: "10px",
        border: "1px solid rgb(208, 215, 222)",
      }}
    >
      <Skeleton animation="wave" variant="circular">
        <ListItemIcon>{icon}</ListItemIcon>
      </Skeleton>
      <Skeleton animation="wave">
        <ListItemText primary={title} />
        {unreadCounts !== 0 && (
          <Chip
            label={unreadCounts}
            size="small"
            sx={{ textAlign: "center" }}
          />
        )}
      </Skeleton>
    </ListItemButton>
  ) : (
    <ListItemButton
      selected={filter === filterType}
      onClick={() => handleFilter(filterType)}
      sx={{
        minWidth: "150px",
        marginRight: "10px",
        border: "1px solid rgb(208, 215, 222)",
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
      {unreadCounts !== 0 && (
        <Chip label={unreadCounts} size="small" sx={{ textAlign: "center" }} />
      )}
    </ListItemButton>
  );
};

export default MailDrawerButton;
