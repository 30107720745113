import {
  Box,
  Button,
  Chip,
  IconButton,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
export const PermitTableRowSkeleton = () => {
  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox">
        <TableCell align="center">
          <Skeleton animation="wave" variant="rounded">
            <Chip variant="outlined" label="Status" />
          </Skeleton>
        </TableCell>
        <TableCell align="center" sx={{ fontWeight: "bold" }}>
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="center">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell align="right">
          <Box display="flex" gap="5px" justifyContent={"right"}>
            <Skeleton animation="wave" variant="circular">
              <IconButton color="error" size="large">
                <DeleteIcon sx={{ fontSize: "1.1rem" }} />
              </IconButton>
            </Skeleton>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
