"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.absolute = exports.editProject = exports.acceptInvite = exports.createAccount = exports.businessAccount = exports.userAccount = exports.mail = exports.condition = exports.conditions = exports.allConditions = exports.tasks = exports.permits = exports.inbox = exports.documents = exports.globalDirectory = exports.projectGlobalDirectory = exports.directory = exports.overview = exports.blogDetail = exports.users = exports.listProjects = exports.conditionPublic = exports.termsConditions = exports.privacyPolicy = exports.support = exports.whoWeare = exports.definitions = exports.partnership = exports.blog = exports.implementation = exports.apiIntegration = exports.smartDirectory = exports.reportingAndAnalytics = exports.demo = exports.verify = exports.login = exports.signUp = exports.pricing = exports.home = void 0;
//Public routes
exports.home = "/";
exports.pricing = "/pricing";
exports.signUp = "/sign-up";
exports.login = "/login";
exports.verify = "/verify";
exports.demo = "/request-demo";
exports.reportingAndAnalytics = "/permit-management";
exports.smartDirectory = "/communication-portal";
exports.apiIntegration = "/document-control";
exports.implementation = "/reporting-and-analytics";
exports.blog = "/blog";
exports.partnership = "/api-integration";
exports.definitions = "/templates";
exports.whoWeare = "/our-team";
exports.support = "/support";
exports.privacyPolicy = "/privacy-policy";
exports.termsConditions = "/terms-conditions";
var conditionPublic = function (accessId) {
    return (0, exports.absolute)(["condition-external", accessId]);
};
exports.conditionPublic = conditionPublic;
//Private routes
exports.listProjects = "/list-projects";
exports.users = "/users";
var blogDetail = function (title) { return (0, exports.absolute)(["blog", title]); };
exports.blogDetail = blogDetail;
var overview = function (projectId) {
    return (0, exports.absolute)(["overview", projectId]);
};
exports.overview = overview;
var directory = function (projectId) {
    return (0, exports.absolute)(["directory", projectId]);
};
exports.directory = directory;
var projectGlobalDirectory = function (projectId) {
    return (0, exports.absolute)(["global-directory", projectId]);
};
exports.projectGlobalDirectory = projectGlobalDirectory;
exports.globalDirectory = "/global-directory";
var documents = function (projectId) {
    return (0, exports.absolute)(["documents", projectId]);
};
exports.documents = documents;
var inbox = function (projectId) { return (0, exports.absolute)(["inbox", projectId]); };
exports.inbox = inbox;
var permits = function (projectId) { return (0, exports.absolute)(["permits", projectId]); };
exports.permits = permits;
var tasks = function (projectId) { return (0, exports.absolute)(["tasks", projectId]); };
exports.tasks = tasks;
var allConditions = function (projectId) {
    return (0, exports.absolute)(["all-conditions", projectId]);
};
exports.allConditions = allConditions;
var conditions = function (projectId, permitId) {
    return (0, exports.absolute)(["conditions", projectId, permitId]);
};
exports.conditions = conditions;
var condition = function (projectId, permitId, conditionId, tab) {
    return (0, exports.absolute)([
        "condition",
        projectId,
        permitId,
        conditionId,
        tab || "properties",
    ]);
};
exports.condition = condition;
var mail = function (projectId, permitId, conditionId, mailId) { return (0, exports.absolute)(["mail", projectId, permitId, conditionId, mailId]); };
exports.mail = mail;
exports.userAccount = "/user-account";
exports.businessAccount = "/business-account";
exports.createAccount = "/create-account";
exports.acceptInvite = "/accept-invite";
var editProject = function (projectId) {
    return (0, exports.absolute)(["edit-project", projectId]);
};
exports.editProject = editProject;
var absolute = function (params) {
    return "/".concat(params.filter(function (a) { return !!a; }).join("/"));
};
exports.absolute = absolute;
