import { useTable } from "@hooks/utils/useTable";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { Grid, InputAdornment, Table, TableBody, Box } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { Parser } from "@json2csv/plainjs";
import { useState } from "react";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import ContactModal from "../../ContactModal/ContactModal";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import { DirectoryTableRow } from "./DirectoryTableRow";
import { DirectoryTableRowSkeleton } from "./DirectoryTableRowSkeleton";
import { DirectoryContactLineItem } from "permit-one-common/src/interfaces/directoryContact";
import { camelCaseToWords } from "permit-one-common/src/utils/string";

interface DirectoryTableProps {
  loading: boolean;
  directory: DirectoryContactLineItem[];
  sendInvite: (profiles: ProfileLineItem[]) => Promise<void>;
}

export const DirectoryTable = ({
  loading,
  directory,
  sendInvite,
}: DirectoryTableProps) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const TABLE_HEAD: TableHeaderCell[] = [
    { id: "" },
    { id: "name", label: "Name", alignRight: false },
    { id: "company", label: "Company", alignRight: false },
    { id: "role", label: "Job Title", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "phone", label: "Phone", alignRight: false },
  ];

  const {
    page,
    selected,
    filterName,
    rowsPerPage,
    order,
    orderBy,
    handleRequestSort,
    handleFilterByName,
  } = useTable();

  const filteredUsers =
    filterName.length > 0
      ? directory.filter(
          (u) =>
            u.name.toLowerCase().includes(filterName.toLocaleLowerCase()) ||
            u.jobTitle.toLowerCase().includes(filterName.toLocaleLowerCase()) ||
            u.email.toLowerCase().includes(filterName.toLocaleLowerCase()) ||
            u.organisationName
              .toLowerCase()
              .includes(filterName.toLocaleLowerCase()) ||
            u.email.toLowerCase().includes(filterName.toLocaleLowerCase())
        )
      : directory;

  let sortedUsers = filteredUsers.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      case 1:
        return a.organisationName.toLowerCase() <
          b.organisationName.toLowerCase()
          ? -1
          : 1;
      case 2:
        return a.jobTitle.toLowerCase() < b.jobTitle.toLowerCase() ? -1 : 1;
      case 3:
        return a.email.toLowerCase() < b.email.toLowerCase() ? -1 : 1;
      case 4:
        return a.phoneNumber.toLowerCase() < b.phoneNumber.toLowerCase()
          ? -1
          : 1;
      default:
        return 0;
    }
  });

  sortedUsers = order === "asc" ? sortedUsers : sortedUsers.reverse();

  const isNotFound = !sortedUsers.length && !!filterName;

  const downloadCSV = () => {
    const opts = {
      fields: [
        {
          label: "Name",
          value: "name",
        },
        {
          label: "Organisation Name",
          value: "organisationName",
        },
        {
          label: "Job Title",
          value: "jobTitle",
        },
        {
          label: "Email",
          value: "email",
        },
        {
          label: "Phone Number",
          value: "phoneNumber",
        },
      ],
    };
    const parser = new Parser(opts);
    const csvString = parser.parse(directory);

    const exportedFilenmae = "permit-one-directory.csv";

    const formattedString = camelCaseToWords(csvString);
    const blob = new Blob([formattedString], {
      type: "text/csv;charset=utf-8;",
    });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", exportedFilenmae);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          typography: "body1",
          background: "transparent",
        }}
      >
        <Grid
          item
          md={12}
          sx={{
            padding: "0",
            margin: "0",
            width: "100%",
            justifyContent: "space-between",
            display: "flex",
            marginBottom: "25px",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Grid item md={3}>
            <StyledTextField
              className="search"
              loading={loading}
              type="text"
              placeholder="Search Directory..."
              name="filterProject"
              value={filterName}
              onChange={handleFilterByName}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            md={8}
            sx={{ justifyContent: "flex-end", display: "flex", gap: "10px" }}
          >
            <StyledButton
              loading={loading}
              onClick={downloadCSV}
              variant="contained"
              disabled={loading}
              startIcon={<Iconify icon="foundation:page-export-csv" />}
            >
              {"Download Excel"}
            </StyledButton>
          </Grid>
        </Grid>
      </Box>

      <Table
        stickyHeader
        aria-label="sticky table"
        className="project-table hd-no-arrow"
      >
        <ListTableHead
          headLabel={TABLE_HEAD}
          loading={loading}
          rowCount={directory.length}
          numSelected={selected.length}
          showCheckbox={false}
          onSelectAllClick={() => {
            console.log("Not implemented");
          }}
          order={order}
          orderBy={orderBy}
          handleRequestSort={handleRequestSort}
        />
        <TableBody>
          {!loading
            ? sortedUsers
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isSelected = selected.indexOf(row.name) !== -1;
                  return (
                    <DirectoryTableRow
                      key={row.id}
                      isSelected={isSelected}
                      contact={row}
                    />
                  );
                })
            : null}
          {isNotFound && (
            <NoResultsFoundRow
              colSpan={TABLE_HEAD.length + 1}
              filterName={filterName}
            />
          )}
          {directory.length === 0 && !loading ? (
            <NoRows colSpan={TABLE_HEAD.length + 1} title={"users"} />
          ) : null}
          {loading
            ? Array.from({ length: 6 }, (_, index) => (
                <DirectoryTableRowSkeleton key={index} />
              ))
            : null}
        </TableBody>
      </Table>

      <ContactModal
        open={open}
        sendInvite={sendInvite}
        handleClose={handleClose}
      />
    </>
  );
};
