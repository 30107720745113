import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { useFormik } from "formik";
import * as yup from "yup";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
interface ShareConditionProps {
  loading: boolean;
  open: boolean;
  permit: PermitLineItem;
  shareConditions: (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string
  ) => Promise<void>;
  setOpen: (open: boolean) => void;
}
export default function ShareCondition({
  loading,
  open,
  permit,
  shareConditions,
  setOpen,
}: ShareConditionProps) {
  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await shareConditions(
        values.email,
        values.firstName,
        values.lastName,
        permit.projectId,
        permit.id
      );
      setOpen(false);
    },
  });

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      className="bgBlueOverlay"
      maxWidth={"sm"}
    >
      <DialogTitle>Export Checklist</DialogTitle>
      <DialogContent dividers>
        <form onSubmit={formik.handleSubmit} style={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <StyledTextField
                id="email"
                name="email"
                label="Email"
                fullWidth
                margin="dense"
                autoComplete="email"
                loading={loading}
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email ? formik.errors.email : ""}
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                id="firstName"
                name="firstName"
                label="First name"
                fullWidth
                loading={loading}
                margin="dense"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.touched.firstName ? formik.errors.firstName : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <StyledTextField
                id="lastName"
                name="lastName"
                label="Last name"
                fullWidth
                loading={loading}
                margin="dense"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={
                  formik.touched.lastName ? formik.errors.lastName : ""
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <StyledButton
          loading={loading}
          variant="contained"
          disabled={loading}
          onClick={formik.submitForm}
          endIcon={<ForwardToInboxIcon />}
        >
          Share
        </StyledButton>
      </DialogActions>
    </Dialog>
  );
}
