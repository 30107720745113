"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProfileName = exports.createDefaultProfile = exports.createInvite = exports.profileTypeToString = exports.ProfileType = void 0;
var uuid_1 = require("uuid");
var ProfileType;
(function (ProfileType) {
    ProfileType["Admin"] = "profile-type_admin";
    ProfileType["Standard"] = "profile-type_standard";
})(ProfileType || (exports.ProfileType = ProfileType = {}));
var profileTypeToString = function (profileType) {
    switch (profileType) {
        case ProfileType.Admin:
            return "Admin";
        case ProfileType.Standard:
            return "Standard";
    }
};
exports.profileTypeToString = profileTypeToString;
var createInvite = function (email) {
    return {
        id: (0, uuid_1.v4)(),
        firstName: "",
        lastName: "",
        name: "",
        email: email.toLowerCase(),
        photoUrl: "",
        jobTitle: "",
        phoneNumber: "",
        created: new Date(),
        invited: true,
        taskNotifications: true,
        messageNotifications: true,
    };
};
exports.createInvite = createInvite;
var createDefaultProfile = function (user) {
    return {
        id: user ? user.id : (0, uuid_1.v4)(),
        firstName: "",
        lastName: "",
        name: "",
        email: user ? user.email.toLowerCase() : "",
        jobTitle: "",
        photoUrl: "",
        phoneNumber: "",
        taskNotifications: true,
        messageNotifications: true,
        created: new Date(),
    };
};
exports.createDefaultProfile = createDefaultProfile;
var getProfileName = function (profile) {
    if (profile.invited) {
        return "".concat(profile.email, " - (Invite)");
    }
    else {
        return "".concat(profile.firstName, " ").concat(profile.lastName);
    }
};
exports.getProfileName = getProfileName;
