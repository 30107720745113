import { useProfile } from "@hooks/crud/useProfile";
import { UserTable } from "@stories/molecules/UserTable/UserTable";
import { useState } from "react";
import { useProfileContext } from "@hooks/context/useProfileContext";
import UserModal from "@stories/organisms/UserModal/UserModal";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { Box, Grid, Typography } from "@mui/material";

export const Users = () => {
  const { userProfile, userOrganisation, isAuthProfileLoading } =
    useProfileContext();

  const [selectedProfile, setSelectedProfile] = useState<
    ProfileLineItem | undefined
  >(undefined);

  const {
    isProfilesLoading,
    profiles,
    deleteProfile,
    updateProfile,
    inviteProfile,
  } = useProfile(userOrganisation?.id);

  const [open, setOpen] = useState(false);

  if (!userProfile) {
    return <PageLoader />;
  }
  const handleClose = () => {
    setOpen(false);
    setSelectedProfile(undefined);
  };
  const handleOpen = () => {
    setSelectedProfile(undefined);
    setOpen(true);
  };

  const handleInviteProfile = async (profile: ProfileLineItem) => {
    await inviteProfile(profile);
  };

  const handleEditUser = (profile: ProfileLineItem) => {
    setSelectedProfile(profile);
    setOpen(true);
  };

  const handleUpdateProfile = (profile: ProfileLineItem) => {
    updateProfile(profile);
  };

  const handleDeleteUser = (profile: ProfileLineItem) => {
    deleteProfile(profile);
  };

  const loading = isProfilesLoading || isAuthProfileLoading;
  return (
    <>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0 0 30px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontSize: "28px !important",
            fontWeight: "700",
            color: "#333333",
          }}
        >
          User Accounts
        </Typography>
      </Grid>
      <Box sx={{ padding: "0 40px 40px" }}>
        <UserTable
          loading={loading}
          profile={userProfile}
          profiles={profiles}
          handleOpen={handleOpen}
          handleDeleteUser={handleDeleteUser}
          handleEditUser={handleEditUser}
        />
        {userProfile && userOrganisation && open ? (
          <UserModal
            open={open}
            loading={loading}
            organisation={userOrganisation}
            profiles={profiles}
            handleClose={handleClose}
            updateUser={handleUpdateProfile}
            inviteUser={handleInviteProfile}
            existingUser={selectedProfile}
          />
        ) : null}
      </Box>
    </>
  );
};
