"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePermitLodgement = exports.decodePermitLodgement = void 0;
var string_1 = require("../utils/string");
var decodePermitLodgement = function (permitLodgement) {
    var safePermitLodgement = (0, string_1.reversePostgresSafe)(permitLodgement);
    return {
        id: safePermitLodgement.permit_lodgement_id,
        projectId: safePermitLodgement.project_id,
        permitId: safePermitLodgement.permit_id,
        lodgementName: safePermitLodgement.lodgement_name,
        url: safePermitLodgement.url,
        created: new Date(safePermitLodgement.created),
    };
};
exports.decodePermitLodgement = decodePermitLodgement;
var encodePermitLodgement = function (permitLodgement) {
    var safePermitLodgement = (0, string_1.makePostgresSafe)(permitLodgement);
    return {
        permit_lodgement_id: safePermitLodgement.id,
        project_id: safePermitLodgement.projectId,
        permit_id: safePermitLodgement.permitId,
        lodgement_name: safePermitLodgement.lodgementName,
        url: safePermitLodgement.url,
        created: safePermitLodgement.created,
    };
};
exports.encodePermitLodgement = encodePermitLodgement;
