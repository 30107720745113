"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodePermitType = exports.decodePermitEventType = exports.encodePermitEvent = exports.decodePermitEvent = void 0;
var permitEvent_1 = require("../entities/permitEvent");
var string_1 = require("../utils/string");
var decodePermitEvent = function (permitEvent) {
    var safePermitEvent = (0, string_1.reversePostgresSafe)(permitEvent);
    return {
        id: safePermitEvent.permit_event_id,
        projectId: safePermitEvent.project_id,
        permitId: safePermitEvent.permit_id,
        conditionId: safePermitEvent.condition_id,
        info: safePermitEvent.info,
        created: new Date(safePermitEvent.created),
        eventType: (0, exports.decodePermitEventType)(safePermitEvent.event_type),
    };
};
exports.decodePermitEvent = decodePermitEvent;
var encodePermitEvent = function (permitEvent) {
    var safePermitEvent = (0, string_1.makePostgresSafe)(permitEvent);
    return {
        permit_event_id: safePermitEvent.id,
        project_id: safePermitEvent.projectId,
        permit_id: safePermitEvent.permitId,
        condition_id: safePermitEvent.conditionId,
        info: safePermitEvent.info,
        created: safePermitEvent.created,
        event_type: (0, exports.encodePermitType)(safePermitEvent.eventType),
    };
};
exports.encodePermitEvent = encodePermitEvent;
var decodePermitEventType = function (permitEventType) {
    switch (permitEventType) {
        case "permit-event-type_permit-created":
            return permitEvent_1.PermitEventType.PermitCreated;
        case "permit-event-type_permit-assigned-manager":
            return permitEvent_1.PermitEventType.PermitAssignedManager;
        case "permit-event-type_permit-unassigned-manager":
            return permitEvent_1.PermitEventType.PermitUnassignedManager;
        case "permit-event-type_permit-assigned-approver":
            return permitEvent_1.PermitEventType.PermitAssignedApprover;
        case "permit-event-type_permit-unassigned-approver":
            return permitEvent_1.PermitEventType.PermitAssignedApprover;
        case "permit-event-type_permit-in_review":
            return permitEvent_1.PermitEventType.PermitInReview;
        case "permit-event-type_permit-rejected":
            return permitEvent_1.PermitEventType.PermitRejected;
        case "permit-event-type_permit-updated":
            return permitEvent_1.PermitEventType.PermitUpdated;
        case "permit-event-type_permit-approved":
            return permitEvent_1.PermitEventType.PermitApproved;
        case "permit-event-type_uploaded_document":
            return permitEvent_1.PermitEventType.PermitUploadedDocument;
        case "permit-event-type_condition-created":
            return permitEvent_1.PermitEventType.ConditionCreated;
        case "permit-event-type_condition-assigned":
            return permitEvent_1.PermitEventType.ConditionAssigned;
        case "permit-event-type_condition-in_review":
            return permitEvent_1.PermitEventType.ConditionInReview;
        case "permit-event-type_condition-rejected":
            return permitEvent_1.PermitEventType.ConditionRejected;
        case "permit-event-type_condition-updated":
            return permitEvent_1.PermitEventType.ConditionUpdated;
        case "permit-event-type_condition-approved":
            return permitEvent_1.PermitEventType.ConditionApproved;
        case "permit-event-type_condition-document-uploaded":
            return permitEvent_1.PermitEventType.ConditionDocumentUploaded;
        case "permit-event-type_condition-document-revision-uploaded":
            return permitEvent_1.PermitEventType.ConditionDocumentRevisionUploaded;
        case "permit-event-type_condition-uploaded-assigned":
            return permitEvent_1.PermitEventType.ConditionDocumentAssigned;
        case "permit-event-type_condition-uploaded-unassigned":
            return permitEvent_1.PermitEventType.ConditionDocumentUnassigned;
        default:
            throw new Error("No permit event type known");
    }
};
exports.decodePermitEventType = decodePermitEventType;
var encodePermitType = function (permitEventType) {
    switch (permitEventType) {
        case permitEvent_1.PermitEventType.PermitCreated:
            return "permit-event-type_permit-created";
        case permitEvent_1.PermitEventType.PermitAssignedManager:
            return "permit-event-type_permit-assigned-manager";
        case permitEvent_1.PermitEventType.PermitUnassignedManager:
            return "permit-event-type_permit-unassigned-manager";
        case permitEvent_1.PermitEventType.PermitAssignedApprover:
            return "permit-event-type_permit-assigned-approver";
        case permitEvent_1.PermitEventType.PermitUnassignedApprover:
            return "permit-event-type_permit-unassigned-approver";
        case permitEvent_1.PermitEventType.PermitInReview:
            return "permit-event-type_permit-in_review";
        case permitEvent_1.PermitEventType.PermitRejected:
            return "permit-event-type_permit-rejected";
        case permitEvent_1.PermitEventType.PermitUpdated:
            return "permit-event-type_permit-updated";
        case permitEvent_1.PermitEventType.PermitApproved:
            return "permit-event-type_permit-approved";
        case permitEvent_1.PermitEventType.PermitUploadedDocument:
            return "permit-event-type_uploaded_document";
        case permitEvent_1.PermitEventType.ConditionCreated:
            return "permit-event-type_condition-created";
        case permitEvent_1.PermitEventType.ConditionAssigned:
            return "permit-event-type_condition-assigned";
        case permitEvent_1.PermitEventType.ConditionInReview:
            return "permit-event-type_condition-in_review";
        case permitEvent_1.PermitEventType.ConditionRejected:
            return "permit-event-type_condition-rejected";
        case permitEvent_1.PermitEventType.ConditionUpdated:
            return "permit-event-type_condition-updated";
        case permitEvent_1.PermitEventType.ConditionApproved:
            return "permit-event-type_condition-approved";
        case permitEvent_1.PermitEventType.ConditionDocumentUploaded:
            return "permit-event-type_condition-document-uploaded";
        case permitEvent_1.PermitEventType.ConditionDocumentRevisionUploaded:
            return "permit-event-type_condition-document-revision-uploaded";
        case permitEvent_1.PermitEventType.ConditionDocumentAssigned:
            return "permit-event-type_condition-uploaded-assigned";
        case permitEvent_1.PermitEventType.ConditionDocumentUnassigned:
            return "permit-event-type_condition-uploaded-assigned";
        default:
            throw new Error("No permit event type known");
    }
};
exports.encodePermitType = encodePermitType;
