import { TaskSummary } from "@stories/organisms/Summary/TaskSummary";
import { PermitSummary } from "@stories/organisms/Summary/PermitSummary";
import { ConditionSummary } from "@stories/organisms/Summary/ConditionSummary";
import { useProjectContext } from "@hooks/context/useProjectContext";
import {
  Box,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Zoom,
} from "@mui/material";
import { useProfileContext } from "@hooks/context/useProfileContext";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import ProjectModal from "@stories/organisms/ProjectModal/ProjectModal";
import { usePermitCount } from "@hooks/crud/usePermitCount";
import { useTaskCount } from "@hooks/crud/useTaskCount";
import { useConditionCount } from "@hooks/crud/useConditionCount";

export const Dashboard = (): JSX.Element => {
  const { selectedProject, updateProject, isProjectLoading } =
    useProjectContext();
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { isPermitCountLoading, permitCounts } = usePermitCount(
    selectedProject?.id
  );

  const { isTaskCountLoading, taskCounts } = useTaskCount(selectedProject?.id);
  const { isConditionCountLoading, conditionCounts } = useConditionCount(
    selectedProject?.id
  );

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const loading =
    isProjectLoading ||
    isAuthProfileLoading ||
    isPermitCountLoading ||
    isTaskCountLoading ||
    isConditionCountLoading;
  return (
    <>
      <Box sx={{ margin: "0" }}>
        <Grid
          className="borderBottom"
          container
          spacing={2}
          sx={{
            padding: "20px 40px 20px 40px",
            margin: "0",
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h1" component="div" sx={{ fontWeight: "700" }}>
            Project Overview
            <span
              style={{
                display: "block",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "500",
                marginTop: "5px",
                color: "rgb(101, 109, 118)",
              }}
            >
              {selectedProject?.projectName}
            </span>
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <Tooltip
              placement="top"
              title="Project Settings"
              TransitionComponent={Zoom}
            >
              <IconButton
                size="large"
                disabled={loading}
                onClick={() => setOpen(true)}
              >
                <SettingsIcon sx={{ color: "rgb(101, 109, 118)" }} />
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: "14px !important",
                    fontWeight: "500",
                    color: " rgb(101, 109, 118) !important",
                  }}
                >
                  Project Settings
                </Typography>
              </IconButton>
            </Tooltip>
          </div>
        </Grid>

        <Box sx={{ padding: "10px 30px 20px" }}>
          <Box
            sx={{
              padding: "20px",
              border: "1px solid rgb(208, 215, 222)",
              borderRadius: "4px",
              background: "#F6F8FA",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <TaskSummary
              taskCounts={taskCounts}
              project={selectedProject}
              showHeader={true}
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              padding: "20px",
              border: "1px solid rgb(208, 215, 222)",
              borderRadius: "4px",
              background: "#F6F8FA",
              marginBottom: "20px",
            }}
          >
            <PermitSummary
              permitCounts={permitCounts}
              project={selectedProject}
              loading={loading}
            />
          </Box>
          <Box
            sx={{
              padding: "20px",
              border: "1px solid rgb(208, 215, 222)",
              borderRadius: "4px",
              background: "#F6F8FA",
              marginBottom: "20px",
            }}
          >
            <ConditionSummary
              conditionCounts={conditionCounts}
              project={selectedProject}
              loading={loading}
              showHeader={true}
            />
          </Box>
        </Box>
      </Box>
      {userOrganisation ? (
        <ProjectModal
          open={open}
          organisation={userOrganisation}
          handleClose={handleClose}
          createProject={() => {
            console.log("Not implemeted");
          }}
          updateProject={updateProject}
          existingProject={selectedProject}
        />
      ) : null}
    </>
  );
};
