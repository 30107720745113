import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import DashboardCard from "@stories/molecules/DashboardCard/DashboardCard";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { MultiProgressBar } from "@stories/molecules/MultiProgressBar/MultiProgressBar";
import * as RouteHelper from "@utils/routes";
import { useNavigate } from "react-router-dom";
import { TaskCountLineItem } from "permit-one-common/src/interfaces/task";

interface TaskSummaryProps {
  taskCounts?: TaskCountLineItem;
  project?: ProjectLineItem;
  showHeader?: boolean;
  loading: boolean;
}

export const TaskSummary = ({
  loading,
  taskCounts,
  project,
  showHeader,
}: TaskSummaryProps) => {
  const navigate = useNavigate();

  const assignedTasks = taskCounts?.assigned || 0;
  const inReviewTasks = taskCounts?.inReview || 0;
  const approvedTasks = taskCounts?.approved || 0;
  const overdueTasks = taskCounts?.overdue || 0;
  //const notApplicableTasks = taskCounts?.notApplicable || 0;
  const allTasks = assignedTasks + inReviewTasks + approvedTasks + overdueTasks;

  return (
    <>
      {showHeader ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "calc(100% - 538px)" }}>
            <Stack direction="row" spacing={1}>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography
                    sx={{
                      fontSize: "19px",
                      color: "rgb(31, 35, 40) !important",
                      margin: "0 0 10px",
                      fontWeight: 700,
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    My Tasks{" "}
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  sx={{
                    fontSize: "19px",
                    color: "rgb(31, 35, 40) !important",
                    margin: "0 0 10px",
                    fontWeight: 700,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  My Tasks{" "}
                </Typography>
              )}

              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography
                    sx={{
                      fontSize: "19px",
                      color: "rgb(31, 35, 40) !important",
                      margin: "0 0 10px",
                      fontWeight: 700,
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <span className="task-number">(199)</span>
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  sx={{
                    fontSize: "19px",
                    color: "rgb(31, 35, 40) !important",
                    margin: "0 0 10px",
                    fontWeight: 700,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  <span className="task-number">({allTasks})</span>
                </Typography>
              )}
            </Stack>
            {loading ? (
              <Skeleton animation="wave" variant="text">
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgb(101, 109, 118) !important",
                    margin: "-5px 0 30px",
                    fontWeight: 500,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  Summary of My Tasks across all Project Permits and Conditions.
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgb(101, 109, 118) !important",
                  margin: "-5px 0 30px",
                  fontWeight: 500,
                }}
                color="text.secondary"
                gutterBottom
              >
                Summary of My Tasks across all Project Permits and Conditions.
              </Typography>
            )}
          </div>
          <MultiProgressBar
            loading={loading}
            assigned={assignedTasks}
            assignedText="Assigned"
            inReview={inReviewTasks}
            inReviewText="In Review"
            approved={approvedTasks}
            approvedText="Approved"
            overdue={overdueTasks}
            overdueText="Overdue"
            /*notApplicable={notApplicableTasks}
            notApplicableText="notApplicable"*/
          />
        </div>
      ) : undefined}

      <Box
        className="dashb-cards"
        flexGrow="1"
        flexDirection={"row"}
        justifyContent="space-between"
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={2.4} className="assigned">
            <DashboardCard
              loading={loading}
              title={"Assigned"}
              subTitle={assignedTasks.toString()}
              desc={"Current tasks requiring action from assignee"}
              handleClick={() => {
                if (project) {
                  navigate(`${RouteHelper.tasks(project.id)}?status=1`);
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={2.4} className="inreview">
            <DashboardCard
              loading={loading}
              title={"In Review"}
              subTitle={inReviewTasks.toString()}
              desc={"Actioned and pending review by permit approver"}
              handleClick={() => {
                if (project) {
                  navigate(`${RouteHelper.tasks(project.id)}?status=3`);
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={4}
            md={2.4}
            sx={{ paddingLeft: "20px" }}
            className="approved"
          >
            <DashboardCard
              loading={loading}
              title={"Approved"}
              subTitle={approvedTasks.toLocaleString()}
              desc={"Permits approved with no further action required"}
              handleClick={() => {
                if (project) {
                  navigate(`${RouteHelper.tasks(project.id)}?status=4`);
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={2.4} className="overdue">
            <DashboardCard
              loading={loading}
              title={"Overdue"}
              subTitle={overdueTasks.toString()}
              desc={"Assigned tasks requiring urgent action"}
              handleClick={() => console.log("Not implemented")}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
