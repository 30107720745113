import { InputAdornment, Grid, Box } from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import React, { useState } from "react";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import SearchIcon from "@mui/icons-material/Search";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import ConditionExport from "../ConditionExport/ConditionExport";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import { FileGenerationStatus } from "permit-one-common/src/entities/fileGeneration";

interface PublicConditionTableToolbarProps {
  loading: boolean;
  filterName: string;
  profile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  altControl?: React.ReactNode;
  onFilter: (event: any) => void;
  downloadStepPDF: FileGenerationStatus;
  PDFDownloadProgress: number;
  getPdfReport: (
    projectId: string,
    permitId: string,
    reportName: string
  ) => Promise<void>;
  getExcelReport: (
    projectId: string,
    permitId: string,
    reportName: string
  ) => Promise<void>;

  downloadStepExcel: FileGenerationStatus;
  excelDownloadProgress: number;
  getFullReportInfo: (
    projectId: string,
    permitId: string,
    zipFileTitle: string
  ) => Promise<void>;
  downloadStepAllFiles: FileGenerationStatus;
  allFilesDownloadProgress: number;
}

export default function PublicConditionTableToolbar({
  loading,
  filterName,
  userOrganisation,
  profile,
  project,
  permit,
  onFilter,
  downloadStepPDF,
  PDFDownloadProgress,
  getPdfReport,
  getExcelReport,
  downloadStepExcel,
  excelDownloadProgress,
  getFullReportInfo,
  downloadStepAllFiles,
  allFilesDownloadProgress,
}: PublicConditionTableToolbarProps) {
  const [exportOpen, setExportOpen] = useState(false);

  const isProjectOwner =
    userOrganisation && project && project.owner?.id === userOrganisation.id;
  const isPermitManager =
    profile &&
    permit &&
    permit.managers.map((pm) => pm.id).includes(profile.id);
  const isPermitApprover =
    profile &&
    permit &&
    permit.approvers.map((pa) => pa.id).includes(profile.id);

  return (
    <>
      <Grid
        container
        sx={{
          padding: "0",
          margin: "0",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Grid
          item
          md={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0",
          }}
        >
          <Grid item md={3} sx={{ padding: "0" }}>
            <StyledTextField
              className="search"
              loading={loading}
              type="text"
              placeholder="Search Conditions..."
              name="filterProject"
              value={filterName}
              onChange={onFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid
            item
            md={8}
            sx={{
              padding: "0",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              justifyContent: "flex-end",
            }}
          >
            {isProjectOwner || isPermitManager || isPermitApprover ? (
              <StyledButton
                loading={loading}
                onClick={() => {
                  setExportOpen(true);
                }}
                variant="outlined"
                disabled={loading}
                startIcon={<Iconify icon="foundation:page-export-csv" />}
                className="outlined-btn filled"
              >
                {"Download Checklist"}
              </StyledButton>
            ) : undefined}
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: "0" }}>
        {project && permit ? (
          <ConditionExport
            project={project}
            permit={permit}
            loading={false}
            open={exportOpen}
            setOpen={setExportOpen}
            downloadStepPDF={downloadStepPDF}
            PDFDownloadProgress={PDFDownloadProgress}
            getPdfReport={getPdfReport}
            getExcelReport={getExcelReport}
            downloadStepExcel={downloadStepExcel}
            excelDownloadProgress={excelDownloadProgress}
            getFullReportInfo={getFullReportInfo}
            downloadStepAllFiles={downloadStepAllFiles}
            allFilesDownloadProgress={allFilesDownloadProgress}
          />
        ) : null}
      </Box>
    </>
  );
}
