import { NavItemType } from "types";
import * as RouteHelper from "@utils/routes";
import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

export const getDirectoryNav = (): NavItemType => {
  const theme = useTheme();
  return {
    id: "directory",
    title: "Contacts",
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    type: "group",
    children: [
      {
        id: "global-directory",
        title: "Global Directory",
        type: "item",
        icon: (
          <TravelExploreIcon style={{ color: theme.palette.primary.main }} />
        ),
        url: RouteHelper.globalDirectory,
      },
    ],
  };
};
