import { useProjectContext } from "@hooks/context/useProjectContext";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import { ConditionSummary } from "@stories/organisms/Summary/ConditionSummary";
import { ConditionTable } from "@stories/organisms/Tables/ConditionTable/ConditionTable";
import { useCondition } from "@hooks/crud/useCondition";
import * as RouteHelper from "@utils/routes";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { useConditionCount } from "@hooks/crud/useConditionCount";

export const AllConditions = (): JSX.Element => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { selectedProject } = useProjectContext();
  const { conditions, isConditionLoading } = useCondition(projectId);

  const { isConditionCountLoading, conditionCounts } = useConditionCount(
    selectedProject?.id
  );

  const handleViewCondition = (condition: ConditionLineItem) => {
    navigate(
      RouteHelper.condition(
        selectedProject?.id || "",
        condition.permitId,
        condition.id
      )
    );
  };

  const loading = isConditionLoading || isConditionCountLoading;
  return (
    <Box>
      <Grid
        className="borderBottom"
        container
        spacing={2}
        sx={{
          padding: "20px 40px 20px 40px",
          margin: "0 0 30px",
          width: "100%",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Typography
          variant="h1"
          component="div"
          sx={{
            fontSize: "28px !important",
            fontWeight: "700",
            color: "#333333",
          }}
        >
          My Conditions
        </Typography>
      </Grid>
      <Box sx={{ padding: "20px 40px 40px" }}>
        <ConditionSummary
          conditionCounts={conditionCounts}
          project={selectedProject}
          loading={loading}
        />
        {/* <ConditionTable
          loading={loading}
          conditions={conditions}
          setConditions={() => {
            console.log("Not implemented");
          }}
          handleViewCondition={handleViewCondition}
          handleOpen={() => {
            console.log("not implemented");
          }}
          handleImportConditons={async () => {
            console.log("not implemented");
          }}
          handleEditCondition={() => {
            console.log("not implemented");
          }}
          handleDeleteCondition={() => {
            console.log("not implemented");
          }}
          showToolbar={false}
        /> */}
      </Box>
    </Box>
  );
};
