import { TableCell, TableRow } from "@mui/material";
import { DownloadButton } from "@stories/atoms/DownloadButton/DownloadButton";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { getDocumentName } from "permit-one-common/src/utils/string";
import { DeleteButton } from "@stories/atoms/DeleteButton/DeleteButton";
import { PermitFeeLineItem } from "permit-one-common/src/interfaces/permitFee";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { useState } from "react";

interface FeeTableRowProps {
  selected: boolean;
  fee: PermitFeeLineItem;
  isDownloading: boolean;
  selectedId?: string;
  downloadProgress: number;
  setSelectedId: (id: string) => void;
  onDownload: (uri: string, feeName: string) => void;
  deletePermitFee: (fee: PermitFeeLineItem) => void;
}

export const FeeTableRow = ({
  fee,
  selected,
  isDownloading,
  selectedId,
  downloadProgress,
  setSelectedId,
  onDownload,
  deletePermitFee,
}: FeeTableRowProps) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  return (
    <>
      <TableRow hover tabIndex={-1} role="checkbox" selected={selected}>
        <TableCell align="center">{fee.feeName}</TableCell>
        <TableCell align="center">{fee.created.toLocaleDateString()}</TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          {isDownloading && fee.id === selectedId ? (
            <CircularProgressWithLabel value={downloadProgress} />
          ) : (
            <DownloadButton
              disabled={isDownloading}
              onClick={() => {
                setSelectedId(fee.id);
                onDownload(fee.url, getDocumentName(fee.feeName, fee.url));
              }}
            />
          )}
        </TableCell>
        <TableCell align="center" sx={{ width: "10%" }}>
          <DeleteButton
            disabled={isDownloading}
            onClick={() => {
              setOpenConfirmDialog(true);
            }}
          
          />
        </TableCell>
      </TableRow>
      <ConfirmationDialog
        message={"Do you want to delete the fee?"}
        open={openConfirmDialog}
        title={"Delete Fee"}
        intent={"error"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => deletePermitFee(fee)}
      />
    </>
  );
};
