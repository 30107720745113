import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import {
  ConditionDocumentLineItem,
  createDefaultConditionDocument,
} from "permit-one-common/src/interfaces/conditionDocument";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Stack } from "@mui/material";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { useRef, useState } from "react";
import { UploadStatus, uploadImage } from "@hooks/utils/useUpload";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ToastOptions, toast } from "react-toastify";
import { SelectedDocumentsRow } from "./SelectedDocumentsRow";
import { UploadModal } from "./UploadModal";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

interface BulkDocumentFormProps {
  project: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  userProfile: ProfileLineItem;
  documentCount: number;
  handleSubmit: (permit: ConditionDocumentLineItem[]) => void;
  handleClose: () => void;
}

export const BulkDocumentForm = ({
  project,
  permit,
  condition,
  userProfile,
  documentCount,
  handleSubmit,
  handleClose,
}: BulkDocumentFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [conditionDocuments, setConditionDocuments] = useState<
    ConditionDocumentLineItem[]
  >([]);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );
  const [currentUploadFile, setCurrentUploadFile] = useState<string>();
  const [uploadedIndice, setUploadedIndices] = useState<number[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const validationSchema = yup.object({});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: async () => {
      if (conditionDocuments.length > 0) {
        setUploadStatus(UploadStatus.Uploading);
        const uploadKeys = await handleUpload();
        conditionDocuments.forEach((cd, index) => {
          cd.url = uploadKeys[index];
          cd.revisions[0].url = uploadKeys[index];
        });

        await handleSubmit(conditionDocuments);
        setUploadStatus(UploadStatus.Idle);
        handleClose();
      }
    },
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleSelectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      project &&
      e.currentTarget?.files &&
      e.currentTarget?.files.length > 0
    ) {
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        const currentFile = e.currentTarget.files[i];
        const newDocument = createDefaultConditionDocument(
          project.id,
          currentFile,
          userProfile.id,
          `${userProfile.firstName} ${userProfile.lastName}`,
          documentCount + i + conditionDocuments.length,
          project,
          permit,
          condition
        );

        setConditionDocuments((value) => [...value, newDocument]);
      }
    }
  };

  const handleRemoveFile = (fileIndex: number) => {
    const updatedFiles = conditionDocuments.filter(
      (f, index) => index !== fileIndex
    );
    setConditionDocuments(updatedFiles);
  };

  const handleUpload = async () => {
    const uploadKeys = [];
    if (conditionDocuments.length > 0) {
      const filesToUpload = Array.from(conditionDocuments);
      for (let i = 0; i < filesToUpload.length; i++) {
        const fileToUpload = filesToUpload[i].file;
        if (fileToUpload) {
          setCurrentUploadFile(fileToUpload.name);
          setUploadProgress(0);
          if (fileToUpload) {
            const fileKey = `documents/${project?.id}/${condition?.id || 0}/${
              fileToUpload.name
            }`;
            await uploadImage(
              fileKey,
              fileToUpload,
              (progress: any) => {
                const total = (progress.loaded / progress.total) * 100;
                setUploadProgress(total);
              },
              () => {
                setUploadProgress(0);
                toast("Upload failed - something went wrong!", {
                  type: "error",
                } as ToastOptions);
              }
            );
            setUploadedIndices([...uploadedIndice, i]);
            uploadKeys.push(fileKey);
          }
        }
        setUploadStatus(UploadStatus.Saving);
        setCurrentUploadFile(undefined);
      }
    }
    return uploadKeys;
  };

  const formDisabled =
    formik.isSubmitting || uploadStatus !== UploadStatus.Idle;
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container sx={{ padding: "0 " }}>
          <Grid item xs={12} md={4} sx={{ padding: "10px 0 30px" }}>
            <Stack>
              <Button
                className="gray-hover-button"
                variant="contained"
                disabled={formDisabled}
                onClick={onFileInput}
                sx={{
                  background: "transparent !important",
                  border: "1px solid rgba(145, 158, 171, 0.32) !important",
                  color: "black !important",
                  gap: "10px",
                  "&:hover": {
                    borderColor: "black",
                    backgroundColor: "#F2F2F2 !important",
                  },
                  padding: "7px 10px",
                }}
              >
                <input
                  hidden
                  ref={fileInput}
                  accept="*/*"
                  multiple
                  type="file"
                  onChange={handleSelectFiles}
                />
                <CloudUploadIcon sx={{ marginRight: "10px" }} /> Select
                Documents
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
            {/* <SelectedDocumentsRow
              project={project}
              documentCount={documentCount + conditionDocuments.length}
              conditionDocuments={conditionDocuments}
              setConditionDocuments={setConditionDocuments}
              removeFile={handleRemoveFile}
            /> */}
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <StyledButton
              loading={false}
              disabled={formDisabled || conditionDocuments.length == 0}
              color="primary"
              variant="contained"
              type="submit"
              sx={{ cursor: "pointer !important" }}
            >
              Submit
            </StyledButton>
          </Grid>
        </Grid>
      </form>
      <UploadModal total={uploadProgress} uploadStatus={uploadStatus} />
    </>
  );
};
