"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.plansAbleToMakeProjects = exports.paymentPlanToString = exports.PaymentPlan = exports.createDefaultOrganisation = void 0;
var organisation_1 = require("../entities/organisation");
var uuid_1 = require("uuid");
var createDefaultOrganisation = function () {
    return {
        id: (0, uuid_1.v4)(),
        organisationName: "",
        abn: "",
        planType: PaymentPlan.Business,
        country: "AU",
        state: "",
        city: "",
        postCode: "",
        memberType: organisation_1.OrganisationMemberType.Admin,
        planActive: false,
        created: new Date(),
    };
};
exports.createDefaultOrganisation = createDefaultOrganisation;
var PaymentPlan;
(function (PaymentPlan) {
    PaymentPlan["Free"] = "plan-type_free";
    PaymentPlan["Business"] = "plan-type_business";
    PaymentPlan["Enterprise"] = "plan-type_enterprise";
})(PaymentPlan || (exports.PaymentPlan = PaymentPlan = {}));
var paymentPlanToString = function (paymentPlan) {
    switch (paymentPlan) {
        case PaymentPlan.Free:
            return "Free";
        case PaymentPlan.Business:
            return "Business";
        case PaymentPlan.Enterprise:
            return "Enterprise";
    }
};
exports.paymentPlanToString = paymentPlanToString;
exports.plansAbleToMakeProjects = [
    PaymentPlan.Business,
    PaymentPlan.Enterprise,
];
