import { v4 as uuid } from "uuid";
import { getImageUrl, uploadImage } from "@hooks/utils/useUpload";
import { useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Quill = ReactQuill.Quill;
const Font = Quill.import("formats/font");
Font.whitelist = ["roboto"];
Quill.register(Font, true);

interface QuillInputProps {
  loading: boolean;
  value?: string;
  allowImages?: boolean;
  onChange: (value: string) => void;
  onBlur: () => void;
}

export const QuillInput = ({
  loading,
  value,
  allowImages,
  onChange,
  onBlur,
}: QuillInputProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const quillRef = useRef<any>();

  const imageHandler = (e: any) => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const imageModule = allowImages ? ["image"] : [];
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [["bold", "italic", "underline"], imageModule],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      const editor = quillRef.current.getEditor();

      const file = e.currentTarget.files[0];
      const fileKey = `upload/${uuid()}/${file.name}`;
      await uploadImage(
        fileKey,
        file,
        () => {
          console.log("Not implemented");
        },
        () => {
          console.log("Not implemented");
        }
      );
      const s3Key = await getImageUrl(fileKey);
      editor.insertEmbed(editor.getSelection(), "image", s3Key);
    }
  };

  const randomId = uuid();
  return (
    <>
      <input
        hidden
        ref={fileInput}
        accept="*/image"
        type="file"
        onChange={onUpload}
      />
      <ReactQuill
        className={`quill-input-class-${randomId}`}
        ref={quillRef}
        modules={modules}
        readOnly={loading}
        value={value}
        onChange={(
          value: string,
          delta: any,
          source: any,
          editor: ReactQuill.UnprivilegedEditor
        ) => {
          if (source == "user") {
            onChange(value);
          }
        }}
        onBlur={(
          previousSelection: ReactQuill.Range,
          source: any,
          editor: ReactQuill.UnprivilegedEditor
        ) => {
          if (source == "user") {
            onBlur();
          }
        }}
        // onFocus={(
        //   previousSelection: ReactQuill.Range,
        //   source: any,
        //   editor: ReactQuill.UnprivilegedEditor
        // ) => {
        //   if (source == "user") {
        //     console.
        //   }
        // }}
      />
    </>
  );
};
