import * as React from "react";
import { v4 as uuid } from "uuid";
import { getApiData } from "@hooks/utils/api";
import { downloadFile } from "@hooks/utils/useUpload";
import { FileGenerationLineItem } from "permit-one-common/src/interfaces/fileGeneration";
import { decodeFileGeneration } from "permit-one-common/src/decoders/fileGeneration";
import { FileGeneration, FileGenerationStatus } from "permit-one-common/src/entities/fileGeneration";
import { sleep } from "permit-one-common/src/utils/sleep";

export const usePdf = () => {
  const [downloadStepPDF, setDownloadStepPDF] =
    React.useState<FileGenerationStatus>(FileGenerationStatus.Idle);
  const [PDFDownloadProgress, setPDFDownloadProgress] =
    React.useState<number>(0);

  const [error, setError] = React.useState<string | null>(null);
  const getPdfReport = async (
    projectId: string,
    permitId: string,
    zipFileTitle: string
  ) => {
    try {
      const fileGenerationId = uuid();
      setPDFDownloadProgress(0);
      setDownloadStepPDF(FileGenerationStatus.Fetching);

      let fileGenerationResult: FileGenerationLineItem | undefined = undefined;
      let counter = 0;
      while (counter < 300) {
        const profileResult = await getApiData(
          "getPdfReport",
          "fileGeneration",
          fileGenerationId,
          {
            projectId,
            permitId,
          }
        );
        fileGenerationResult = decodeFileGeneration(
          profileResult.data as FileGeneration
        );

        setDownloadStepPDF(fileGenerationResult.status);
        if (fileGenerationResult.status === FileGenerationStatus.Error) {
          setError("Could not fetch pdf report");
          break;
        } else if (
          fileGenerationResult.status === FileGenerationStatus.Generated
        ) {
          break;
        }
        await sleep(1000);
        counter++;
      }

      if (fileGenerationResult && fileGenerationResult.url) {
        await downloadFile(
          fileGenerationResult.url,
          zipFileTitle,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;

            setPDFDownloadProgress(total);
            if (total >= 100) {
              setDownloadStepPDF(FileGenerationStatus.Idle);
            }
          }
        );
      } else {
        setError("Could not fetch pdf report");
        setDownloadStepPDF(FileGenerationStatus.Error);
      }
    } catch {
      setError("Could not fetch pdf report");
      setDownloadStepPDF(FileGenerationStatus.Error);
    } finally {
      setDownloadStepPDF(FileGenerationStatus.Idle);
    }
  };

  return {
    getPdfReport,
    downloadStepPDF,
    PDFDownloadProgress,
    error,
  };
};
