import { v4 as uuid } from "uuid";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import * as yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import dayjs from "dayjs";
import { useRef, useState } from "react";
import { NumericFormatCustom } from "@stories/atoms/NumericalFormatCustom/NumericalFormatCustom";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { IconButton, Stack, Typography } from "@mui/material";
import AttachmentTwoToneIcon from "@mui/icons-material/AttachmentTwoTone";
import { fileTypeToIcon } from "../../../utils/file";
import { UploadModal } from "../BulkDocumentForm/UploadModal";
import { UploadStatus, uploadImage } from "@hooks/utils/useUpload";
import { ToastOptions, toast } from "react-toastify";
import {
  PermitType,
  permitTypeToAbbreviationString,
  permitTypeToString,
} from "permit-one-common/src/entities/permit";

interface PermitFormProps {
  permit: PermitLineItem;
  handleSubmit: (permit: PermitLineItem) => void;
}

export const PermitDetailsForm = ({
  permit,
  handleSubmit,
}: PermitFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentUploadFile, setCurrentUploadFile] = useState<string>();
  const [uploadedIndice, setUploadedIndices] = useState<number[]>([]);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );

  const validationSchema = yup.object({
    permitName: yup.string().required("Permit name is required"),
    permitType: yup.string().required("Permit type is required"),
    permitNumber: yup.number().required("Permit number is required"),
    permitDescription: yup.string().required("Permit description is required"),
    permitScope: yup.string().required("Permit scope is required"),
    startDate: yup.date().required("Start date is required"),
    endDate: yup.date(),
    costOfPermitWorks: yup
      .number()
      .moreThan(0, "Cost of permit works must be greater than 0"),
    costOfProjectWorks: yup
      .number()
      .moreThan(0, "Cost of project works must be greater than 0"),
  });
  const formik = useFormik({
    initialValues: {
      permitName: permit.permitName,
      permitType: permit.permitType,
      permitNumber: permit.permitNumber,
      permitDescription: permit.permitDescription,
      permitScope: permit.permitScope,
      startDate: dayjs(permit.startDate),
      endDate: permit.endDate ? dayjs(permit.endDate) : undefined,
      costOfPermitWorks: permit.costOfPermitWorks,
      costOfProjectWorks: permit.costOfProjectWorks,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const uploadKeys = await handleUpload();

      await handleSubmit({
        ...permit,
        permitName: values.permitName,
        permitDescription: values.permitDescription,
        permitScope: values.permitScope,
        permitType: values.permitType,
        permitNumber: values.permitNumber,
        costOfPermitWorks: values.costOfPermitWorks,
        costOfProjectWorks: values.costOfProjectWorks,
        uri: uploadKeys.length > 0 ? uploadKeys[0] : permit.uri,
      });
    },
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleUpload = async () => {
    const uploadKeys = [];
    for (let i = 0; i < filesToUpload.length; i++) {
      const fileToUpload = filesToUpload[i];
      if (fileToUpload) {
        setCurrentUploadFile(fileToUpload.name);
        setUploadProgress(0);
        if (fileToUpload) {
          const fileKey = `attachments/${uuid()}/${fileToUpload.name}`;
          await uploadImage(
            fileKey,
            fileToUpload,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
            },
            () => {
              setUploadProgress(0);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
            }
          );
          setUploadedIndices([...uploadedIndice, i]);
          uploadKeys.push(fileKey);
        }
      }
      setUploadStatus(UploadStatus.Saving);
      setCurrentUploadFile(undefined);
    }
    return uploadKeys;
  };

  const handleSelectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files && e.currentTarget?.files.length > 0) {
      for (let i = 0; i < e.currentTarget.files.length; i++) {
        setFilesToUpload(Array.from(e.currentTarget?.files));
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2} mt={2}>
        <Grid item md={6}>
          <FormControl fullWidth>
            <TextField
              select
              id="permitType"
              name="permitType"
              label="Permit Type"
              fullWidth
              disabled={formik.isSubmitting}
              value={formik.values.permitType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.permitType && Boolean(formik.errors.permitType)
              }
              helperText={
                formik.touched.permitType ? formik.errors.permitType : ""
              }
            >
              {Object.values(PermitType).map((p, index) => (
                <MenuItem key={`permit-type-${index}`} value={p}>
                  {permitTypeToString(p)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item md={6}>
          <TextField
            id="permitName"
            name="permitName"
            label="Permit Name"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.permitName}
            error={
              formik.touched.permitName && Boolean(formik.errors.permitName)
            }
            helperText={
              formik.touched.permitName ? formik.errors.permitName : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            id="permitNumber"
            name="permitNumber"
            label="Permit Number/Code"
            fullWidth
            type="number"
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.permitNumber}
            error={
              formik.touched.permitNumber && Boolean(formik.errors.permitNumber)
            }
            helperText={
              formik.touched.permitNumber ? formik.errors.permitNumber : ""
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {`${permitTypeToAbbreviationString(
                    formik.values.permitType
                  )} -`}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            id="permitDescription"
            name="permitDescription"
            label="Permit Description"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.permitDescription}
            error={
              formik.touched.permitDescription &&
              Boolean(formik.errors.permitDescription)
            }
            helperText={
              formik.touched.permitDescription
                ? formik.errors.permitDescription
                : ""
            }
          />
        </Grid>
        <Grid item md={12}>
          <TextField
            id="permitScope"
            name="permitScope"
            label="Permit Scope"
            fullWidth
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.permitScope}
            error={
              formik.touched.permitScope && Boolean(formik.errors.permitScope)
            }
            helperText={
              formik.touched.permitScope ? formik.errors.permitScope : ""
            }
          />
        </Grid>
        <Grid item md={6}>
          <DatePicker
            onChange={(value) => {
              if (value) {
                formik.setFieldValue("startDate", value, true);
              }
            }}
            disabled={formik.isSubmitting}
            value={formik.values.startDate}
            slotProps={{
              textField: {
                label: "Estimated Start Date",
                helperText:
                  formik.touched.startDate && formik.errors.startDate
                    ? "Invalid Date"
                    : "",
                error:
                  formik.touched.startDate && Boolean(formik.errors.startDate),
              },
            }}
            sx={{ width: "100%" }}
            className="datepicker"
          />
        </Grid>
        <Grid item md={6}>
          <DatePicker
            onChange={(value) => {
              if (value) {
                formik.setFieldValue("endDate", value, true);
              }
            }}
            disabled={formik.isSubmitting}
            value={formik.values.endDate}
            slotProps={{
              textField: {
                label: "Estimated End Date",
                helperText:
                  formik.touched.endDate && formik.errors.endDate
                    ? "Invalid Date"
                    : "",
                error: formik.touched.endDate && Boolean(formik.errors.endDate),
              },
            }}
            sx={{ width: "100%" }}
            className="datepicker"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="costOfPermitWorks"
            name="costOfPermitWorks"
            label="Cost of Permit Works"
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.costOfPermitWorks}
            error={
              formik.touched.costOfPermitWorks &&
              Boolean(formik.errors.costOfPermitWorks)
            }
            helperText={
              formik.touched.costOfPermitWorks
                ? formik.errors.costOfPermitWorks
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="costOfProjectWorks"
            name="costOfProjectWorks"
            label="Cost of Project Works"
            fullWidth
            InputProps={{
              inputComponent: NumericFormatCustom as any,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            disabled={formik.isSubmitting}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.costOfProjectWorks}
            error={
              formik.touched.costOfProjectWorks &&
              Boolean(formik.errors.costOfProjectWorks)
            }
            helperText={
              formik.touched.costOfProjectWorks
                ? formik.errors.costOfProjectWorks
                : ""
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="center" sx={{ mt: 2 }}>
        <Grid item>
          <Stack direction="row" spacing={2}>
            <IconButton
              size="large"
              onClick={onFileInput}
              className="attach-btn"
            >
              <input
                hidden
                ref={fileInput}
                accept="*/*"
                type="file"
                onChange={handleSelectFiles}
              />
              <Stack direction="row" spacing={2}>
                <AttachmentTwoToneIcon />
                <Typography>Attach Checklist</Typography>
              </Stack>
            </IconButton>
            {filesToUpload.map((file) => {
              return fileTypeToIcon(file.name);
            })}
          </Stack>
        </Grid>
      </Grid>
      <Grid
        item
        md={12}
        sx={{
          display: "flex !important",
          padding: "10px 10px 0",
          justifyContent: "flex-end",
        }}
      >
        <StyledButton
          loading={false}
          color="primary"
          variant="contained"
          type="submit"
          disabled={formik.isSubmitting}
        >
          Next
        </StyledButton>
      </Grid>

      <UploadModal total={uploadProgress} uploadStatus={uploadStatus} />
    </form>
  );
};
