import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterLogo from "assets/images/foot-logo.png";
import instgram from "assets/images/instgram-logo.webp";
import linkedin from "assets/images/linkedin-logo.webp";
import LogoActive from "assets/images/LogoActive.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

export const TermsConditions = () => {
  const [IsSticky, setIsSticky] = useState(false);
  const [IsActive, setIsActive] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const [menus, setMenus] = useState([
    {
      label: "Solutions",
      items: [
        { label: "permit management", url: "/permit-management" },
        { label: "Communication portal", url: "/communication-portal" },
        { label: "document Control", url: "/document-control" },
        { label: "Reporting & Analytics", url: "/reporting-and-analytics" },
      ],
      isOpen: false,
    },
    {
      label: "Resources",
      items: [
        { label: "Templates", url: "/templates" },
        { label: "News & Articles", url: "/blog" },
        { label: "API Integration", url: "/api-integration" },
      ],
      isOpen: false,
    },
    {
      label: "Company",
      items: [
        { label: "Our Team", url: "/our-team" },
        { label: "Support", url: "/support" },
        { label: "Privacy Policy", url: "/privacy-policy" },
        { label: "Terms & Conditions ", url: "/terms-conditions" },
      ],
      isOpen: false,
    },
    // Add more menu objects as needed
  ]);

  const handleMenuToggle = (menuIndex: number) => {
    setMenus((prevMenus) => {
      const updatedMenus = [...prevMenus];
      updatedMenus[menuIndex].isOpen = !updatedMenus[menuIndex].isOpen;
      return updatedMenus;
    });
  };

  return (
    <>
      {/* header Section  */}
      <header
        className={IsSticky === true ? "active sub-header" : "sub-header"}
      >
        <div className="container-new">
          <div className="navigation">
            <a href="/" className="site-logo">
              <img src={LogoActive} alt="socialpro" />
              {/* {IsSticky === true ? (
                <img src={Logo} alt="socialpro" />
              ) : (
                <img src={LogoActive} alt="socialpro" />
              )} */}
            </a>
            <ul>
              <li>
                <span>Solutions</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/permit-management">permit management</Link>
                  </li>
                  <li>
                    <Link to="/communication-portal">Communication portal</Link>
                  </li>
                  <li>
                    <Link to="/document-control">document Control</Link>
                  </li>
                  <li>
                    <Link to="/reporting-and-analytics">
                      Reporting & Analytics
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Resources</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/templates">templates</Link>
                  </li>
                  <li>
                    <Link to="/blog">News & Articles</Link>
                  </li>
                  <li>
                    <Link to="/api-integration">API Integration</Link>
                  </li>
                </ul>
              </li>
              <li>
                <span>Company</span>
                <ul className="dropdown-content">
                  <li>
                    <Link to="/our-team">Our Team</Link>
                  </li>
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                </ul>
              </li>
            </ul>
            <div className="head-btns">
              <a href="/request-demo" className="demo-btn">
                Request a Demo
              </a>
              <a href="/login" className="nav-login sub-head">
                <svg
                  data-bbox="0 0 50 50"
                  data-type="shape"
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  viewBox="0 0 50 50"
                  fill="#fff"
                >
                  <g>
                    <path
                      d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                      fill="#005CD1"
                    ></path>
                  </g>
                </svg>
                <span>Login</span>
              </a>
            </div>

            {IsActive === false ? (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                {IsSticky === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#005CD1"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="hamburger-menu"
                onClick={() => setIsActive(!IsActive)}
              >
                {IsSticky === true ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#005CD1"
                    height={40}
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </button>
            )}
          </div>
        </div>
        {/* Mob Navigation  */}

        <div className={IsActive === true ? "active mob-bar" : "mob-bar"}>
          <div className="mob-nav">
            <ul>
              {menus.map((menu, index) => (
                <li
                  key={index}
                  onClick={() => handleMenuToggle(index)}
                  className={
                    menu.isOpen ? "dropdown-link svg-rotate" : "dropdown-link"
                  }
                >
                  <span>
                    {menu.label}
                    <ArrowDropDownIcon />
                  </span>
                  {menu.isOpen && (
                    <ul className=" mob-nav-dropdown">
                      {menu.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <Link to={item.url}>{item.label}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
              <li className="demo-btn">
                <a href="/request-demo" className="demo-link">
                  Request a Demo
                </a>
                <a href="/login" className="nav-login">
                  <svg
                    data-bbox="0 0 50 50"
                    data-type="shape"
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 50 50"
                    fill="#fff"
                  >
                    <g>
                      <path
                        d="M25 48.077c-5.924 0-11.31-2.252-15.396-5.921 2.254-5.362 7.492-8.267 15.373-8.267 7.889 0 13.139 3.044 15.408 8.418-4.084 3.659-9.471 5.77-15.385 5.77m.278-35.3c4.927 0 8.611 3.812 8.611 8.878 0 5.21-3.875 9.456-8.611 9.456s-8.611-4.246-8.611-9.456c0-5.066 3.684-8.878 8.611-8.878M25 0C11.193 0 0 11.193 0 25c0 .915.056 1.816.152 2.705.032.295.091.581.133.873.085.589.173 1.176.298 1.751.073.338.169.665.256.997.135.515.273 1.027.439 1.529.114.342.243.675.37 1.01.18.476.369.945.577 1.406.149.331.308.657.472.98.225.446.463.883.714 1.313.182.312.365.619.56.922.272.423.56.832.856 1.237.207.284.41.568.629.841.325.408.671.796 1.02 1.182.22.244.432.494.662.728.405.415.833.801 1.265 1.186.173.154.329.325.507.475l.004-.011A24.886 24.886 0 0 0 25 50a24.881 24.881 0 0 0 16.069-5.861.126.126 0 0 1 .003.01c.172-.144.324-.309.49-.458.442-.392.88-.787 1.293-1.209.228-.232.437-.479.655-.72.352-.389.701-.78 1.028-1.191.218-.272.421-.556.627-.838.297-.405.587-.816.859-1.24a26.104 26.104 0 0 0 1.748-3.216c.208-.461.398-.93.579-1.406.127-.336.256-.669.369-1.012.167-.502.305-1.014.44-1.53.087-.332.183-.659.256-.996.126-.576.214-1.164.299-1.754.042-.292.101-.577.133-.872.095-.89.152-1.791.152-2.707C50 11.193 38.807 0 25 0"
                        fill="#005CD1"
                      ></path>
                    </g>
                  </svg>
                  <span>Login</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      {/* secondary Hero section */}
      <div className="bg-overlay sec-hero banner-pt terms-hero">
        <div className="container-new">
          <h3>LEGAL</h3>
          <h2 data-aos="fade-in" data-aos-duration="1000">
            Terms and Conditions
          </h2>
          <p>
          JANUARY 2024
          </p>
        </div>
      </div>

      {/* text Section  */}

      <div className="definitions-section bg-white">
        <div className="container-new">
          <div className="def-txt" data-aos="fade-up" data-aos-duration="1000">
            <p>
              The content of the pages of this website is for your general
              information and use only. It is subject to change without notice.
            </p>

            <p>
              Commentary and other materials posted on our website are not
              intended to amount to advice on which reliance should be placed.
              We therefore disclaim all liability and responsibility arising
              from any reliance placed on such materials by any visitor to our
              website, or by anyone who may be informed of any of its contents.
            </p>

            <p>
              Your use of any information or materials on this website is
              entirely at your own risk, for which we shall not be liable. It
              shall be your own responsibility to ensure that any products,
              services or information available through this website meet your
              specific requirements.
            </p>

            <h4>Intellectual Property and Copyright</h4>

            <p>
              We are the owner or licensee of all intellectual property rights
              in our website, save where expressly stated otherwise, and in the
              material published on it. This material includes, but is not
              limited to, the design, layout, look, appearance and graphics.
              Reproduction is prohibited other than in accordance with the
              copyright notice, which forms part of these terms and conditions.
            </p>

            <p>
              This website and its content is copyright of PermitOne Software
              Pty Ltd – All rights reserved.
            </p>

            <p>
              Any redistribution or reproduction of part or all of the contents
              of this website in any form is prohibited other than the
              following:
            </p>

            <ul>
              <li>
                You may print or download to a local hard disk extracts for your
                personal and non-commercial use only;
              </li>

              <li>
                You may copy the content to individual third parties for their
                personal use, but only if you acknowledge the website as the
                source of the material;
              </li>

              <li>
                You may not, except with our express written permission,
                distribute or commercially exploit the content. Nor may you
                transmit it or store it in any other website or other form of
                electronic retrieval system.
              </li>

              <li>
                You must not modify the paper or digital copies of any materials
                you have printed off or downloaded in any way, and you must not
                use any illustrations, photographs, video or audio sequences or
                any graphics separately from any accompanying text.
              </li>

              <li>
                If you print off, copy or download any part of our website in
                breach of these terms of use, your right to use our website will
                cease immediately and you must, at our option, return or destroy
                any copies of the materials you have made.
              </li>
            </ul>

            <p>
              . You may not create a link to this website from another website
              or document without the prior written consent of PermitOne
              Software Pty Ltd.
            </p>

            <h4>Third Party Sites</h4>
            <p>
              From time to time this website may also include links to other
              websites. These links are provided for information purposes only.
              They do not signify that we endorse the website(s). We have no
              control over the contents of these websites or resources and
              accept no responsibility for them or for any loss or damage that
              may arise from your use of them.
            </p>

            <h4>Access to our Website</h4>
            <p>
              Access to our website is permitted on a temporary basis, and we
              reserve the right to withdraw or amend the service we provide on
              our website without notice (see below). We will not be liable if
              for any reason our website is unavailable at any time or for any
              period.
            </p>

            <p>
              Access to certain areas of our website may be restricted from time
              to time. We reserve the right to restrict access to any areas of
              our website, or indeed our whole website, at our discretion.
            </p>

            <p>
              If we provide you with a user ID and password (or any other piece
              of information as part of our security procedures) to enable you
              to access restricted areas of our website or other content or
              services, it is your responsibility to ensure that that user ID,
              password and other information is kept confidential.
            </p>

            <p>
              We reserve the right to disable your user ID and password at our
              sole discretion without notice or explanation.
            </p>

            <p>
              You are responsible for making all arrangements necessary for you
              to have access to our website. You are also responsible for
              ensuring that all persons who access our website through your
              internet connection are aware of these terms, and that they comply
              with them.
            </p>

            <p>
              We have provided our clients with user ID and passwords for those
              of their employees that they have informed us require access to
              the restricted customer log-in areas of our website. We shall not
              be liable for any loss or damage suffered by our clients or any
              third party due to any individual employees’ use of the website or
              any breach of section 4 paragraph III or use of the website by any
              unauthorised personnel.
            </p>

            <h4>Acceptable Use</h4>

            <p>
              You must not use our website in any way that causes, or may cause,
              damage to the website or impairment of the availability or
              accessibility of the website; or in any way which is unlawful,
              illegal, fraudulent or harmful, or in connection with any
              unlawful, illegal, fraudulent or harmful purpose or activity.
            </p>

            <p>
              You must not use our website to copy, store, host, transmit, send,
              use, publish or distribute any material which consists of (or is
              linked to) any spyware, computer virus, Trojan horse, worm,
              keystroke logger, logic bombs, rootkit or other malicious or
              technologically harmful computer software. By breaching this
              provision, you would commit a criminal offence under the Computer
              Misuse Act 1990. We will report any such breach to the relevant
              law enforcement authorities and we will co-operate with those
              authorities by disclosing your identity to them. In the event of
              such a breach, your right to use our website will cease
              immediately.
            </p>

            <p>
              We will not be liable for any loss or damage caused by a
              distributed denial-of-service attack, viruses or other
              technologically harmful material that may infect your computer
              equipment, computer programs, data or other proprietary material
              due to your use of our website or to your downloading of any
              material posted on it, or on any website linked to it.
            </p>

            <p>
              You must not conduct any systematic or automated data collection
              activities (including without limitation scraping, data mining,
              data extraction and data harvesting) on or in relation to our
              website without our express written consent.
            </p>

            <p>
              You must not use our website to transmit or send unsolicited
              commercial communications
            </p>

            <p>
              You must not use our website for any purposes related to marketing
              without our express written consent.
            </p>

            <p>
              Unauthorised use of this website may give rise to a claim for
              damages and/or be a criminal offence.
            </p>
            <h4>Information about you and your visits to PermitOne website</h4>

            <p>
              We process information about you in accordance with our privacy
              statement . By using our website, you consent to such processing
              and you warrant that all data provided by you is accurate.
            </p>

            <h4>Transactions Concluded Through PermitOne</h4>
            <p>
              Contracts for the supply of PermitOne formed through our website
              or as a result of visits made by you are governed by our terms and
              conditions of supply.
            </p>

            <h4>Limitations of Liability</h4>
            <p>
              Neither we nor any third parties provide any warranty or guarantee
              as to the accuracy, timeliness, performance, completeness or
              suitability of the information and materials found or offered on
              this website for any particular purpose. You acknowledge that such
              information and materials may contain inaccuracies or errors. To
              the extent permitted by law, we, other members of our group of
              companies and third parties connected to us hereby expressly
              exclude:
            </p>

            <p>
              All conditions, warranties and other terms which might otherwise
              be implied by statute, common law or the law of equity; and All
              liability for direct, indirect or consequential loss or damage
              incurred by any user in connection with our website or in
              connection with the use, inability to use, or results of the use
              of our website, any websites linked to it and any materials posted
              on it, including,
            </p>

            <ul>
              <li>loss of income or revenue;</li>
              <li>loss of business;</li>
              <li>loss of profits or contracts;</li>
              <li>loss of anticipated savings;</li>
              <li>loss of data or information;</li>
              <li>loss of goodwill;</li>
              <li>
                damage to reputation; andwasted management or office time,
              </li>
            </ul>

            <p>
              whether in tort (including negligence), breach of contract or
              otherwise, even if foreseeable.
            </p>
            <p>
              Nothing in these terms of use shall limit or exclude our liability
              for death or personal injury arising from our negligence, nor our
              liability for fraudulent misrepresentation or misrepresentation as
              to a fundamental matter, nor any other liability which cannot be
              excluded or limited under applicable law.
            </p>

            <p>
              Where a Force Majeure Event gives rise to a failure or delay in us
              performing our obligations under these terms and conditions, those
              obligations will be suspended for the duration of the Force
              Majeure Event.
            </p>

            <p>
              For the purposes of this paragraph a “Force Majeure Event” means
              any event beyond a party’s reasonable control, which by its nature
              could not have been foreseen, or, if it could have been foreseen,
              was unavoidable, including strikes, lock outs or other industrial
              disputes (whether involving its own workforce or a third party’s),
              failure of energy sources or transport network, acts of God, war,
              terrorism, riot, civil commotion, interference by civil or
              military authorities, national or international calamity, armed
              conflict, malicious damage, breakdown of plant or machinery,
              hacker attacks or virus or other malicious software attacks or
              infections, problems with the internet, part of the internet or
              any third party internet service provider, nuclear, chemical or
              biological contamination, sonic boom, explosions, collapse of
              building structures, fires, floods, storms, earthquakes, loss at
              sea, epidemics or similar events, natural disasters or extreme
              adverse weather conditions, or default of suppliers or
              subcontractors
            </p>

            <p>
              Your use of any information or materials on this website is
              entirely at your own risk, for which we shall not be liable. It
              shall be your own responsibility to ensure that any products,
              services or information available through this website meet your
              specific requirements.
            </p>

            <h4>Breaches of these Terms and Conditions</h4>

            <p>
              Without prejudice to our other rights under these terms and
              conditions, if you breach these terms and conditions in any way,
              we may take such action as we deem appropriate to deal with the
              breach, including suspending your access to the website,
              prohibiting you from accessing the website, blocking computers
              using your IP address from accessing the website, contacting your
              internet service provider to request that they block your access
              to the website and/or bringing court proceedings against you.
            </p>

            <h4>Variation</h4>
            <p>
              We may revise these terms and conditions from time-to-time.
              Revised terms and conditions will apply to the use of our website
              from the date of the publication of the revised terms and
              conditions on our website. Please check this page regularly to
              ensure you are familiar with the current version. Some of the
              provisions contained in these terms of use may also be superseded
              by provisions or notices published elsewhere on our website
            </p>

            <h4>verability</h4>
            <p>
              If a provision of these terms and conditions is determined by any
              court or other competent authority to be unlawful and/or
              unenforceable, the other provisions will continue in effect. If
              any unlawful and/or unenforceable provision would be lawful or
              enforceable if part of it were deleted, that part will be deemed
              to be deleted, and the rest of the provision will continue in
              effect.
            </p>

            <h4>Exclusion of Third Party Rights</h4>
            <p>
              These terms and conditions are for the benefit of you and us, and
              are not intended to benefit any third party or be enforceable by
              any third party. The exercise of our and your rights in relation
              to these terms and conditions is not subject to the consent of any
              third party.
            </p>

            <h4>General Disclaimer</h4>
            <p>
              All images and photographs on this Website are for illustrative
              purposes only.
            </p>

            <h4>Entire Agreement</h4>

            <p>
              These terms and conditions, together with our privacy policy,
              constitute the entire agreement and understanding between you and
              us in relation to the matters dealt with in them and supersedes,
              cancels and nullifies any previous agreement, understanding,
              undertaking, statement, representation, warranty or arrangement
              (in each case whether oral or written) between you and us relating
              to such matters.
            </p>

            <p>
              Each of the parties acknowledges and agrees that in entering into
              these terms and conditions it has not relied on and, subject to
              paragraph IV of this section, shall have no remedy (whether in
              equity, contract, tort, in respect of any agreement, statement,
              representation, warranty, undertaking or understanding (whether
              negligently or innocently made) which is not expressly set out in
              these terms and conditions.
            </p>

            <p>
              {" "}
              Subject to paragraph IV of this section the only remedy available
              to either party in respect of any agreement, statement,
              representation, warranty, undertaking or understanding which is
              contained in these terms and conditions shall be damages for
              breach of contract under these terms and conditions and neither
              party shall have the right to rescind or to claim damages for
              negligent or innocent misrepresentation in relation to these
              terms.
            </p>

            <h4>Our Details</h4>
            <p>
              The full name of our company is PermitOne Software Pty Ltd. You
              can contact us by email at{" "}
              <a href="mailto:support@permitoneapp.com">
                support@permitoneapp.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      {/* Footer Section  */}
      <footer className="darkblue-bg">
        <div className="container-new footer-flex">
          <div className="footer-links">
            <a href="/" className="footer-logo-new">
              <img src={FooterLogo} alt="FooterLogo" />
            </a>
            <div className="footer-social mob-hide">
              <ul className="social-links">
                <li>
                  <a
                    href=" https://www.instagram.com/permit.one/"
                    target="_blank"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br></br>SOFTWARE PTY LTD
              </p>
            </div>
          </div>
          <div className="footer-links">
            <h4>SOLUTION</h4>
            <ul>
              <li>
                <Link to="/permit-management">permit management</Link>
              </li>
              <li>
                <Link to="/communication-portal">Communication portal</Link>
              </li>
              <li>
                <Link to="/document-control">document Control</Link>
              </li>
              <li>
                <Link to="/reporting-and-analytics">Reporting & Analytics</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>RESOURCES</h4>
            <ul>
              <li>
                <Link to="/request-demo">Request a demo</Link>
              </li>
              <li>
                <Link to="/templates">TEMPLATES</Link>
              </li>
              <li>
                <Link to="/blog">News & Articles</Link>
              </li>
              <li>
                <Link to="/api-integration">API Integration</Link>
              </li>
            </ul>
          </div>
          <div className="footer-links">
            <h4>COMPANY</h4>
            <ul>
              <li>
                <Link to="/our-team">Our Team</Link>
              </li>
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-conditions">Terms & Conditions</Link>
              </li>
            </ul>
            <div className="footer-social desk-hide">
              <ul className="social-links">
                <li>
                  <a
                    href="https://www.instagram.com/permit.one/"
                    target="_blank"
                  >
                    <img src={instgram} alt="instgram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/permitone/"
                    target="_blank"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </a>
                </li>
              </ul>
              <p>
                COPYRIGHT © 2024 · PERMITONE <br></br>SOFTWARE PTY LTD
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
