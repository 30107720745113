import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import CloseIcon from "@mui/icons-material/Close";
import Iconify from "@stories/atoms/Iconify/Iconify";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { PdfDownlodDialog } from "../PdfDownlodDialog/PdfDownlodDialog";
import { FileGenerationStatus } from "permit-one-common/src/entities/fileGeneration";

interface ConditionExportProps {
  loading: boolean;
  open: boolean;
  project: ProjectLineItem;
  permit: PermitLineItem;
  downloadStepPDF: FileGenerationStatus;
  PDFDownloadProgress: number;
  getPdfReport: (
    projectId: string,
    permitId: string,
    reportName: string
  ) => Promise<void>;
  setOpen: (open: boolean) => void;
  getExcelReport: (
    projectId: string,
    permitId: string,
    reportName: string
  ) => Promise<void>;

  downloadStepExcel: FileGenerationStatus;
  excelDownloadProgress: number;
  getFullReportInfo: (
    projectId: string,
    permitId: string,
    zipFileTitle: string
  ) => Promise<void>;
  downloadStepAllFiles: FileGenerationStatus;
  allFilesDownloadProgress: number;
}
export default function ConditionExport({
  loading,
  open,
  project,
  permit,
  setOpen,
  getPdfReport,
  downloadStepPDF,
  PDFDownloadProgress,
  getExcelReport,
  downloadStepExcel,
  excelDownloadProgress,
  getFullReportInfo,
  downloadStepAllFiles,
  allFilesDownloadProgress,
}: ConditionExportProps) {
  const handleDownloadChecklist = async () => {
    const reportName = `${project.projectName}-${permit.permitName}.zip`;
    await getFullReportInfo(project.id, permit.id, reportName);
  };

  const handleDownloadCSV = async () => {
    const reportName = `${project.projectName}-${permit.permitName}.csv`;
    await getExcelReport(project.id, permit.id, reportName);
  };

  const handleDownloadPDF = async () => {
    const reportName = `${project.projectName}-${permit.permitName}.pdf`;
    await getPdfReport(project.id, permit.id, reportName);
  };

  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      className="bgBlueOverlay"
      maxWidth={"md"}
    >
      <DialogTitle>Export Checklist</DialogTitle>
      <DialogContent dividers>
        <Grid
          item
          md={12}
          sx={{
            margin: "0",
            display: "flex",
            padding: "20px 30px 30px",
            justifyContent: "center",
            gap: "30px",
          }}
        >
          <IconButton
            className="closeBtn"
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 14,
              top: 11,
              color: "#fff",
              background: "#000",
              "&:hover": {
                background: "#0054D1",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          <StyledButton
            loading={false}
            variant="contained"
            disabled={loading}
            onClick={handleDownloadPDF}
            startIcon={<Iconify icon="grommet-icons:document-pdf" />}
          >
            {"Export Checklist PDF"}
          </StyledButton>
          <StyledButton
            loading={false}
            variant="contained"
            disabled={loading}
            onClick={handleDownloadCSV}
            startIcon={<Iconify icon="grommet-icons:document-csv" />}
          >
            {"Export Checklist CSV"}
          </StyledButton>
          <StyledButton
            loading={false}
            variant="contained"
            disabled={loading}
            onClick={handleDownloadChecklist}
            startIcon={<Iconify icon="grommet-icons:document-zip" />}
          >
            {"Download All Files"}
          </StyledButton>
        </Grid>
      </DialogContent>
      <PdfDownlodDialog
        downloadStep={downloadStepAllFiles}
        total={allFilesDownloadProgress}
        title={"Exporting All Files"}
      />
      <PdfDownlodDialog
        downloadStep={downloadStepPDF}
        total={PDFDownloadProgress}
        title={"Exporting Checklist PDF"}
      />
      <PdfDownlodDialog
        downloadStep={downloadStepExcel}
        total={excelDownloadProgress}
        title={"Exporting Checklist CSV"}
      />
    </Dialog>
  );
}
