import Blog1 from "./blog1.jpg";
import Blog2 from "./blog2.webp";
import Blog3 from "./blog3.jpg";


const styles = {
  display: "contents",
  border: "2px solid black",
  maxWidth: "100%",
};
const imgStyle = {
  width: "-webkit-fill-available",
};

export function Blog1Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog1} />
    </div>
  );
}
export function Blog2Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog2} />
    </div>
  );
}
export function Blog3Component() {
  return (
    <div style={styles}>
      <img style={imgStyle} src={Blog3} />
    </div>
  );
}

