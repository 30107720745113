import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@mui/material/Button";
import {
  ConditionDocumentLineItem,
  createDefaultConditionDocument,
} from "permit-one-common/src/interfaces/conditionDocument";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { DialogContentText, Stack } from "@mui/material";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { useRef, useState } from "react";
import { UploadStatus, uploadFile } from "@hooks/utils/useUpload";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { ToastOptions, toast } from "react-toastify";
import { SelectedDocumentsRow } from "./SelectedDocumentsRow";
import { UploadModal } from "./UploadModal";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";

interface ConditionDocumentFormProps {
  project: ProjectLineItem;
  permit?: PermitLineItem;
  condition?: ConditionLineItem;
  userProfile: ProfileLineItem;
  documentCount: number;
  documents: ConditionDocumentLineItem[];
  deleteDocument: (document: ConditionDocumentLineItem) => void;
  createDocuments: (documents: ConditionDocumentLineItem[]) => void;
  updateDocuments: (documents: ConditionDocumentLineItem[]) => void;
  handleSubmit: (permit: ConditionDocumentLineItem[]) => void;
  handleClose: () => void;
}

export const ConditionDocumentForm = ({
  project,
  permit,
  condition,
  userProfile,
  documentCount,
  documents,
  deleteDocument,
  createDocuments,
  updateDocuments,
  handleSubmit,
  handleClose,
}: ConditionDocumentFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    UploadStatus.Idle
  );
  const [currentUploadFile, setCurrentUploadFile] = useState<string>();
  const [uploadedIndice, setUploadedIndices] = useState<number[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const validationSchema = yup.object({});
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: validationSchema,
    onSubmit: async () => {
      if (documents.length > 0) {
        await handleSubmit(documents);
      }
    },
  });

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleSelectFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      project &&
      e.currentTarget &&
      e.currentTarget?.files &&
      e.currentTarget?.files.length > 0
    ) {
      const documents: ConditionDocumentLineItem[] = [];
      const files = Array.from(e.currentTarget.files);
      for (let i = 0; i < files.length; i++) {
        console.log(i);
        const currentFile = files[i];
        setUploadProgress(0);

        const fileKey = `documents/${project?.id}/${condition?.id || 0}/${
          currentFile.name
        }`;
        await uploadFile(
          fileKey,
          currentFile,
          (progress: any) => {
            const total = (progress.loaded / progress.total) * 100;
            setUploadProgress(total);
          },
          (error: any) => {
            console.log(error);
            setUploadProgress(0);
            toast("Upload failed - something went wrong!", {
              type: "error",
            } as ToastOptions);
          }
        );

        console.log(e?.currentTarget?.files);
        const newDocument = createDefaultConditionDocument(
          project.id,
          currentFile,
          userProfile.id,
          `${userProfile.firstName} ${userProfile.lastName}`,
          documentCount + i + documents.length,
          project,
          permit,
          condition
        );

        documents.push(newDocument);
        setUploadedIndices([...uploadedIndice, i]);
      }

      setCurrentUploadFile(undefined);
      setUploadStatus(UploadStatus.Saving);
      await createDocuments(documents);
      setUploadStatus(UploadStatus.Idle);
    }
  };

  const formDisabled =
    formik.isSubmitting || uploadStatus !== UploadStatus.Idle;
  return (
    <>
      <DialogContentText
        mt={2}
        sx={{
          padding: "0 0 10px",
          fontSize: "14px",
          lineHeight: "16px",
          color: "black",
        }}
      >
        Select one or many files to upload to the document register
      </DialogContentText>
      <Grid container sx={{ padding: "0 " }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            padding: "0 0 20px",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "flex-end",
          }}
        >
          <form>
            <Button
              // className="gray-hover-button"
              variant="contained"
              disabled={formDisabled}
              onClick={onFileInput}
              // sx={{
              //   background: "transparent !important",
              //   border: "1px solid rgba(145, 158, 171, 0.32) !important",
              //   color: "black !important",
              //   gap: "10px",
              //   "&:hover": {
              //     borderColor: "black",
              //     backgroundColor: "#F2F2F2 !important",
              //   },
              //   padding: "7px 10px",
              // }}
            >
              <input
                hidden
                ref={fileInput}
                accept="*/*"
                multiple
                type="file"
                onChange={handleSelectFiles}
              />
              <CloudUploadIcon sx={{ marginRight: "10px" }} /> Select Documents
            </Button>
          </form>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid container sx={{ padding: "0 " }}>
          <Grid item xs={12} md={12} sx={{ padding: "0 0 20px" }}>
            <SelectedDocumentsRow
              project={project}
              documentCount={documentCount + documents.length}
              conditionDocuments={documents}
              removeFile={deleteDocument}
              updateDocuments={updateDocuments}
            />
          </Grid>
          <Grid
            item
            sm={12}
            md={12}
            sx={{
              display: "flex !important",
              justifyContent: "right !important;",
            }}
          >
            <Stack direction={"row"} spacing={2}>
              <StyledButton
                loading={false}
                disabled={formDisabled || documents.length == 0}
                color="primary"
                variant="contained"
                sx={{ cursor: "pointer !important" }}
                onClick={handleClose}
              >
                Exit
              </StyledButton>
              <StyledButton
                loading={false}
                disabled={formDisabled || documents.length == 0}
                color="primary"
                variant="contained"
                type="submit"
                sx={{ cursor: "pointer !important" }}
              >
                Continue
              </StyledButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
      <UploadModal
        total={uploadProgress}
        uploadStatus={uploadStatus}
        currentDocumentName={currentUploadFile}
      />
    </>
  );
};
