import { Box, Stack, Typography } from "@mui/material";
import { DefaultExtensionType, FileIcon, defaultStyles } from "react-file-icon";

export const fileTypeToIcon = (fileUrl: string) => {
  const fileName = fileUrl.split("/").pop();
  if (!fileName) {
    return null;
  }
  const fileExtension = fileName.split(".").pop();
  if (fileExtension) {
    return (
      <Box sx={{ maxWidth: "100px" }}>
        <Stack direction="row" spacing={2}>
          <Box>
            <FileIcon
              radius={2}
              labelColor={"red"}
              extension={fileExtension}
              {...defaultStyles[fileExtension as DefaultExtensionType]}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle2" sx={{ display: "inline-block" }}>
              {fileName}
            </Typography>
          </Box>
        </Stack>
      </Box>
    );
  }
  return <></>;
};
