import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import { useTable } from "@hooks/utils/useTable";
import { Grid } from "@mui/material";
import ConditionTableToolbar from "@stories/molecules/ListTable/ConditionTableToolbar";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";
import { ListConditionsTabs } from "@stories/molecules/ListConditionsTabs/ListConditionsTabs";
import { ConditionStatus } from "permit-one-common/src/entities/condition";
import { useEffect, useState } from "react";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { OrganisationLineItem } from "permit-one-common/src/interfaces/organisation";
import EditableTable from "@stories/atoms/EditableTable/EditableTable";

interface ConditionTableProps {
  loading: boolean;
  profile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  project?: ProjectLineItem;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  handleOpenConditionEditModal: () => void;
  setConditions: (conditions: ConditionLineItem[]) => void;
  handleImportConditons: (conditions: ConditionLineItem[]) => Promise<void>;
  handleViewCondition: (condition: ConditionLineItem) => void;
  handleEditCondition: (condition: ConditionLineItem) => void;
  handleDeleteCondition: (condition: ConditionLineItem) => void;
  handleShareConditions: (
    email: string,
    firstName: string,
    lastName: string,
    projectId: string,
    permitId: string
  ) => Promise<void>;
  showToolbar?: boolean;
}
export const ConditionTable = ({
  loading,
  profile,
  userOrganisation,
  project,
  permit,
  conditions,
  showToolbar = true,
  setConditions,
  handleOpenConditionEditModal,
  handleImportConditons,
  handleViewCondition,
  handleEditCondition,
  handleDeleteCondition,
  handleShareConditions,
}: ConditionTableProps) => {
  const params = new URLSearchParams(location.search);
  const initialConditionStatus = parseInt(params.get("status") || "0");

  const {
    selected,
    filterName,
    order,
    orderBy,
    handleRequestSort,
    handleSelectAllClick,
    handleFilterByName,
  } = useTable();

  const [selectedConditionStatus, setSelectedConditionStatus] =
    useState<number>(initialConditionStatus);

  useEffect(() => {
    setSelectedConditionStatus(initialConditionStatus);
  }, [initialConditionStatus]);

  const handleConditionStatusChange = (newValue: number) => {
    setSelectedConditionStatus(newValue);
  };

  let filteredConditions =
    filterName.length > 0
      ? conditions.filter(
          (t) =>
            t?.conditionName?.toLowerCase() === filterName?.toLocaleLowerCase()
        )
      : conditions;

  filteredConditions = filteredConditions.filter((c) => {
    switch (selectedConditionStatus) {
      default:
      case 0:
        return true;
      case 1:
        return (
          c.status === ConditionStatus.Assigned ||
          c.status === ConditionStatus.InReview ||
          c.status === ConditionStatus.Rejected
        );
      case 2:
        return c.status === ConditionStatus.Overdue;
      case 3:
        return c.status === ConditionStatus.InReview;
      case 4:
        return c.status === ConditionStatus.Approved;
    }
  });

  let sortedConditions = filteredConditions.sort((a, b) => {
    switch (orderBy) {
      case 0:
        return (a?.conditionDetails?.toLocaleLowerCase() || "") <
          (b?.conditionDetails?.toLocaleLowerCase() || "")
          ? -1
          : 1;
      case 1:
        return (a?.conditionComments?.toLocaleLowerCase() || "") <
          (b?.conditionComments?.toLocaleLowerCase() || "")
          ? -1
          : 1;
      case 2:
        // if (a.assignees && b.assignees) {
        //   return a.assignees < b.assignees ? -1 : 1;
        // }
        // if (!a.assignees && b.assignees) {
        //   return -1;
        // }
        // if (a.assignees && !b.assignees) {
        //   return 1;
        // }
        return 0;
      case 3:
        return a.discipline < b.discipline ? -1 : 1;
      case 4:
        return a.dueDate.getTime() < b.dueDate.getTime() ? -1 : 1;
      case 5:
        return a.status < b.status ? -1 : 1;
      default:
        return 0;
    }
  });
  sortedConditions =
    order === "asc" ? sortedConditions : sortedConditions.reverse();

  const isNotFound = !sortedConditions.length && !!filterName;

  const isProjectOwner =
    userOrganisation && project && project.owner?.id === userOrganisation.id;
  const isPermitManager =
    profile &&
    permit &&
    permit.managers.map((pm) => pm.id).includes(profile.id);

  const canEdit = isProjectOwner || isPermitManager;
  return (
    <>
      {showToolbar ? (
        <ConditionTableToolbar
          loading={loading}
          profile={profile}
          userOrganisation={userOrganisation}
          project={project}
          permit={permit}
          handleOpenConditionEditModal={handleOpenConditionEditModal}
          filterName={filterName}
          handleImportConditons={handleImportConditons}
          onFilter={handleFilterByName}
          handleShareConditions={handleShareConditions}
          conditions={conditions}
        />
      ) : null}
      <Grid item md={12} sx={{ padding: "30px 0px 20px" }}>
        <ListConditionsTabs
          loading={loading}
          value={selectedConditionStatus}
          handleChange={handleConditionStatusChange}
        />
      </Grid>
      <EditableTable
        loading={loading}
        permit={permit}
        conditions={conditions}
        setData={setConditions}
        handleOpenConditionEditModal={handleOpenConditionEditModal}
        handleEditCondition={handleEditCondition}
        handleDeleteCondition={handleDeleteCondition}
      />
    </>
  );
};
