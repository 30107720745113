import { useTheme } from "@mui/material/styles";
import { Grid, Stack, Typography } from "@mui/material";
import { gridSpacing } from "constant";
import { MailBodyProps } from "./MailProps";
import Parser from "html-react-parser";
import AttachmentCard from "@stories/ui-component/cards/AttachmentCard";
import AttachmentTwoToneIcon from "@mui/icons-material/AttachmentTwoTone";

const MessageBody = ({ data }: MailBodyProps) => {
  const theme = useTheme();

  return (
    <Grid item xs={12} >
   

          <Grid
            item
            
            sx={{
              background:'#E9F0F5',
              padding:'10px 15px',
              borderRadius:'12px',
              margin:'20px 0 20px ',
              minWidth:'200px !important',
              width:'100% !important',
              maxWidth: 'max-content',
            }}
            className="messageText"
          >
            <Typography sx={{fontSize:'16px',
                color:'black', margin:'0 !important'}}>
            {Parser(data.message)}
            </Typography>
          
          </Grid>
       
      
      {data?.attachments && (
        <Grid item xs={12}>
          <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
              <Stack direction="row" alignItems="center" spacing={1} sx={{padding:'10px 0'}}>
                <AttachmentTwoToneIcon fontSize="small" />
                <Typography variant="h5" sx={{fontSize:'14px !important'}}>
                  {data?.attachments.length}{" "}
                </Typography>
                <Typography variant="h6" sx={{fontSize:'14px !important'}}>Attachments</Typography>
              </Stack>
            </Grid>
            {data?.attachments.map((item, index) => (
              <Grid item md={5} key={index}>
                <AttachmentCard uri={item} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default MessageBody;
