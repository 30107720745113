// @mui
import { Order } from "@hooks/utils/useTable";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import {
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Typography,
  Skeleton,
  Checkbox,
} from "@mui/material";

interface ListTableHeadProps {
  rowCount: number;
  loading: boolean;
  headLabel: TableHeaderCell[];
  numSelected: number;
  showCheckbox?: boolean;
  order?: Order;
  orderBy?: number;
  onSelectAllClick: (event: any) => void;
  handleRequestSort?: (property: number) => void;
}

export default function ListTableHead({
  loading,
  showCheckbox = true,
  rowCount,
  headLabel,
  numSelected,
  order,
  orderBy,
  onSelectAllClick,
  handleRequestSort,
}: ListTableHeadProps) {
  return (
    <TableHead>
      {showCheckbox ? (
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
      ) : null}
      {headLabel.map((headCell: TableHeaderCell, index) => {
        const content = loading ? (
          <Skeleton animation="wave" sx={{ magin: "auto" }}>
            <Typography sx={{ magin: "auto" }}>{headCell.label}</Typography>
          </Skeleton>
        ) : (
          headCell.label
        );
        return (
          <TableCell key={headCell.id} align={"center"}>
            {headCell.static ? (
              content
            ) : (
              <TableSortLabel
                active={index === orderBy}
                direction={index === orderBy ? order : "asc"}
                onClick={
                  handleRequestSort
                    ? () => {
                        handleRequestSort(index);
                      }
                    : undefined
                }
              >
                {content}
              </TableSortLabel>
            )}
          </TableCell>
        );
      })}
    </TableHead>
  );
}
