"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.daysActive = exports.timeSince = exports.getDaysDiff = exports.getISOString = exports.getNextOccurrenceDate = exports.dateInfoToDate = exports.getCurrentAndPrevMonths = exports.formatReportDate = exports.getDayDiff = exports.getTIMESTAMPTZ = exports.getOffSet = exports.makeLocalTime = exports.monthNames = exports.dateSuffix = void 0;
var dateSuffix = function (d) {
    if (d > 3 && d < 21)
        return "th";
    switch (d % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};
exports.dateSuffix = dateSuffix;
exports.monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
var makeLocalTime = function (utcFromServer) {
    var localTime = new Date(utcFromServer);
    localTime.setMinutes(localTime.getMinutes() - (0, exports.getOffSet)());
    return localTime;
};
exports.makeLocalTime = makeLocalTime;
var getOffSet = function () {
    return new Date().getTimezoneOffset();
};
exports.getOffSet = getOffSet;
var getTIMESTAMPTZ = function (date) {
    var offset = -date.getTimezoneOffset() / 60;
    var sign = offset >= 0 ? "+" : "-";
    offset = Math.abs(offset);
    var offsetString = offset < 10 ? "0" + offset : offset;
    var TIMESTAMPTZ = date.toISOString();
    TIMESTAMPTZ = TIMESTAMPTZ.replace("T", " ");
    TIMESTAMPTZ = TIMESTAMPTZ.replace("Z", "000".concat(sign).concat(offsetString));
    return TIMESTAMPTZ;
};
exports.getTIMESTAMPTZ = getTIMESTAMPTZ;
var getDayDiff = function (a, b) {
    var diffTime = Math.abs(b.getTime() - a.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};
exports.getDayDiff = getDayDiff;
var formatReportDate = function (reportDate) {
    var day = reportDate.getDate();
    return "".concat(day).concat((0, exports.dateSuffix)(day), " ").concat(exports.monthNames[reportDate.getMonth()], " ").concat(reportDate.getFullYear());
};
exports.formatReportDate = formatReportDate;
var getCurrentAndPrevMonths = function (day, month, year) {
    var endDate = new Date(year, month, day);
    var startDate = new Date(year, month === 0 ? 11 : month - 1, day);
    return { endDate: endDate, startDate: startDate };
};
exports.getCurrentAndPrevMonths = getCurrentAndPrevMonths;
var dateInfoToDate = function (dateInfo, reportingDate) {
    return new Date(dateInfo.year, dateInfo.month, reportingDate);
};
exports.dateInfoToDate = dateInfoToDate;
var getNextOccurrenceDate = function (dayOfMonth) {
    // Get today's date
    var today = new Date();
    // Get the current month and year
    var month = today.getMonth();
    var year = today.getFullYear();
    // Get the next occurrence of the dayOfMonth
    var nextDate = new Date(year, month, dayOfMonth);
    if (nextDate < today) {
        // If the next occurrence has already passed this month,
        // add a month to the date to get the next occurrence.
        nextDate.setMonth(month + 1);
    }
    return nextDate;
};
exports.getNextOccurrenceDate = getNextOccurrenceDate;
var getISOString = function (date) {
    var d = new Date(date), year = "" + d.getFullYear();
    var month = "" + (d.getMonth() + 1), day = "" + d.getDate(), hours = "" + d.getHours(), minutes = "" + d.getMinutes(), seconds = "" + d.getSeconds();
    month = month.length < 2 ? "0" + month : month;
    day = day.length < 2 ? "0" + day : day;
    hours = hours.length < 2 ? "0" + hours : hours;
    minutes = minutes.length < 2 ? "0" + minutes : minutes;
    seconds = seconds.length < 2 ? "0" + seconds : seconds;
    return ([year, month, day].join("-") +
        "T" +
        [hours, minutes, seconds].join(":") +
        ".000");
};
exports.getISOString = getISOString;
var getDaysDiff = function (start, end) {
    var diff = Math.abs(end.getTime() - start.getTime());
    return Math.ceil(diff / (1000 * 3600 * 24));
};
exports.getDaysDiff = getDaysDiff;
var timeSince = function (date) {
    var seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
};
exports.timeSince = timeSince;
var daysActive = function (created) {
    var currentDate = new Date();
    var countDays = Math.floor((currentDate.getTime() - created.getTime()) / (1000 * 60 * 60 * 24)); //totalMilliseconds / dayMilliseconds
    return countDays;
};
exports.daysActive = daysActive;
