import { Skeleton, TextField, TextFieldProps } from "@mui/material";

type StyledTextFieldProps = TextFieldProps & {
  loading: boolean;
  maxLength?: number;
};
export const StyledTextField = (props: StyledTextFieldProps) => {
  const { loading, maxLength, ...otherProps } = props;
  if (loading) {
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        sx={{ margin: "auto" }}
        width={"100%"}
        height={50}
      >
        <TextField
          label="DEFAULT TEXT FIELD CONTENT - LOADING"
          margin="dense"
          fullWidth
        />
      </Skeleton>
    );
  }
  return (
    <TextField
      {...otherProps}
      margin="dense"
      inputProps={{
        maxLength: maxLength || 100,
      }}
      InputProps={{
        ...otherProps.InputProps,
      }}
      sx={{ border: "none !important" }}
    />
  );
};
