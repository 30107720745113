import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import MailDetails from "../Mail/MailDetails";
import MailInput from "../Mail/MailInput";
import MailEmpty from "../Mail/MailEmpty";
import MailDetailsSkeleton from "../Mail/MailDetailsSkeleton";
import { MailThreadLineItem } from "permit-one-common/src/interfaces/mail";
import MessageBody from "../Mail/MessageBody";
import MailInputSkeleton from "../Mail/MailInputSkeleton";
import { useEffect } from "react";
import {
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { useNavigate } from "react-router-dom";
import * as RouteHelper from "@utils/routes";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
interface MailViewProps {
  isLoading: boolean;
  profile?: ProfileLineItem;
  selectedMail: MailThreadLineItem;
  showRedirect: boolean;
  updateMailThread: (mail: MailThreadLineItem) => Promise<void>;
  handlOnBack: () => void;
}
export const MailView = ({
  isLoading,
  profile,
  selectedMail,
  showRedirect,
  updateMailThread,
  handlOnBack,
}: MailViewProps): JSX.Element => {
  const navigate = useNavigate();
  const submitMail = async (mail: MailThreadLineItem) => {
    await updateMailThread(mail);
  };

  useEffect(() => {
    if (selectedMail && profile) {
      const mail = selectedMail.topMail;
      if (
        mail.sender.id !== profile?.id &&
        !mail.readBy.includes(profile?.id)
      ) {
        selectedMail.mail.forEach((m) => {
          if (!m.readBy.includes(profile?.id)) {
            m.readBy.push(profile?.id);
          }
        });
        submitMail(selectedMail);
      }
    }
  }, [selectedMail]);

  const loading = isLoading;

  const orderedMessages = selectedMail?.mail.sort((a, b) => {
    return a.created.getTime() - b.created.getTime();
  });

  const handleRedirect = () => {
    navigate(
      RouteHelper.condition(
        selectedMail?.projectId,
        selectedMail?.permitId,
        selectedMail?.conditionId
      )
    );
  };
  return (
    <>
      {loading ? (
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Grid
            item
            sx={{ padding: "0px !important", alignItems: "center" }}
            md={6}
          >
            <Stack direction="row" spacing={1}>
              <Skeleton animation="wave" variant="circular">
                <IconButton
                  size="large"
                  onClick={handlOnBack}
                  sx={{
                    color: "black",
                    width: "auto",
                    height: "auto",
                    padding: "5px",
                  }}
                >
                  <ArrowCircleLeftOutlinedIcon sx={{ fontSize: "30px" }} />
                </IconButton>
              </Skeleton>
              <Skeleton animation="wave">
                <Typography
                  variant="h1"
                  component="div"
                  sx={{ fontSize: "28px !important", fontWeight: "700" }}
                >
                  {"Permit Name"}
                </Typography>
              </Skeleton>
            </Stack>
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              padding: "0 !important",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "right",
            }}
          >
            {showRedirect ? (
              <StyledButton
                loading={loading}
                className="css-kok4z1"
                variant="contained"
                startIcon={<ExitToAppIcon />}
              >
                Go to Condition
              </StyledButton>
            ) : undefined}
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            sx={{
              margin: "0 0 40px",
              width: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Grid
              item
              sx={{ padding: "0px !important", alignItems: "center" }}
              md={6}
            >
              <Stack direction="row" spacing={1}>
                <Tooltip
                  TransitionComponent={Zoom}
                  placement="top"
                  title="Back"
                >
                  <IconButton
                    size="large"
                    onClick={handlOnBack}
                    sx={{
                      color: "black",
                      width: "auto",
                      height: "auto",
                      padding: "5px",
                    }}
                  >
                    <ArrowCircleLeftOutlinedIcon sx={{ fontSize: "30px" }} />
                  </IconButton>
                </Tooltip>
                <Typography
                  variant="h1"
                  component="div"
                  sx={{
                    fontSize: "28px !important",
                    fontWeight: "700",
                    paddingTop: "2px",
                  }}
                >
                  {`${selectedMail?.permitName} ${selectedMail?.conditionName}: ${selectedMail.subject}`}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                padding: "0 !important",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "right",
              }}
            >
              {showRedirect ? (
                <StyledButton
                  loading={loading}
                  className="css-kok4z1"
                  variant="contained"
                  startIcon={<ExitToAppIcon />}
                  onClick={handleRedirect}
                >
                  Go to Condition
                </StyledButton>
              ) : undefined}
            </Grid>
          </Grid>
        </>
      )}
      {loading ? <MailDetailsSkeleton /> : null}
      {!loading && selectedMail ? (
        <>
          <MailDetails
            isLoading={loading}
            exisitngMail={selectedMail.topMail}
          />
          <MessageBody data={selectedMail.topMail} />
        </>
      ) : null}
      {!loading && !selectedMail ? <MailEmpty /> : null}
      {!loading && selectedMail ? (
        <MailInput
          isLoading={isLoading}
          existingMail={selectedMail}
          profile={profile}
          submitMail={submitMail}
        />
      ) : null}
      {loading ? <MailInputSkeleton /> : null}
      {orderedMessages.reverse().map((m, i) => {
        return i === 0 ? null : (
          <>
            <MailDetails exisitngMail={m} isLoading={isLoading} />
            <MessageBody data={m} />
          </>
        );
      })}
    </>
  );
};
