import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { CommentTableRow } from "./CommentTableRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ConditionCommentLineItem } from "permit-one-common/src/interfaces/conditionComment";

interface CommentTableProps {
  conditionComments: ConditionCommentLineItem[];
  deleteComment: (comment: ConditionCommentLineItem) => void;
}

export const CommentTable = ({
  conditionComments,
  deleteComment,
}: CommentTableProps) => {
  return (
    <Table aria-label="sticky table" className="project-table popup-input">
      <TableHead>
        <TableRow>
          <TableCell sx={{ width: "70%" }}>Comment</TableCell>
          <TableCell sx={{ width: "20%" }}>By</TableCell>
          <TableCell sx={{ width: "10%" }}>Remove</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {conditionComments.map((conditionComment) => (
          <CommentTableRow
            key={conditionComment.id}
            conditionComment={conditionComment}
            deleteComment={deleteComment}
          />
        ))}
        {conditionComments.length === 0 ? (
          <NoRows colSpan={3} title={"comments"} />
        ) : null}
      </TableBody>
    </Table>
  );
};
