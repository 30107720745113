import { BasicLayout } from "@stories/layout/BasicLayout";
import * as RouteHelper from "@utils/routes";
import { LoginView } from "@views/Pages/Authenticate/LoginView";
import ConditionView from "@views/Pages/Condition/ConditionView";
import ListConditionsView from "@views/Pages/Condition/ListConditionsView";
import DashboardView from "@views/Pages/Dashboard/DashboardView";
import DirectoryView from "@views/Pages/Directory/DirectoryView";
import DocumentsView from "@views/Pages/Document/DocumentsView";
import { HomeView } from "@views/Pages/Home/HomeView";
import { PricingView } from "@views/Pages/Home/PricingView";
import PermitsView from "@views/Pages/Permit/PermitsView";
import TasksView from "@views/Pages/Task/TasksView";

import ListProjectsView from "@views/Pages/Project/ListProjectsView";
import { UsersView } from "@views/Pages/UsersView/UsersView";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import ProjectMailView from "@views/Pages/ProjectMail/ProjectMailView";
import { VerifyView } from "@views/Pages/Authenticate/VerifyView";
import { NoLayout } from "@stories/layout/NoLayout";
import { DemoView } from "@views/Pages/Home/DemoView";
import { SmartDirectoryView } from "@views/Pages/Home/SmartDirectoryView";
import { ApiIntegrationView } from "@views/Pages/Home/ApiIntegrationView";
import { ReportingAndAnalyticsView } from "@views/Pages/Home/ReportingAndAnalyticsView";
import { ImplementationView } from "@views/Pages/Home/ImplementationView";
import { BlogView } from "@views/Pages/Home/BlogView";
import { BlogDetail } from "@views/Pages/Home/BlogDetails";
import { PartnerView } from "@views/Pages/Home/PartnerView";
import { DefinitionsView } from "@views/Pages/Home/DefinitionsView";
import { WhoWeAreView } from "@views/Pages/Home/WhoWeAreView";
import { SupportView } from "@views/Pages/Home/SupportView";
import { PrivacyPolicyView } from "@views/Pages/Home/PrivacyPolicyView";
import { TermsConditionsView } from "@views/Pages/Home/TermsConditionsView";
import MainDashboardLayout from "@stories/layout/MainLayout";
import { UserAccountView } from "@views/Pages/Account/UserAccountView";
import { CreateAccountView } from "@views/Pages/Account/CreateAccountView";
import { NoProfileLayout } from "@stories/layout/NoProfileLayout";
import HomePageLayout from "@stories/layout/ProjectsLayout";
import { BusinessAccountView } from "@views/Pages/Account/BusinessAccountView";
import { browserStorage } from "permit-one-common/src/utils/browserStorage";
import { AcceptInviteView } from "@views/Pages/Account/AcceptInviteView";
import GlobalDirectoryView from "@views/Pages/GlobalDirectory/GlobalDirectory";
import AllConditionsView from "@views/Pages/AllConditions/AllConditionsView";
import PublicConditionsView from "@views/Pages/Condition/PublicConditionsView";
import PublicLayout from "@stories/layout/PublicLayout";

export interface RouteInfo {
  index?: boolean;
  path: string;
  component: React.FC<any>;
  layout: React.FC<any>;
}

const routesUnauthenticated: RouteInfo[] = [
  {
    index: true,
    path: RouteHelper.home,
    component: HomeView,
    layout: BasicLayout,
  },
  // {
  //   path: RouteHelper.signUp,
  //   component: SignUpView,
  //   layout: BasicLayout,
  // },
  {
    path: RouteHelper.login,
    component: LoginView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.verify,
    component: VerifyView,
    layout: NoLayout,
  },
  {
    path: RouteHelper.pricing,
    component: PricingView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.demo,
    component: DemoView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.partnership,
    component: PartnerView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.smartDirectory,
    component: SmartDirectoryView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.apiIntegration,
    component: ApiIntegrationView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.implementation,
    component: ImplementationView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.blog,
    component: BlogView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.blogDetail(":title"),
    component: BlogDetail,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.definitions,
    component: DefinitionsView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.whoWeare,
    component: WhoWeAreView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.support,
    component: SupportView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.reportingAndAnalytics,
    component: ReportingAndAnalyticsView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.privacyPolicy,
    component: PrivacyPolicyView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.termsConditions,
    component: TermsConditionsView,
    layout: BasicLayout,
  },
  {
    path: RouteHelper.conditionPublic(":accessId"),
    component: PublicConditionsView,
    layout: PublicLayout,
  },
];

const routesAuthenticatedNoProfile: RouteInfo[] = [
  {
    index: true,
    path: RouteHelper.createAccount,
    component: CreateAccountView,
    layout: NoProfileLayout,
  },
  {
    path: RouteHelper.verify,
    component: VerifyView,
    layout: NoLayout,
  },
];

const routesAuthenticatedNoProfileInvite: RouteInfo[] = [
  {
    index: true,
    path: RouteHelper.acceptInvite,
    component: AcceptInviteView,
    layout: NoProfileLayout,
  },
];

const routesAuthenticated: RouteInfo[] = [
  {
    path: RouteHelper.userAccount,
    component: UserAccountView,
    layout: HomePageLayout,
  },
  {
    path: RouteHelper.businessAccount,
    component: BusinessAccountView,
    layout: HomePageLayout,
  },
  {
    index: true,
    path: RouteHelper.listProjects,
    component: ListProjectsView,
    layout: HomePageLayout,
  },
  {
    index: true,
    path: RouteHelper.users,
    component: UsersView,
    layout: HomePageLayout,
  },
  {
    index: true,
    path: RouteHelper.overview(":projectId"),
    component: DashboardView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.directory(":projectId"),
    component: DirectoryView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.globalDirectory,
    component: GlobalDirectoryView,
    layout: HomePageLayout,
  },
  {
    path: RouteHelper.projectGlobalDirectory(":projectId"),
    component: GlobalDirectoryView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.documents(":projectId"),
    component: DocumentsView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.permits(":projectId"),
    component: PermitsView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.tasks(":projectId"),
    component: TasksView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.allConditions(":projectId"),
    component: AllConditionsView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.conditions(":projectId", ":permitId"),
    component: ListConditionsView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "properties"
    ),
    component: ConditionView,
    layout: MainDashboardLayout,
  },

  {
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "documents"
    ),
    component: ConditionView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "collaborators"
    ),
    component: ConditionView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "mail"
    ),
    component: ConditionView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.condition(
      ":projectId",
      ":permitId",
      ":conditionId",
      "event-log"
    ),
    component: ConditionView,
    layout: MainDashboardLayout,
  },
  {
    path: RouteHelper.inbox(":projectId"),
    component: ProjectMailView,
    layout: MainDashboardLayout,
  },
];

export const getRoutes = (loggedIn: boolean, profile?: ProfileLineItem) => {
  if (loggedIn && !profile) {
    const isInvite = browserStorage.getItem("isInvite") === "true";
    if (isInvite) {
      return routesAuthenticatedNoProfileInvite;
    }
    return routesAuthenticatedNoProfile;
  }
  if (loggedIn) {
    return routesAuthenticated;
  }

  return routesUnauthenticated;
};
