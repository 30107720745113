import { useTable } from "@hooks/utils/useTable";
import { TableHeaderCell } from "permit-one-common/src/interfaces/tables";
import { Table, TableBody, Box } from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import ListTableToolbar from "@stories/molecules/ListTable/ListTableToolbar";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { ProfileLineItem } from "permit-one-common/src/interfaces/profile";
import { UserTableRow } from "../UserTableRow/UserTableRow";
import { UserTableRowSkeleton } from "../UserTableRow/UserTableRowSkeleton";

interface UserTableProps {
  loading: boolean;
  profile: ProfileLineItem;
  profiles: ProfileLineItem[];
  handleOpen: () => void;
  handleDeleteUser: (profile: ProfileLineItem) => void;
  handleEditUser: (profile: ProfileLineItem) => void;
}

export const UserTable = ({
  loading,
  profile,
  profiles,
  handleOpen,
  handleDeleteUser,
  handleEditUser,
}: UserTableProps) => {
  const TABLE_HEAD: TableHeaderCell[] = [
    { id: "first-name", label: "First Name", alignRight: false },
    { id: "last-name", label: "Last Name", alignRight: false },
    { id: "email", label: "Email", alignRight: false },
    { id: "status", label: "Status", alignRight: false },
    { id: "" },
  ];

  const {
    selected,
    filterName,
    clearSelected,
    handleSelectAllClick,
    handleFilterByName,
  } = useTable();

  const filteredUsers =
    filterName.length > 0
      ? profiles.filter(
          (t) => t.firstName.toLowerCase() === filterName.toLocaleLowerCase()
        )
      : profiles;

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Box sx={{ paddingTop: "10px" }}>
        <ListTableToolbar
          loading={loading}
          handleOpen={handleOpen}
          buttonTitle={"Add User"}
          searchTitle="Search Users..."
          numSelected={selected.length}
          filterName={filterName}
          onFilter={handleFilterByName}
          clearSelected={clearSelected}
        />
      </Box>

      <Table stickyHeader aria-label="sticky table" sx={{ marginTop: "30px" }}>
        <ListTableHead
          headLabel={TABLE_HEAD}
          loading={loading}
          rowCount={profiles.length}
          numSelected={selected.length}
          showCheckbox={false}
          onSelectAllClick={(e: any) =>
            handleSelectAllClick(
              e,
              profiles.map((c) => c.id)
            )
          }
        />
        <TableBody>
          {!loading
            ? filteredUsers.map((row) => {
                return (
                  <UserTableRow
                    profile={row}
                    disabled={row.id === profile.id}
                    handleDeleteUser={handleDeleteUser}
                    handleEditUser={handleEditUser}
                  />
                );
              })
            : null}
          {loading
            ? Array.from({ length: 6 }, (_, index) => (
                <UserTableRowSkeleton key={index} />
              ))
            : null}
          {!loading && isNotFound && (
            <NoResultsFoundRow
              colSpan={TABLE_HEAD.length + 1}
              filterName={"filterName"}
            />
          )}

          {!loading && profiles.length === 0 ? (
            <NoRows colSpan={TABLE_HEAD.length + 1} title={"Users"} />
          ) : null}
        </TableBody>
      </Table>
    </>
  );
};
