import * as React from "react";
import {
  ConditionDocument,
  ConditionDocumentLineItem,
} from "permit-one-common/src/interfaces/conditionDocument";
import {
  decodeConditionDocument,
  encodeConditionDocument,
} from "permit-one-common/src/decoders/conditionDocument";
import { listApiData, postApiData, postBatchApiData } from "@hooks/utils/api";
import { getImageUrl } from "@hooks/utils/useUpload";

export const useDocument = (
  projectId?: string,
  permitId?: string,
  conditionId?: string
) => {
  const [documents, setDocuments] = React.useState<ConditionDocumentLineItem[]>(
    []
  );
  const [isDocumentLoading, setIsDocumentLoading] =
    React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);

  const listDocumentsForCondition = async (
    conditionId: string
  ): Promise<void> => {
    try {
      setIsDocumentLoading(true);
      const documentResult = await listApiData(
        "listConditionDocuments",
        "conditionDocument",
        conditionId
      );
      const documentLineItems = await Promise.all(
        documentResult.data.map((c) =>
          decodeConditionDocument(c as ConditionDocument, getImageUrl)
        )
      );
      console.log(documentLineItems);
      setDocuments(documentLineItems);
    } catch (e: any) {
      console.log(e);
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const listDocumentsForPermit = async (projectId: string): Promise<void> => {
    try {
      setIsDocumentLoading(true);

      const documentRes = await listApiData(
        "listDocumentsForPermit",
        "conditionDocument",
        projectId
      );
      const documentLineItems = await Promise.all(
        documentRes.data.map((c) =>
          decodeConditionDocument(c as ConditionDocument, getImageUrl)
        )
      );

      setDocuments(documentLineItems);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const listDocumentsForProject = async (projectId: string): Promise<void> => {
    try {
      setIsDocumentLoading(true);

      const documentRes = await listApiData(
        "listDocumentsForProject",
        "conditionDocument",
        projectId
      );
      const documentLineItems = await Promise.all(
        documentRes.data.map((c) =>
          decodeConditionDocument(c as ConditionDocument, getImageUrl)
        )
      );

      setDocuments(documentLineItems);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const createDocuments = async (
    documentsToCreate: ConditionDocumentLineItem[]
  ): Promise<void> => {
    try {
      setIsDocumentLoading(true);

      const encodedDocuments = documentsToCreate.map((d) =>
        encodeConditionDocument(d)
      );
      const response = await postBatchApiData(
        "createConditionDocuments",
        "conditionDocument",
        encodedDocuments
      );
      const decodedDocuments = await Promise.all(
        response.data.map((d) =>
          decodeConditionDocument(d as never as ConditionDocument, getImageUrl)
        )
      );

      setDocuments([...documents, ...decodedDocuments]);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const assignDocumentsToConditions = async (
    conditionDocumentsToAssign: ConditionDocumentLineItem[]
  ): Promise<void> => {
    try {
      setIsDocumentLoading(true);

      const encodedDocuments = conditionDocumentsToAssign.map((d) =>
        encodeConditionDocument(d)
      );
      await postBatchApiData(
        "upsertConditionDocumentConditions",
        "conditionDocument",
        encodedDocuments
      );
      setDocuments([...documents, ...conditionDocumentsToAssign]);
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const unassignDocumentsToConditions = async (
    conditionDocumentsToAssign: ConditionDocumentLineItem[]
  ): Promise<void> => {
    try {
      setIsDocumentLoading(true);

      const encodedDocuments = conditionDocumentsToAssign.map((d) =>
        encodeConditionDocument(d)
      );
      await postBatchApiData(
        "upsertConditionDocumentConditions",
        "conditionDocument",
        encodedDocuments
      );

      const documentIdsToDelete = conditionDocumentsToAssign.map((d) => d.id);
      setDocuments(
        documents.filter((p) => !documentIdsToDelete.includes(p.id))
      );
    } catch (e: any) {
      setError("Could not list documents");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const updateDocuments = async (
    documentsToUpdate: ConditionDocumentLineItem[]
  ): Promise<void> => {
    try {
      setIsDocumentLoading(true);
      const encodedDocuments = documentsToUpdate.map((d) =>
        encodeConditionDocument(d)
      );

      const response = await postBatchApiData(
        "updateConditionDocuments",
        "conditionDocument",
        encodedDocuments
      );

      const decodedDocuments = await Promise.all(
        response.data.map((d) =>
          decodeConditionDocument(d as never as ConditionDocument, getImageUrl)
        )
      );

      setDocuments(
        documents.map((c) => {
          const matchingDocument = decodedDocuments.find((d) => d.id === c.id);
          if (matchingDocument) {
            return matchingDocument;
          }
          return c;
        })
      );
    } catch (e: any) {
      setError("Could not update document");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  const deleteDocument = async (
    document: ConditionDocumentLineItem
  ): Promise<void> => {
    try {
      setIsDocumentLoading(true);
      await postApiData(
        "deleteConditionDocument",
        "conditionDocument",
        encodeConditionDocument(document)
      );
      setDocuments(documents.filter((p) => p.id !== document.id));
    } catch (e: any) {
      setError("Could not delete document");
    } finally {
      setIsDocumentLoading(false);
    }
  };

  React.useEffect(() => {
    if (conditionId) {
      listDocumentsForCondition(conditionId);
    } else if (permitId) {
      listDocumentsForPermit(permitId);
    } else if (projectId) {
      listDocumentsForProject(projectId);
    }
  }, [projectId, conditionId]);

  return {
    documents,
    createDocuments,
    updateDocuments,
    deleteDocument,
    assignDocumentsToConditions,
    unassignDocumentsToConditions,
    isDocumentLoading,
    error,
  };
};
