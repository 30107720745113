import { Container, styled } from "@mui/material";
import LoginForm from "@stories/organisms/LoginForm/LoginForm";
import logo from "assets/images/LogoActive.png";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export const Login = () => {
  return (
    <>
      <StyledRoot
        className="loginform"
        style={{
          padding: "40px",
          position: "relative",
          background:
            " linear-gradient(90deg, rgba(0, 32, 71, 1) 0%, rgba(0, 92, 209, 1) 61%)",
          height: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container
          style={{
            background: "white",
            borderRadius: "10px",
            maxWidth: "475px",
          }}
        >
          <StyledContent style={{ padding: "40px" }}>
            <img
              src={logo}
              alt="logo"
              style={{ margin: "0 auto", maxWidth: "210px" }}
            />
            {/* <h2>Sign in to PermitOne</h2> */}
            <LoginForm />
            {/* <div style={{ position: "absolute", bottom: "10px", left: "20px" }}>
              <a
                href="permitone.co"
                style={{ color: "#fff", fontSize: "14px" }}
                className="login-footer-link"
              >
                permitone.co
              </a>
            </div> */}
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
};
