import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import DashboardCard from "@stories/molecules/DashboardCard/DashboardCard";
import { ProjectLineItem } from "permit-one-common/src/interfaces/project";
import { MultiProgressBar } from "@stories/molecules/MultiProgressBar/MultiProgressBar";
import * as RouteHelper from "@utils/routes";
import { useNavigate } from "react-router-dom";
import { ConditionCountLineItem } from "permit-one-common/src/interfaces/condition";

interface ConditionSummaryProps {
  conditionCounts?: ConditionCountLineItem;
  project?: ProjectLineItem;
  permitId?: string;
  showHeader?: boolean;
  loading: boolean;
}
export const ConditionSummary = ({
  conditionCounts,
  project,
  permitId,
  showHeader,
  loading,
}: ConditionSummaryProps) => {
  const navigate = useNavigate();

  const assignedConditions = conditionCounts?.assigned || 0;
  const rejectedConditions = conditionCounts?.rejected || 0;
  //const notAssignedConditions = conditionCounts?.notAssigned || 0;
  const overdueConditions = conditionCounts?.overdue || 0;
  const inReviewConditions = conditionCounts?.inReview || 0;
  const approvedConditions = conditionCounts?.approved || 0;
  const notApplicableConditions = conditionCounts?.notApplicable || 0;

  const allConditions =
    assignedConditions +
    overdueConditions +
    rejectedConditions +
    //notAssignedConditions +
    overdueConditions +
    inReviewConditions +
    approvedConditions +
    notApplicableConditions;
  return (
    <>
      {showHeader ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "calc(100% - 538px)" }}>
            <Stack direction="row" spacing={1}>
              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography
                    sx={{
                      fontSize: "19px",
                      color: "rgb(31, 35, 40) !important",
                      margin: "0 0 10px",
                      fontWeight: 700,
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Condition Status{" "}
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  sx={{
                    fontSize: "19px",
                    color: "rgb(31, 35, 40) !important",
                    margin: "0 0 10px",
                    fontWeight: 700,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  Condition Status{" "}
                </Typography>
              )}

              {loading ? (
                <Skeleton animation="wave" variant="text">
                  <Typography
                    sx={{
                      fontSize: "19px",
                      color: "rgb(31, 35, 40) !important",
                      margin: "0 0 10px",
                      fontWeight: 700,
                    }}
                    color="text.secondary"
                    gutterBottom
                  >
                    <span className="task-number">(199)</span>
                  </Typography>
                </Skeleton>
              ) : (
                <Typography
                  sx={{
                    fontSize: "19px",
                    color: "rgb(31, 35, 40) !important",
                    margin: "0 0 10px",
                    fontWeight: 700,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  <span className="task-number">({allConditions})</span>
                </Typography>
              )}
            </Stack>
            {loading ? (
              <Skeleton animation="wave" variant="text">
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgb(101, 109, 118) !important",
                    margin: "-5px 0 30px",
                    fontWeight: 500,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  Summary of all Permit Conditions.
                </Typography>
              </Skeleton>
            ) : (
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "rgb(101, 109, 118) !important",
                  margin: "-5px 0 30px",
                  fontWeight: 500,
                }}
                color="text.secondary"
                gutterBottom
              >
                Summary of all Permit Conditions.
              </Typography>
            )}
          </div>
          <MultiProgressBar
            loading={loading}
            assigned={assignedConditions + rejectedConditions}
            assignedText="Assigned"
            overdue={overdueConditions}
            overdueText="Overdue"
            inReview={inReviewConditions}
            inReviewText="In Review"
            approved={approvedConditions}
            approvedText="Approved"
            notApplicable={notApplicableConditions}
            notApplicableText="Not Applicable"
            //notAssigned={notAssignedConditions}
            //notAssignedText="Not Assigned"
          />
        </div>
      ) : undefined}
      <Box
        className="dashb-cards"
        flexGrow="1"
        flexDirection={"row"}
        justifyContent="space-between"
        alignItems="center"
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={2.4} className="assigned">
            <DashboardCard
              loading={loading}
              title={"Assigned"}
              subTitle={(
                assignedConditions + rejectedConditions
              ).toLocaleString()}
              desc={"Current conditions requiring action and approval"}
              handleClick={() => {
                if (project) {
                  if (permitId) {
                    navigate(
                      `${RouteHelper.conditions(project.id, permitId)}?status=1`
                    );
                  } else {
                    navigate(
                      `${RouteHelper.allConditions(project?.id || "")}?status=1`
                    );
                  }
                }
              }}
            />
          </Grid>
          {/*<Grid item xs={6} md={2.4} className="overdue">
            <DashboardCard
              loading={loading}
              title={"Overdue"}
              subTitle={overdueConditions.toLocaleString()}
              desc={"Assigned conditions requiring urgent action"}
              handleClick={() => {
                if (project) {
                  if (permitId) {
                    navigate(
                      `${RouteHelper.conditions(project.id, permitId)}?status=2`
                    );
                  } else {
                    navigate(
                      `${RouteHelper.allConditions(project?.id || "")}?status=2`
                    );
                  }
                }
              }}
            />
          </Grid>*/}
          <Grid item xs={6} md={2.4} className="inreview">
            <DashboardCard
              loading={loading}
              title={"In Review"}
              subTitle={inReviewConditions.toLocaleString()}
              desc={"Actioned and pending review by "}
              handleClick={() => {
                if (project) {
                  if (permitId) {
                    navigate(
                      `${RouteHelper.conditions(project.id, permitId)}?status=3`
                    );
                  } else {
                    navigate(
                      `${RouteHelper.allConditions(project?.id || "")}?status=3`
                    );
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={2.4} className="approved">
            <DashboardCard
              loading={loading}
              title={"Approved"}
              subTitle={approvedConditions.toLocaleString()}
              desc={"Conditions approved with no further action required"}
              handleClick={() => {
                if (project) {
                  if (permitId) {
                    navigate(
                      `${RouteHelper.conditions(project.id, permitId)}?status=4`
                    );
                  } else {
                    navigate(
                      `${RouteHelper.allConditions(project?.id || "")}?status=4`
                    );
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={6} md={2.4} className="overdue">
            <DashboardCard
              loading={loading}
              title={"Overdue"}
              subTitle={overdueConditions.toLocaleString()}
              desc={"Assigned conditions requiring urgent action"}
              handleClick={() => {
                console.log("Not implemented");
              }}
            />
          </Grid>
          <Grid item xs={6} md={2.4} className="notapplicable">
            <DashboardCard
              loading={loading}
              title={"Not Applicable"}
              subTitle={notApplicableConditions.toLocaleString()}
              desc={"Condition that are not applicable to this checklist"}
              handleClick={() => {
                console.log("Not implemented");
              }}
            />
          </Grid>
          {/*<Grid item xs={6} md={2.4} className="notassigned">
            <DashboardCard
              loading={loading}
              title={"Not Assigned"}
              subTitle={notAssignedConditions.toLocaleString()}
              desc={"Current conditions requiring assignment for action"}
              handleClick={() => {
                if (project) {
                  if (permitId) {
                    navigate(
                      `${RouteHelper.conditions(project.id, permitId)}?status=1`
                    );
                  } else {
                    navigate(
                      `${RouteHelper.allConditions(project?.id || "")}?status=0`
                    );
                  }
                }
              }}
            />
            </Grid>*/}
        </Grid>
      </Box>
    </>
  );
};
