import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAuthContext } from "@hooks/context/useAuthContext";
import { useState } from "react";
import {
  AppSnackbar,
  SnackBarMessage,
} from "@stories/molecules/AppSnackbar/AppSnackbar";

export default function LoginForm() {
  const { authenticate, isAuthLoading } = useAuthContext();
  const [message, setMessage] = useState<SnackBarMessage | null>(null);

  const validationSchema = yup.object({
    email: yup.string().email().required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setMessage(null);
      const res = await authenticate(values.email.toLowerCase());
      if (!res.authenticated) {
        if (res.error) {
          setMessage({
            info: res.error,
            intent: "error",
          });
        } else {
          setMessage({
            info: "Something went wrong",
            intent: "error",
          });
        }
      } else {
        setMessage({
          info: "Login link sent to email",
          intent: "success",
        });
      }
    },
  });

  return (
    <>
      <Stack spacing={5}>
        <form onSubmit={formik.handleSubmit} style={{ textAlign: "center" }}>
          <TextField
            id="email"
            name="email"
            label="Email address"
            disabled={isAuthLoading}
            fullWidth
            margin="dense"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email ? formik.errors.email : ""}
            sx={{ margin: "32px 0 30px" }}
            placeholder="Email"
          />

          <LoadingButton
            className="signin-btn"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isAuthLoading}
            disabled={isAuthLoading}
            sx={{
              background: "#0054D1 !important",
              opacity: "1",
              "&:hover": {
                background: "#05214B !important",
              },
            }}
          >
            Sign In
          </LoadingButton>
        </form>
        {/* <Link href="/sign-up" variant="body2" style={{ textAlign: "center" }}>
          {"Don't have an account? Sign Up"}
        </Link> */}
      </Stack>
      {message ? (
        <AppSnackbar
          open={!!message}
          handleClose={() => {
            setMessage(null);
          }}
          message={message}
        />
      ) : null}
    </>
  );
}
