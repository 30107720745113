import { useAuthContext } from "@hooks/context/useAuthContext";
import { PageLoader } from "@stories/atoms/Loaders/PageLoader/PageLoader";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as RouteHelper from "@utils/routes";
import { browserStorage } from "permit-one-common/src/utils/browserStorage";

export const Verify = () => {
  const { answerCustomChallenge } = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    const verify = async () => {
      if (!isSubmitting) {
        setIsSubmitting(true);
        const params = new URLSearchParams(location.search);
        const [email, answer, isInvite] = [
          params.get("email") || "",
          params.get("code") || "",
          params.get("invite"),
        ];

        if (isInvite) {
          browserStorage.setItem("isInvite", isInvite);
        } else {
          browserStorage.removeItem("isInvite");
        }
        try {
          const res = await answerCustomChallenge(email, answer);
          if (res) {
            navigate(RouteHelper.home);
          }
        } catch (e) {
          alert("Invalid login link!");
          navigate(RouteHelper.home);
        }
        setIsSubmitting(false);
      }
    };
    if (!isSubmitting) {
      verify();
    }
  }, []);
  return (
    <>
      <PageLoader />
    </>
  );
};
