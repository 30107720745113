import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { ConditionLineItem } from "permit-one-common/src/interfaces/condition";
import EditableTableRow from "./EditableTableRow";
import EditableTableRowSkeleton from "./EditableTableRowSkeleton";
import { NoRows } from "@stories/molecules/ListTable/NoRows";
import { TableHeader } from "../TableHeader/TableHeader";
import { PermitLineItem } from "permit-one-common/src/interfaces/permit";

interface EditableTableProps {
  loading: boolean;
  permit?: PermitLineItem;
  conditions: ConditionLineItem[];
  setData: (data: ConditionLineItem[]) => void;
  handleEditCondition: (condition: ConditionLineItem) => void;
  handleDeleteCondition: (condition: ConditionLineItem) => void;
  handleOpenConditionEditModal: (condition: ConditionLineItem) => void;
}

function EditableTable({
  loading,
  permit,
  conditions,
  setData,
  handleEditCondition,
  handleDeleteCondition,
  handleOpenConditionEditModal,
}: EditableTableProps) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeader loading={loading} title={"Item"} width={"5%"} />
            <TableHeader loading={loading} title={"Condition"} width={"20%"} />
            <TableHeader loading={loading} title={"Comments"} width={"20%"} />
            <TableHeader loading={loading} title={"Discipline"} width={"20%"} />
            <TableHeader
              loading={loading}
              title={"Assignee Notes"}
              width={"20%"}
            />
            <TableHeader loading={loading} title={"Status"} width={"5%"} />
            <TableHeader loading={loading} title={"Actions"} width={"5%"} />
            <TableHeader loading={loading} title={""} width={"5%"} />
          </TableRow>
        </TableHead>
        <TableBody>
          {loading || !permit
            ? Array.from(new Array(10)).map((_, index) => (
                <EditableTableRowSkeleton key={index} />
              ))
            : null}
          {!loading && permit
            ? conditions.map((row) => (
                <EditableTableRow
                  key={row.id}
                  permit={permit}
                  condition={row}
                  conditions={conditions}
                  setData={setData}
                  handleOpenConditionEditModal={handleOpenConditionEditModal}
                  handleEditCondition={handleEditCondition}
                  handleDeleteCondition={handleDeleteCondition}
                  loading={loading}
                />
              ))
            : null}
          {!loading && conditions.length === 0 ? (
            <NoRows title={"Conditions"} colSpan={7} />
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EditableTable;
