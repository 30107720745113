// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Toolbar,
  Typography,
  OutlinedInput,
  InputAdornment,
  Button,
  Stack,
} from "@mui/material";
import Iconify from "@stories/atoms/Iconify/Iconify";
import React from "react";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
const transparent = 10;

export const StyledRoot = styled(Toolbar)(({ theme }) => ({

  display: "flex",
  justifyContent: "space-between",
  padding: "0 !important",
  minHeight:'auto !important'
}));

export const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 320,
    boxShadow: `0 8px 16px 0 ${transparent}`,
  },
}));

// ----------------------------------------------------------------------

interface TableToolbarProps {
  loading: boolean;
  buttonTitle?: string;
  searchTitle?: string;
  numSelected: number;
  filterName: string;
  altControl?: React.ReactNode;
  handleOpen: () => void;
  onFilter: (event: any) => void;
  clearSelected: () => void;
}

export default function ListTableToolbar({
  loading,
  buttonTitle,
  searchTitle,
  numSelected,
  filterName,
  altControl,
  handleOpen,
  onFilter,
  clearSelected,
}: TableToolbarProps) {
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
          padding: "0px !important",
          alignItems:'flex-end',
          
        }),
      }}
    >
      {numSelected > 0 ? (
        <Stack direction="row"  sx={{display:'flex', justifyContent:'space-between',alignItems:'flex-end', width:'90%'}}>
          <Typography
            component="div"
            variant="subtitle1"
            sx={{ paddingTop: "30px" , color:'rgb(102, 102, 102)'}}
          >
            {numSelected} selected
          </Typography>
          <Button color="error" variant="contained" onClick={clearSelected} className="outlined-btn">
            Clear
          </Button>
        </Stack>
      ) : searchTitle ? (
        <div className="search">
          {/* <StyledSearch
              value={filterName}
              onChange={onFilter}
              placeholder={searchTitle}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{  width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              /> */}
          <StyledTextField
            style={{ width: "100%", minWidth: "300px" }}
            className="search"
            loading={loading}
            type="text"
            placeholder={searchTitle}
            name="filterProject"
            value={filterName}
            onChange={onFilter}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      ) : null}

      {numSelected > 0 ? (
        altControl
      ) : buttonTitle ? (
        <StyledButton
          loading={false}
          onClick={handleOpen}
          variant="contained"
          disabled={loading}
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          {buttonTitle}
        </StyledButton>
      ) : null}
    </StyledRoot>
  );
}
