"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encodeProfileType = exports.decodeProfileType = exports.encodeProfile = exports.decodeProfile = void 0;
var profile_1 = require("../interfaces/profile");
var string_1 = require("../utils/string");
var decodeProfile = function (profile, getImageUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var safeProfile, presignedUrl, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                safeProfile = (0, string_1.reversePostgresSafe)(profile);
                if (!(safeProfile.photo_url && safeProfile.photo_url.length > 0)) return [3 /*break*/, 2];
                return [4 /*yield*/, getImageUrl(safeProfile.photo_url)];
            case 1:
                _a = _b.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = "";
                _b.label = 3;
            case 3:
                presignedUrl = _a;
                return [2 /*return*/, {
                        id: safeProfile.profile_id,
                        organisationId: safeProfile.organisation_id,
                        email: safeProfile.email,
                        jobTitle: safeProfile.job_title,
                        firstName: safeProfile.first_name,
                        lastName: safeProfile.last_name,
                        name: "".concat(safeProfile.first_name, " ").concat(safeProfile.last_name),
                        photoUrl: presignedUrl,
                        organisationName: safeProfile.organisation_name,
                        originalPhotoUrl: safeProfile.photo_url || "",
                        phoneNumber: safeProfile.phone_number,
                        created: new Date(safeProfile.created),
                        invited: safeProfile.invited,
                        isAdmin: safeProfile.is_admin,
                        taskNotifications: safeProfile.task_notifications,
                        messageNotifications: safeProfile.message_notifications,
                    }];
        }
    });
}); };
exports.decodeProfile = decodeProfile;
var encodeProfile = function (profile) {
    var safeProfile = (0, string_1.makePostgresSafe)(profile);
    return {
        profile_id: safeProfile.id,
        organisation_id: safeProfile.organisationId,
        organisation_name: safeProfile.organisationName,
        email: safeProfile.email,
        job_title: safeProfile.jobTitle,
        first_name: safeProfile.firstName,
        last_name: safeProfile.lastName,
        photo_url: safeProfile.originalPhotoUrl || "",
        invited: safeProfile.invited,
        phone_number: safeProfile.phoneNumber,
        created: safeProfile.created,
        is_admin: safeProfile.isAdmin,
        task_notifications: safeProfile.taskNotifications,
        message_notifications: safeProfile.messageNotifications,
    };
};
exports.encodeProfile = encodeProfile;
var decodeProfileType = function (profileType) {
    switch (profileType) {
        case "profile-type_admin":
            return profile_1.ProfileType.Admin;
        default:
        case "profile-type_standard":
            return profile_1.ProfileType.Standard;
    }
};
exports.decodeProfileType = decodeProfileType;
var encodeProfileType = function (profileType) {
    switch (profileType) {
        case profile_1.ProfileType.Standard:
            return "profile-type_standard";
        case profile_1.ProfileType.Admin:
            return "profile-type_admin";
    }
};
exports.encodeProfileType = encodeProfileType;
